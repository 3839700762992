<template>
  <svg
    width="640"
    height="637"
    viewBox="0 0 640 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      opacity="0.5"
      cx="483"
      cy="622.5"
      rx="27"
      ry="4.5"
      fill="#DFD28F"
    />
    <path d="M469.72 537.65H477.313V529.355H469.72V537.65Z" fill="#7486E8" />
    <mask
      id="mask0_4829_11644"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="469"
      y="529"
      width="9"
      height="9"
    >
      <path d="M477.314 537.65L469.721 529.355H477.314V537.65Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4829_11644)">
      <path
        d="M469.721 537.65H477.314V529.355H469.721V537.65Z"
        fill="#3C51C3"
      />
    </g>
    <path d="M478.182 530.709H468.852V528H478.182V530.709Z" fill="#3C51C3" />
    <path
      d="M484.033 622.791C486.242 622.791 488.033 621 488.033 618.791V553.197C488.033 547.217 483.185 542.368 477.205 542.368H469.829C463.848 542.368 459 547.217 459 553.197V618.791C459 621 460.791 622.791 463 622.791H484.033Z"
      fill="#7486E8"
    />
    <path
      d="M484.051 544.711V537.364C484.051 535.663 482.672 534.285 480.973 534.285H466.061C464.36 534.285 462.981 535.663 462.981 537.364V544.711H484.051Z"
      fill="#3C51C3"
    />
    <path
      d="M472.835 567C471.683 567 470.661 565.941 469.89 564.886C468.021 562.327 463.269 554.516 465.656 551.07C466.244 550.224 467.149 550 468.068 550C468.669 550 469.276 550.094 469.807 550.176C471.751 550.47 474.357 551.357 471.807 553.336C470.682 554.21 470.487 556.117 470.918 557.613C471.348 559.111 472.249 560.346 472.97 561.681C473.689 563.018 474.248 564.63 473.887 566.152C473.825 566.408 473.73 566.67 473.541 566.819C473.428 566.907 473.294 566.948 473.161 566.97C473.05 566.991 472.943 567 472.835 567Z"
      fill="#8B9AE2"
    />
  </svg>
</template>
<script>
export default {
  name: "ExerciseClassOverlay"
};
</script>
