import SchemaService from "@/services/question-schema/schema-service.js";
import AnswerConstants from "@/components/common/assessments/answer-constants";
import sentry from "@/sentry";
import { getSchema } from "../assessment/get-schema-service";
import { assessmentTypes } from "@/constants/constants";

const driverAssessmentSchema = getSchema(assessmentTypes.driverAssessment);

const TOTAL_HABITS_SCORE = 3;

const questionsByTheme = driverAssessmentSchema.reduce(function (acc, item) {
  acc[item.theme] = {};
  switch (item.theme) {
    case "drivingPosture":
    case "habits":
      item.questions.forEach(question => {
        acc[item.theme][question.name] = question;
      });
      break;
  }
  return acc;
}, {});

export default {
  getOverallScore(results) {
    let postureScore = this.getPostureScore(results);
    let habitsScore = this.getHabitsScore(results);
    const score = (postureScore + habitsScore) / 2;
    return Math.round(score);
  },
  getPostureScore(results) {
    const totalAnsweredQuestions = Object.keys(results.drivingPosture).length;
    if (!totalAnsweredQuestions) {
      sentry.captureMessage(
        "Tried to get Driver Posture score but no questions have been answered"
      );
      return 0;
    }

    let totalScore = SchemaService.getScore(
      questionsByTheme.drivingPosture,
      results.drivingPosture
    );

    return getPercentageScore(totalScore, totalAnsweredQuestions);
  },
  getHabitsScore(results) {
    let currentScore = 0;

    const longJourney =
      results.habits.journeyLength ===
      AnswerConstants.DRIVER.JOURNEY_LENGTH.MORE_THAN_2_HOURS;

    const noBreaks =
      results.habits.takeBreaks === AnswerConstants.YES_NO_QUESTION.NO;

    const insufficientBreaks =
      !noBreaks &&
      results.habits.driveLength >
        AnswerConstants.DRIVER.DRIVE_LENGTH.TWO_HOURS &&
      results.habits.breakLength ===
        AnswerConstants.DRIVER.BREAK_LENGTH.LESS_THAN_FIFTEEN_MINS;

    const inactiveBreaks =
      results.habits.breaksExercise === AnswerConstants.YES_NO_QUESTION.NO;

    if (longJourney) {
      if (noBreaks) {
        currentScore += 2;
      } else {
        currentScore += insufficientBreaks ? 1 : 0;
        currentScore += inactiveBreaks ? 1 : 0;
      }
    }

    const schemaScore = SchemaService.getScore(
      questionsByTheme.habits,
      results.habits
    );

    const totalScore = currentScore + schemaScore;
    return getPercentageScore(totalScore, TOTAL_HABITS_SCORE);
  }
};

function getPercentageScore(scoreValue, totalPossibleScore) {
  if (scoreValue === 0) {
    return 100;
  }
  const percentage = (1 - scoreValue / totalPossibleScore) * 100;
  return Math.round(percentage);
}
