<template>
  <v-dialog v-model="dialog" max-width="500">
    <section class="dialog-container d-flex flex-column">
      <header class="d-flex py-2 pl-8 pr-3 align-center">
        <h6 class="text-h6">
          {{
            isEditMode
              ? $t("userProfile.equipment.editEquipment")
              : $t("userProfile.equipment.addEquipment")
          }}
        </h6>
        <vfl-icon-button
          variant="ghost"
          class="ml-auto"
          @click="close"
          :screen-reader="$t('buttons.close')"
        >
          <v-icon>mdi-close</v-icon>
        </vfl-icon-button>
      </header>
      <user-profile-add-equipment
        class="px-8 pt-8 pb-4"
        :edit-mode="isEditMode"
        :default-location="defaultLocation"
      />
    </section>
  </v-dialog>
</template>

<script>
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";
import UserProfileAddEquipment from "@/components/user-profile/user-profile-equipment/UserProfileAddEquipment.vue";
import { mapActions, mapGetters } from "vuex";
import { assessmentLocations } from "@/constants/constants.js";

export default {
  name: "UserProfileAddEquipmentDialog",
  components: { UserProfileAddEquipment, VflIconButton },
  props: {
    defaultLocation: {
      type: String,
      default: assessmentLocations.office
    }
  },
  computed: {
    ...mapGetters("userProfile/equipment", [
      "equipmentDialogOpen",
      "editingEquipment",
      "equipmentDialogLocation"
    ]),
    dialog: {
      get() {
        return this.equipmentDialogOpen;
      },
      set(value) {
        if (!value) this.closeEquipmentDialog();
      }
    },
    isEditMode() {
      return !!this.editingEquipment;
    }
  },
  methods: {
    ...mapActions("userProfile/equipment", ["closeEquipmentDialog"]),
    close() {
      this.closeEquipmentDialog();
    }
  }
};
</script>

<style scoped lang="scss">
.dialog-container {
  background-color: white;
}

header {
  border-bottom: 1px solid var(--v-vflNeutralLight2-base);
}
</style>
