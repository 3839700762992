<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M610 610.344C610 619.55 490.736 623 346.024 623C201.312 623 84 615.537 84 606.331C84 597.126 201.341 596 346.053 596C490.765 596 610 601.139 610 610.344Z"
      fill="#EAE0AD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M463.058 232.025C454.517 236.448 446.286 238.32 442.558 237.098C433.209 234.033 434.583 199.197 442.825 185.961C451.067 172.726 489.269 169.194 489.42 196.483C489.472 205.954 484.969 214.112 478.676 220.6L486.005 270.162L455.539 268.193L463.058 232.025Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M480.622 226.866C485.546 210.102 497.188 199.21 496.016 191.499C494.844 183.788 487.759 181.469 487.759 181.469C487.759 181.469 485.007 166.154 464.552 167.092C444.098 168.031 431.157 175.664 435.31 194.569C440.231 194.887 446.287 193.491 454.304 197.071C458.12 198.775 459.315 206.433 459.315 206.433L463.027 206.672C463.027 206.672 469.01 198.063 474.109 200.777C479.208 203.491 475.751 212.467 475.751 212.467L476.673 226.611L480.622 226.866Z"
      fill="#1D292B"
    />
    <path
      d="M430.957 263.541C429.636 265.549 421.708 280.621 417.744 295.481C413.78 310.341 402.053 320.382 401.613 321.586C401.173 322.791 394.582 322.447 380.645 326.297C369.065 329.497 357.268 330.22 357.268 330.22L358.165 340.548C358.165 340.548 394.683 341.617 402.055 340.462C413.387 338.688 417.03 332.029 421.876 326.004C426.72 319.98 450.505 277.811 450.505 277.811L430.957 263.541Z"
      fill="#B28B67"
    />
    <path
      d="M336.274 333.402C335.934 332.311 331.187 329.528 330.987 329.613C330.787 329.698 328.17 330.083 326.279 331.853C324.387 333.622 321.904 336.308 321.829 337.439C321.754 338.57 322.979 338.849 324.986 336.87C326.992 334.892 329.63 332.918 330.807 332.804C331.985 332.692 336.274 333.402 336.274 333.402Z"
      fill="#EAAD96"
    />
    <path
      d="M362.594 340.549C362.213 338.548 361.82 336.549 361.449 334.545C361.242 333.43 362.359 331.177 362.137 330.063C356.398 330.529 351.444 329.913 349.031 329.305C342.647 327.694 331.941 327.887 329.046 330.809C327.518 332.351 325.47 334.271 324.02 336.697C322.722 338.869 322.559 340.044 322.454 340.909C322.23 342.739 322.446 344.574 323.838 343.883C325.23 343.192 325.948 339.876 326.853 338.815C327.758 337.755 330.478 335.685 330.478 335.685C330.478 335.685 327.843 338.845 327.148 340.442C326.453 342.039 325.225 345.357 326.831 346.379C327.889 347.052 328.441 344.867 329.215 342.706C329.612 341.599 330.621 339.982 332.104 338.639C333.519 337.358 335.418 336.323 335.418 336.323C335.418 336.323 332.401 340.985 332.094 342.128C331.789 343.271 330.602 345.057 331.722 345.881C332.542 346.486 334.066 345.373 335.271 342.403C336.212 340.082 340.264 338.639 340.264 338.639C343.377 337.695 345.549 337.097 345.625 338.327C345.669 339.046 345.444 340.271 345.14 340.754C344.455 341.839 343.447 342.484 342.438 343.041C341.411 343.608 339.92 343.934 339.395 344.357C337.89 345.569 338.553 346.258 339.748 346.505C340.281 346.615 342.127 346.31 343.022 346.067C344.296 345.722 344.174 345.88 345.997 345.491C350.478 344.533 351.039 342.356 353.755 341.465C353.765 341.463 359.706 340.724 362.594 340.549Z"
      fill="#B28B67"
    />
    <path
      d="M476.822 464.648H462.573L490.385 609.811H497.644L476.822 464.648Z"
      fill="#3C5555"
    />
    <path
      d="M508.184 450.195L495.649 464.647L523.461 609.809H530.721L508.184 450.195Z"
      fill="#3C5555"
    />
    <path
      d="M410.681 464.648H424.93L397.118 609.811H389.858L410.681 464.648Z"
      fill="#3C5555"
    />
    <path
      d="M379.318 450.195L391.852 464.647L364.042 609.809H356.782L379.318 450.195Z"
      fill="#3C5555"
    />
    <path
      d="M541.314 298.152C534.356 285.708 515.495 286.44 515.495 286.44L460.161 299.877C460.161 299.877 437.081 338.993 432.618 359.248C431.073 366.258 432.717 419.487 421.746 437.271C408.681 458.453 366.889 431.456 358.187 445.8C353.09 454.202 362.03 467.63 377.031 468.529C388.424 469.212 458.602 468.93 496.052 468.93C507.516 468.93 526.181 469.946 531.25 456.134C534.896 446.198 543.117 377.109 543.941 334.151C544.271 316.968 544.299 303.494 541.314 298.152Z"
      fill="#064546"
    />
    <path
      d="M501.882 454.994C511.058 454.994 516.793 451.824 520.521 440.496C525.14 426.462 544.016 316.011 536.044 297.522C526.954 276.441 467.222 285.018 447.521 297.522C431.089 307.949 429.293 338.067 424.83 358.32C421.067 375.404 416.041 437.141 414.561 456.051C445.713 456.133 472.323 454.994 501.882 454.994Z"
      fill="#064546"
    />
    <path
      d="M344.217 574.948C344.217 574.948 342.537 595.527 343.098 598.913C343.286 600.043 344.14 602.354 344.305 603.481C344.525 604.981 345.004 610.348 343.777 611.048H327.234H299.519L297.416 606.522C296.86 603.446 301.274 601.645 303.832 600.577C309.193 598.337 317.922 592.839 317.922 592.839L321.25 571.723L344.217 574.948Z"
      fill="#393A45"
    />
    <path
      d="M298.796 611.046C298.796 611.046 296.929 608.108 297.416 606.52L344.575 605.789C344.575 605.789 345.233 610.22 343.775 611.046H298.796Z"
      fill="white"
    />
    <path
      d="M322.392 451.937C323.716 435.285 334.845 422.979 347.386 417.462C364.879 409.766 391.565 402.752 395.502 400.449L418.611 393.822L447.379 394.853C447.379 394.853 446.348 433.195 434.181 444.762C431.279 447.522 422.787 452.818 411.177 454.02C386.398 456.587 364.096 458.146 364.096 458.146C364.096 458.146 357.661 485.459 353.537 518.547C347.744 565.009 343.74 577.095 343.74 577.095L319.177 576.466C318.465 576.14 317.7 566.76 318.941 517.281C319.468 496.232 320.869 471.099 322.392 451.937Z"
      fill="#426557"
    />
    <path
      d="M418.612 393.82L395.502 400.447C392.513 402.196 382.127 405.339 362.99 412.191C375.97 424.368 375.175 439.038 381.3 454.962C393.226 455.023 399.175 455.262 411.177 454.019C422.787 452.818 431.28 447.521 434.182 444.762C446.349 433.195 447.38 394.853 447.38 394.853L418.612 393.82Z"
      fill="#426557"
    />
    <path
      d="M389.86 456.062L350.166 459.398"
      stroke="#345045"
      stroke-width="1.284"
      stroke-miterlimit="10"
    />
    <path
      d="M400.51 581.467C400.51 581.467 399.405 595.524 399.597 598.911C399.662 600.041 400.264 602.352 400.306 603.48C400.363 604.979 400.381 610.346 399.077 611.046H379.458H358.038L356.428 606.52C356.207 603.445 360.51 600.186 363.185 599.119C368.79 596.88 372.103 593.887 372.103 593.887L379.591 573.277L400.51 581.467Z"
      fill="#393A45"
    />
    <path
      d="M357.315 611.046C357.315 611.046 355.768 608.108 356.427 606.52L400.325 605.789C400.325 605.789 400.624 610.22 399.076 611.046H357.315Z"
      fill="white"
    />
    <path
      d="M382.443 447.62C383.841 442.395 387.756 437.218 393.052 432.29C411.27 415.339 445.833 401.352 450.507 398.617L480.129 393.82L508.897 394.851C508.897 394.851 509.376 430.706 497.208 442.272C494.307 445.031 485.814 450.328 474.204 451.53C449.426 454.096 420.098 456.106 420.098 456.106C420.098 456.106 418.072 485.526 412.152 518.972C404.632 561.459 400.347 583.605 400.347 583.605L372.101 580.371C372.101 580.371 373.717 543.406 376.122 506.467C377.567 484.305 378.372 462.833 382.443 447.62Z"
      fill="#426557"
    />
    <path
      d="M372.103 580.374C373.176 565.1 375.74 524.344 377.82 492.313C379.009 474.011 378.862 458.569 381.162 452.325C382.832 447.793 387.855 438.071 392.858 433.099C404.63 421.403 424.93 411.619 424.93 411.619"
      stroke="#345045"
      stroke-width="1.284"
      stroke-miterlimit="10"
    />
    <path
      d="M440.501 454.863L412.152 456.589"
      stroke="#345045"
      stroke-width="1.284"
      stroke-miterlimit="10"
    />
    <path
      d="M426 382.001L419 395.001C427.833 400.334 451.5 411.301 475.5 412.501C505.5 414.001 494.5 399.501 491 390.501C488.2 383.301 446.5 381.834 426 382.001Z"
      fill="#426557"
    />
    <path
      d="M473.5 229.002C472.957 236.872 474.46 238.197 477.5 243.002C482.551 250.984 498.5 255.002 498.5 255.002C498.5 255.002 452.36 295.246 436.573 294.069C420.789 292.892 445.855 252.578 445.855 252.578C445.855 252.578 454.821 249.641 456.5 245.501C458.179 241.361 459 231.501 459 231.501L473.5 229.002Z"
      fill="#B28B67"
    />
    <path
      d="M435.287 320.431C440.015 311.641 444.232 303.273 446.7 298.199C453.478 284.266 436.614 257.375 436.614 257.375C436.614 257.375 426.054 262.134 420.339 275.981C417.638 282.527 411.604 300.273 407.16 313.391C416.713 314.62 426.136 316.951 435.287 320.431Z"
      fill="#D9C19D"
    />
    <path
      d="M485.689 249.002C485.689 249.002 505.825 253.953 513.118 258.967C521.313 264.601 518.855 283.312 517.904 292.309C508.905 295.998 503.275 340.669 502 369.502C501.615 378.199 508.905 414.002 508.905 414.002C496.731 411.906 485.909 400.182 450.099 400.067C433.482 400.012 421.993 397.035 415 392.569C415 392.569 424.855 371.371 426.898 351.502C430.016 321.195 424.041 317.176 426.898 295.998C428.964 280.687 435.966 257.321 435.966 257.321L447.553 251.507C449.409 254.715 452.591 255.489 454.265 255.842C476.165 260.45 485.689 249.002 485.689 249.002Z"
      fill="#FCD497"
    />
    <path
      d="M388.187 335.076C392.8 330.464 401.029 328.959 405.928 328.705C410.828 328.45 423.828 331.93 431.527 333.289C439.226 334.647 454.263 331.19 454.263 331.19L464.387 342.954C464.387 342.954 442.725 344.624 436.912 346.268C431.994 347.659 430.159 349.187 424.729 349.228C419.301 349.27 411.176 344.028 409.203 343.73C407.231 343.433 402.882 344.323 400.464 345.775C398.993 346.66 397.442 347.441 396.523 347.41C395.593 347.379 395.665 345.507 397.392 344.698C397.004 344.937 395.976 345.522 395.604 345.778C395.069 346.146 394.422 346.753 394.093 346.97C391.835 348.464 390.85 347.948 391.878 345.838C392.226 345.126 393.104 343.966 394.206 343.014C396.363 341.148 399.51 339.359 399.51 339.359C399.51 339.359 393.211 341.882 391.43 344.491C389.648 347.1 387.05 348.212 386 347.609C384.95 347.004 389.299 340.972 391.208 339.276C393.117 337.58 396.804 335.615 396.804 335.615C396.804 335.615 394.102 336.244 392.247 337.039C390.391 337.834 384.653 342.826 382.671 342.708C380.69 342.594 383.573 339.688 388.187 335.076Z"
      fill="#B28B67"
    />
    <path
      d="M511 268.002C511 268.002 512.431 293.283 514 307.502C511 322.002 508.386 332.048 506.555 334.728C504.723 337.408 497.323 339.253 486.598 340.613C475.874 341.972 446.669 344.628 446.669 344.628L442.259 333.207C452.651 331.383 468.701 325.998 474.569 325.183C480.437 324.368 482.556 323.717 482.556 323.717C482.556 323.717 502.592 294.682 501.027 283.062C499.462 271.441 497.174 267.596 503.347 264.173C509.521 260.75 510.296 264.013 511 268.002Z"
      fill="#B28B67"
    />
    <path
      d="M484.465 312.09C495.509 312.149 506.382 313.912 517.099 316.865C520.171 303.457 523.282 287.783 523.333 281.332C523.5 260.025 513.529 258.729 512.359 257.054C511.189 255.379 493.149 251.913 486.909 290.446C486.389 295.707 485.411 304.16 484.465 312.09Z"
      fill="#FCDFB3"
    />
    <path
      d="M454.782 351.686H334.583V350.108C334.583 348.129 336.187 346.525 338.165 346.525H451.201C453.18 346.525 454.783 348.129 454.783 350.108V351.686H454.782Z"
      fill="#A0A2AF"
    />
    <path
      d="M413.144 330.081L406.347 331.274L390.893 212.064L395.573 213.505C397.892 214.219 399.642 216.358 400.076 219.008L418.337 321.873C418.972 325.76 416.625 329.47 413.144 330.081Z"
      fill="#B4B6C7"
    />
    <path
      d="M406.347 331.275L261.723 331.623C259.427 331.535 257.495 329.672 257.074 327.142L239.748 217.894C239.18 214.481 241.545 211.343 244.642 211.399L390.891 212.063C393.288 212.107 395.305 214.092 395.655 216.751L411.355 324.986C411.802 328.377 409.401 331.39 406.347 331.275Z"
      fill="#D4D5E0"
    />
    <path
      d="M343.649 293.912H311.507C309.136 293.912 307.118 292.189 306.747 289.847L303.66 270.379C303.197 267.452 305.458 264.805 308.42 264.805H340.562C342.932 264.805 344.951 266.528 345.322 268.87L348.409 288.338C348.872 291.264 346.611 293.912 343.649 293.912Z"
      fill="#B4B6C7"
    />
    <path
      d="M332.863 347.006L294.982 347.198L309.214 349.567L350.89 349.055L332.863 347.006Z"
      fill="#D0D0DA"
    />
    <path
      d="M332.863 351.636H294.982V347.198L332.863 347.006V351.636Z"
      fill="#B4B6C7"
    />
    <path
      d="M332.862 351.636L351.082 351.664L350.89 349.055L332.862 347.006V351.636Z"
      fill="#B4B6C7"
    />
    <path
      d="M339.487 347.68L332.528 347.068L298.96 347.201L314.928 271.672H335.753L338.455 280.788L339.487 347.68Z"
      fill="#B4B6C7"
    />
    <path
      d="M332.528 347.068L295.097 347.201L314.928 271.672H335.753L332.528 347.068Z"
      fill="#D4D5E0"
    />
    <path
      d="M295.097 347.201L314.928 271.672"
      stroke="#B4B6C7"
      stroke-width="1.284"
      stroke-miterlimit="10"
    />
    <path d="M506.766 374.145H498.085V609.81H506.766V374.145Z" fill="#B48669" />
    <path d="M558.082 374.145H505.595V609.81H558.082V374.145Z" fill="#9E765C" />
    <path
      d="M529.142 361.977H481.895V374.852H529.142V361.977Z"
      fill="#9E765C"
    />
    <path
      d="M571.449 361.977H502.331V374.852H571.449V361.977Z"
      fill="#957057"
    />
    <path d="M128.65 374.145H119.969V609.81H128.65V374.145Z" fill="#B48669" />
    <path d="M179.966 374.145H127.479V609.81H179.966V374.145Z" fill="#9E765C" />
    <path
      d="M151.026 361.977H103.778V374.852H151.026V361.977Z"
      fill="#9E765C"
    />
    <path
      d="M193.332 361.977H124.214V374.852H193.332V361.977Z"
      fill="#957057"
    />
    <path d="M550.691 351.273H67.624V364.149H550.691V351.273Z" fill="#B48669" />
    <path
      d="M617.501 351.273H530.667V364.149H617.501V351.273Z"
      fill="#9E765C"
    />
    <path
      d="M260 138.408H43.7098C42.7655 138.408 42 139.174 42 140.118V147.188C42 148.132 42.7655 148.898 43.7098 148.898H260V138.408Z"
      fill="#B48669"
    />
    <path
      d="M258.289 97.1855H251.22C250.275 97.1855 249.51 97.951 249.51 98.8953V148.896H259.999V98.8953C259.999 97.951 259.234 97.1855 258.289 97.1855Z"
      fill="#B48669"
    />
    <path
      d="M101.684 138.408H63.8916C63.4898 138.408 63.1641 137.951 63.1641 137.386V129.071C63.1641 128.507 63.4898 128.049 63.8916 128.049H101.684C102.086 128.049 102.412 128.507 102.412 129.071V137.386C102.412 137.95 102.086 138.408 101.684 138.408Z"
      fill="#FF454D"
    />
    <path
      d="M96.1315 128.047H86.1465V138.406H96.1315V128.047Z"
      fill="#75462F"
    />
    <path
      d="M248.501 66.3848H230.244C229.771 66.3848 229.389 66.7675 229.389 67.2396V137.515C229.389 137.987 229.771 138.37 230.244 138.37H248.501C248.973 138.37 249.356 137.987 249.356 137.515V67.2396C249.356 66.7675 248.973 66.3848 248.501 66.3848Z"
      fill="#FFB34C"
    />
    <path
      d="M249.356 76.3301H229.389V92.0804H249.356V76.3301Z"
      fill="#75462F"
    />
    <path
      d="M245.473 123.352H233.273V130.196H245.473V123.352Z"
      fill="#9DBF5C"
    />
    <path
      d="M249.356 93.0547H229.389V94.3413H249.356V93.0547Z"
      fill="#75462F"
    />
    <path
      d="M249.356 74.0625H229.389V75.3491H249.356V74.0625Z"
      fill="#75462F"
    />
    <path
      d="M227.646 56H218.596C218.124 56 217.741 56.3827 217.741 56.8549V137.516C217.741 137.989 218.124 138.371 218.596 138.371H227.646C228.118 138.371 228.501 137.989 228.501 137.516V56.8549C228.501 56.3827 228.118 56 227.646 56Z"
      fill="#138784"
    />
    <path
      d="M220.367 101.668C220.367 101.982 220.622 102.237 220.937 102.237H225.305C225.62 102.237 225.874 101.982 225.874 101.668V60.8858C225.874 60.5712 225.62 60.3164 225.305 60.3164H220.937C220.622 60.3164 220.367 60.5712 220.367 60.8858V101.668Z"
      fill="#9DBF5C"
    />
    <path
      d="M181.114 69.9098L173.041 124.955C172.973 125.422 173.296 125.856 173.763 125.925L178.729 126.653C179.196 126.722 179.63 126.398 179.698 125.931L187.771 70.8862C187.84 70.419 187.516 69.9848 187.049 69.9163L182.084 69.188C181.616 69.1195 181.182 69.4427 181.114 69.9098Z"
      fill="#138784"
    />
    <path
      d="M180.892 71.4215L178.06 90.7383L184.717 91.7146L187.55 72.3978L180.892 71.4215Z"
      fill="#9DBF5C"
    />
    <path
      d="M179.047 83.9988L178.694 86.4043L185.352 87.3806L185.705 84.9751L179.047 83.9988Z"
      fill="#FF454D"
    />
    <path
      d="M180.238 75.8659L179.315 82.1562L185.973 83.1326L186.895 76.8422L180.238 75.8659Z"
      fill="#FF454D"
    />
    <path
      d="M171.232 72.8972L156.634 122.966C156.502 123.42 156.762 123.894 157.215 124.026L164.142 126.046C164.595 126.178 165.07 125.918 165.202 125.465L179.8 75.3953C179.932 74.942 179.672 74.4674 179.219 74.3353L172.292 72.3157C171.839 72.1836 171.364 72.4439 171.232 72.8972Z"
      fill="#75462F"
    />
    <path
      d="M161.527 106.182L156.394 123.787L164.962 126.285L170.095 108.68L161.527 106.182Z"
      fill="#FF454D"
    />
    <path
      d="M167.506 85.6899L166.866 87.8828L175.434 90.3809L176.074 88.188L167.506 85.6899Z"
      fill="#FFB34C"
    />
    <path
      d="M169.664 78.2804L167.992 84.0137L176.56 86.5118L178.232 80.7785L169.664 78.2804Z"
      fill="#FFB34C"
    />
    <path
      d="M204.696 75.3535H188.749C188.277 75.3535 187.895 75.7363 187.895 76.2084V137.517C187.895 137.989 188.277 138.372 188.749 138.372H204.696C205.169 138.372 205.551 137.989 205.551 137.517V76.2084C205.551 75.7363 205.169 75.3535 204.696 75.3535Z"
      fill="#9DBF5C"
    />
    <path
      d="M205.551 119.988H187.895V121.373H205.551V119.988Z"
      fill="#FFB34C"
    />
    <path
      d="M205.551 122.152H187.895V123.537H205.551V122.152Z"
      fill="#FFB34C"
    />
    <path
      d="M205.551 124.314H187.895V125.699H205.551V124.314Z"
      fill="#FFB34C"
    />
    <path
      d="M205.551 88.0234H187.895V89.4083H205.551V88.0234Z"
      fill="#FFB34C"
    />
    <path
      d="M205.551 90.1875H187.895V91.5724H205.551V90.1875Z"
      fill="#FFB34C"
    />
    <path
      d="M205.551 92.3496H187.895V93.7345H205.551V92.3496Z"
      fill="#FFB34C"
    />
    <path
      d="M196.723 112.528C199.851 112.528 202.387 109.992 202.387 106.864C202.387 103.735 199.851 101.199 196.723 101.199C193.595 101.199 191.059 103.735 191.059 106.864C191.059 109.992 193.595 112.528 196.723 112.528Z"
      fill="#91B055"
    />
    <path
      d="M215.82 61.4434H212.911C212.438 61.4434 212.056 61.8261 212.056 62.2982V137.516C212.056 137.988 212.438 138.371 212.911 138.371H215.82C216.292 138.371 216.675 137.988 216.675 137.516V62.2982C216.675 61.8261 216.292 61.4434 215.82 61.4434Z"
      fill="#75462F"
    />
    <path
      d="M210.378 61.4434H207.469C206.997 61.4434 206.614 61.8261 206.614 62.2982V137.516C206.614 137.988 206.997 138.371 207.469 138.371H210.378C210.85 138.371 211.233 137.988 211.233 137.516V62.2982C211.233 61.8261 210.85 61.4434 210.378 61.4434Z"
      fill="#FF454D"
    />
    <path
      d="M186.213 127.053H152.637C152.165 127.053 151.782 127.435 151.782 127.908V137.553C151.782 138.025 152.165 138.408 152.637 138.408H186.213C186.685 138.408 187.067 138.025 187.067 137.553V127.908C187.067 127.435 186.685 127.053 186.213 127.053Z"
      fill="#75462F"
    />
    <path
      d="M164.688 127.053H155.333V138.408H164.688V127.053Z"
      fill="#138784"
    />
    <path d="M167.801 127.053H166.4V138.408H167.801V127.053Z" fill="#138784" />
    <path
      d="M134.058 138.171C134.084 137.66 134.098 137.141 134.098 136.616C134.098 128.523 130.949 121.963 127.065 121.963C123.182 121.963 120.033 128.523 120.033 136.616C120.033 137.141 120.047 137.66 120.073 138.171H134.058Z"
      fill="#FFC37B"
    />
    <path
      d="M123.728 107.787C124.805 111.301 125.547 116.375 124.632 116.657C123.715 116.937 121.487 112.318 120.411 108.805C119.334 105.291 119.816 104.213 120.733 103.933C121.648 103.651 122.651 104.274 123.728 107.787Z"
      fill="#F1921E"
    />
    <path
      d="M123.367 108.665C124.067 110.95 124.55 114.251 123.954 114.433C123.358 114.616 121.909 111.611 121.209 109.326C120.509 107.041 120.823 106.34 121.418 106.158C122.014 105.975 122.667 106.38 123.367 108.665Z"
      fill="#FFC37B"
    />
    <path
      d="M130.405 107.787C129.327 111.301 128.585 116.375 129.501 116.656C130.417 116.937 132.645 112.318 133.722 108.804C134.799 105.291 134.316 104.213 133.4 103.932C132.485 103.652 131.482 104.274 130.405 107.787Z"
      fill="#F1921E"
    />
    <path
      d="M130.822 108.614C130.122 110.9 129.639 114.2 130.235 114.382C130.831 114.565 132.28 111.56 132.98 109.275C133.68 106.99 133.366 106.289 132.77 106.107C132.175 105.924 131.523 106.329 130.822 108.614Z"
      fill="#FFC37B"
    />
    <path
      d="M127.066 125.079C130.301 125.079 132.923 122.676 132.923 119.712C132.923 116.747 130.301 114.344 127.066 114.344C123.831 114.344 121.208 116.747 121.208 119.712C121.208 122.676 123.831 125.079 127.066 125.079Z"
      fill="#F1921E"
    />
    <path
      d="M123.722 117.622C123.722 117.789 123.586 117.926 123.419 117.926C123.251 117.926 123.114 117.79 123.114 117.622C123.114 117.454 123.25 117.318 123.419 117.318C123.586 117.318 123.722 117.454 123.722 117.622Z"
      fill="black"
    />
    <path
      d="M131.018 117.622C131.018 117.789 130.882 117.926 130.714 117.926C130.545 117.926 130.409 117.79 130.409 117.622C130.409 117.454 130.545 117.318 130.714 117.318C130.882 117.318 131.018 117.454 131.018 117.622Z"
      fill="black"
    />
    <path
      d="M127.102 121.923C127.039 121.923 126.988 121.872 126.988 121.81V120.079C126.988 120.015 127.04 119.965 127.102 119.965C127.164 119.965 127.216 120.016 127.216 120.079V121.81C127.216 121.873 127.165 121.923 127.102 121.923Z"
      fill="black"
    />
    <path
      d="M127.101 121.923C125.369 121.923 123.77 121.314 123.026 120.372C122.988 120.322 122.996 120.251 123.045 120.212C123.095 120.173 123.166 120.181 123.205 120.231C123.907 121.121 125.436 121.696 127.101 121.696C128.765 121.696 130.295 121.121 130.996 120.231C131.036 120.181 131.107 120.173 131.156 120.212C131.206 120.251 131.214 120.322 131.175 120.372C130.432 121.315 128.833 121.923 127.101 121.923Z"
      fill="black"
    />
    <path
      d="M128.69 119.057C128.69 119.568 127.979 120.301 127.101 120.301C126.223 120.301 125.512 119.568 125.512 119.057C125.512 118.546 126.223 118.449 127.101 118.449C127.979 118.449 128.69 118.546 128.69 119.057Z"
      fill="#005F61"
    />
    <path
      d="M144 601.65C143.559 611.72 138.375 620.771 128.1 626.63C115.444 633.846 99.3337 631.631 89.1267 621.504C76.1024 608.582 77.1439 587.701 91.4437 576.197C108.152 562.757 133.662 568.806 141.817 588.805C143.268 592.364 143.966 596.082 144 601.65Z"
      fill="#FFB455"
    />
    <path
      d="M131.225 575.429C130.005 575.429 129.109 575.402 128.216 575.434C123.924 575.585 120.267 577.325 117.046 580.043C112.818 583.613 109.936 588.134 108.01 593.277C105.19 600.812 104.469 608.497 106.601 616.333C108.13 621.952 110.954 626.684 116.102 629.76C116.202 629.82 116.234 629.995 116.363 630.241C112.15 631.065 108.046 630.672 104.007 629.558C103.29 629.36 102.63 628.641 102.132 628.013C98.7424 623.742 96.768 618.81 95.9251 613.483C94.0856 601.859 96.3881 591.082 103.11 581.369C106.327 576.721 110.504 573.154 115.725 570.809C118.101 569.742 120.428 569.508 122.743 570.568C125.427 571.798 128.007 573.26 130.623 574.638C130.793 574.728 130.883 574.97 131.225 575.429Z"
      fill="#C6805E"
    />
    <path
      d="M134.211 577.907C126.781 576.812 122.367 580.852 118.569 585.904C115.476 590.019 114.004 594.816 113.319 599.851C112.649 604.782 112.812 609.707 113.685 614.618C114.628 619.924 117.853 623.815 121.457 627.494C121.825 627.871 122.222 628.221 122.674 628.647C121.656 629.53 120.873 629.387 119.967 628.506C115.106 623.779 111.958 618.235 111.423 611.348C110.936 605.074 111.191 598.88 113.151 592.843C114.862 587.574 117.793 583.1 122.102 579.607C124.74 577.469 127.651 575.881 131.221 575.951C132.476 575.977 133.345 576.477 134.211 577.907Z"
      fill="#C6805E"
    />
  </svg>
</template>

<script>
export default {
  name: "ReturnBase"
};
</script>
