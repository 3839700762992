import { assessmentTypes } from "@/constants/constants.js";

export const ASSESSMENT_CONFIG = [
  {
    id: assessmentTypes.deskAssessment,
    translationKey: "desk",
    alwaysEnabled: true,
    featureFlag: null,
    apiFeatureFlag: null
  },
  {
    id: assessmentTypes.burnoutAssessment,
    translationKey: "resilience",
    alwaysEnabled: false,
    featureFlag: "hideBurnoutCompletely",
    apiFeatureFlag: "HideBurnout",
    invertValue: true
  },
  {
    id: assessmentTypes.driverAssessment,
    translationKey: "driver",
    alwaysEnabled: false,
    featureFlag: "driverAssessmentEnabled",
    apiFeatureFlag: "EnableDriverAssessment"
  },
  {
    id: assessmentTypes.physicalLabourAssessment,
    translationKey: "physicalLabour",
    alwaysEnabled: false,
    featureFlag: "physicalLabourAssessmentEnabled",
    apiFeatureFlag: "EnablePhysicalLabourAssessment"
  },
  {
    id: assessmentTypes.menopauseAssessment,
    translationKey: "menopause",
    alwaysEnabled: false,
    featureFlag: "enableMenopauseAssessment",
    apiFeatureFlag: "EnableMenopauseAssessment"
  },
  {
    id: assessmentTypes.menstruationAssessment,
    translationKey: "menstruation",
    alwaysEnabled: false,
    featureFlag: "enableMenstruationAssessment",
    apiFeatureFlag: "EnableMenstruationAssessment"
  },
  {
    id: assessmentTypes.pregnancyAssessment,
    translationKey: "pregnancy",
    alwaysEnabled: false,
    featureFlag: "enablePregnancyAssessment",
    apiFeatureFlag: "EnablePregnancyAssessment"
  },
  {
    id: assessmentTypes.newParentAssessment,
    translationKey: "newParent",
    alwaysEnabled: false,
    featureFlag: "enableNewParentAssessment",
    apiFeatureFlag: "EnableNewParentAssessment"
  }
];
