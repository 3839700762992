<template>
  <div class="dialog-container">
    <vfl-icon-button
      class="close-button"
      variant="ghost"
      @click="$emit('closeDialog')"
      :screen-reader="$t('buttons.close')"
    >
      <v-icon>mdi-close</v-icon>
    </vfl-icon-button>
    <div class="dialog-content">
      <div class="image-column">
        <img src="/images/phone-app/app-on-phone.webp" width="100%" />
      </div>

      <div class="content-column">
        <h5 class="title text-h5">
          {{ $t(`phoneApp.qrDialog.title`) }}
        </h5>

        <p class="text-body-1 text-body-dark">
          {{ $t(`phoneApp.qrDialog.description`) }}
        </p>

        <canvas ref="qrCanvas" style="width: 100%; max-width: 250px" />
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "PhoneAppQRDialog",
  mounted() {
    sendEvent("Phone App", {
      action: "Opened app QR dialog"
    });
    this.generateQRCode();
  },
  computed: {
    qrCodeUrl() {
      return `${import.meta.env.VITE_APP_REDIRECT_URL}#/phone-app?add-app=true`;
    }
  },
  methods: {
    generateQRCode() {
      QRCode.toCanvas(
        this.$refs.qrCanvas,
        this.qrCodeUrl,
        { width: 250 },
        error => {
          if (error) console.error(error);
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$breakpoint: 710px;

.dialog-container {
  background: white;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
}

.dialog-content {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $breakpoint) {
    grid-template-columns: 1fr 1fr;
  }
}

.image-column {
  background: var(--v-vitruePaleGrey-base);
  display: flex;
  align-content: center;
  justify-content: center;

  @media (max-width: $breakpoint) {
    display: none;
  }
}

.content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 3rem 2rem;
}

.title {
  margin-bottom: 16px;
}
</style>
