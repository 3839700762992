<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.postnatal.title")
    }}</template>
    <template #content>
      <report-section-card
        imagePath="/images/pregnancy-assessment/report/postnatal-recovery.svg"
        :fact="$t(`pregnancyAssessment.report.content.postnatal.title`)"
        :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        ><template #text>
          <p>
            {{ $t("pregnancyAssessment.report.content.postnatal.text.intro") }}
          </p>
          <ul>
            <li
              v-for="(text, index) in $t(
                'pregnancyAssessment.report.content.postnatal.text.bulletPoints'
              )"
              :key="`postnatal-bulletpoint-${index}`"
              v-html="text"
              class="mb-4"
            ></li></ul></template
      ></report-section-card>
      <recommendation-container
        class="mt-10"
        :title="
          $t(
            'pregnancyAssessment.report.content.postnatal.recommendationsTitle'
          )
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="postnatalRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import { assessmentTypes } from "@/constants/constants.js";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/pregnancy/report/recommendations/recommendation-service.js";
import ReportSectionCard from "@/components/assessment/common/report/ReportSectionCard.vue";

export default {
  name: "PregnancyReportPostnatal",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer,
    ReportSectionCard
  },
  data() {
    return { postnatalRecommendations: {} };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.postnatalRecommendations = getRecommendationsByType(
      RecommendationSections.POSTNATAL,
      this.results
    );
  }
};
</script>
