import { ImageService } from "@/components/assessment/common/dynamic-images/image-service.js";
import { Theme } from "./steps.js";
import Answers from "@/components/assessment/new-parent/answers.json";

// Get Started
import GetStartedBase from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedBase.vue";
import GetStartedMother from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedMother.vue";
import GetStartedFather from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedFather.vue";
import GetStartedOneChild from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedOneChild.vue";
import GetStartedTwins from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedTwins.vue";
import GetStartedTriplets from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedTriplets.vue";
import GetStartedUpToSixMonths from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedUpToSixMonths.vue";
import GetStartedSixToTwelveMonths from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedSixToTwelveMonths.vue";
import GetStartedMoreThanTwelveMonths from "@/components/assessment/common/dynamic-images/svgs/new-parent/get-started/GetStartedMoreThanTwelveMonths.vue";

// Carrying
import CarryingBase from "@/components/assessment/common/dynamic-images/svgs/new-parent/carrying/CarryingBase.vue";
import CarryingUnanswered from "@/components/assessment/common/dynamic-images/svgs/new-parent/carrying/CarryingUnanswered.vue";
import CarryingBaby from "@/components/assessment/common/dynamic-images/svgs/new-parent/carrying/CarryingBaby.vue";

// Lifestyle
import LifestyleBase from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleBase.vue";
import LifestyleBreastFeeding from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleBreastFeeding.vue";
import LifestyleExpressingBreastmilk from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleExpressingBreastmilk.vue";
import LifestyleSleepFourToSix from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleSleepFourToSix.vue";
import LifestyleSleepLessThanFour from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleSleepLessThanFour.vue";
import LifestyleSleepMoreThanSeven from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleSleepMoreThanSeven.vue";
import LifestyleStayingActive from "@/components/assessment/common/dynamic-images/svgs/new-parent/lifestyle/LifestyleStayingActive.vue";

// Return To Work
import ReturnBase from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnBase.vue";
import ReturnAlreadyReturned from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnAlreadyReturned.vue";
import ReturnAnxious from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnAnxious.vue";
import ReturnExcited from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnExcited.vue";
import ReturnExcitedAndNervous from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnExcitedAndNervous.vue";
import ReturnHome from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnHome.vue";
import ReturnHybrid from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnHybrid.vue";
import ReturnOffice from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnOffice.vue";
import ReturnPartTime from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnPartTime.vue";
import ReturnPreparingForTransition from "@/components/assessment/common/dynamic-images/svgs/new-parent/return-to-work/ReturnPreparingForTransition.vue";

const baseComponents = {
  [Theme.GET_STARTED]: GetStartedBase,
  [Theme.CARRYING]: CarryingBase,
  [Theme.LIFESTYLE]: LifestyleBase,
  [Theme.RETURN_TO_WORK]: ReturnBase
};

export class NewParentImageService extends ImageService {
  registerComponents(vm) {
    const components = {
      GetStartedBase,
      GetStartedMother,
      GetStartedFather,
      GetStartedOneChild,
      GetStartedTwins,
      GetStartedTriplets,
      GetStartedUpToSixMonths,
      GetStartedSixToTwelveMonths,
      GetStartedMoreThanTwelveMonths,

      CarryingBase,
      CarryingUnanswered,
      CarryingBaby,

      LifestyleBase,
      LifestyleBreastFeeding,
      LifestyleExpressingBreastmilk,
      LifestyleSleepFourToSix,
      LifestyleSleepLessThanFour,
      LifestyleSleepMoreThanSeven,
      LifestyleStayingActive,

      ReturnBase,
      ReturnAlreadyReturned,
      ReturnAnxious,
      ReturnExcited,
      ReturnExcitedAndNervous,
      ReturnHome,
      ReturnHybrid,
      ReturnOffice,
      ReturnPartTime,
      ReturnPreparingForTransition
    };

    Object.entries(components).forEach(([name, component]) => {
      vm.$options.components[name] = component;
    });
  }

  getBaseComponent(schema) {
    return schema?.illustration?.base || baseComponents[this.currentTheme];
  }

  getOverlayComponents(currentTheme, results, schema) {
    const overlayHandlers = {
      [Theme.GET_STARTED]: () => this.getGetStartedOverlays(results, schema),
      [Theme.CARRYING]: () => this.getCarryingOverlays(results, schema),
      [Theme.LIFESTYLE]: () => this.getLifestyleOverlays(results, schema),
      [Theme.RETURN_TO_WORK]: () =>
        this.getReturnToWorkOverlays(results, schema)
    };

    return overlayHandlers[currentTheme]?.() || [];
  }

  getGetStartedOverlays(results, schema) {
    return this.processQuestionOverlays(results.getStarted, schema, [
      "babyAge",
      "numberOfNewborns",
      "parentRole"
    ]);
  }

  getCarryingOverlays(results, schema) {
    const carryingResults = results.carrying;

    if (
      !carryingResults.liftingOrCarryingBaby &&
      schema?.illustration?.overlays
    ) {
      return schema.illustration.overlays;
    }

    return ["CarryingBaby"];
  }

  getLifestyleOverlays(results, schema) {
    return this.processQuestionOverlays(results.lifestyle, schema, [
      "totalHoursSleep",
      "hoursActivePerWeek",
      "breastfeeding",
      "expressBreastmilk"
    ]);
  }

  getReturnToWorkOverlays(results, schema) {
    return this.processQuestionOverlays(results.returnToWork, schema, [
      "returnToWorkStatus",
      "workTimeSplit",
      "returnToWorkFeeling"
    ]);
  }

  processQuestionOverlays(results, schema, questionTypes) {
    const overlays = [];

    questionTypes.forEach(type => {
      const selectedValue = results[type];
      const question = schema.questions.find(q => q.name === type);

      if (selectedValue && question) {
        const option = question.options.find(
          opt => opt.value === selectedValue
        );
        this.addUniqueOverlays(overlays, option?.illustration?.overlays);
      }
    });
    return overlays;
  }
}
