<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5289_51566)">
      <path d="M523 699H15L72.9244 588H465.076L523 699Z" fill="#ABC792" />
      <path
        d="M493.356 690.819H44.6426L91.071 591.785H446.927L493.356 690.819Z"
        fill="#C2D1B5"
      />
      <path
        d="M458.687 683.139H79.3145L113.598 598.031H424.402L458.687 683.139Z"
        fill="#ABC792"
      />
      <path
        d="M430.857 676.275H107.139L132.782 602.762H405.214L430.857 676.275Z"
        fill="#C2D1B5"
      />
      <path
        d="M566.511 637.251V674.251C566.511 675.765 565.282 676.99 563.772 676.99H482.375C480.179 676.99 478.568 674.922 479.104 672.79L481.836 661.93L482.921 657.62C483.546 655.135 485.451 653.239 487.825 652.568C488.435 652.394 489.078 652.302 489.74 652.302H499.111L514.248 634.898C514.744 634.328 515.464 634 516.221 634H563.261C565.056 634 566.511 635.456 566.511 637.251Z"
        fill="#005F61"
      />
      <path
        d="M488.153 654.934C488.153 658.825 485.616 661.98 482.485 661.98C482.265 661.98 482.047 661.965 481.836 661.931L482.92 657.621C483.545 655.137 485.451 653.24 487.825 652.569C488.036 653.308 488.153 654.104 488.153 654.934Z"
        fill="#F2A226"
      />
      <path
        d="M518.174 637.472L506.34 652.568H561.718V637.472H518.174Z"
        fill="#DCEBEB"
      />
      <path
        d="M529.508 654.016H531.04V636.554H529.508V654.016Z"
        fill="#005F61"
      />
      <path
        d="M523.382 659.223H516.336V657.385H523.382V659.223Z"
        fill="#AEB791"
      />
      <path
        d="M541.761 659.223H534.715V657.385H541.761V659.223Z"
        fill="#AEB791"
      />
      <path
        d="M528.435 674.893H528.129V664.124H528.435V674.893Z"
        fill="#AC6869"
      />
      <path
        d="M560.448 676.991C560.448 683.081 555.509 688.02 549.419 688.02C543.329 688.02 538.391 683.081 538.391 676.991C538.391 670.898 543.329 665.962 549.419 665.962C555.509 665.962 560.448 670.898 560.448 676.991Z"
        fill="#331823"
      />
      <path
        d="M508.065 676.991C508.065 683.081 503.127 688.02 497.036 688.02C490.946 688.02 486.008 683.081 486.008 676.991C486.008 670.898 490.946 665.962 497.036 665.962C503.127 665.962 508.065 670.898 508.065 676.991Z"
        fill="#331823"
      />
      <path
        d="M501.632 676.991C501.632 679.527 499.573 681.586 497.037 681.586C494.497 681.586 492.441 679.527 492.441 676.991C492.441 674.451 494.497 672.395 497.037 672.395C499.573 672.395 501.632 674.451 501.632 676.991Z"
        fill="#AEB791"
      />
      <path
        d="M554.015 676.991C554.015 679.527 551.956 681.586 549.419 681.586C546.88 681.586 544.824 679.527 544.824 676.991C544.824 674.451 546.88 672.395 549.419 672.395C551.956 672.395 554.015 674.451 554.015 676.991Z"
        fill="#AEB791"
      />
      <path
        d="M429.15 132.304C435.308 132.304 440.3 127.312 440.3 121.154C440.3 114.996 435.308 110.004 429.15 110.004C422.992 110.004 418 114.996 418 121.154C418 127.312 422.992 132.304 429.15 132.304Z"
        fill="#DDBC91"
      />
      <path
        d="M483.847 132.304C490.006 132.304 494.999 127.311 494.999 121.152C494.999 114.993 490.006 110 483.847 110C477.688 110 472.695 114.993 472.695 121.152C472.695 127.311 477.688 132.304 483.847 132.304Z"
        fill="#DDBC91"
      />
      <path
        d="M456.499 181.798C476.153 181.798 492.087 165.864 492.087 146.209C492.087 126.555 476.153 110.621 456.499 110.621C436.844 110.621 420.91 126.555 420.91 146.209C420.91 165.864 436.844 181.798 456.499 181.798Z"
        fill="#DDBC91"
      />
      <path
        d="M456.498 177.058C473.536 177.058 487.349 163.245 487.349 146.207C487.349 129.168 473.536 115.355 456.498 115.355C439.459 115.355 425.646 129.168 425.646 146.207C425.646 163.245 439.459 177.058 456.498 177.058Z"
        fill="white"
      />
      <path
        d="M456.499 150.359C458.122 150.359 459.437 149.044 459.437 147.421C459.437 145.798 458.122 144.482 456.499 144.482C454.876 144.482 453.561 145.798 453.561 147.421C453.561 149.044 454.876 150.359 456.499 150.359Z"
        fill="#402A15"
      />
      <path
        d="M450.401 138.493C453.14 141.685 454.728 144.815 453.947 145.483C453.169 146.151 450.317 144.104 447.58 140.912C444.842 137.721 443.254 134.591 444.034 133.923C444.813 133.255 447.663 135.302 450.401 138.493Z"
        fill="#402A15"
      />
      <path
        d="M467.505 136.581C463.429 141.47 459.486 144.903 458.697 144.245C457.908 143.588 460.573 139.092 464.649 134.2C468.726 129.309 472.668 125.879 473.457 126.536C474.247 127.193 471.582 131.69 467.505 136.581Z"
        fill="#402A15"
      />
      <path
        d="M549.857 410.466L548.679 397.622C548.487 395.486 548.309 393.355 548.214 391.226C548.166 390.162 548.139 389.099 548.14 388.036C548.141 386.973 548.169 385.908 548.213 384.84C548.257 383.772 548.318 382.703 548.382 381.631L548.584 378.416C548.726 376.273 548.88 374.131 549.046 371.989C549.379 367.706 549.765 363.426 550.202 359.15C550.215 359.024 550.123 358.911 549.997 358.898C549.873 358.885 549.762 358.973 549.745 359.096C549.168 363.358 548.641 367.629 548.167 371.906C547.93 374.044 547.706 376.185 547.494 378.326L547.186 381.54C547.086 382.611 546.99 383.686 546.91 384.764C546.831 385.843 546.766 386.925 546.729 388.011C546.693 389.098 546.685 390.185 546.699 391.269C546.724 393.438 546.834 395.599 546.957 397.749L547.714 410.627C547.748 411.22 548.257 411.673 548.85 411.637C549.443 411.603 549.895 411.094 549.86 410.501C549.859 410.492 549.858 410.477 549.857 410.466Z"
        fill="#4B8945"
      />
      <path
        d="M528.928 415.84L529.633 419.404L531.018 426.391L531.646 429.567L532.112 431.92L532.788 435.331L534.097 441.939C534.447 443.705 535.724 445.137 537.433 445.7C540.083 446.575 543.672 447.383 547.94 447.347C552.1 447.313 555.587 446.488 558.162 445.615C559.738 445.079 560.926 443.764 561.339 442.149C561.773 440.44 562.208 438.731 562.645 437.022C562.908 435.985 563.171 434.95 563.437 433.913C563.661 433.029 563.886 432.143 564.112 431.259C564.457 429.907 564.801 428.553 565.146 427.202C565.803 424.616 566.462 422.028 567.122 419.442L568.039 415.84L528.928 415.84Z"
        fill="#D6A88E"
      />
      <path
        opacity="0.18"
        d="M530.253 415.84H534.178C535.61 429.469 541.686 444.185 562.273 438.49C561.963 439.71 561.652 440.929 561.341 442.149C561.106 443.071 560.617 443.895 559.948 444.532C537.489 445.989 531.427 429.617 530.253 415.84Z"
        fill="#282828"
      />
      <path
        opacity="0.27"
        d="M530.255 415.84C531.429 429.617 537.49 445.989 559.949 444.532C559.446 445.011 558.842 445.385 558.166 445.615C555.591 446.488 552.105 447.313 547.944 447.346C543.677 447.383 540.087 446.575 537.437 445.7C535.728 445.136 534.452 443.705 534.102 441.939L532.793 435.331L532.117 431.92L531.651 429.566L531.023 426.391L529.638 419.404L528.933 415.84H530.255Z"
        fill="#282828"
      />
      <path
        opacity="0.25"
        d="M528.931 415.84L529.637 419.404C535.466 420.349 542.267 420.995 549.845 420.908C556.233 420.837 562.029 420.256 567.125 419.441L568.043 415.84H528.931Z"
        fill="#AA723F"
      />
      <path
        d="M523.227 407.949V413.367C523.227 414.826 524.263 416.07 525.694 416.351C532.314 417.65 540.486 418.664 549.843 418.556C558.051 418.464 565.29 417.528 571.309 416.346C572.123 416.184 572.805 415.705 573.238 415.053C573.562 414.566 573.746 413.985 573.746 413.368V407.8C573.746 406.432 572.459 405.431 571.13 405.763C566.453 406.929 557.191 408.504 549.07 408.347C536.006 408.093 531.597 407.76 526.014 405.964C526 405.958 525.989 405.955 525.976 405.953C524.618 405.514 523.227 406.52 523.227 407.949Z"
        fill="#DDBC91"
      />
      <path
        d="M532.115 431.921L532.791 435.332C534.165 436.21 535.556 437.068 536.957 437.901C538.733 437.192 539.62 436.82 541.399 436.041C543.178 437.03 544.068 437.507 545.849 438.426C547.63 437.577 548.52 437.135 550.3 436.216C552.081 437.065 552.971 437.472 554.75 438.251C556.527 437.263 557.417 436.752 559.19 435.693C560.608 436.26 561.46 436.588 562.647 437.022C562.911 435.985 563.174 434.95 563.439 433.913C561.793 433.317 560.907 432.978 559.196 432.294C557.422 433.353 556.533 433.865 554.754 434.854C552.976 434.075 552.085 433.668 550.304 432.819C548.525 433.738 547.634 434.18 545.853 435.029C544.074 434.11 543.182 433.633 541.405 432.644C539.626 433.423 538.736 433.795 536.963 434.505C535.473 433.619 533.994 432.705 532.533 431.77C532.388 431.821 532.25 431.873 532.115 431.921Z"
        fill="#DDBC91"
      />
      <path
        d="M531.019 426.39L531.647 429.565C532.034 429.429 532.452 429.278 532.936 429.103C534.397 430.039 535.874 430.95 537.366 431.838C539.139 431.129 540.027 430.757 541.806 429.978C543.585 430.967 544.475 431.444 546.256 432.363C548.037 431.514 548.926 431.072 550.707 430.153C552.488 431.002 553.378 431.409 555.157 432.188C556.936 431.199 557.824 430.687 559.599 429.628C561.373 430.337 562.259 430.674 564.027 431.313C564.056 431.295 564.084 431.276 564.113 431.258C564.458 429.906 564.802 428.552 565.146 427.201C564.776 427.44 564.404 427.677 564.032 427.915C562.264 427.276 561.378 426.939 559.603 426.23C557.829 427.289 556.94 427.801 555.163 428.79C553.384 428.011 552.494 427.604 550.713 426.755C548.932 427.674 548.042 428.116 546.261 428.965C544.481 428.046 543.591 427.569 541.812 426.58C540.033 427.359 539.145 427.731 537.37 428.44C535.88 427.554 534.401 426.642 532.942 425.707C532.185 425.981 531.59 426.191 531.019 426.39Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.18"
        d="M523.226 407.949V413.368C523.226 414.826 524.262 416.07 525.693 416.352C532.313 417.65 540.485 418.664 549.842 418.556C558.05 418.465 565.288 417.528 571.308 416.346C572.122 416.184 572.804 415.705 573.236 415.053C531.731 421.259 525.669 412.297 526.012 405.964C525.999 405.959 525.988 405.956 525.974 405.953C524.616 405.515 523.226 406.521 523.226 407.949Z"
        fill="#282828"
      />
      <path
        opacity="0.22"
        d="M558.427 436.634C555.973 436.634 553.983 433.45 553.983 429.523C553.983 425.596 555.973 422.412 558.427 422.412C560.881 422.412 562.871 425.596 562.871 429.523C562.871 433.45 560.881 436.634 558.427 436.634Z"
        fill="#C99A73"
      />
      <path
        d="M567.805 378.321C570.691 377.422 573.379 375.817 576.155 374.518C584.492 370.616 593.775 369.396 601.423 363.883C603.212 362.594 604.917 361.067 606.912 360.306C608.907 359.546 611.345 359.74 612.703 361.532C613.579 358.782 612.863 355.643 611.373 353.336C609.883 351.029 607.717 349.433 605.493 348.121C592.179 340.266 575.017 341.574 562.363 351.408C557.019 355.561 552.762 362.024 553.768 369.809C554.729 377.242 562.126 380.089 567.805 378.321Z"
        fill="#87AA44"
      />
      <path
        d="M547.889 381.777C547.95 381.777 548.011 381.752 548.056 381.704C549.759 379.893 551.421 377.905 553.027 375.983C556.166 372.226 559.412 368.342 563.232 365.434C567.228 362.392 576.047 357.939 585.171 355.313C595.637 352.301 603.427 352.549 607.105 356.011C607.197 356.098 607.343 356.094 607.43 356.001C607.517 355.909 607.512 355.763 607.42 355.676C605.361 353.738 602.161 352.755 597.908 352.754C597.907 352.754 597.905 352.754 597.903 352.754C594.253 352.754 589.926 353.466 585.044 354.871C575.865 357.513 566.983 362 562.954 365.068C559.093 368.007 555.83 371.912 552.674 375.688C551.072 377.605 549.415 379.588 547.721 381.389C547.635 381.482 547.639 381.627 547.731 381.714C547.776 381.756 547.832 381.777 547.889 381.777Z"
        fill="#4B8945"
      />
      <path
        d="M599.207 352.98C599.229 352.98 599.251 352.974 599.271 352.964L605.481 349.664C605.549 349.629 605.574 349.545 605.539 349.478C605.503 349.41 605.419 349.385 605.352 349.42L599.142 352.72C599.075 352.755 599.049 352.839 599.085 352.906C599.11 352.953 599.158 352.98 599.207 352.98Z"
        fill="#4B8945"
      />
      <path
        d="M586.269 354.89C586.315 354.89 586.359 354.867 586.385 354.826C588.581 351.364 591.871 348.582 595.65 346.994C595.72 346.964 595.753 346.883 595.723 346.813C595.694 346.743 595.613 346.71 595.543 346.739C591.713 348.349 588.378 351.169 586.152 354.678C586.112 354.742 586.131 354.828 586.195 354.868C586.218 354.883 586.244 354.89 586.269 354.89Z"
        fill="#4B8945"
      />
      <path
        d="M575.584 358.945C575.655 358.945 575.715 358.891 575.721 358.819C575.993 355.833 577.778 353.463 579.227 352C581.083 350.127 583.356 348.702 585.555 347.323C585.619 347.283 585.639 347.197 585.598 347.133C585.558 347.069 585.472 347.049 585.408 347.089C583.197 348.476 580.91 349.91 579.032 351.806C577.55 353.301 575.726 355.727 575.447 358.794C575.44 358.87 575.496 358.937 575.571 358.944C575.576 358.944 575.58 358.945 575.584 358.945Z"
        fill="#4B8945"
      />
      <path
        d="M569.322 361.382C569.393 361.382 569.453 361.328 569.459 361.257C569.892 356.656 571.528 352.227 574.19 348.45C574.234 348.388 574.219 348.302 574.156 348.258C574.094 348.214 574.008 348.229 573.964 348.291C571.275 352.107 569.622 356.582 569.185 361.231C569.177 361.306 569.233 361.374 569.309 361.381C569.314 361.381 569.318 361.382 569.322 361.382Z"
        fill="#4B8945"
      />
      <path
        d="M561.15 366.317C561.226 366.317 561.288 366.256 561.288 366.18C561.32 361.8 562.471 357.466 564.617 353.647C564.654 353.581 564.631 353.496 564.564 353.459C564.498 353.422 564.414 353.446 564.376 353.512C562.208 357.372 561.045 361.751 561.012 366.178C561.012 366.255 561.073 366.317 561.149 366.317H561.15Z"
        fill="#4B8945"
      />
      <path
        d="M601.729 358.9C601.778 358.9 601.826 358.873 601.851 358.827C601.887 358.76 601.862 358.676 601.795 358.64C598.486 356.861 595.387 354.691 592.585 352.189C592.528 352.139 592.441 352.144 592.39 352.2C592.339 352.257 592.344 352.344 592.401 352.395C595.219 354.911 598.336 357.094 601.664 358.883C601.685 358.894 601.707 358.9 601.729 358.9Z"
        fill="#4B8945"
      />
      <path
        d="M594.304 363.872C594.343 363.872 594.382 363.856 594.409 363.824C594.458 363.766 594.451 363.679 594.393 363.629L584.555 355.285C584.497 355.235 584.41 355.243 584.36 355.301C584.311 355.359 584.318 355.446 584.376 355.495L594.214 363.84C594.24 363.862 594.272 363.872 594.304 363.872Z"
        fill="#4B8945"
      />
      <path
        d="M588.198 367.796C588.247 367.796 588.295 367.769 588.32 367.722C588.356 367.655 588.33 367.572 588.262 367.536C583.304 364.915 579.031 362.393 576.953 358.063C576.919 357.994 576.837 357.965 576.769 357.998C576.7 358.031 576.671 358.113 576.704 358.182C578.818 362.589 583.131 365.136 588.133 367.78C588.154 367.791 588.176 367.796 588.198 367.796Z"
        fill="#4B8945"
      />
      <path
        d="M578.016 368.414C578.072 368.414 578.124 368.38 578.145 368.325C578.172 368.254 578.137 368.174 578.065 368.147C574.328 366.725 570.8 364.539 567.863 361.824C567.808 361.772 567.72 361.775 567.669 361.831C567.617 361.887 567.62 361.975 567.676 362.026C570.638 364.765 574.197 366.971 577.967 368.405C577.984 368.411 578 368.414 578.016 368.414Z"
        fill="#4B8945"
      />
      <path
        d="M572.46 371.836C572.513 371.836 572.565 371.804 572.587 371.751C572.616 371.681 572.584 371.6 572.513 371.571C568.913 370.049 565.491 368.102 562.342 365.787C562.281 365.741 562.194 365.755 562.149 365.816C562.104 365.877 562.117 365.964 562.179 366.009C565.345 368.337 568.786 370.294 572.406 371.825C572.424 371.832 572.442 371.836 572.46 371.836Z"
        fill="#4B8945"
      />
      <path
        d="M567.506 375.578C567.557 375.578 567.605 375.551 567.629 375.503C567.664 375.436 567.637 375.352 567.57 375.318C564.266 373.622 560.852 372.043 557.421 370.624C557.351 370.595 557.27 370.628 557.241 370.699C557.212 370.769 557.246 370.85 557.316 370.879C560.74 372.295 564.147 373.871 567.444 375.563C567.464 375.574 567.485 375.578 567.506 375.578Z"
        fill="#4B8945"
      />
      <path
        opacity="0.14"
        d="M591.386 343.318C595.122 343.791 598.789 344.823 602.258 346.424C587.501 349.923 563.356 358.722 567.625 378.37C563.83 379.481 559.318 378.555 556.471 375.602C556.855 360.372 576.626 349.4 591.386 343.318Z"
        fill="#212120"
      />
      <path
        opacity="0.23"
        d="M562.363 351.408C570.849 344.812 581.363 342.052 591.385 343.319C576.625 349.401 556.854 360.372 556.471 375.603C555.076 374.156 554.08 372.227 553.768 369.809C552.762 362.023 557.019 355.561 562.363 351.408Z"
        fill="#212120"
      />
      <path
        d="M544.083 378.712C551.656 356.254 499.618 342.989 492.654 366.887C490.361 374.757 494.295 374.889 501.46 376.218C508.815 377.582 514.257 376.696 519.362 382.56C522.851 386.568 528.81 390.155 534.994 387.744C537.634 386.714 543.224 381.259 544.083 378.712Z"
        fill="#87AA44"
      />
      <path
        d="M547.673 397.75C547.713 397.75 547.753 397.739 547.79 397.717C547.899 397.653 547.935 397.512 547.87 397.403C543.501 390.054 538.549 381.725 531.833 375.109C524.474 367.859 515.813 363.735 507.447 363.497C505.145 363.432 503.173 363.667 501.421 364.219C499.381 364.861 497.531 365.968 495.743 367.039C495.634 367.105 495.598 367.246 495.663 367.355C495.728 367.464 495.87 367.499 495.979 367.434C497.744 366.377 499.57 365.283 501.559 364.658C503.262 364.122 505.184 363.892 507.434 363.957C515.684 364.192 524.234 368.268 531.51 375.436C538.184 382.011 543.12 390.313 547.475 397.638C547.518 397.71 547.594 397.75 547.673 397.75Z"
        fill="#4B8945"
      />
      <path
        d="M536.4 380.573C536.401 380.573 536.402 380.573 536.403 380.573C536.479 380.572 536.539 380.508 536.538 380.432C536.447 376.167 536.908 371.903 537.91 367.756C537.928 367.682 537.882 367.608 537.808 367.59C537.735 367.572 537.66 367.618 537.642 367.692C536.635 371.861 536.17 376.15 536.262 380.438C536.263 380.513 536.325 380.573 536.4 380.573Z"
        fill="#4B8945"
      />
      <path
        d="M532.789 376.846C532.854 376.846 532.912 376.8 532.924 376.734C533.755 372.37 533.566 367.801 532.379 363.521C532.358 363.447 532.283 363.405 532.209 363.425C532.135 363.445 532.093 363.521 532.113 363.595C533.289 367.834 533.476 372.36 532.653 376.682C532.639 376.757 532.688 376.83 532.763 376.844C532.772 376.845 532.781 376.846 532.789 376.846Z"
        fill="#4B8945"
      />
      <path
        d="M528.375 372.338C528.38 372.338 528.386 372.338 528.391 372.337C528.467 372.328 528.521 372.26 528.513 372.184C528.055 368.113 526.771 364.062 524.8 360.47C524.763 360.403 524.679 360.379 524.612 360.415C524.545 360.452 524.521 360.536 524.558 360.603C526.512 364.163 527.785 368.179 528.239 372.215C528.247 372.286 528.306 372.338 528.375 372.338Z"
        fill="#4B8945"
      />
      <path
        d="M523.005 368.488C523.023 368.488 523.041 368.485 523.059 368.477C523.129 368.447 523.162 368.366 523.131 368.296C521.645 364.849 520.077 361.383 518.47 357.993C518.438 357.924 518.355 357.895 518.287 357.927C518.218 357.96 518.188 358.042 518.221 358.111C519.826 361.498 521.393 364.961 522.878 368.405C522.9 368.457 522.951 368.488 523.005 368.488Z"
        fill="#4B8945"
      />
      <path
        d="M516.067 365.39C516.09 365.39 516.112 365.384 516.133 365.373C516.2 365.337 516.225 365.253 516.188 365.186C514.772 362.566 513.191 359.999 511.488 357.555C511.444 357.493 511.358 357.477 511.296 357.521C511.233 357.564 511.218 357.65 511.261 357.713C512.958 360.148 514.534 362.706 515.946 365.317C515.971 365.364 516.018 365.39 516.067 365.39Z"
        fill="#4B8945"
      />
      <path
        d="M510.255 364.051C510.286 364.051 510.316 364.041 510.342 364.02C510.401 363.972 510.41 363.886 510.362 363.826C508.751 361.844 506.7 360.175 504.432 359C504.364 358.965 504.281 358.991 504.246 359.059C504.21 359.126 504.237 359.21 504.305 359.245C506.54 360.402 508.56 362.047 510.148 364.001C510.175 364.034 510.215 364.051 510.255 364.051Z"
        fill="#4B8945"
      />
      <path
        d="M503.815 363.662C503.863 363.662 503.91 363.636 503.935 363.592C503.973 363.525 503.949 363.441 503.883 363.404C502.206 362.453 500.459 361.579 498.693 360.805C498.623 360.774 498.541 360.806 498.511 360.876C498.48 360.945 498.512 361.027 498.582 361.057C500.34 361.827 502.078 362.698 503.747 363.644C503.769 363.656 503.792 363.662 503.815 363.662Z"
        fill="#4B8945"
      />
      <path
        opacity="0.14"
        d="M497.373 359.438C508.226 366.115 532.744 379.487 544.663 372.231C545.004 374.279 544.847 376.448 544.084 378.711C543.607 380.126 541.67 382.437 539.59 384.389C522.645 386.321 499.466 369.347 493.467 364.688C494.411 362.607 495.745 360.866 497.373 359.438Z"
        fill="#212120"
      />
      <path
        d="M496.689 371.307C496.752 371.307 496.81 371.263 496.824 371.198C497.371 368.696 498.9 366.437 501.02 365C501.083 364.958 501.099 364.872 501.056 364.809C501.013 364.745 500.928 364.729 500.864 364.772C498.687 366.248 497.116 368.569 496.554 371.139C496.538 371.214 496.585 371.287 496.659 371.303C496.669 371.305 496.679 371.307 496.689 371.307Z"
        fill="#4B8945"
      />
      <path
        d="M503.461 372.768C503.471 372.768 503.481 372.767 503.492 372.764C503.566 372.747 503.612 372.672 503.595 372.598C503.176 370.816 503.666 369.055 504.151 367.891C504.777 366.389 505.718 365.023 506.628 363.702C506.671 363.639 506.655 363.554 506.593 363.511C506.53 363.468 506.444 363.483 506.401 363.546C505.482 364.879 504.533 366.257 503.896 367.785C503.395 368.987 502.89 370.808 503.326 372.661C503.341 372.725 503.398 372.768 503.461 372.768Z"
        fill="#4B8945"
      />
      <path
        d="M509.363 374.408C509.366 374.408 509.369 374.408 509.372 374.407C509.448 374.402 509.505 374.337 509.501 374.261C509.271 370.718 511.127 367.052 514.118 365.139C514.182 365.098 514.201 365.013 514.16 364.949C514.119 364.884 514.034 364.865 513.969 364.907C512.451 365.878 511.198 367.279 510.347 368.96C509.497 370.64 509.109 372.48 509.225 374.278C509.23 374.352 509.291 374.408 509.363 374.408Z"
        fill="#4B8945"
      />
      <path
        d="M515.096 378.111C515.162 378.111 515.221 378.063 515.232 377.995C515.902 373.918 518.433 370.243 522.004 368.163C522.069 368.125 522.092 368.041 522.054 367.975C522.015 367.909 521.93 367.887 521.865 367.925C518.224 370.045 515.643 373.793 514.96 377.951C514.947 378.026 514.998 378.097 515.073 378.109C515.081 378.111 515.089 378.111 515.096 378.111Z"
        fill="#4B8945"
      />
      <path
        d="M518.275 379.893C518.318 379.893 518.36 379.873 518.387 379.836C520.924 376.351 524.994 373.97 529.275 373.466C529.35 373.458 529.405 373.389 529.395 373.313C529.387 373.237 529.317 373.184 529.243 373.192C524.887 373.705 520.745 376.127 518.163 379.673C518.119 379.735 518.132 379.821 518.194 379.866C518.219 379.884 518.247 379.893 518.275 379.893Z"
        fill="#4B8945"
      />
      <path
        d="M521.953 384.412C522.001 384.412 522.046 384.388 522.072 384.345C524.637 380.029 530.052 377.708 534.947 378.827C535.021 378.844 535.095 378.797 535.112 378.723C535.129 378.649 535.082 378.575 535.008 378.558C532.599 378.007 529.944 378.273 527.532 379.307C525.121 380.34 523.098 382.079 521.835 384.204C521.796 384.27 521.818 384.354 521.883 384.393C521.905 384.406 521.929 384.412 521.953 384.412Z"
        fill="#4B8945"
      />
      <path
        d="M526.698 386.785C526.721 386.785 526.744 386.78 526.766 386.768C530.401 384.728 534.525 383.694 538.693 383.781C538.768 383.783 538.832 383.722 538.834 383.646C538.836 383.569 538.775 383.507 538.699 383.505C534.478 383.418 530.309 384.463 526.631 386.527C526.564 386.565 526.541 386.649 526.578 386.715C526.603 386.76 526.65 386.785 526.698 386.785Z"
        fill="#4B8945"
      />
      <path
        opacity="0.23"
        d="M539.59 384.389C537.925 385.952 536.168 387.286 534.994 387.743C528.81 390.154 522.851 386.567 519.362 382.559C514.257 376.696 508.816 377.581 501.46 376.217C494.295 374.888 490.361 374.756 492.654 366.886C492.879 366.115 493.152 365.383 493.467 364.688C499.466 369.347 522.644 386.321 539.59 384.389Z"
        fill="#212120"
      />
      <path
        d="M561.083 357.919C564.396 357.149 567.63 355.55 569.856 352.901C571.717 350.687 572.759 347.901 573.765 345.178C574.603 342.91 575.442 340.641 576.152 338.331C578.149 331.831 579.116 325.018 579.003 318.242C578.958 315.582 578.433 312.437 576.07 311.365C574.806 310.791 573.332 310.997 571.964 311.288C562.556 313.293 553.889 319.002 548.17 326.963C546.921 328.702 545.801 330.556 545.057 332.569C544.286 334.65 543.931 336.86 543.673 339.062C543.274 342.45 543.095 345.911 543.792 349.229C544.521 352.696 546.568 356.935 549.943 358.415C552.93 359.725 557.999 358.636 561.083 357.919Z"
        fill="#87AA44"
      />
      <path
        d="M549.767 360.119C549.88 360.119 549.978 360.037 549.995 359.922C551.127 352.092 552.932 343.07 557.737 335.528C559.572 332.647 561.83 330.013 564.015 327.466L574.724 314.977C574.807 314.881 574.795 314.736 574.699 314.653C574.603 314.57 574.457 314.581 574.375 314.678L563.665 327.167C561.471 329.726 559.201 332.372 557.349 335.281C552.498 342.897 550.679 351.978 549.539 359.857C549.521 359.982 549.608 360.099 549.734 360.117C549.745 360.119 549.757 360.119 549.767 360.119Z"
        fill="#4B8945"
      />
      <path
        d="M567.543 322.76C567.559 322.76 567.575 322.757 567.591 322.751C570.99 321.478 574.307 319.896 577.452 318.048C577.517 318.009 577.539 317.925 577.5 317.859C577.462 317.793 577.377 317.771 577.312 317.81C574.181 319.65 570.878 321.225 567.494 322.493C567.423 322.52 567.387 322.599 567.413 322.671C567.434 322.726 567.487 322.76 567.543 322.76Z"
        fill="#4B8945"
      />
      <path
        d="M569.247 320.626C569.324 320.626 569.385 320.564 569.385 320.488C569.378 318.025 569.956 315.554 571.058 313.344C571.092 313.276 571.064 313.193 570.996 313.159C570.928 313.125 570.845 313.153 570.811 313.221C569.69 315.47 569.101 317.983 569.109 320.489C569.11 320.565 569.172 320.626 569.247 320.626Z"
        fill="#4B8945"
      />
      <path
        d="M563.867 327.025C563.871 327.025 563.874 327.025 563.878 327.025C563.954 327.019 564.011 326.953 564.005 326.877C563.766 323.718 564.102 320.569 565.004 317.517C565.026 317.444 564.984 317.367 564.911 317.345C564.838 317.324 564.761 317.366 564.739 317.439C563.828 320.523 563.488 323.705 563.73 326.897C563.735 326.97 563.796 327.025 563.867 327.025Z"
        fill="#4B8945"
      />
      <path
        d="M559.176 332.803C559.184 332.803 559.193 332.802 559.201 332.801C564.779 331.75 569.887 328.522 574.395 325.674C574.459 325.634 574.478 325.549 574.438 325.484C574.397 325.42 574.312 325.401 574.247 325.441C569.761 328.276 564.676 331.488 559.15 332.53C559.075 332.544 559.026 332.616 559.04 332.691C559.053 332.757 559.11 332.803 559.176 332.803Z"
        fill="#4B8945"
      />
      <path
        d="M556.906 336.031C556.91 336.031 556.914 336.031 556.919 336.03C556.995 336.023 557.05 335.955 557.043 335.88C556.61 331.383 556.841 326.847 557.732 322.398C557.747 322.323 557.698 322.25 557.624 322.235C557.549 322.22 557.476 322.269 557.461 322.343C556.566 326.819 556.333 331.383 556.769 335.906C556.775 335.977 556.835 336.031 556.906 336.031Z"
        fill="#4B8945"
      />
      <path
        d="M554.197 342.575C554.214 342.575 554.232 342.573 554.249 342.568C554.343 342.544 554.442 342.452 554.437 342.345C554.436 342.303 554.416 342.257 554.377 342.224C559.868 337.572 567.039 335.288 573.367 333.273C573.439 333.25 573.479 333.173 573.456 333.1C573.433 333.027 573.356 332.987 573.283 333.01C566.926 335.034 559.721 337.329 554.186 342.024C553.952 342.223 553.985 342.387 554.025 342.468C554.059 342.536 554.124 342.575 554.197 342.575Z"
        fill="#4B8945"
      />
      <path
        d="M552.075 347.862C552.076 347.862 552.077 347.862 552.078 347.862C552.154 347.86 552.214 347.797 552.213 347.721C552.189 346.698 551.889 345.714 551.599 344.762C551.457 344.297 551.31 343.816 551.198 343.339C550.821 341.726 550.813 340.029 550.804 338.387L550.748 327.327C550.747 327.251 550.686 327.189 550.61 327.189H550.609C550.533 327.19 550.471 327.252 550.472 327.328L550.529 338.389C550.537 340.045 550.546 341.758 550.93 343.401C551.044 343.887 551.192 344.373 551.335 344.842C551.62 345.776 551.915 346.742 551.937 347.727C551.939 347.803 552 347.862 552.075 347.862Z"
        fill="#4B8945"
      />
      <path
        d="M551.152 351.503C551.178 351.503 551.204 351.497 551.227 351.482C554.965 349.082 558.909 346.954 562.95 345.156C565.157 344.175 567.791 343.101 570.594 342.58C570.669 342.566 570.719 342.494 570.705 342.42C570.691 342.345 570.619 342.295 570.544 342.309C567.713 342.835 565.06 343.916 562.838 344.904C558.784 346.707 554.828 348.842 551.078 351.25C551.014 351.291 550.995 351.376 551.036 351.44C551.062 351.481 551.107 351.503 551.152 351.503Z"
        fill="#4B8945"
      />
      <path
        d="M549.87 354.203C549.896 354.203 549.923 354.196 549.946 354.18C550.01 354.138 550.027 354.053 549.985 353.989C548.927 352.395 547.834 350.746 547.4 348.83C547.192 347.911 547.145 346.947 547.099 346.013C546.916 342.315 546.727 338.491 547.529 334.788C547.545 334.714 547.498 334.64 547.424 334.624C547.349 334.608 547.276 334.656 547.26 334.73C546.45 338.468 546.64 342.311 546.824 346.027C546.87 346.972 546.918 347.949 547.131 348.891C547.576 350.856 548.734 352.602 549.755 354.142C549.782 354.181 549.825 354.203 549.87 354.203Z"
        fill="#4B8945"
      />
      <path
        d="M549.943 355.983C549.952 355.983 549.961 355.982 549.97 355.98L553.285 355.322C558.026 354.381 562.929 353.407 567.259 350.918C567.325 350.88 567.348 350.795 567.31 350.729C567.272 350.663 567.188 350.641 567.122 350.678C562.831 353.145 557.951 354.115 553.231 355.052L549.917 355.71C549.842 355.725 549.794 355.797 549.808 355.872C549.821 355.937 549.879 355.983 549.943 355.983Z"
        fill="#4B8945"
      />
      <path
        opacity="0.14"
        d="M576.005 311.338C576.026 311.347 576.049 311.355 576.07 311.365C577.123 311.843 577.811 312.734 578.254 313.809C570.13 318.546 539.755 338.297 560.433 358.068C557.92 358.637 554.436 359.31 551.721 358.902C533.874 334.02 562.815 317.334 576.005 311.338Z"
        fill="#212120"
      />
      <path
        opacity="0.23"
        d="M545.058 332.568C545.802 330.555 546.922 328.701 548.171 326.962C553.89 319.001 562.556 313.292 571.964 311.287C573.309 311.001 574.756 310.798 576.006 311.337C562.816 317.333 533.875 334.019 551.722 358.901C551.078 358.805 550.477 358.648 549.944 358.414C546.568 356.934 544.522 352.695 543.793 349.228C543.095 345.91 543.274 342.449 543.673 339.061C543.932 336.86 544.288 334.65 545.058 332.568Z"
        fill="#212120"
      />
      <path
        d="M561.756 384.531C565.697 381.399 570.468 379.142 575.494 378.858C580.52 378.574 585.783 380.454 588.96 384.358C589.686 385.251 590.309 386.262 590.544 387.388C591.133 390.213 589.182 392.943 587.007 394.839C582.874 398.441 577.556 400.557 572.116 401.23C568.909 401.627 565.528 401.515 562.627 400.09C555.627 396.652 556.184 388.96 561.756 384.531Z"
        fill="#87AA44"
      />
      <path
        d="M557.363 369.704C557.369 369.704 557.376 369.704 557.382 369.703C557.458 369.692 557.51 369.622 557.5 369.547C557.052 366.378 557.163 363.184 557.831 360.053C557.847 359.979 557.799 359.906 557.725 359.89C557.65 359.874 557.577 359.921 557.561 359.996C556.886 363.158 556.774 366.384 557.226 369.585C557.236 369.654 557.295 369.704 557.363 369.704Z"
        fill="#4B8945"
      />
      <path
        d="M548.154 401.567C548.236 401.567 548.316 401.523 548.357 401.445C549.221 399.817 550.823 398.922 552.519 397.974C554 397.147 555.531 396.291 556.55 394.897C558.075 392.811 563.72 390.274 570.597 388.586C577.667 386.85 583.794 386.573 586.205 387.88C586.317 387.941 586.456 387.899 586.517 387.788C586.578 387.676 586.536 387.537 586.425 387.476C583.382 385.828 576.197 386.738 570.488 388.139C565.109 389.46 558.134 391.951 556.179 394.626C555.22 395.939 553.734 396.769 552.296 397.573C550.616 398.511 548.879 399.482 547.952 401.229C547.892 401.341 547.935 401.481 548.047 401.54C548.081 401.559 548.118 401.567 548.154 401.567Z"
        fill="#4B8945"
      />
      <path
        d="M575.682 387.836C575.715 387.836 575.748 387.824 575.775 387.8L582.914 381.346C582.97 381.295 582.974 381.207 582.923 381.151C582.872 381.094 582.785 381.09 582.728 381.141L575.59 387.595C575.533 387.646 575.529 387.734 575.58 387.79C575.607 387.82 575.645 387.836 575.682 387.836Z"
        fill="#4B8945"
      />
      <path
        d="M567.118 389.297C567.159 389.297 567.2 389.278 567.228 389.242C569.392 386.386 571.868 383.757 574.589 381.426C574.646 381.377 574.653 381.289 574.603 381.232C574.554 381.174 574.467 381.167 574.409 381.217C571.674 383.561 569.183 386.205 567.008 389.076C566.962 389.136 566.974 389.223 567.034 389.269C567.06 389.288 567.089 389.297 567.118 389.297Z"
        fill="#4B8945"
      />
      <path
        d="M584.868 392.995C584.911 392.995 584.954 392.975 584.981 392.937C585.025 392.875 585.009 392.788 584.947 392.745C582.173 390.793 579.603 388.537 577.307 386.041C577.256 385.985 577.168 385.981 577.112 386.032C577.056 386.084 577.052 386.171 577.104 386.227C579.413 388.739 581.999 391.007 584.789 392.97C584.813 392.987 584.841 392.995 584.868 392.995Z"
        fill="#4B8945"
      />
      <path
        d="M574.501 397.781C574.53 397.781 574.559 397.772 574.584 397.753C574.645 397.707 574.657 397.621 574.611 397.56C572.307 394.514 570.375 391.19 568.87 387.679C568.84 387.609 568.759 387.577 568.689 387.607C568.619 387.637 568.587 387.718 568.617 387.788C570.131 391.319 572.073 394.663 574.391 397.727C574.418 397.762 574.459 397.781 574.501 397.781Z"
        fill="#4B8945"
      />
      <path
        d="M568.069 398.209C568.093 398.209 568.118 398.203 568.14 398.189C568.205 398.15 568.227 398.066 568.187 398C566.588 395.33 564.73 392.796 562.664 390.468C563.561 389.352 564.091 388.226 564.604 387.137C565.125 386.033 565.662 384.892 566.583 383.773C566.632 383.714 566.623 383.627 566.564 383.578C566.506 383.53 566.419 383.538 566.37 383.597C565.427 384.743 564.882 385.9 564.355 387.02C563.834 388.123 563.297 389.265 562.376 390.384C562.332 390.436 562.334 390.513 562.379 390.563C564.465 392.903 566.34 395.453 567.95 398.142C567.977 398.185 568.023 398.209 568.069 398.209Z"
        fill="#4B8945"
      />
      <path
        opacity="0.23"
        d="M561.754 384.531C565.695 381.399 570.466 379.142 575.492 378.858C578.453 378.69 581.494 379.28 584.145 380.587C574.925 381.789 564.636 386.102 560.239 398.516C555.864 394.67 556.919 388.373 561.754 384.531Z"
        fill="#212120"
      />
      <path
        opacity="0.14"
        d="M584.146 380.588C585.994 381.499 587.654 382.755 588.959 384.359C589.252 384.719 589.527 385.1 589.768 385.499C580.285 384.861 568.152 386.967 565.48 401.069C564.495 400.856 563.536 400.538 562.626 400.091C561.699 399.635 560.908 399.104 560.24 398.517C564.638 386.103 574.926 381.79 584.146 380.588Z"
        fill="#212120"
      />
      <path
        d="M520.079 444.604H516.385V577.605H520.079V444.604Z"
        fill="#DDBC91"
      />
      <path
        d="M638.302 444.604H634.607V577.605H638.302V444.604Z"
        fill="#DDBC91"
      />
      <path
        d="M634.607 446.449H519.463V577.603H634.607V446.449Z"
        fill="#D0AC78"
      />
      <path d="M650 441.213H504.092V445.79H650V441.213Z" fill="#DDBC91" />
      <path
        d="M644.831 444.949H509.264V449.202H644.831V444.949Z"
        fill="#DDBC91"
      />
      <path
        d="M634.606 573.908H518.23V577.603H634.606V573.908Z"
        fill="#DDBC91"
      />
      <path
        d="M531.318 587.477L531.186 588.675L529.466 604.246C529.417 604.722 529.089 605.08 528.707 605.08H525.053C524.671 605.08 524.347 604.727 524.289 604.25L524.273 604.09L522.52 588.675L522.385 587.477H531.318Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.6"
        d="M531.318 587.477L531.187 588.675C530.879 588.79 530.542 588.848 530.197 588.848H523.506C523.161 588.848 522.828 588.79 522.52 588.675L522.385 587.477H531.318Z"
        fill="#DDBC91"
      />
      <path
        d="M533.182 585.178C533.182 586.003 532.849 586.75 532.308 587.288C531.77 587.83 531.023 588.163 530.198 588.163H523.506C521.856 588.163 520.521 586.829 520.521 585.178C520.521 584.837 520.579 584.509 520.69 584.205C520.834 583.77 521.08 583.384 521.396 583.068C521.934 582.53 522.681 582.193 523.506 582.193H530.198C531.507 582.193 532.615 583.035 533.014 584.205C533.125 584.509 533.182 584.837 533.182 585.178Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.6"
        d="M533.013 584.205C532.841 584.238 532.668 584.254 532.488 584.254H521.215C521.034 584.254 520.858 584.238 520.689 584.205C520.833 583.77 521.079 583.384 521.396 583.068C521.933 582.53 522.681 582.193 523.506 582.193H530.197C531.507 582.194 532.615 583.035 533.013 584.205Z"
        fill="#DDBC91"
      />
      <path
        d="M533.175 583.572H520.526C519.258 583.572 518.23 582.544 518.23 581.276V579.899C518.23 578.631 519.258 577.604 520.526 577.604H533.175C534.443 577.604 535.471 578.632 535.471 579.899V581.276C535.471 582.544 534.443 583.572 533.175 583.572Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.28"
        d="M531.318 587.477L531.187 588.675L529.467 604.246C529.417 604.722 529.089 605.08 528.707 605.08H525.054C524.672 605.08 524.347 604.727 524.29 604.25L524.273 604.09C524.902 603.967 525.517 603.807 526.112 603.577C527.221 603.146 528.255 602.436 528.859 601.414C529.405 600.486 529.561 599.386 529.688 598.318C529.992 595.806 530.214 593.285 530.366 590.761C530.427 589.73 530.279 588.478 530.559 587.477H531.318Z"
        fill="#DDBC91"
      />
      <path
        d="M630.452 587.477L630.321 588.675L628.601 604.246C628.552 604.722 628.223 605.08 627.841 605.08H624.188C623.806 605.08 623.482 604.727 623.424 604.25L623.408 604.09L621.655 588.675L621.52 587.477H630.452Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.6"
        d="M630.453 587.477L630.321 588.675C630.013 588.79 629.677 588.848 629.332 588.848H622.64C622.296 588.848 621.963 588.79 621.655 588.675L621.52 587.477H630.453Z"
        fill="#DDBC91"
      />
      <path
        d="M632.317 585.178C632.317 586.003 631.984 586.75 631.442 587.288C630.904 587.83 630.157 588.163 629.332 588.163H622.641C620.991 588.163 619.656 586.829 619.656 585.178C619.656 584.837 619.714 584.509 619.825 584.205C619.968 583.77 620.215 583.384 620.531 583.068C621.069 582.53 621.816 582.193 622.641 582.193H629.332C630.642 582.193 631.75 583.035 632.148 584.205C632.259 584.509 632.317 584.837 632.317 585.178Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.6"
        d="M632.148 584.205C631.975 584.238 631.803 584.254 631.622 584.254H620.35C620.169 584.254 619.993 584.238 619.824 584.205C619.968 583.77 620.214 583.384 620.53 583.068C621.068 582.53 621.815 582.193 622.64 582.193H629.332C630.641 582.194 631.75 583.035 632.148 584.205Z"
        fill="#DDBC91"
      />
      <path
        d="M632.31 583.572H619.661C618.393 583.572 617.365 582.544 617.365 581.276V579.899C617.365 578.631 618.393 577.604 619.661 577.604H632.31C633.578 577.604 634.606 578.632 634.606 579.899V581.276C634.606 582.544 633.578 583.572 632.31 583.572Z"
        fill="#DDBC91"
      />
      <path
        opacity="0.28"
        d="M630.453 587.477L630.321 588.675L628.601 604.246C628.552 604.722 628.223 605.08 627.842 605.08H624.188C623.806 605.08 623.482 604.727 623.425 604.25L623.408 604.09C624.036 603.967 624.652 603.807 625.247 603.577C626.356 603.146 627.39 602.436 627.994 601.414C628.54 600.486 628.696 599.386 628.823 598.318C629.127 595.806 629.348 593.285 629.5 590.761C629.562 589.73 629.414 588.478 629.693 587.477H630.453Z"
        fill="#DDBC91"
      />
      <path
        d="M630.456 451.99H522.387V482.163H630.456V451.99Z"
        fill="#DDBC91"
      />
      <path
        d="M630.456 485.215H522.387V525.01H630.456V485.215Z"
        fill="#DDBC91"
      />
      <path
        d="M630.456 528.568H522.387V568.368H630.456V528.568Z"
        fill="#DDBC91"
      />
      <g opacity="0.14">
        <path
          d="M548.104 485.215H560.009L522.385 514.304V507.297L548.104 485.215Z"
          fill="#DDBC91"
        />
        <path
          d="M602.981 451.992L563.954 482.165H551.654L586.799 451.992H602.981Z"
          fill="#DDBC91"
        />
        <path
          d="M547.628 528.57H557.919L522.385 556.047V550.242L547.628 528.57Z"
          fill="#DDBC91"
        />
        <path
          d="M613.989 485.215L562.523 525.01H551.775L598.123 485.215H613.989Z"
          fill="#DDBC91"
        />
        <path
          d="M630.453 457.457V472.486L617.933 482.166H601.676L630.453 457.457Z"
          fill="#DDBC91"
        />
        <path
          d="M597.071 528.57H600.577L552.858 568.37H550.715L597.071 528.57Z"
          fill="#DDBC91"
        />
        <path
          d="M630.452 499.908V503.648L604.84 525.011H601.215L630.452 499.908Z"
          fill="#DDBC91"
        />
        <path
          d="M527.861 528.57H530.123L522.385 535.024V533.271L527.861 528.57Z"
          fill="#DDBC91"
        />
        <path
          d="M582.099 485.215L534.389 525.01H532.008L578.359 485.215H582.099Z"
          fill="#DDBC91"
        />
        <path
          d="M621.932 451.992L585.757 482.165H581.91L617.055 451.992H621.932Z"
          fill="#DDBC91"
        />
        <path
          d="M522.385 485.215H524.663L522.385 487.115V485.215Z"
          fill="#DDBC91"
        />
        <path
          d="M564.496 451.992L528.321 482.165H525.48L560.625 451.992H564.496Z"
          fill="#DDBC91"
        />
        <path
          d="M609.666 528.57H613.722L566.007 568.37H563.314L609.666 528.57Z"
          fill="#DDBC91"
        />
        <path
          d="M630.455 510.721V514.612L617.991 525.011H613.812L630.455 510.721Z"
          fill="#DDBC91"
        />
      </g>
      <path
        opacity="0.58"
        d="M611.353 471.452H549.419C548.11 471.452 547.049 470.391 547.049 469.082V467.661C547.049 466.352 548.11 465.291 549.419 465.291H611.353C612.662 465.291 613.723 466.352 613.723 467.661V469.082C613.723 470.391 612.662 471.452 611.353 471.452Z"
        fill="#C89F6B"
      />
      <path
        opacity="0.58"
        d="M611.353 504.628H549.419C548.11 504.628 547.049 503.567 547.049 502.258V500.837C547.049 499.528 548.11 498.467 549.419 498.467H611.353C612.662 498.467 613.723 499.528 613.723 500.837V502.258C613.723 503.567 612.662 504.628 611.353 504.628Z"
        fill="#C89F6B"
      />
      <path
        opacity="0.58"
        d="M611.353 547.028H549.419C548.11 547.028 547.049 545.967 547.049 544.658V543.237C547.049 541.928 548.11 540.867 549.419 540.867H611.353C612.662 540.867 613.723 541.928 613.723 543.237V544.658C613.723 545.967 612.662 547.028 611.353 547.028Z"
        fill="#C89F6B"
      />
      <path
        d="M611.353 469.809H549.419C548.11 469.809 547.049 468.748 547.049 467.44V466.018C547.049 464.71 548.11 463.648 549.419 463.648H611.353C612.662 463.648 613.723 464.71 613.723 466.018V467.44C613.723 468.748 612.662 469.809 611.353 469.809Z"
        fill="#C89F6B"
      />
      <path
        d="M611.353 502.987H549.419C548.11 502.987 547.049 501.926 547.049 500.617V499.196C547.049 497.887 548.11 496.826 549.419 496.826H611.353C612.662 496.826 613.723 497.887 613.723 499.196V500.617C613.723 501.926 612.662 502.987 611.353 502.987Z"
        fill="#C89F6B"
      />
      <path
        d="M611.353 545.388H549.419C548.11 545.388 547.049 544.326 547.049 543.018V541.596C547.049 540.288 548.11 539.227 549.419 539.227H611.353C612.662 539.227 613.723 540.288 613.723 541.596V543.018C613.723 544.327 612.662 545.388 611.353 545.388Z"
        fill="#C89F6B"
      />
      <path
        d="M30.2906 71.4856C27.2593 74.6758 24.4737 78.0971 21.9465 81.6994C21.4687 82.3804 21.801 83.491 22.5153 83.8683C23.3276 84.2976 24.1744 84.0262 24.6842 83.2996C25.2895 82.4365 25.9096 81.584 26.5456 80.7435C26.6195 80.6458 26.6937 80.5482 26.7682 80.4509C26.7784 80.4375 27.0681 80.0612 26.8789 80.3053C27.0288 80.1116 27.1801 79.9185 27.3319 79.7261C27.6658 79.3028 28.0038 78.8824 28.3459 78.4655C29.6822 76.8349 31.0804 75.2557 32.5328 73.7275C33.1045 73.126 33.1748 72.0747 32.5328 71.4856C31.913 70.9171 30.901 70.8436 30.2906 71.4856Z"
        fill="#DDD7C7"
      />
      <path
        d="M77.1587 72.8033C77.6333 75.0641 78.049 77.3371 78.4037 79.6198C78.4909 80.1813 78.5746 80.7435 78.6545 81.3059C78.6945 81.5878 78.7335 81.8696 78.7718 82.1515C78.7874 82.2663 78.7858 82.2542 78.7668 82.115C78.7769 82.1921 78.787 82.2691 78.7972 82.3462C78.814 82.4746 78.8305 82.603 78.847 82.7314C78.9985 83.9159 79.1332 85.1023 79.2537 86.2903C79.3371 87.1127 79.923 87.9164 80.839 87.8755C81.6262 87.8403 82.5136 87.1739 82.4242 86.2903C81.9366 81.4797 81.2093 76.6925 80.2157 71.9602C80.0451 71.1473 79.0454 70.6007 78.2655 70.8531C77.4072 71.1311 76.9757 71.9323 77.1587 72.8033Z"
        fill="#DDD7C7"
      />
      <path
        d="M114.05 62.0783C108.167 56.0591 102.232 50.091 96.245 44.1754C90.2585 38.2605 84.2202 32.398 78.131 26.589C74.6945 23.3104 71.2415 20.0492 67.773 16.8045C67.1671 16.2379 66.1234 16.1593 65.5311 16.8045C64.9661 17.4199 64.8843 18.4414 65.5311 19.0463C71.6768 24.7951 77.7724 30.5968 83.8167 36.4518C89.8616 42.3074 95.8554 48.2162 101.797 54.1768C105.151 57.5413 108.488 60.9226 111.808 64.3202C112.389 64.9143 113.475 64.946 114.05 64.3202C114.636 63.6813 114.67 62.7121 114.05 62.0783Z"
        fill="#DDD7C7"
      />
      <path
        d="M69.0536 60.486C70.3005 55.0863 69.8766 49.5246 69.5938 44.0447C69.3053 38.4564 69.0171 32.8681 68.7286 27.2794C68.5653 24.1162 68.402 20.953 68.2388 17.7898C68.196 16.9629 67.5403 16.1649 66.6535 16.2045C65.8311 16.2413 65.0226 16.9027 65.0683 17.7898C65.3552 23.3518 65.6424 28.9138 65.9293 34.4758C66.0723 37.2437 66.215 40.0115 66.358 42.7794C66.4978 45.4886 66.6738 48.199 66.7229 50.912C66.7502 52.411 66.7318 53.9123 66.6234 55.4081C66.5961 55.787 66.5628 56.1655 66.5232 56.5431C66.5048 56.7194 66.4848 56.8954 66.4636 57.0714C66.4544 57.1468 66.4446 57.222 66.4354 57.2974C66.4011 57.5748 66.4924 56.9233 66.4195 57.4023C66.3051 58.1547 66.1675 58.9014 65.9963 59.6429C65.8102 60.4492 66.2448 61.3975 67.1034 61.5931C67.9122 61.7773 68.8538 61.3503 69.0536 60.486Z"
        fill="#DDD7C7"
      />
      <path
        d="M77.9033 56.8581C75.1475 47.3371 72.891 37.6728 71.153 27.9143C70.6577 25.1334 70.205 22.3453 69.7931 19.5508C69.6717 18.7278 68.5886 18.2024 67.8429 18.4437C66.9438 18.7347 66.6058 19.5115 66.7358 20.3939C68.1813 30.1996 70.1406 39.9286 72.6108 49.5279C73.3146 52.2634 74.0606 54.9879 74.8456 57.7012C75.0761 58.497 75.9737 59.0743 76.7958 58.8083C77.6049 58.5467 78.1503 57.7116 77.9033 56.8581Z"
        fill="#DDD7C7"
      />
      <path
        d="M47.8222 59.8299C50.3333 54.6284 52.8443 49.4269 55.3553 44.2253C57.8664 39.0238 60.3774 33.8223 62.8884 28.6208C64.3101 25.6757 65.7321 22.7306 67.1537 19.7855C67.5136 19.0398 67.3826 18.0379 66.5849 17.6166C65.8751 17.2415 64.8009 17.388 64.416 18.1854C61.9049 23.3869 59.3939 28.5884 56.8829 33.7899C54.3719 38.9915 51.8608 44.193 49.3498 49.3945C47.9281 52.3396 46.5062 55.2847 45.0845 58.2301C44.7247 58.9755 44.8556 59.9776 45.6533 60.399C46.3632 60.7738 47.4373 60.6273 47.8222 59.8299Z"
        fill="#DDD7C7"
      />
      <path
        d="M35.5343 63.0897C41.1185 58.5616 45.2319 52.5338 48.7914 46.357C52.3747 40.1394 55.4916 33.6462 59.4554 27.652C59.9877 26.847 60.535 26.0519 61.1003 25.2697C61.3935 24.8642 61.6915 24.4618 61.9946 24.0636C62.0644 23.9717 62.1342 23.8797 62.2052 23.7888C61.9379 24.1296 62.2613 23.7184 62.3117 23.6547C62.4544 23.4733 62.5983 23.2932 62.7432 23.1137C64.0019 21.557 65.3512 20.0751 66.8002 18.6934C67.4016 18.1199 67.4209 17.0213 66.8002 16.4516C66.155 15.8593 65.2 15.8397 64.5583 16.4516C59.3178 21.4489 55.4754 27.6786 51.9898 33.9689C48.5279 40.2158 45.3441 46.6623 41.1359 52.4574C40.8553 52.8439 40.57 53.2272 40.2802 53.607C40.2234 53.6815 40.0592 53.9035 40.2837 53.6039C40.1924 53.7259 40.096 53.8448 40.0018 53.9646C39.8601 54.1444 39.7174 54.3229 39.5732 54.5008C38.9498 55.2706 38.304 56.0223 37.6341 56.7518C36.287 58.2191 34.8399 59.5929 33.2927 60.8475C32.646 61.3722 32.7065 62.5513 33.2927 63.0894C33.9795 63.7203 34.8444 63.6493 35.5343 63.0897Z"
        fill="#DDD7C7"
      />
      <path
        d="M13.4819 64.9891C20.0896 58.7397 26.779 52.5772 33.5499 46.5051C40.3208 40.4333 47.1723 34.4511 54.1024 28.5613C57.9958 25.2526 61.9139 21.973 65.8558 18.7223C66.4981 18.1925 66.4455 17.0216 65.8558 16.4804C65.1728 15.8536 64.299 15.9154 63.6139 16.4804C56.5969 22.2666 49.6564 28.145 42.7954 34.1151C35.9345 40.0852 29.1531 46.1465 22.4535 52.297C18.6898 55.7522 14.9517 59.2359 11.2397 62.7469C10.6361 63.3179 10.6208 64.4206 11.2397 64.9888C11.8875 65.5833 12.838 65.5985 13.4819 64.9891Z"
        fill="#DDD7C7"
      />
      <path
        d="M5.46148 83.9001C11.5492 88.035 25.4179 90.3983 25.4179 90.3983C38.7251 92.455 36.4357 92.1357 49.4836 93.0786C58.6029 93.7375 65.2549 93.241 75.1637 92.8164C83.3426 92.4661 88.8124 92.2185 96.1997 91.2445C101.3 90.572 107.783 90.0093 115.596 87.5766C119.27 86.433 123.741 84.757 126.784 80.8123C127.752 79.558 129.244 77.5622 128.96 74.919C128.689 72.3921 126.27 70.7193 125.592 70.1309C124.953 71.5249 124.541 73.4884 122.688 75.4101C118.476 79.7774 113.11 81.0459 109.502 81.7942C96.6172 84.4663 84.751 84.9406 79.4176 85.109C66.1186 85.5294 57.0535 85.2006 54.7102 85.109C47.5306 84.8281 41.3287 84.5972 33.1038 83.7399C30.5842 83.4774 25.4382 82.7384 15.4872 80.4055C12.1493 79.623 4.40982 77.3726 2.99165 73.4684C2.84771 73.0724 2.48439 71.3398 2.01579 71.3239C1.2926 71.2995 0.381694 74.3717 0.47998 76.5882C0.579851 78.8399 1.77261 80.4778 2.62104 81.643C2.72503 81.7853 3.34105 82.4594 5.46148 83.9001Z"
        fill="#E6D5A3"
      />
      <path
        d="M0.568631 67.72C7.15884 63.0527 22.1724 60.3847 22.1724 60.3847C36.5782 58.0633 34.0995 58.4234 48.2244 57.3591C58.0964 56.6153 65.2979 57.1758 76.0243 57.6549C84.8783 58.0503 90.7995 58.3299 98.7968 59.4295C104.319 60.1888 111.336 60.8235 119.795 63.5698C123.771 64.8608 128.612 66.7524 131.906 71.205C132.954 72.621 134.569 74.8739 134.262 77.857C133.968 80.7095 131.35 82.5976 130.616 83.2618C129.924 81.6883 129.478 79.4718 127.471 77.3025C122.912 72.3727 117.103 70.9406 113.197 70.0963C99.2489 67.0799 86.4033 66.5447 80.6298 66.3545C66.2329 65.8799 56.4196 66.2508 53.8829 66.3545C46.1107 66.6718 39.3971 66.9321 30.4931 67.8998C27.7655 68.1962 22.1946 69.0304 11.4222 71.6638C7.80878 72.5471 -0.569574 75.0873 -2.10505 79.4943C-2.26072 79.9413 -2.65419 81.8972 -3.16147 81.915C-3.94427 81.9426 -4.93062 78.4746 -4.82409 75.9728C-4.71597 73.4313 -3.42462 71.5823 -2.50612 70.2672C-2.39357 70.1071 -1.72681 69.3462 0.568631 67.72Z"
        fill="#E6D5A3"
      />
      <path
        d="M64.2533 65.3477C26.7942 65.3477 -3.57227 72.0266 -3.57227 80.2658C-3.57227 88.5047 26.7942 95.1837 64.2533 95.1837C101.712 95.1837 132.079 88.5047 132.079 80.2658C132.079 72.0266 101.712 65.3477 64.2533 65.3477ZM64.1677 92.639C33.3523 92.639 8.37132 88.2165 8.37132 82.7607C8.37132 77.3052 33.352 72.8827 64.1677 72.8827C94.9831 72.8827 119.964 77.3052 119.964 82.7607C119.964 88.2165 94.9831 92.639 64.1677 92.639Z"
        fill="#BC957A"
      />
      <path
        d="M65.2316 69.495C64.1568 77.7089 63.3169 85.961 62.7361 94.2383C62.1644 102.385 61.8379 110.553 61.7577 118.725C61.7123 123.34 61.7469 127.955 61.8579 132.569C61.8804 133.502 62.5709 134.4 63.4431 134.356C64.2827 134.313 65.0525 133.57 65.0284 132.569C64.8324 124.429 64.8781 116.282 65.1694 108.146C65.4611 100.01 65.9966 91.8851 66.7753 83.7899C66.9947 81.5103 67.2331 79.2332 67.4909 76.9587C67.5537 76.4048 67.6177 75.8509 67.6827 75.297C67.6963 75.1804 67.7103 75.064 67.7239 74.9473C67.7391 74.818 67.7375 74.8306 67.7191 74.9854C67.7261 74.927 67.7331 74.8687 67.7401 74.8107C67.7753 74.5193 67.8101 74.2282 67.846 73.9369C67.9883 72.772 68.1361 71.6082 68.2883 70.4449C68.3529 69.9503 68.3618 69.5172 68.1285 69.068C67.936 68.6973 67.559 68.3438 67.1811 68.2468C66.4294 68.0537 65.3609 68.5062 65.2316 69.495Z"
        fill="#DDD7C7"
      />
      <path
        d="M80.6395 96.3508C80.1354 110.202 80.1826 124.134 80.793 137.968C80.9746 142.09 81.2121 146.198 81.4844 150.299C81.5973 151.997 82.6949 153.071 83.4346 152.579C84.3408 151.975 84.6629 150.383 84.5417 148.563C84.4977 147.9 84.5265 148.336 84.5357 148.478C84.5218 148.267 84.5085 148.055 84.4948 147.843C84.4679 147.419 84.4416 146.995 84.4156 146.571C84.3639 145.723 84.3141 144.875 84.2665 144.026C84.1714 142.328 84.0849 140.628 84.0066 138.926C83.8477 135.469 83.7238 132.005 83.6344 128.538C83.4587 121.71 83.4188 114.867 83.5142 108.033C83.5687 104.134 83.6683 100.24 83.81 96.3508C83.8721 94.6441 83.0364 93.0116 82.2247 93.0865C81.3126 93.1708 80.7061 94.5227 80.6395 96.3508Z"
        fill="#DDD7C7"
      />
      <path
        d="M107.709 72.4223C109.059 82.5279 109.304 92.7369 109.278 102.92C109.252 113.174 109.083 123.429 108.949 133.682C108.872 139.492 108.79 145.302 108.702 151.112C108.69 151.942 109.44 152.735 110.288 152.697C111.157 152.658 111.859 152.001 111.873 151.112C112.029 140.76 112.168 130.407 112.288 120.055C112.406 109.874 112.629 99.6806 112.258 89.503C112.05 83.7926 111.637 78.0876 110.88 72.4223C110.822 71.9844 110.742 71.6274 110.416 71.3012C110.141 71.0263 109.69 70.8192 109.295 70.837C108.53 70.8712 107.592 71.5418 107.709 72.4223Z"
        fill="#DDD7C7"
      />
      <path
        d="M123.562 81.9096C122.52 88.5642 122.698 95.2856 123.072 101.987C123.441 108.575 123.972 115.188 123.52 121.786C123.458 122.69 123.378 123.594 123.275 124.495C123.248 124.732 123.22 124.969 123.189 125.205C123.177 125.3 123.165 125.395 123.153 125.489C123.2 125.128 123.136 125.599 123.131 125.634C123.07 126.059 123.004 126.483 122.932 126.906C122.619 128.749 122.198 130.572 121.658 132.362C121.418 133.156 121.944 134.125 122.765 134.312C123.62 134.507 124.459 134.055 124.715 133.205C126.638 126.833 126.994 120.116 126.822 113.499C126.65 106.861 125.983 100.243 125.938 93.6005C125.925 91.762 125.96 89.9227 126.068 88.087C126.123 87.1479 126.197 86.2097 126.294 85.2738C126.341 84.8179 126.393 84.3626 126.451 83.9083C126.463 83.8125 126.476 83.7168 126.489 83.6214C126.441 83.9768 126.507 83.5022 126.511 83.4692C126.546 83.2301 126.582 82.9917 126.62 82.7533C126.747 81.9375 126.412 81.0082 125.512 80.8031C124.754 80.6297 123.7 81.0336 123.562 81.9096Z"
        fill="#DDD7C7"
      />
      <path
        d="M0.971432 83.1732C1.30212 84.345 1.53133 85.5295 1.72315 86.7308C1.76817 87.0139 1.6794 86.3516 1.73551 86.8221C1.75295 86.9689 1.77134 87.1154 1.78751 87.2622C1.82017 87.5561 1.84839 87.85 1.87312 88.1449C1.92417 88.7593 1.95808 89.3753 1.97742 89.9917C2.05288 92.4088 1.90291 94.8273 1.66639 97.2321C1.19113 102.063 0.420391 106.854 0.166751 111.706C-0.34687 121.545 0.54372 131.379 0.662297 141.216C0.695587 143.974 0.668003 146.733 0.531037 149.489C0.462555 150.864 0.366809 152.238 0.23777 153.61C0.175628 154.27 0.105559 154.93 0.027248 155.589C-0.0149196 155.944 -0.0589887 156.3 -0.106546 156.654C-0.060891 156.313 -0.129686 156.814 -0.133173 156.84C-0.161708 157.043 -0.191508 157.246 -0.221628 157.448C-0.343058 158.265 -0.0184167 159.192 0.885493 159.398C1.6388 159.57 2.70504 159.168 2.83567 158.291C4.31566 148.344 3.80015 138.308 3.41715 128.301C3.22692 123.335 3.08171 118.362 3.26306 113.393C3.44283 108.472 4.13463 103.621 4.67901 98.734C5.28743 93.2728 5.53822 87.6772 4.02906 82.3308C3.80395 81.5331 2.89719 80.9586 2.07888 81.2236C1.26533 81.4861 0.730157 82.3181 0.971432 83.1732Z"
        fill="#DDD7C7"
      />
      <path
        d="M23.9841 92.6072C24.5713 99.9444 24.9163 107.298 25.0136 114.656C25.1109 122.013 24.9629 129.373 24.5688 136.721C24.3481 140.836 24.0498 144.947 23.676 149.053C23.6056 149.828 24.4556 150.569 25.2612 150.535C26.1803 150.497 26.771 149.883 26.8465 149.053C27.5139 141.722 27.9387 134.372 28.1166 127.015C28.2944 119.66 28.2263 112.299 27.9127 104.947C27.7371 100.83 27.4834 96.7159 27.1546 92.6069C27.0928 91.8355 26.4698 91.087 25.5694 91.1244C24.7634 91.159 23.9179 91.7794 23.9841 92.6072Z"
        fill="#DDD7C7"
      />
      <path
        d="M41.5974 70.6376C40.5552 78.4206 40.7334 86.2818 41.1076 94.1199C41.4757 101.825 42.0074 109.558 41.5552 117.275C41.4931 118.333 41.4129 119.39 41.3102 120.444C41.2832 120.721 41.2547 120.998 41.2246 121.275C41.2125 121.385 41.2001 121.496 41.1878 121.606C41.2356 121.184 41.1713 121.734 41.1662 121.776C41.1053 122.273 41.0391 122.769 40.9674 123.264C40.6545 125.419 40.2328 127.551 39.6929 129.645C39.4535 130.573 39.9792 131.707 40.8 131.926C41.6548 132.153 42.4937 131.625 42.7502 130.631C44.6731 123.178 45.0289 115.322 44.8573 107.583C44.6852 99.8189 44.0184 92.079 43.9727 84.31C43.9601 82.1598 43.9949 80.0086 44.1031 77.8616C44.1582 76.763 44.2324 75.666 44.3291 74.5712C44.376 74.0383 44.4283 73.5056 44.4864 72.9743C44.4984 72.8623 44.5108 72.7504 44.5238 72.6385C44.4759 73.0541 44.5418 72.499 44.5466 72.4606C44.5808 72.1813 44.6173 71.902 44.6547 71.6233C44.7825 70.669 44.447 69.5822 43.5476 69.3428C42.7889 69.1405 41.7347 69.6129 41.5974 70.6376Z"
        fill="#DDD7C7"
      />
      <path
        d="M48.0502 142.135C47.9126 140.823 47.8216 138.025 49.4151 135.214C50.066 134.066 52.0206 130.617 55.459 130.438C57.3778 130.337 58.8806 131.301 59.5531 131.802C60.1773 131.261 61.3317 130.433 62.9648 130.145C63.6861 130.018 66.1633 129.581 67.8389 131.217C68.7463 132.104 69.0145 133.204 69.1061 133.752C69.8822 133.678 71.296 133.668 72.7129 134.434C74.949 135.643 75.67 137.963 76.0273 139.113C76.54 140.764 76.5089 142.211 76.4173 143.11C76.9468 143.614 78.1903 144.939 78.5618 147.009C78.6078 147.265 79.2635 151.311 76.2223 153.54C74.1152 155.085 71.132 155.1 68.8138 153.54C67.6711 154.802 66.518 155.17 65.987 155.295C63.9071 155.786 61.3625 154.923 59.943 153.345C59.3581 152.695 59.1533 152.133 58.5785 152.078C57.6879 151.993 57.179 153.246 56.2389 154.125C54.4479 155.8 50.9385 156.261 48.3431 154.808C45.0011 152.936 44.8397 148.824 44.834 148.569C44.7434 144.643 47.762 142.347 48.0502 142.135Z"
        fill="#C8E1E4"
      />
      <path
        d="M111.046 146.217C113.022 148.884 114.997 151.55 116.973 154.217C120.232 155.798 123.491 157.378 126.751 158.958C124.479 161.773 122.207 164.588 119.936 167.403C119.541 170.959 119.146 174.515 118.751 178.071C115.343 176.589 111.935 175.108 108.528 173.626C105.022 174.466 101.515 175.305 98.0088 176.145C98.1569 172.638 98.3052 169.132 98.4533 165.626C96.7742 162.514 95.0951 159.403 93.416 156.292C96.8731 155.353 100.33 154.415 103.787 153.477C106.206 151.057 108.626 148.637 111.046 146.217Z"
        fill="#C8E1E4"
      />
      <path
        d="M-6.13301 153.437C-5.97195 152.778 -1.22697 152.294 2.14549 153.179C4.82045 153.882 7.19959 154.8 9.60188 157.629C11.9633 160.41 13.1234 164.466 12.1732 168.456C12.1177 168.689 10.7908 173.89 5.81218 176.577C3.10964 178.035 0.633801 178.05 -2.03735 178.066C-4.58738 178.08 -7.14345 178.096 -9.61644 176.441C-11.4816 175.193 -12.4921 173.536 -13 172.517C-11.7131 172.861 -9.65447 173.24 -7.18053 172.923C-5.11875 172.658 -1.89056 172.244 0.263163 169.539C2.85632 166.282 1.93496 162.161 1.75203 161.419C0.956547 158.195 -1.32747 156.214 -2.03735 155.599C-4.05505 153.85 -6.24715 153.905 -6.13301 153.437Z"
        fill="#C8E1E4"
      />
      <path
        d="M78.2287 151.016C78.3435 150.588 81.7312 150.273 84.1385 150.848C86.0481 151.304 87.7469 151.9 89.4618 153.736C91.1476 155.541 91.9757 158.174 91.2975 160.763C91.2579 160.914 90.3109 164.29 86.7564 166.033C84.8272 166.98 83.0596 166.989 81.1526 167C79.3321 167.009 77.5071 167.019 75.7418 165.945C74.4102 165.136 73.6889 164.06 73.3262 163.398C74.245 163.622 75.7145 163.868 77.4808 163.662C78.9529 163.49 81.2572 163.221 82.7949 161.466C84.6461 159.352 83.9886 156.678 83.8576 156.196C83.2898 154.104 81.6592 152.818 81.1522 152.419C79.7122 151.283 78.1472 151.319 78.2287 151.016Z"
        fill="#C8E1E4"
      />
      <path
        d="M26.5028 145.877C27.6487 147.424 28.7942 148.97 29.94 150.517C31.8305 151.434 33.7208 152.35 35.6114 153.267C34.2937 154.899 32.9761 156.532 31.6587 158.165C31.4295 160.227 31.2003 162.289 30.9714 164.351C28.9949 163.492 27.0187 162.633 25.0422 161.774C23.0086 162.261 20.9751 162.748 18.9412 163.234C19.0271 161.201 19.113 159.167 19.199 157.133C18.225 155.329 17.2513 153.524 16.2773 151.72C18.2824 151.175 20.2874 150.631 22.2924 150.087C23.6957 148.684 25.0993 147.281 26.5028 145.877Z"
        fill="#C8E1E4"
      />
      <path
        d="M117.034 129.492C116.936 128.55 116.871 126.542 118.014 124.524C118.481 123.7 119.884 121.225 122.352 121.096C123.729 121.024 124.808 121.716 125.291 122.075C125.739 121.687 126.567 121.093 127.74 120.886C128.257 120.795 130.035 120.481 131.238 121.656C131.889 122.292 132.082 123.081 132.148 123.475C132.705 123.422 133.72 123.415 134.736 123.965C136.342 124.833 136.859 126.498 137.115 127.323C137.483 128.508 137.461 129.547 137.395 130.192C137.775 130.554 138.668 131.505 138.934 132.991C138.967 133.174 139.438 136.078 137.255 137.678C135.743 138.787 133.602 138.797 131.938 137.678C131.118 138.584 130.29 138.848 129.909 138.937C128.416 139.29 126.589 138.671 125.571 137.538C125.151 137.071 125.004 136.668 124.591 136.628C123.952 136.567 123.587 137.467 122.912 138.098C121.627 139.3 119.108 139.631 117.245 138.587C114.846 137.244 114.73 134.293 114.726 134.11C114.661 131.292 116.827 129.644 117.034 129.492Z"
        fill="#C8E1E4"
      />
      <path
        d="M42.8046 120.297C43.9082 121.787 45.0119 123.276 46.1152 124.766C47.936 125.649 49.7572 126.532 51.578 127.415C50.3088 128.988 49.0397 130.56 47.7705 132.133C47.5498 134.119 47.3292 136.106 47.1085 138.092C45.2049 137.264 43.3011 136.437 41.3975 135.609C39.4388 136.078 37.4797 136.547 35.521 137.016C35.6037 135.057 35.6865 133.098 35.7692 131.139C34.8311 129.401 33.8932 127.663 32.9551 125.925C34.8862 125.401 36.8177 124.876 38.7489 124.352C40.1008 123.001 41.4527 121.649 42.8046 120.297Z"
        fill="#C8E1E4"
      />
      <path d="M66 18.5V-1.5" stroke="#DDD7C7" stroke-width="3" />
    </g>
    <defs>
      <clipPath id="clip0_5289_51566">
        <rect width="708" height="704" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CarryingBase"
};
</script>
