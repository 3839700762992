import Answers from "@/components/assessment/new-parent/answers.json";
import i18n from "@/plugins/i18n.js";

const basePath = `newParentAssessment.report.returnToWork`;

export function getTitle() {
  return i18n.t(`${basePath}.title`);
}

export function getContent({ returnToWork } = {}) {
  // Early return if no data is available
  if (!returnToWork) {
    return [];
  }

  const { returnToWorkStatus, newParentsOwnTime } = returnToWork;
  const returnToWorkStatusAnswers = Answers.returnToWork.returnToWorkStatus;

  // Determine if the person has returned to work
  const hasReturned =
    returnToWorkStatus === returnToWorkStatusAnswers.returned ||
    returnToWorkStatus === returnToWorkStatusAnswers.flexible;

  // Get the appropriate return to work text
  const returnToWorkKey = hasReturned ? "returned" : "notReturned";
  const returnToWorkText = i18n.t(
    `${basePath}.text.returnToWorkStatus.${returnToWorkKey}`
  );
  const returnToWorkArray = Array.isArray(returnToWorkText)
    ? returnToWorkText
    : [returnToWorkText];

  // If no own time data, just return the work status text
  if (!newParentsOwnTime) {
    return returnToWorkArray;
  }

  // Determine if the person makes time for themselves
  const makeRegularTime =
    Answers.returnToWork.newParentsOwnTime.makeRegularTime;
  const ownTimeKey =
    newParentsOwnTime === makeRegularTime ? "makeTime" : "dontMakeTime";

  // Get the appropriate own time text
  const ownTimeText = i18n.t(
    `${basePath}.text.newParentsOwnTime.${ownTimeKey}`
  );
  const ownTimeArray = Array.isArray(ownTimeText) ? ownTimeText : [ownTimeText];

  // Combine both text arrays
  return [...returnToWorkArray, ...ownTimeArray];
}
