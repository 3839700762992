<template>
  <button
    class="vfl-button-primary-neutral is-medium"
    :class="{ 'is-loading': loading }"
    @click="exit"
  >
    {{ $t("layout.topbar.exitAssessmentButton") }}
  </button>
</template>

<script>
import { track } from "@/customApi.js";
import { mapGetters } from "vuex";

export default {
  name: "ExitAssessmentButton",
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("routerStore", ["getPreviousRoute"])
  },
  methods: {
    async exit() {
      this.loading = true;
      try {
        track("Exit assessment from TopBar button", {
          route: this.$route.path
        });

        const previousRoute = this.getPreviousRoute;
        const targetRoute = previousRoute !== "/" ? previousRoute : "/wellness";

        await this.$router.push(targetRoute);
      } catch (e) {
        this.$logger.captureException(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
