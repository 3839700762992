import { assessmentTypes } from "@/constants/constants.js";

export const HistoryIcons = {
  ASSESSMENT: {
    icon: "mdi-account-check",
    color: "blue"
  },
  PAIN: {
    icon: "mdi-account-check",
    color: "blue"
  },
  RECOMMENDATION: {
    icon: "mdi-account-check",
    color: "blue"
  },
  ACCOUNT: {
    icon: "mdi-home-account",
    color: "green"
  },
  NEEDS_ATTENTION: {
    icon: "mdi-home-account",
    color: "yellow"
  }
};

export const PainLevel = {
  SEVERE: { min: 7, max: 10 },
  MODERATE: { min: 4, max: 6 },
  MILD: { min: 0, max: 3 }
};

export const PainColors = {
  SEVERE: { background: "#FFDFE0", border: "#F9D6D7" },
  MODERATE: { background: "#FEE6DA", border: "#FBDECF" },
  MILD: { background: "#FEF0DA", border: "#FBEACF" }
};

export const AssessmentTitlesMap = {
  [assessmentTypes.deskAssessment]: "userProfile.assessments.titles.desk",
  [assessmentTypes.driverAssessment]: "userProfile.assessments.titles.drivers",
  [assessmentTypes.physicalLabourAssessment]:
    "userProfile.assessments.titles.physical",
  [assessmentTypes.burnoutAssessment]:
    "userProfile.assessments.titles.resilience"
};

export const AssessmentIconsMap = {
  [assessmentTypes.deskAssessment]: "mdi-laptop",
  [assessmentTypes.driverAssessment]: "mdi-car-hatchback",
  [assessmentTypes.physicalLabourAssessment]:
    "mdi-badge-account-horizontal-outline",
  [assessmentTypes.burnoutAssessment]: "mdi-shield-star-outline"
};

export const DESK_BACKGROUND_GRADIENT = `linear-gradient(
  80deg,
  hsl(0deg, 0%, 100%) 0%,
  hsla(210deg, 100%, 99%, 50%) 3%,
  hsla(208deg, 100%, 97%, 50%) 9%,
  hsla(208deg, 100%, 96%, 50%) 22%,
  hsla(209deg, 100%, 95%, 50%) 60%,
  hsla(208deg, 100%, 94%, 50%) 78%,
  hsla(208deg, 100%, 93%, 50%) 87%,
  hsla(218deg, 100%, 93%, 50%) 92%,
  hsla(226deg, 100%, 93%, 50%) 95%,
  hsla(235deg, 100%, 94%, 50%) 97%,
  hsla(244deg, 100%, 94%, 50%) 99%,
  hsla(252deg, 100%, 94%, 50%) 100%,
  hsla(261deg, 100%, 94%, 50%) 100%
)`;

export const AssessmentCardTheme = {
  [assessmentTypes.deskAssessment]: "#EEF5FC",
  [assessmentTypes.driverAssessment]: "#F2FFF5",
  [assessmentTypes.physicalLabourAssessment]: "#FFFBF1",
  [assessmentTypes.burnoutAssessment]: "#F3F1FF"
};

export const NEEDS_ATTENTION_STATE = {
  NONE: "None",
  NEEDS_ATTENTION: "NeedsAttention",
  RESOLVED: "Resolved"
};
