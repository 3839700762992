import BaseScoreHelper from "./base-score-helper";
import SchemaService from "@/services/question-schema/schema-service.js";
import SimsComponents from "@/assets/json/DeskAssessment/SimsComponents.json";
import WindowScoring from "@/services/deskassessment/desk-assessment-window-scoring";
import SimsAssessmentText from "@/services/deskassessment/sims-assessment-text";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";
import EnvironmentScoreHelper from "@/components/assessment/desk/results-helpers/environment-score-helper";

import i18n from "@/plugins/i18n.js";

export default class EquipmentScoreHelper extends BaseScoreHelper {
  static maxSimsScore = 2;

  static calculateEquipmentPercentage(results) {
    const wristPositioningAndNicheEquipment = this.getPercentageScore(
      this.getSetupScoreFromResult(
        this.questionsByTheme.setup.equipment,
        results.setup
      ),
      this.maxEquipmentScore(results)
    );

    // Calculate seating percentage
    let seatingPercentage = 0;
    const maxSeatingScore = this.maxSeatingScore(results);
    const seatingScoreExists = maxSeatingScore !== 0;
    if (seatingScoreExists) {
      seatingPercentage = this.getPercentageScore(
        this.getSetupScoreFromResult(
          this.questionsByTheme.setup.seating,
          results.setup
        ),
        maxSeatingScore
      );
    }

    // Calculate desk planner score
    let visualDeskPlannerScore = 0;
    if (results?.setup?.deskItems) {
      visualDeskPlannerScore = this.getVisualDeskPlannerScore(
        results.setup,
        500
      );
    } else {
      visualDeskPlannerScore = this.getPercentageScore(
        this.getSetupScoreFromResult(
          this.questionsByTheme.setup.sims,
          results.setup
        ),
        this.maxSimsScore
      );
    }

    // Calculate height score
    const maxHeightScore = this.maxHeightScore(results);
    const heightScoreExists = maxHeightScore !== 0;
    let heightScore = 0;
    if (heightScoreExists) {
      heightScore = this.getPercentageScore(
        this.getSetupScoreFromResult(
          this.questionsByTheme.setup.height,
          results.setup
        ),
        maxHeightScore
      );
    }

    // Calculate weights and final score
    const weights = this.calculateEquipmentWeights(
      seatingScoreExists,
      heightScoreExists
    );

    return Math.round(
      seatingPercentage * weights.seating +
        heightScore * weights.height +
        wristPositioningAndNicheEquipment * weights.wrists +
        visualDeskPlannerScore * weights.visualDeskPlanner
    );
  }

  static getSetupScoreFromResult(setupQuestions, setupResults) {
    let totalScore = 0;

    for (const [key, question] of Object.entries(setupQuestions)) {
      const result = setupResults?.[key];
      if (result != null && question.scores) {
        totalScore += SchemaService.getScoreFromResult(question, result);
      }
    }

    return totalScore;
  }

  static calculateEquipmentWeights(seatingExists, heightExists) {
    const baseWeights = {
      wrists: 0.1,
      visualDeskPlanner: 0.5,
      seating: heightExists ? 0.2 : 0.4,
      height: heightExists ? 0.2 : 0
    };

    if (!seatingExists) {
      baseWeights.seating = 0;
      baseWeights.height = heightExists ? 0.4 : 0;
    }

    const totalWeight = Object.values(baseWeights).reduce((a, b) => a + b, 0);

    return {
      seating: baseWeights.seating / totalWeight,
      wrists: baseWeights.wrists / totalWeight,
      height: baseWeights.height / totalWeight,
      visualDeskPlanner: baseWeights.visualDeskPlanner / totalWeight
    };
  }

  static maxHeightScore(results) {
    return results?.setup?.deskHeight ? 1 : 0;
  }

  static maxSeatingScore(results) {
    const setup = results?.setup;
    if (!setup) return 0;

    const noStandingDesk = setup.standingdesk === 2 || setup.standingdesk === 3;
    const standingDeskUseLessThanWorkHours =
      setup.standingdesk === 1 && setup.hoursstandingdesk < setup.hours;
    const hasChair =
      (setup.chair === 1 || setup.armrests === 1) && setup.seatadjustable !== 3;

    if (noStandingDesk || standingDeskUseLessThanWorkHours || hasChair) {
      return setup.armrests === 1 ? 5 : 4;
    }

    return 0;
  }

  static maxEquipmentScore(results) {
    let equipmentScore = 3;
    if (results?.setup?.headset === 2) {
      equipmentScore++;
    }
    return equipmentScore;
  }

  static getVisualDeskPlannerScore(results, canvasHeight) {
    const deskItems = results.deskItems;
    let score = 0;

    // Add screen score
    score += this.getVisualDeskPlannerScreenScore(deskItems);

    // Deduct for clutter and excess paper
    score += this.getDeskItemCount("clutter", deskItems) > 0 ? -2 : 0;
    score += this.getDeskItemCount("stackOfPaper", deskItems) > 1 ? -1 : 0;

    // Add for positive items
    score += this.getDeskItemCount("plants", deskItems) > 0 ? 1 : 0;
    score += this.getBeneficialDeskItemScore(
      this.getDeskItemCount("water", deskItems),
      [1, 0.75, 0.5]
    );

    // Handle lighting
    const hasWindow = this.getDeskItemCount("window", deskItems) > 0;
    if (!hasWindow && results.workarea !== 4) {
      score += this.getBeneficialDeskItemScore(
        this.getDeskItemCount("lamp", deskItems),
        [2.5, 1.7, 0.8]
      );
    }

    // Input devices scoring
    const mouseCount = this.getTotalMouseCount(deskItems);
    score += this.getBeneficialDeskItemScore(mouseCount, [1.5, 1, 0.5]);

    const keyboardCount = this.getTotalKeyboardCount(deskItems);
    score += keyboardCount > 0 ? 1.5 : 0;

    // Window scoring for non-phone booth setups
    if (results.workarea !== 4) {
      score += WindowScoring.getVisualDeskPlannerWindowScore(
        deskItems,
        canvasHeight
      );
    }

    // Seating score
    score += this.getSimsSeatingScore(deskItems, results.workarea);

    // Adjust score for non-desk work areas
    // Pass isV2Schema = true to use the new schema
    if (
      results.workarea > SimsAssessmentText.getValueFromWorkArea("desk", true)
    ) {
      score = score / 2;
    }

    // Normalize score to percentage (0-100)
    return Math.max(0, (score / 10) * 100);
  }

  static getTotalMouseCount(deskItems) {
    return (
      this.getDeskItemCount("mouse", deskItems) +
      this.getDeskItemCount("rollerMouse", deskItems) +
      this.getDeskItemCount("trackpad", deskItems) +
      this.getDeskItemCount("ergonomicMouse", deskItems)
    );
  }

  static getTotalKeyboardCount(deskItems) {
    return (
      this.getDeskItemCount("keyboard", deskItems) +
      this.getDeskItemCount("ergonomicKeyboard", deskItems)
    );
  }

  static getDeskItemCount(id, deskItems, idIncludes = false) {
    if (!Array.isArray(deskItems)) return 0;
    return deskItems.filter(item =>
      idIncludes ? item?.id?.includes(id) : item?.id === id
    ).length;
  }

  static getBeneficialDeskItemScore(count, scores) {
    switch (count) {
      case 0:
        return 0;
      case 1:
        return scores[0];
      case 2:
        return scores[1];
      default:
        return scores[2];
    }
  }

  static getVisualDeskPlannerScreenScore(deskItems) {
    const counts = {
      monitors: this.getDeskItemCount("computerScreen", deskItems, true),
      laptops: this.getDeskItemCount("laptop", deskItems),
      laptopStands: this.getDeskItemCount("laptopWithStand", deskItems),
      mouse: this.getTotalMouseCount(deskItems),
      keyboard: this.getTotalKeyboardCount(deskItems)
    };

    if (
      counts.laptopStands > 0 ||
      (counts.monitors === 1 && counts.laptops === 0) ||
      counts.monitors > 1
    ) {
      return 2.5;
    }

    if (
      counts.laptops === 1 &&
      counts.monitors === 1 &&
      counts.mouse === 1 &&
      counts.keyboard === 1
    ) {
      return 1.7;
    }

    if (
      counts.laptops === 1 &&
      counts.monitors === 1 &&
      (counts.mouse === 0 || counts.keyboard === 0)
    ) {
      return 0.8;
    }

    return 0;
  }

  static getSimsSeatingScore(deskItems, workarea) {
    if (this.isMainSeatingOption(deskItems, "stool", workarea)) {
      return -2.3;
    }

    if (
      this.isMainSeatingOption(deskItems, "beanBag", workarea) ||
      this.isMainSeatingOption(deskItems, "gymBall", workarea)
    ) {
      return -2.5;
    }

    return 0;
  }

  static isMainSeatingOption(deskItems, itemName, workarea) {
    if (workarea === 2 || workarea === 3) {
      return false;
    }

    const seatingComponent = SimsComponents.find(i => i.id === "seats");
    if (!seatingComponent) return false;

    const seatingOptions = seatingComponent.items;
    const comparingOption = seatingOptions.find(i => i.id === itemName);
    if (!comparingOption) return false;

    const seatExists = this.getDeskItemCount(itemName, deskItems) > 0;
    if (!seatExists) return false;

    // Check if any higher priority seat exists
    return !seatingOptions.some(
      option =>
        option.priority < comparingOption.priority &&
        this.getDeskItemCount(option.id, deskItems) > 0
    );
  }

  static getVisualDeskPlannerRating(results, canvasHeight) {
    if (!results?.deskItems?.length) {
      return { rating: "", score: null };
    }

    const score = this.getVisualDeskPlannerScore(results, canvasHeight);
    const rating = this.getVisualDeskPlannerRatingFromScore(score);
    return { rating, score };
  }

  static getVisualDeskPlannerRatingFromScore(score) {
    let rating = i18n.t("deskAssessment.setup.sims.rating.veryGood");

    if (score < 39) {
      rating = i18n.t("deskAssessment.setup.sims.rating.needsImprovement");
    } else if (score < 56) {
      rating = i18n.t("deskAssessment.setup.sims.rating.fair");
    } else if (score < 78) {
      rating = i18n.t("deskAssessment.setup.sims.rating.good");
    }
    return rating;
  }

  static getVisualDeskPlannerRatingColor(rating) {
    if (
      rating === i18n.t("deskAssessment.setup.sims.rating.needsImprovement")
    ) {
      return "poorOutcome";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.fair")) {
      return "fairOutcome";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.good")) {
      return "primary";
    }

    return "green";
  }

  static getVisualDeskPlannerRatingTheme(rating) {
    if (
      rating === i18n.t("deskAssessment.setup.sims.rating.needsImprovement")
    ) {
      return "needsImprovement";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.fair")) {
      return "fair";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.good")) {
      return "good";
    }
    return "veryGood";
  }

  static calculateVisualDeskPlannerResults(schema, results, deskItems) {
    if (!schema || !results) return;

    // Helper function to make the code more DRY
    const getDeskItemsCount = (itemId, includesSearch = false) => {
      return this.getDeskItemCount(itemId, deskItems, includesSearch);
    };

    // Calculate screen-related counts
    const screenCounts = {
      laptops: getDeskItemsCount("laptop"),
      laptopsWithStands: getDeskItemsCount("laptopWithStand"),
      monitors: getDeskItemsCount("computerScreen", true)
    };

    const hasMultipleScreens =
      screenCounts.laptops + screenCounts.monitors > 1 ||
      screenCounts.laptopsWithStands + screenCounts.monitors > 1;

    // Calculate input device counts
    const inputDeviceCounts = {
      keyboards:
        getDeskItemsCount("keyboard") + getDeskItemsCount("ergonomicKeyboard"),
      pointers: this.getTotalMouseCount(deskItems)
    };

    // Calculate seating-related information
    const hasAnySeating = [
      "chairNoArmrest",
      "chairWithArmrest",
      "stool",
      "gymBall",
      "beanBag",
      "wheelchair",
      "saddleChair",
      "kneelingChair"
    ].some(seatType => getDeskItemsCount(seatType) > 0);

    const badSeatingOption =
      this.isMainSeatingOption(deskItems, "beanBag", results.workarea) ||
      this.isMainSeatingOption(deskItems, "gymBall", results.workarea) ||
      this.isMainSeatingOption(deskItems, "stool", results.workarea) ||
      (results.workarea && results.workarea > 1);

    // Set results using schema values
    const setSchemaResult = (field, value) => {
      results[field] = this.getDeskPlannerResultsSchemaValue(
        schema,
        field,
        value ? "Yes" : "No"
      );
    };

    // Screen setup results
    setSchemaResult("screennumber", hasMultipleScreens);
    setSchemaResult(
      "laptop",
      screenCounts.laptops + screenCounts.laptopsWithStands > 0
    );
    setSchemaResult("dockingstation", screenCounts.laptopsWithStands > 0);
    setSchemaResult(
      "separatemousekeyboard",
      inputDeviceCounts.keyboards > 0 && inputDeviceCounts.pointers > 0
    );

    // Seating results
    setSchemaResult("chair", hasAnySeating);

    // Handle bad seating options
    if (badSeatingOption) {
      results.armrests = 2;
      results.backsupport = 2;
      results.seatadjustable = 2;
    }

    // Handle armrests specifically
    if (results.chair === 2) {
      results.armrests = undefined;
    } else {
      setSchemaResult("armrests", getDeskItemsCount("chairWithArmrest") > 0);
    }

    // Workspace environment results
    setSchemaResult("water", getDeskItemsCount("water") > 0);
    setSchemaResult("naturallight", getDeskItemsCount("window") > 0);
    setSchemaResult("plants", getDeskItemsCount("plants") > 0);
    setSchemaResult("wristrest", getDeskItemsCount("wristRest") > 0);
    setSchemaResult("clutter", getDeskItemsCount("clutter") > 0);

    // Store desk items
    results.deskItems = deskItems;
  }

  static getDeskPlannerResultsSchemaValue(schema, jsonName, optionsLabel) {
    if (!schema.setup.pages[1].questions) return undefined;

    const question = schema.setup.pages[1].questions.find(
      q => q.name === jsonName
    );

    if (!question?.options) return undefined;

    return question.options.find(o => o.label === optionsLabel)?.value;
  }

  static getNumberOfScreens(results) {
    if (!results?.setup?.deskItems) {
      return 0;
    }

    return results.setup.deskItems.filter(
      item =>
        item.id === "laptopWithStand" ||
        item.id === "laptop" ||
        item.id.includes("computerScreen")
    ).length;
  }

  static getStandaloneSetupPercentageScore(results) {
    const equipmentPercentage =
      EquipmentScoreHelper.calculateEquipmentPercentage(results);
    const environmentPercentage =
      EnvironmentScoreHelper.calculateEnvironmentPercentage(results);

    if (environmentPercentage !== null) {
      return Math.round(
        environmentPercentage * 0.25 + equipmentPercentage * 0.75
      );
    }

    return equipmentPercentage;
  }

  static getEquipmentWeight() {
    return 0.6;
  }
}

EquipmentScoreHelper.calculateEquipmentPercentage = withErrorHandling(
  EquipmentScoreHelper.calculateEquipmentPercentage,
  0
);

EquipmentScoreHelper.getSetupScoreFromResult = withErrorHandling(
  EquipmentScoreHelper.getSetupScoreFromResult,
  0
);

EquipmentScoreHelper.calculateEquipmentWeights = withErrorHandling(
  EquipmentScoreHelper.calculateEquipmentWeights,
  {
    seating: 0,
    wrists: 0.2,
    height: 0,
    visualDeskPlanner: 0.8
  }
);

EquipmentScoreHelper.maxSeatingScore = withErrorHandling(
  EquipmentScoreHelper.maxSeatingScore,
  0
);

EquipmentScoreHelper.maxEquipmentScore = withErrorHandling(
  EquipmentScoreHelper.maxEquipmentScore,
  3
);

EquipmentScoreHelper.getVisualDeskPlannerScore = withErrorHandling(
  EquipmentScoreHelper.getVisualDeskPlannerScore,
  0
);

EquipmentScoreHelper.isMainSeatingOption = withErrorHandling(
  EquipmentScoreHelper.isMainSeatingOption,
  false
);

EquipmentScoreHelper.getVisualDeskPlannerRating = withErrorHandling(
  EquipmentScoreHelper.getVisualDeskPlannerRating,
  { rating: "", score: null }
);

EquipmentScoreHelper.calculateVisualDeskPlannerResults = withErrorHandling(
  EquipmentScoreHelper.calculateVisualDeskPlannerResults,
  {}
);

EquipmentScoreHelper.getStandaloneSetupPercentageScore = withErrorHandling(
  EquipmentScoreHelper.getStandaloneSetupPercentageScore,
  0
);
