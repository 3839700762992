<template>
  <vfl-callout v-if="conditionText.length" size="large">
    <div
      v-for="condition in textToDisplay"
      :key="condition.key"
      class="mb-2 d-flex vitrueDarkGrey--text"
    >
      <img
        :src="
          condition.icon
            ? condition.icon
            : '/images/icons/health-conditions/msk.svg'
        "
        class="mr-4"
        width="25"
      />
      <p class="text-subtitle">
        {{ $t(condition.text) }}
      </p>
    </div>
    <button
      v-if="conditionText.length > conditionCountLimit"
      @click="showAll = !showAll"
    >
      <v-icon large>{{ showAll ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
    </button>
  </vfl-callout>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import { getBannerText } from "@/components/assessment/pre-existing-conditions/report/pain-banner-text-service.js";

export default {
  name: "HealthConditionPainBanner",
  components: { VflCallout },
  props: {
    results: Object
  },
  data() {
    return { showAll: false, conditionCountLimit: 2 };
  },
  computed: {
    conditionText() {
      return getBannerText(this.results);
    },
    textToDisplay() {
      return this.showAll
        ? this.conditionText
        : this.conditionText.slice(0, this.conditionCountLimit);
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-callout {
  background: linear-gradient(
    to bottom right,
    #ffffff,
    rgba(217, 237, 255, 0.5),
    rgba(236, 226, 255, 0.5)
  );
  display: block !important;
  position: relative;
}

button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
