<template>
  <p
    v-if="!hasPain && $vuetify.breakpoint.mdAndUp"
    class="mt-12 d-flex align-center vflNeutralDark1--text text-body-bold"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <path
        d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
        fill="black"
      />
    </svg>
    {{ text }}
  </p>
</template>
<script>
import { assessmentTypes } from "@/constants/constants";
import { mapGetters } from "vuex";

export default {
  name: "PainPersonInstruction",
  props: {
    results: Object,
    assessmentType: {
      type: String,
      required: true,
      validator: function (value) {
        return Object.values(assessmentTypes).includes(value);
      }
    }
  },
  computed: {
    ...mapGetters(["team_xrppst"]),
    hasPain() {
      return (
        this.results?.pain?.areas &&
        Object.keys(this.results.pain.areas).length > 0
      );
    },
    text() {
      if (this.team_xrppst) {
        return this.$t(
          "deskAssessment.pain.speechBubbleText.xrppstNoneSelected"
        );
      }

      const key =
        this.assessmentType === assessmentTypes.menstruationAssessment
          ? "menstruationAssessment"
          : "menopauseAssessment";

      return this.$t(`${key}.schema.pain.instruction`);
    }
  }
};
</script>
