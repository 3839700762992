<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="34.4006"
      height="33"
      rx="10"
      transform="matrix(-0.964761 0.263128 0.263128 0.964761 285.611 221.734)"
      fill="#BE9523"
    />
    <path
      d="M268.941 258.99L283.894 254.964C288.564 253.707 291.33 248.901 290.073 244.231L286.047 229.278C284.789 224.608 279.984 221.841 275.314 223.099L260.361 227.125C255.691 228.382 252.924 233.187 254.182 237.857L258.208 252.81C259.465 257.48 264.271 260.248 268.941 258.99Z"
      fill="#F46B17"
    />
    <path
      d="M281.621 234.448C281.026 232.239 278.753 230.931 276.544 231.525C274.335 232.12 273.026 234.393 273.621 236.602C274.216 238.811 276.489 240.121 278.698 239.526C280.908 238.931 282.216 236.657 281.621 234.448Z"
      fill="white"
    />
    <path
      d="M279.41 235.044C279.143 234.055 278.126 233.47 277.138 233.736C276.149 234.002 275.564 235.019 275.83 236.008C276.096 236.996 277.113 237.582 278.102 237.316C279.09 237.05 279.676 236.032 279.41 235.044Z"
      fill="#2E2D2D"
    />
    <path
      d="M268.607 237.952C268.013 235.743 265.74 234.435 263.53 235.029C261.321 235.624 260.013 237.897 260.608 240.106C261.202 242.315 263.475 243.625 265.685 243.03C267.894 242.435 269.202 240.161 268.607 237.952Z"
      fill="white"
    />
    <path
      d="M266.397 238.546C266.131 237.557 265.114 236.972 264.125 237.238C263.137 237.504 262.551 238.521 262.817 239.51C263.083 240.498 264.1 241.084 265.089 240.818C266.077 240.552 266.663 239.534 266.397 238.546Z"
      fill="#2E2D2D"
    />
    <path
      d="M277.304 248.042C275.28 248.744 272.983 248.951 270.908 248.339C270.334 248.17 269.778 247.939 269.257 247.643C269.021 247.508 268.786 247.363 268.569 247.2C268.404 247.076 267.929 246.574 267.777 246.967C267.637 247.326 268.325 247.88 268.549 248.087C269.943 249.374 271.923 249.924 273.793 249.822C274.799 249.768 275.795 249.56 276.747 249.231C277.198 249.076 277.64 248.893 278.077 248.703C278.448 248.542 278.861 248.399 279.122 248.072C279.252 247.91 279.192 247.704 279.016 247.608C278.805 247.494 278.516 247.568 278.307 247.647C277.97 247.773 277.645 247.924 277.304 248.042Z"
      fill="#2E2D2D"
    />
  </svg>
</template>

<script>
export default {
  name: "ReturnExcitedAndNervous"
};
</script>
