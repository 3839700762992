<template>
  <v-container fluid class="pa-0 py-2 vitrueGrey--text">
    <div v-if="page === 0">
      <file-drop
        v-model="uploadedFile"
        @file-error-type="fileErrorMessage = $event"
        :maxFileSizeMb="2"
        :extraInformationText="
          addingUsers
            ? $t('account.team.file.infoText')
            : $t('invite.viaFile.infoText')
        "
        style="height: 250px"
      />

      <div
        v-if="this.uploadedFile || fileLoading || fileLoaded || fileError"
        class="mb-4 mt-2 text-h6"
        style="min-height: 75px"
      >
        <v-row class="my-0 py-0 mx-1" justify="space-between">
          <p class="pr-8 my-0" v-if="this.uploadedFile">
            {{ $t("invite.viaFile.fileName") + this.uploadedFile.name }}
          </p>
          <p class="my-0">{{ loadingBarText }}</p>
        </v-row>

        <v-progress-linear
          v-if="fileLoading || fileLoaded || fileError"
          :color="loadingBarColour"
          :indeterminate="!fileLoaded && !fileError"
          :value="fileLoaded || fileError ? '100' : ''"
          height="10"
          rounded
        ></v-progress-linear>
      </div>
    </div>

    <div v-if="page === 1">
      <v-row class="mx-0 mb-4" align="center" justify="start"
        ><p class="ma-0 mr-3 text-body-1">
          {{ $t("invite.viaFile.fileName") }}
          <span class="font-weight-bold">{{ this.uploadedFile.name }}</span>
        </p>
        <v-btn
          class="mr-6 primary--text text-h6"
          tile
          depressed
          small
          color="#DFEDFF"
          @click="$emit('go-to-previous-page')"
          >{{ $t("buttons.reload") }}</v-btn
        >
      </v-row>
      <v-checkbox
        class="text-body-1"
        :label="$t('invite.viaFile.headerRowLabel')"
        v-model="includeHeaders"
      />
      <p class="text-body-1">
        {{ $t("invite.viaFile.instructions.matchHeaders") }}
      </p>
      <v-row class="ma-0 flex-nowrap" justify="start" style="overflow-x: auto">
        <div
          class="my-2 mr-1"
          v-for="(column, columnIndex) in entriesToDisplay[0]"
          :item="column"
          :index="columnIndex"
          :key="columnIndex"
        >
          <v-sheet
            class="columnBorder vitrueGrey--text"
            elevation="0"
            outlined
            width="225"
            :class="
              isColumnUsed(columnIndex)
                ? 'selectedBorder selectedColor'
                : 'columnBorder'
            "
          >
            <v-select
              :id="'dropDown' + columnIndex"
              :items="availableTags(usedHeaders[columnIndex])"
              v-model="usedHeaders[columnIndex]"
              @change="dropDownChanged"
              hide-details
              hide-selected
              class="my-0 py-3"
              :no-data-text="
                $t('invite.viaFile.instructions.noHeadersRemaining')
              "
            >
              <template v-slot:selection="{ item }">
                <span
                  class="pl-4 text-body-1 textOverflow"
                  :class="
                    item === defaultColumnHeader
                      ? 'disabled--text'
                      : 'vitrueGrey--text'
                  "
                  style="width: 100%"
                >
                  {{ translateHeaderText(item) }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span>
                  {{ translateHeaderText(item) }}
                </span>
              </template>
            </v-select>
            <v-row dense class="px-4 text-caption">
              <v-col
                cols="12"
                class="my-0 textOverflow"
                style="min-height: 30px"
                v-for="(item, rowIndex) in entriesToDisplay"
                :key="rowIndex"
              >
                {{ entriesToDisplay[rowIndex][columnIndex] }}
              </v-col>
            </v-row>
          </v-sheet>
        </div></v-row
      >
    </div>
    <div v-if="page === 2">
      <v-row class="mx-0 mb-4" align="start" justify="start">
        <v-col cols="10"
          ><p class="ma-0 mr-3 text-body-1">
            {{
              addingUsers
                ? userUploadText
                : $tc(
                    "invite.viaFile.instructions.previewInfo",
                    invitationList.length,
                    { n: invitationList.length }
                  )
            }}
          </p></v-col
        >
        <v-col cols="2"
          ><v-btn
            class="primary--text text-h6"
            tile
            depressed
            small
            color="#DFEDFF"
            @click="$emit('go-to-previous-page')"
            >{{ $t("buttons.change") }}</v-btn
          ></v-col
        >
      </v-row>
      <v-row class="ma-0 py-2 flex-nowrap" style="overflow-x: auto"
        ><div
          class="mr-1"
          v-for="(key, columnIndex) in Object.keys(reducedInviteList[0])"
          :item="key"
          :index="columnIndex"
          :key="columnIndex"
        >
          <v-sheet
            class="selectedBorder selectedColor vitrueGrey--text"
            v-if="!invitationList.every(i => i[columnIndex] === '')"
            elevation="0"
            outlined
            width="200"
          >
            <v-select
              :value="inviteListHeaders[columnIndex]"
              :items="inviteListHeaders"
              hide-details
              hide-selected
              disabled
              class="my-0 py-3"
              append-icon="false"
            >
              <template v-slot:selection="{ item }">
                <span class="pl-4 text-body-1 textOverflow" style="width: 100%">
                  {{ translateHeaderText(item) }}
                </span>
              </template>
            </v-select>
            <v-row dense class="px-4 text-caption">
              <v-col
                cols="12"
                class="my-0 textOverflow"
                style="min-height: 30px"
                v-for="(item, rowIndex) in reducedInviteList"
                :key="rowIndex"
              >
                {{ reducedInviteList[rowIndex][key] }}
              </v-col>
            </v-row>
          </v-sheet>
        </div></v-row
      >
    </div>
  </v-container>
</template>

<script>
import Papa from "papaparse";
import XLSX from "xlsx";
import FileDrop from "../../common/FileDrop.vue";
import { getRoleNameFromValue } from "@/services/authorization/user-types.js";

const defaultColumnHeader = "Not set";

export default {
  name: "InviteViaFile",
  components: {
    FileDrop
  },
  data() {
    return {
      uploadedFile: null,
      includeHeaders: false,
      convertedFile: [],
      defaultColumnHeader: defaultColumnHeader,
      usedHeaders: [],
      fileLoading: false,
      fileLoaded: false,
      fileError: false,
      fileErrorMessage: "",
      invitationList: []
    };
  },
  props: {
    page: Number,
    addingUsers: Boolean
  },
  watch: {
    uploadedFile(newFile) {
      // if a new file has been read in, we want to parse it
      // only csv and excel allowed at the moment so if / else for csv file extension *should*  be ok.
      this.convertedFile = [];

      if (newFile) {
        this.fileLoading = true;
        this.fileLoaded = false;
        this.fileError = false;
        const fileExtension = newFile.name.split(".").pop();
        if (fileExtension === "csv") {
          this.readCsvFile(newFile);
        } else {
          this.readExcelFile(newFile);
        }
      }
    },
    page(newPage, oldPage) {
      // set default column headers when going past loading file page.
      if (newPage == 1 && oldPage == 0) {
        this.setDefaultColumnSelection();
      }

      // don't set next button to false if navigating to first page and converted file exists
      if (newPage == 0 && this.convertedFile.length > 0) {
        this.$emit("can-progress-event", true);
        return;
      }

      // if new page is the last stage of process
      // create the invitation list and update the v-model
      if (newPage == 2) {
        this.invitationList = this.createInvitationList();
        this.$emit("input", this.invitationList);
        return;
      }

      // don't disable next button if we have specified email and gone back a step.
      if (newPage == 1 && this.usedHeaders.includes("Email")) {
        return;
      }

      // in all other situations we want to prevent progress to next page on page navigation
      this.$emit("can-progress-event", false);
    },
    fileErrorMessage(errorMessage) {
      // if we set an error message we want to prevent going to next page, update booleans for loading bar & reset currently loaded file.
      if (errorMessage) {
        this.uploadedFile = null;
        this.convertedFile = [];
        this.fileLoaded = false;
        this.fileLoading = false;
        this.$emit("can-progress-event", false);
      }
      this.fileError = !!errorMessage;
    },
    includeHeaders() {
      this.updateSelectedHeaders();
      if (!this.usedHeaders.includes("Email")) {
        this.$emit("can-progress-event", false);
      }
    }
  },
  computed: {
    entriesToDisplay() {
      // only splice after removal of empty columns based on headers to account for those headers if they have no entries in file.
      var entriesToReturn = this.removeEmptyColumns(
        this.convertedFile,
        this.includeHeaders
      );
      entriesToReturn = entriesToReturn.slice(this.includeHeaders ? 1 : 0, 10);
      return entriesToReturn;
    },
    reducedInviteList() {
      return this.invitationList.slice(0, 4);
    },
    loadingBarText() {
      if (this.fileLoaded) {
        return this.$t("invite.viaFile.statusMessages.success");
      } else if (this.fileError) {
        return this.$t("invite.viaFile.statusMessages.failed", {
          0: this.fileErrorMessage
        });
      }
      return this.fileLoading
        ? this.$t("invite.viaFile.statusMessages.uploading")
        : "";
    },
    loadingBarColour() {
      if (this.fileLoaded) {
        return "vflPrimary";
      } else if (this.fileError) {
        return "error";
      }
      return "vitrueGrey";
    },
    selectableHeaders() {
      let headers = [
        defaultColumnHeader,
        this.$t("invite.viaFile.headers.fullName"),
        this.$t("invite.viaFile.headers.firstName"),
        this.$t("invite.viaFile.headers.surname"),
        this.$t("invite.viaFile.headers.email"),
        this.$t("invite.viaFile.headers.tags")
      ];

      if (this.addingUsers) {
        return [
          ...headers,
          this.$t("invite.viaFile.headers.dataAccess"),
          this.$t("invite.viaFile.headers.role")
        ];
      }

      return [...headers];
    },
    inviteListHeaders() {
      return this.addingUsers
        ? [
            this.$t("invite.viaFile.headers.role"),
            this.$t("invite.viaFile.headers.email"),
            this.$t("invite.viaFile.headers.name"),
            this.$t("invite.viaFile.headers.dataAccess"),
            this.$t("account.team.file.headers.tags")
          ]
        : [
            this.$t("invite.viaFile.headers.name"),
            this.$t("invite.viaFile.headers.email"),
            this.$t("invite.viaFile.headers.tags")
          ];
    },
    userUploadText() {
      return this.$t("invite.viaFile.instructions.teamMemberPreviewInfo", {
        0: this.invitationList.filter(x => x.role === getRoleNameFromValue(10))
          .length,
        1: this.invitationList.filter(x => x.role === getRoleNameFromValue(0))
          .length,
        2: this.invitationList.filter(x => x.role === getRoleNameFromValue(20))
          .length,
        3: this.invitationList.filter(x => x.role === getRoleNameFromValue(40))
          .length
      });
    }
  },
  methods: {
    translateHeaderText(item) {
      switch (item) {
        case "Not set":
          return this.$t("invite.viaFile.headers.notSet");
        case "Full Name":
          return this.$t("invite.viaFile.headers.fullName");
        case "First Name":
          return this.$t("invite.viaFile.headers.firstName");
        case "Surname":
          return this.$t("invite.viaFile.headers.surname");
        case "Email":
          return this.$t("invite.viaFile.headers.email");
        case "Tags":
          return this.$t("invite.viaFile.headers.tags");
        case "Name":
          return this.$t("invite.viaFile.headers.name");
        case "Data Access":
          return this.$t("invite.viaFile.headers.dataAccess");
        case "Role":
          return this.$t("invite.viaFile.headers.role");
        default:
          return item;
      }
    },
    convertReadableRoleToBackEndRole(role) {
      switch (role.toLowerCase()) {
        case "super admin":
          return getRoleNameFromValue(10);
        case "admin":
          return getRoleNameFromValue(0);
        case "restricted admin":
          return getRoleNameFromValue(20);
        case "end user":
        default:
          return getRoleNameFromValue(40);
      }
    },
    updateSelectedHeaders() {
      // if includeHeaders is set to true means there is a chance a previously existing column will be removed
      // if set to false there is a chance it can be added back in
      // this works by getting the indices of differentiating columns and removing / adding to array of usedHeaders

      // need to call remove empty columns in order to get rid of columns which have nothing in some cols (including no header)
      var columnsWithHeaders = this.removeEmptyColumns(
        this.convertedFile,
        false
      )[0];
      var columnsWithoutHeaders = this.removeEmptyColumns(
        this.convertedFile,
        true
      )[0];

      // Get indices of columns that differ between with header and without
      var diffIndexes = [];
      columnsWithHeaders.filter(function (i) {
        if (columnsWithoutHeaders.indexOf(i) < 0) {
          diffIndexes.push(columnsWithHeaders.indexOf(i));
          return true;
        }
      });

      // sort by ascending / descending depending on if columns are added or removed
      // this is to make sure that when adding / removing it won't effect the following loops index
      diffIndexes.sort((a, b) => (this.includeHeaders ? b - a : a - b));

      // remove item in used headers where column existed, if new column is added back in then add default column header where it should be
      var component = this;
      diffIndexes.forEach(function (col) {
        if (component.includeHeaders) {
          component.usedHeaders.splice(col, 1);
        } else {
          component.usedHeaders.splice(col, 0, component.defaultColumnHeader);
        }
      });
    },
    createInvitationList() {
      let listToReturn = [];

      // Get relevant columns numbers based on drop downs selected
      var nameColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.fullName")
      );
      var firstNameColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.firstName")
      );
      var surnameColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.surname")
      );
      var emailColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.email")
      );
      var tagColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.tags")
      );
      var roleColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.role")
      );
      var dataAccessColumn = this.usedHeaders.findIndex(
        i => i === this.$t("invite.viaFile.headers.dataAccess")
      );

      var entriesToIterateOver = this.removeEmptyColumns(
        this.convertedFile,
        this.includeHeaders
      );
      entriesToIterateOver = this.includeHeaders
        ? entriesToIterateOver.slice(1)
        : entriesToIterateOver;

      entriesToIterateOver.forEach(row => {
        // only add if email is included
        var email = emailColumn != -1 ? row[emailColumn]?.toLowerCase() : "";

        // handle various name combinations
        var fullName = nameColumn != -1 ? row[nameColumn] : "";
        if (!fullName) {
          var firstName = firstNameColumn != -1 ? row[firstNameColumn] : "";
          var surname = surnameColumn != -1 ? row[surnameColumn] : "";

          if (firstName && surname) {
            fullName = firstName + " " + surname;
          } else if (firstName && !surname) {
            fullName = firstName;
          } else if (!firstName && surname) {
            fullName = surname;
          }
        }

        var tags = tagColumn != -1 ? row[tagColumn] : "";

        if (email) {
          if (this.addingUsers) {
            var role = roleColumn != -1 ? row[roleColumn] : "End User";
            role = this.convertReadableRoleToBackEndRole(role);
            var dataAccess =
              dataAccessColumn != -1 ? row[dataAccessColumn] : "";
            let invite = { role, email, fullName, dataAccess, tags };
            listToReturn.push(invite);
          } else {
            listToReturn.push({ fullName, email, tags });
          }
        }
      });

      var duplicateEmails = [
        ...this.getDuplicateUsers(listToReturn).map(x => x.email)
      ];
      listToReturn = listToReturn.filter(
        x => !duplicateEmails.includes(x.email)
      );
      this.$emit("update-duplicates", duplicateEmails);

      var incorrectEmails = [
        ...this.getIncorrectEmails(listToReturn).map(x => x.email)
      ];
      listToReturn = listToReturn.filter(
        x => !incorrectEmails.includes(x.email)
      );
      this.$emit("update-incorrect-emails", incorrectEmails);

      return listToReturn;
    },
    getDuplicateUsers(users) {
      return users.filter(
        (user, index, self) =>
          index !== self.findIndex(x => x.email === user.email)
      );
    },
    getIncorrectEmails(users) {
      return users.filter(x => !/.+@.+\..+/.test(x.email));
    },
    removeEmptyColumns(array, removeHeaders) {
      if (this.addingUsers) {
        return array;
      }
      var arrayToManipulate = [...array];

      // splice first row if headers are included as we don't want to take this into account for removing empty cols
      arrayToManipulate = removeHeaders
        ? arrayToManipulate.splice(1)
        : arrayToManipulate;

      // remove empty columns
      var columns = arrayToManipulate.reduce(
        (r, a) => (a.forEach((v, i) => (r[i] = r[i] || v)), r),
        []
      );

      // add back header row after columns have been identified
      if (removeHeaders) {
        arrayToManipulate.unshift(array[0]);
      }

      arrayToManipulate = arrayToManipulate.map(a =>
        a.filter((_, i) => columns[i])
      );

      return arrayToManipulate;
    },
    readCsvFile(file) {
      // Need to specify component outside of parse method to load in data.
      let component = this;

      // pushing entry in step as more performant than doing it on complete according to papaparse documentation
      try {
        Papa.parse(file, {
          skipEmptyLines: true,
          worker: true,
          step: function (results) {
            component.convertedFile.push(results.data);
          },
          complete: function () {
            component.fileLoaded = true;
            component.fileLoading = false;
            component.$emit("can-progress-event", true);
          }
        });
      } catch {
        component.fileErrorMessage = this.$t(
          "invite.viaFile.statusMessages.readError"
        );
      }
    },
    readExcelFile(file) {
      const reader = new FileReader();

      reader.addEventListener("error", () => {
        this.fileErrorMessage = this.$t(
          "invite.viaFile.statusMessages.readError"
        );
      });

      reader.onload = e => {
        // Parse data
        const workBook = XLSX.read(e.target.result, { type: "binary" });

        // Get first worksheet in excel file
        const sheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[sheetName];

        // Convert array of arrays
        this.convertedFile = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
          defval: ""
        });
        this.fileLoaded = true;
        this.fileLoading = false;
        this.$emit("can-progress-event", true);
      };
      reader.readAsBinaryString(file);
    },
    availableTags(currentTag) {
      // add tags to array that aren't used or are the current tag in drop down (otherwise not visible)
      let arrayToReturn = this.selectableHeaders.filter(
        t =>
          !this.usedHeaders.includes(t) ||
          t === this.defaultColumnHeader ||
          t == currentTag
      );

      // hide full name / first name / surname as necessary (i.e. if full name drop down select we can't have first / surname available and vice versa)
      if (this.usedHeaders.includes("Full Name")) {
        arrayToReturn.splice(arrayToReturn.indexOf("First Name"), 1);
        arrayToReturn.splice(arrayToReturn.indexOf("Surname"), 1);
      } else if (
        this.usedHeaders.includes("First Name") ||
        this.usedHeaders.includes("Surname")
      ) {
        arrayToReturn.splice(arrayToReturn.indexOf("Full Name"), 1);
      }
      return arrayToReturn;
    },
    isColumnUsed(index) {
      return (
        this.usedHeaders[index] &&
        this.usedHeaders[index] != this.defaultColumnHeader
      );
    },
    dropDownChanged() {
      let valid = this.addingUsers
        ? this.usedHeaders.includes(this.$t("invite.viaFile.headers.email")) &&
          this.usedHeaders.includes(this.$t("invite.viaFile.headers.role"))
        : this.usedHeaders.includes(this.$t("invite.viaFile.headers.email"));
      this.$emit("can-progress-event", valid);
    },
    setDefaultColumnSelection() {
      // set current column headers to default tag
      var numberOfColumns = this.entriesToDisplay[0].length;
      this.usedHeaders = Array(numberOfColumns).fill(this.defaultColumnHeader);
    }
  }
};
</script>

<style scoped>
.columnBorder {
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
.selectedBorder {
  border-style: solid;
  border-width: 2px;
  border-color: #3fb288;
}
.selectedColor {
  background-color: #dcfbf0;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
