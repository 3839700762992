<template>
  <report-header background-color="#dae9fc" :title="title" :content="content">
    <template #background>
      <woman-on-mountains-illustration class="background-image" />
    </template>
  </report-header>
</template>

<script>
import ReportHeader from "@/components/assessment/common/report/ReportHeader.vue";
import WomanOnMountainsIllustration from "@/components/assessment/common/illustrations/WomanOnMountainsIllustration.vue";
import { menstruationReportTextService } from "@/components/assessment/menstruation/report/text-service.js";

export default {
  name: "MenstruationReportHeader",
  components: {
    ReportHeader,
    WomanOnMountainsIllustration
  },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  computed: {
    content() {
      return menstruationReportTextService.getHeaderContent(this.results);
    },
    title() {
      return menstruationReportTextService.getHeaderTitle(this.results);
    }
  }
};
</script>
