﻿import store from "@/store";

export const PhysicalLabourAssessmentSteps = {
  Welcome: {
    INDEX: -1,
    THEME: "welcome",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    isVisible: () => true
  },
  Sectors: {
    INDEX: 0,
    THEME: "sectors",
    STEPPER: false,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  PainIntro: {
    INDEX: 1,
    THEME: "painIntro",
    STEPPER: false,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    isVisible: () => true
  },
  Pain: {
    INDEX: 2,
    THEME: "pain",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  JobNature: {
    INDEX: 3,
    THEME: "jobNature",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  JobNatureActivities: {
    INDEX: 4,
    THEME: "jobNature",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  ChooseActivity: {
    INDEX: 5,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaNeck: {
    INDEX: 6,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaTrunk: {
    INDEX: 7,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaArms: {
    INDEX: 8,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaWrists: {
    INDEX: 9,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaLegs: {
    INDEX: 10,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  RebaRefineExperience: {
    INDEX: 11,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => true
  },
  Health: {
    INDEX: 12,
    THEME: "health",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    isVisible: () => !store.getters.hideHealthSectionInAssessments
  },
  Report: {
    INDEX: 13,
    THEME: "report",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    isVisible: () => true
  }
};

export const getActiveSteps = () => {
  const visibleSteps = Object.entries(PhysicalLabourAssessmentSteps)
    .filter(([key, step]) => step.isVisible())
    .map(([key, step]) => ({ key, step }));

  const activeSteps = {};

  const welcomeStep = visibleSteps.find(({ step }) => step.THEME === "welcome");
  if (welcomeStep) {
    activeSteps[welcomeStep.key] = { ...welcomeStep.step };
  }

  let currentIndex = 0;
  visibleSteps
    .filter(({ step }) => step.THEME !== "welcome")
    .sort((a, b) => a.step.INDEX - b.step.INDEX)
    .forEach(({ key, step }) => {
      activeSteps[key] = {
        ...step,
        INDEX: currentIndex
      };
      currentIndex++;
    });

  return activeSteps;
};

export const getStepByIndex = index => {
  const activeSteps = getActiveSteps();
  return Object.values(activeSteps).find(step => step.INDEX === index) || null;
};
