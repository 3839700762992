<template>
  <div class="introduction-container">
    <div class="introduction-content">
      <img
        src="/images/new-parent-assessment/report/person-at-work-with-child.svg"
        aria-hidden="true"
        alt=""
        class="illustration"
      />
      <div class="introduction-text vflTextDark--text text-body-1">
        <p
          v-for="(p, index) in text"
          :key="`introduction-paragraph-${index}`"
          class="mb-8"
          :class="{ 'text-body-bold': index === text.length - 1 }"
        >
          {{ p }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { NewParentReportIntroductionService } from "@/components/assessment/new-parent/report/new-parent-report-introduction-service.js";

export default {
  name: "NewParentReportIntroduction",
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newParentReportIntroductionService: null
    };
  },
  created() {
    this.newParentReportIntroductionService =
      new NewParentReportIntroductionService(this.results);
  },
  computed: {
    text() {
      return this.newParentReportIntroductionService.getText();
    }
  }
};
</script>

<style lang="scss" scoped>
.illustration {
  max-width: 300px;
  width: 100%;
}

.introduction-container {
  background: white;
  padding: 80px 60px;
}

.introduction-content {
  max-width: 1320px;
  display: flex;
  margin: auto;

  .introduction-text {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 1025px) {
    display: block;

    .introduction-text {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}
</style>
