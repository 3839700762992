import MuscleStrengthRecommendations from "@/assets/json/recommendations/womens-health/MuscleStrengthRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import Answers from "@/components/assessment/menstruation/answers.json";

const cardGroups = {
  anytime: {
    titleKey:
      "menstruationAssessment.report.recommendations.muscleStrength.groups.anytime",
    cards: {
      womens_health_protein_intake: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      womens_health_short_walk: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      womens_health_bicep_curls: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      womens_health_squat: {
        showConditions: results => !exercisesAndWorksOut(results)
      },
      womens_health_protein_after_workout: {
        showConditions: results => exercisesAndWorksOut(results)
      },
      womens_health_rest_day: {
        showConditions: results => exercisesAndWorksOut(results)
      },
      womens_health_strength_training: {
        showConditions: results => exercisesAndWorksOut(results)
      }
    }
  },
  menstruation: {
    titleKey:
      "menstruationAssessment.report.recommendations.muscleStrength.groups.menstruation",
    cards: {
      womens_health_single_leg_stretch: {
        showConditions: () => true
      },
      womens_health_energise_hundreds: {
        showConditions: () => true
      },
      womens_health_pelvic_tilts: {
        showConditions: () => true
      }
    }
  },
  follicular: {
    titleKey:
      "menstruationAssessment.report.recommendations.muscleStrength.groups.follicular",
    cards: {
      womens_health_lunges: {
        showConditions: () => true
      },
      womens_health_calf_raises_for_balance: {
        showConditions: () => true
      },
      womens_health_train_core: {
        showConditions: () => true
      }
    }
  },
  ovulation: {
    titleKey:
      "menstruationAssessment.report.recommendations.muscleStrength.groups.ovulation",
    cards: {
      womens_health_heel_toe_walk: {
        showConditions: () => true
      },
      womens_health_boost_balance: {
        showConditions: () => true
      },
      womens_health_standing_leg_circle: {
        showConditions: () => true
      }
    }
  },
  luteal: {
    titleKey:
      "menstruationAssessment.report.recommendations.muscleStrength.groups.luteal",
    cards: {
      womens_health_glute_bridges: {
        showConditions: () => true
      },
      womens_health_clamshells: {
        showConditions: () => true
      },
      womens_health_wall_sits: {
        showConditions: () => true
      }
    }
  }
};

const exercisesAndWorksOut = results => {
  return (
    (results.lifestyle.physicalActivity ===
      Answers.lifestyle.physicalActivity.once ||
      results.lifestyle.physicalActivity ===
        Answers.lifestyle.physicalActivity.twice ||
      results.lifestyle.physicalActivity ===
        Answers.lifestyle.physicalActivity.threeOrMore) &&
    (results.lifestyle.strengthExercise ===
      Answers.lifestyle.strengthExercise.yes ||
      results.lifestyle.strengthExercise ===
        Answers.lifestyle.strengthExercise.sometimes)
  );
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, MuscleStrengthRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getMuscleStrengthRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results),
          hideTab: group.hideTab
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
