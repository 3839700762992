import MenopauseReportHeader from "../report/MenopauseReportHeader.vue";
import MenopauseReportMskHealth from "../report/MenopauseReportMskHealth.vue";
import MenopauseReportPain from "../report/MenopauseReportPain.vue";
import MenopauseReportMuscleLoss from "../report/MenopauseReportMuscleLoss.vue";
import MenopauseReportSymptoms from "../report/MenopauseReportSymptoms.vue";

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: MenopauseReportHeader,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "mskHealth",
    component: MenopauseReportMskHealth,
    isVisible: () => true
  },
  {
    id: "pain",
    component: MenopauseReportPain,
    isVisible: vm => vm.hasPain
  },
  {
    id: "muscleLoss",
    component: MenopauseReportMuscleLoss,
    isVisible: () => true
  },
  {
    id: "symptoms",
    component: MenopauseReportSymptoms,
    isVisible: vm => vm.hasSymptoms
  }
];
