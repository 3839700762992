<template>
  <div
    v-show="show"
    class="radio-card-question"
    :style="'--grid-columns:' + gridColumns"
  >
    <v-radio-group
      row
      v-model="selectedAnswer"
      @change="emitToParent(selected)"
      :rules="rules"
      class="mt-0"
      hide-details
    >
      <div
        v-for="option in visibleOptions"
        v-bind:key="option.value"
        :class="[
          { divider: option.isDivider },
          option.isNoneOption ? 'none-option' : ''
        ]"
      >
        <div v-if="option.isDivider">
          <span class="text-small font-weight-bold">{{
            $t(`physicalLabourAssessment.schema.common.noneOfThese.divider`)
          }}</span>
        </div>
        <v-radio v-else :label="$t(option.label)" :value="option.value"
          ><template v-slot:label>
            <span v-if="option.image" class="image"
              ><img :src="option.image" aria-hidden="true"
            /></span>
            <div
              class="d-flex pa-4 justify-space-between align-center"
              style="gap: 0.5rem"
            >
              <div class="d-flex flex-column">
                <strong v-if="option.title">{{ option.title }}</strong>
                <span v-if="option.label" :class="option.title ? 'mt-2' : ''">{{
                  option.label
                }}</span>
              </div>
            </div>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import Answer from "@/components/common/assessments/answer-constants";

export default {
  name: "RadioCardQuestion",
  props: {
    errorMessage: String,
    name: String,
    question: String,
    optional: Boolean,
    options: Array,
    show: {
      default: true,
      type: Boolean
    },
    value: Number,
    fullQuestion: Object,
    hasNoneOption: {
      default: false,
      type: Boolean
    },
    gridColumns: {
      type: Number,
      default: 2
    }
  },
  data() {
    return { selected: null };
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.value;
      },
      set(selectedAnswer) {
        this.selected = selectedAnswer;
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t(this.fullQuestion.errorMessage)];
    },
    visibleOptions() {
      return this.processedOptions.filter(x => x.showAnswer);
    }
  },
  created() {
    this.processOptions();
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    },
    processOptions() {
      this.processedOptions = this.options.map(option => ({
        ...option,
        label: this.$t(option.label),
        title: this.$t(option.title)
      }));

      if (this.hasNoneOption) {
        this.processedOptions.push({
          disabled: true,
          isDivider: true,
          showAnswer: true
        });

        this.processedOptions.push({
          isNoneOption: true,
          value: Answer.NONE_ANSWER_VALUE,
          label: this.$t(
            `physicalLabourAssessment.schema.common.${Answer.NONE_ANSWER_KEY}.option`
          ),
          showAnswer: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
$radio-text-color: rgba(0, 0, 0, 0.6);
$radio-border-color: var(--v-vflGreyVeryLight-base);
$radio-box-shadow-color: #1a2b3706;
$radio-box-shadow-color-hover: #1a2b3712;
$radio-border-color-active: var(--v-vflPrimary-base);
$radio-image-bg-color: #edf0f2;
$radio-error-color: var(--v-error-base);

.radio-card-question {
  container-type: inline-size;

  ::v-deep .v-input--radio-group__input {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    > div:not(.divider, .none-option) {
      display: contents;
    }

    @container (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .v-radio {
    border: 1px solid $radio-border-color;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 $radio-box-shadow-color;
    margin: 0;
    min-width: 200px;
    overflow: hidden;

    &:hover {
      box-shadow: 0 4px 12px 0 $radio-box-shadow-color-hover;
    }

    &:has(:focus) {
      outline: 2px dashed $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(:checked) {
      outline: 2px solid $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(.image) {
      ::v-deep label {
        justify-content: start;
      }
    }

    &:has(.error--text) {
      outline: 2px solid $radio-error-color;
      outline-offset: -1px;
    }

    ::v-deep .v-input--selection-controls__input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    ::v-deep label {
      align-items: initial;
      background-color: white;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      min-height: 44px;

      &.error--text {
        color: $radio-text-color !important;
      }
    }

    ::v-deep .image {
      background-color: $radio-image-bg-color;
      display: flex;
      justify-content: center;

      img {
        max-height: 138px;
        width: 100%;
      }
    }
  }
}

.v-radio.v-item--active {
  ::v-deep {
    label {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
    }
  }
}

.divider {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  grid-column: 1/-1;

  &:before,
  &:after {
    background-image: linear-gradient(
      to right,
      var(--v-vflGreyVeryLight-base) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    content: "";
    display: block;
    flex: 1;
    height: 2px;
  }

  span {
    text-transform: uppercase;
  }

  + div {
    display: block;
    grid-column: 1/-1;
  }
}
</style>
