export const LinkTypes = {
  REGULAR: "regularLink",
  PURCHASE: "purchaseLink",
  EMAIL: "emailLink",
  VIDEO: "videoLink",
  REQUEST: "requestLink",
  EMAILREQUEST_TTQWEL: "emailRequestLink",
  REQUEST_LINK_INTERNAL: "requestLinkInternal",
  INTERNAL: "internalRoute",
  APPOINTMENT: "appointmentLink",
  TIPS: "tipsLink",
  REPORT_DOWNLOAD: "reportDownload"
};

export const InfoButtonStyles = {
  [LinkTypes.REGULAR]: {
    icon: "mdi-lightbulb-outline"
  },
  [LinkTypes.PURCHASE]: {
    icon: "mdi-magnify"
  },
  [LinkTypes.EMAIL]: {
    icon: "mdi-email-arrow-right-outline"
  },
  [LinkTypes.REQUEST]: {
    icon: "mdi-help"
  },
  [LinkTypes.REQUEST_LINK_INTERNAL]: {
    icon: "mdi-form-select"
  },
  [LinkTypes.EMAILREQUEST_TTQWEL]: {
    icon: "mdi-email-arrow-right-outline"
  },
  [LinkTypes.INTERNAL]: {
    icon: "mdi-rocket-launch-outline"
  },
  [LinkTypes.APPOINTMENT]: {
    icon: "mdi-calendar-multiselect-outline"
  },
  [LinkTypes.TIPS]: {
    icon: "mdi-handshake-outline"
  },
  [LinkTypes.REPORT_DOWNLOAD]: {
    icon: "mdi-download"
  }
};
