<template>
  <svg
    width="640"
    height="637"
    viewBox="0 0 640 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M524.198 206.486C517.07 200.76 508.087 201.744 508.087 201.744C504.295 201.945 501.224 204.629 500.365 208.137C498.498 208.748 496.45 208.857 496.45 208.857C496.45 208.857 494.403 208.965 492.482 208.554C491.257 205.157 487.919 202.813 484.127 203.014C484.127 203.014 475.091 202.985 468.608 209.432C468.608 209.432 467.292 210.89 467.355 212.088L467.36 212.186C467.424 213.384 468.887 214.695 468.887 214.695C473.521 218.417 478.96 219.709 482.184 219.873C483.402 219.911 484.025 219.86 485.017 219.808C488.992 219.597 492.172 216.659 492.847 212.904C494.677 212.329 496.629 212.225 496.629 212.225C496.629 212.225 498.581 212.122 500.461 212.5C501.529 216.163 505.003 218.749 508.977 218.538C509.97 218.485 510.594 218.47 511.801 218.303C514.99 217.799 520.262 215.94 524.476 211.749C524.476 211.749 525.793 210.291 525.729 209.093L525.724 208.994C525.661 207.796 524.198 206.486 524.198 206.486ZM488.19 214.894C488.19 214.894 486.054 216.645 481.811 216.893C479.675 217.018 477.54 216.445 475.811 215.185C475.214 214.749 474.644 214.218 474.201 213.581C473.43 212.469 473.364 211.035 474.021 209.852C474.423 209.127 474.981 208.508 475.573 207.993C477.139 206.632 479.135 206.06 481.207 205.96C485.458 205.756 487.776 207.095 487.776 207.095C489.073 207.873 489.974 209.26 490.06 210.885C490.146 212.509 489.397 213.984 488.19 214.894ZM519.074 211.203C518.701 211.883 518.191 212.471 517.643 212.968C516.056 214.404 513.994 215.199 511.857 215.301C507.611 215.503 505.303 213.987 505.303 213.987C504.006 213.21 503.106 211.822 503.02 210.198C502.934 208.574 503.682 207.098 504.89 206.188C504.89 206.188 507.053 204.611 511.302 204.366C513.373 204.246 515.418 204.603 517.12 205.791C517.763 206.241 518.383 206.797 518.86 207.475C519.638 208.583 519.723 210.016 519.074 211.203Z"
      fill="#24313D"
    />
    <path
      d="M525.584 209.626L523.271 208.965C524.168 205.825 523.096 202.823 519.99 199.789C517.435 197.292 513.474 194.804 508.218 192.395C502.206 189.638 499.449 191.665 495.276 194.733C493.733 195.866 491.985 197.152 489.902 198.267C485.735 200.496 482.816 200.258 480.242 200.047C478.032 199.866 476.124 199.71 473.564 201.194C468.374 204.204 469.775 211.808 469.789 211.884L467.427 212.34C467.354 211.959 465.704 202.971 472.358 199.113C475.569 197.251 478.045 197.453 480.439 197.649C482.9 197.851 485.224 198.041 488.766 196.145C490.698 195.111 492.301 193.933 493.851 192.794C498.182 189.611 501.922 186.861 509.221 190.207C514.807 192.769 518.88 195.34 521.672 198.068C525.415 201.726 526.731 205.614 525.584 209.626Z"
      fill="#24313D"
    />
  </svg>
</template>
<script>
export default {
  name: "SwimmingOverlay"
};
</script>
