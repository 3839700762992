import ReturnToWorkRecommendations from "@/assets/json/recommendations/new-parent/ReturnToWorkRecommendations.json";
import Answers from "@/components/assessment/new-parent/answers.json";
import { LinkTypes } from "@/services/recommendations/link-types.js";

const conditions = {
  isReturnedOrFlexible: results =>
    [
      Answers.returnToWork.returnToWorkStatus.returned,
      Answers.returnToWork.returnToWorkStatus.flexible
    ].includes(results.returnToWork.returnToWorkStatus),

  isPreparingOrNotReturningYet: results =>
    [
      Answers.returnToWork.returnToWorkStatus.preparing,
      Answers.returnToWork.returnToWorkStatus.notReturningYet
    ].includes(results.returnToWork.returnToWorkStatus),

  isOfficeOrHybrid: results =>
    [
      Answers.returnToWork.workTimeSplit.office,
      Answers.returnToWork.workTimeSplit.hybrid
    ].includes(results.returnToWork.workTimeSplit)
};

const cards = {
  new_parent_plan_energy: {
    showConditions: conditions.isReturnedOrFlexible
  },
  new_parent_communicate_your_schedule: {
    showConditions: conditions.isReturnedOrFlexible
  },
  new_parent_sneak_in_a_cup_of_tea_working: {
    showConditions: conditions.isReturnedOrFlexible
  },
  new_parent_micro_movement_moments_working: {
    showConditions: results =>
      conditions.isReturnedOrFlexible(results) &&
      conditions.isOfficeOrHybrid(results)
  },
  new_parent_1_minute_mindful_breathing: {
    showConditions: conditions.isPreparingOrNotReturningYet
  },
  new_parent_sneak_in_a_cup_of_tea: {
    showConditions: conditions.isPreparingOrNotReturningYet
  },
  new_parent_micro_movement_moments: {
    showConditions: conditions.isPreparingOrNotReturningYet
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCards = (cards, results) => {
  return Object.entries(cards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, ReturnToWorkRecommendations[cardId])
    );
};

export const getReturnToWorkRecommendations = results => {
  const visibleCards = getVisibleCards(cards, results);

  return {
    groups: {
      returnToWork: {
        cards: visibleCards,
        hideTab: true,
        titleKey: "returnToWorkRecommendations"
      }
    }
  };
};
