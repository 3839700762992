<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <div class="assessment-configuration-dialog-container">
      <vfl-icon-button
        class="close-button"
        size="medium"
        variant="ghost"
        @click="closeDialog"
        :screen-reader="$t('buttons.close')"
      >
        <v-icon>mdi-close</v-icon>
      </vfl-icon-button>
      <assessment-configuration />
    </div>
  </v-dialog>
</template>

<script>
import AssessmentConfiguration from "./AssessmentConfiguration.vue";

export default {
  name: "AssessmentConfigurationDialog",
  components: {
    AssessmentConfiguration
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.assessment-configuration-dialog-container {
  height: 100%;
  position: relative;

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>
