import MenstruationReportHeader from "@/components/assessment/menstruation/report/MenstruationReportHeader.vue";
import MenstruationReportMskHealth from "@/components/assessment/menstruation/report/MenstruationReportMskHealth.vue";
import MenstruationReportPain from "@/components/assessment/menstruation/report/MenstruationReportPain.vue";
import MenstruationReportMuscleStrength from "@/components/assessment/menstruation/report/MenstruationReportMuscleStrength.vue";
import MenstruationReportSymptoms from "@/components/assessment/menstruation/report/MenstruationReportSymptoms.vue";

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: MenstruationReportHeader,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "mskHealth",
    component: MenstruationReportMskHealth,
    isVisible: () => true
  },
  {
    id: "pain",
    component: MenstruationReportPain,
    isVisible: vm => vm.hasPain
  },
  {
    id: "muscleStrength",
    component: MenstruationReportMuscleStrength,
    isVisible: () => true
  },
  {
    id: "symptoms",
    component: MenstruationReportSymptoms,
    isVisible: vm => vm.hasSymptoms
  }
];
