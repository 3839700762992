# PregnancyReportPain.vue
<template>
  <report-pain-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
    :results="results"
    :assessment-id="assessmentId"
    :assessment-type="$options.assessmentTypes.pregnancyAssessment"
    :section-title="$t('pregnancyAssessment.report.content.pain.title')"
    :pain-area-title="
      $t('pregnancyAssessment.report.content.pain.painAreaTitle')
    "
    :info-cards="infoCards"
    :recommendation-title="
      $t('pregnancyAssessment.report.content.pain.recommendationsTitle')
    "
    :pain-recommendations="painRecommendations"
  >
    <template #warning>
      <vfl-callout
        v-if="painStartedDuringPregnancy"
        class="mt-6"
        icon="Lightbulb"
        :icon-width="25"
        backgroundColor="vflNeutralLight2"
      >
        {{ $t("pregnancyAssessment.report.content.pain.callout") }}
      </vfl-callout>
    </template>
  </report-pain-section>
</template>

<script>
import ReportPainSection from "@/components/assessment/common/report/ReportPainSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/pregnancy/report/recommendations/recommendation-service.js";

export default {
  name: "PregnancyReportPain",
  components: {
    ReportPainSection,
    VflCallout
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      painRecommendations: {}
    };
  },
  created() {
    this.painRecommendations = getRecommendationsByType(
      RecommendationSections.PAIN,
      this.results
    );
  },
  computed: {
    showBackCard() {
      return PainAssessmentService.hasPainArea(this.results, "lowerback");
    },
    showHipCard() {
      return PainAssessmentService.hasSomePainAreas(this.results, [
        "lefthip",
        "righthip"
      ]);
    },
    showAnyPainCard() {
      return !this.showBackCard || !this.showHipCard;
    },
    infoCards() {
      return [
        {
          title: this.$t("pregnancyAssessment.report.content.pain.back.title"),
          illustration: "/images/pregnancy-assessment/report/back-pain.svg",
          text: this.$t("pregnancyAssessment.report.content.pain.back.text"),
          showCondition: this.showBackCard,
          className: "backCard report-info-card"
        },
        {
          title: this.$t("pregnancyAssessment.report.content.pain.hip.title"),
          illustration: "/images/pregnancy-assessment/report/hip-pain.svg",
          text: this.$t("pregnancyAssessment.report.content.pain.hip.text"),
          showCondition: this.showHipCard,
          className: "hipsCard report-info-card"
        },
        {
          title: this.$t(
            "pregnancyAssessment.report.content.pain.painDuringPregnancy.title"
          ),
          illustration: "/images/pregnancy-assessment/report/any-pain.svg",
          text: this.$t(
            "pregnancyAssessment.report.content.pain.painDuringPregnancy.text"
          ),
          showCondition: this.showAnyPainCard,
          className: "anyPainCard report-info-card"
        }
      ];
    },
    painStartedDuringPregnancy() {
      return Object.values(this.results.pain?.areas).some(
        area => area.experiencePainBeforePregnancy === 2
      );
    }
  }
};
</script>
