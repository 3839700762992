<template>
  <v-radio-group
    v-model="localSelectedValue"
    :class="[toggleClassName, 'vfl-toggle']"
    background-color="transparent"
    row
    @change="handleValueChange"
    hide-details
  >
    <v-radio
      v-for="option in options"
      :key="option.value"
      :id="`${toggleIdPrefix}-${option.value}`"
      :value="option.value"
      :label="option.text"
      :class="isOptionSelected(option.value) ? 'selected-tab' : ''"
      :disabled="option.disabled"
      ><template v-slot:label>
        <slot :name="`option-${option.value}`">
          {{ option.text }}
        </slot>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "VflToggle",
  props: {
    selectedValue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator: options => {
        return options.every(option => typeof option.value !== "undefined");
      }
    },
    toggleClassName: {
      type: String
    },
    toggleIdPrefix: {
      type: String,
      default: "vfl-toggle"
    },
    analyticsEventName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      localSelectedValue: this.selectedValue
    };
  },

  watch: {
    selectedValue(newVal) {
      this.localSelectedValue = newVal;
    },
    localSelectedValue(newVal) {
      if (newVal !== this.selectedValue) {
        this.$emit("update:selectedValue", newVal);
      }
    }
  },
  methods: {
    isOptionSelected(value) {
      return this.localSelectedValue === value;
    },
    handleValueChange(newValue) {
      this.$emit("value-change", newValue);

      if (this.analyticsEventName) {
        sendEvent(this.analyticsEventName, {
          value: newValue
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vfl-toggle {
  margin-top: 0;
  padding-top: 0;

  .v-radio {
    border: 1px solid var(--v-vflNeutralLight3-base);
    border-radius: 4px;
    margin: 0 !important;

    &:hover {
      transition: border-color 0.125s;
      border-color: var(--v-vflPrimary-base);
    }
  }

  .v-radio.selected-tab {
    border-color: var(--v-vflPrimary-base);
    background-color: var(--v-vflPrimaryLight-base);
    color: black;
  }

  ::v-deep {
    .v-input--radio-group__input {
      gap: 0.325rem;
    }

    .v-input--selection-controls__input {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    .v-label {
      color: black;
      font-size: 0.875rem;
      line-height: 1.8;
      padding: 0 0.5rem;
    }
  }
}
</style>
