<template>
  <div class="blob-container">
    <svg
      width="600"
      height="508"
      viewBox="0 0 600 508"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M277.832 87.4185C288.367 98.7824 298.558 110.71 312.245 118.125C335.98 130.982 361.903 128.355 388.071 125.727C413.77 123.146 439.705 120.567 464.036 132.655C521.855 161.383 548.451 241.242 542.567 300.564C532.62 400.828 404.579 455.907 313.781 456C176.616 456.138 51.4773 351.281 56.1271 213.276C57.1904 181.727 65.2302 150.518 79.7157 122.237C103.991 74.8423 158.255 45.1333 213.268 53.3672C238.567 57.1538 260.884 69.1394 277.832 87.4185Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ImageBlob",
  props: {
    color: {
      type: String,
      default: "#FFBEAC"
    }
  }
};
</script>

<style lang="scss" scoped>
.blob-container {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

svg {
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  svg {
    animation: image-blob-float 20s linear infinite;
  }

  path {
    animation: blob-morph 40s linear infinite;
  }
}

@keyframes image-blob-float {
  0%,
  100% {
    transform: translateY(0) scale(1) rotate(0deg);
  }
  33% {
    transform: translateY(-15px) scale(1.03) rotate(3deg);
  }
  66% {
    transform: translateY(10px) scale(0.98) rotate(-3deg);
  }
}

@keyframes blob-morph {
  0% {
    d: path(
      "M277.832 87.4185C288.367 98.7824 298.558 110.71 312.245 118.125C335.98 130.982 361.903 128.355 388.071 125.727C413.77 123.146 439.705 120.567 464.036 132.655C521.855 161.383 548.451 241.242 542.567 300.564C532.62 400.828 404.579 455.907 313.781 456C176.616 456.138 51.4773 351.281 56.1271 213.276C57.1904 181.727 65.2302 150.518 79.7157 122.237C103.991 74.8423 158.255 45.1333 213.268 53.3672C238.567 57.1538 260.884 69.1394 277.832 87.4185Z"
    );
  }
  33% {
    d: path(
      "M381.488 70.7647C401.578 85.7781 410.988 90.1279 431.288 108.356C441.631 117.644 454.003 130.83 461.488 136.128C521.358 178.505 506.874 177.372 525.926 198.881C571.197 249.996 565.563 338.838 536.244 394.654C486.688 488.989 305.734 505.332 216.691 469.375C82.1794 415.055 1.1402 262.586 60.4882 129.15C74.0556 98.6465 127.9 58.0608 153.328 36.0887C195.94 -0.732811 260.928 -8.31167 311.586 21.6006C334.881 35.3561 355.663 51.4649 381.488 70.7647Z"
    );
  }
  66% {
    d: path(
      "M311.21 70.0292C320.388 83.6068 330.488 92.8941 343.598 102.852C366.333 120.121 377.365 122.114 405.14 123.589C432.417 125.038 465.002 132.366 488.483 148.927C544.281 188.283 559.194 276.046 543.473 337.101C516.899 440.294 380.357 489.294 285.438 474.741C142.048 452.754 28.1682 322.963 55.2955 179.467C61.4972 146.663 68.7594 103.25 88.4631 76.0272C121.484 30.4061 182.995 8.10917 239.168 25.5919C264.999 33.6316 296.445 48.1889 311.21 70.0292Z"
    );
  }
  100% {
    d: path(
      "M277.832 87.4185C288.367 98.7824 298.558 110.71 312.245 118.125C335.98 130.982 361.903 128.355 388.071 125.727C413.77 123.146 439.705 120.567 464.036 132.655C521.855 161.383 548.451 241.242 542.567 300.564C532.62 400.828 404.579 455.907 313.781 456C176.616 456.138 51.4773 351.281 56.1271 213.276C57.1904 181.727 65.2302 150.518 79.7157 122.237C103.991 74.8423 158.255 45.1333 213.268 53.3672C238.567 57.1538 260.884 69.1394 277.832 87.4185Z"
    );
  }
}
</style>
