import Answers from "@/components/assessment/new-parent/answers.json";
import i18n from "@/plugins/i18n";
import BodyAreas from "@/components/painareas/body-areas.json";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";

const EMOTIONAL_STATES = {
  EXCITED: "excited",
  ANXIOUS: "anxious",
  BOTH: "both",
  OVERWHELMED: "overwhelmed",
  OTHER: "other"
};

const WORK_STATUS = {
  RETURNED: Answers.returnToWork.returnToWorkStatus.returned,
  FLEXIBLE: Answers.returnToWork.returnToWorkStatus.flexible
};

export class NewParentReportIntroductionService {
  constructor(results) {
    this.results = results;
    this.localeBase = "newParentAssessment.report.introduction";
  }

  getText() {
    return [
      this.getFirstParagraph(),
      this.getSecondParagraph(),
      this.getThirdParagraph()
    ];
  }

  hasReportedPain() {
    return PainService.hasPain(this.results);
  }

  getPainArea() {
    return !this.hasReportedPain() ||
      Object.keys(this.results.pain.areas).length !== 1
      ? ""
      : PainService.getAllPainAreasText(this.results);
  }

  isWorkingOrFlexible() {
    const status = this.results?.returnToWork?.returnToWorkStatus;

    return status === WORK_STATUS.RETURNED || status === WORK_STATUS.FLEXIBLE;
  }

  getEmotionalState() {
    const feeling = this.results?.returnToWork?.returnToWorkFeeling;
    if (!feeling) return EMOTIONAL_STATES.OTHER;

    const feelings = {
      [Answers.returnToWork.returnToWorkFeeling.excited]:
        EMOTIONAL_STATES.EXCITED,
      [Answers.returnToWork.returnToWorkFeeling.anxious]:
        EMOTIONAL_STATES.ANXIOUS,
      [Answers.returnToWork.returnToWorkFeeling.excitedAndNervous]:
        EMOTIONAL_STATES.BOTH,
      [Answers.returnToWork.returnToWorkFeeling.overwhelmed]:
        EMOTIONAL_STATES.OVERWHELMED,
      [Answers.returnToWork.returnToWorkFeeling.other]: EMOTIONAL_STATES.OTHER
    };

    return feelings[feeling] || EMOTIONAL_STATES.OTHER;
  }

  getFirstParagraph() {
    const paragraphKeys = {
      "working-pain": "workingHasPain",
      "working-noPain": "workingNoPain",
      "notWorking-pain": "notWorkingHasPain",
      "notWorking-noPain": "notWorkingNoPain"
    };

    const key = `${this.isWorkingOrFlexible() ? "working" : "notWorking"}-${
      this.hasReportedPain() ? "pain" : "noPain"
    }`;

    const text = i18n.t(`${this.localeBase}.paragraph1.${paragraphKeys[key]}`);

    return this.hasReportedPain()
      ? text.replace("{painArea}", this.getPainArea())
      : text;
  }

  getSecondParagraph() {
    const emotionalState = this.getEmotionalState();
    return i18n.t(`${this.localeBase}.paragraph2.${emotionalState}`);
  }

  getThirdParagraph() {
    return i18n.t(`${this.localeBase}.paragraph3`);
  }
}
