<template>
  <div>
    <v-text-field
      v-model="equipmentName"
      hide-details
      :label="$t('userProfile.equipment.nameLabel')"
    />

    <v-select
      v-model="location"
      :items="locationOptions"
      class="mt-10"
      hide-details
      :label="$t('userProfile.equipment.locationLabel')"
    ></v-select>

    <vfl-textarea
      v-model="equipmentNote"
      :show-counter="false"
      :height="'100'"
      :label="$t('userProfile.equipment.noteLabel')"
      hide-details
      :hide-label="false"
      class="mt-8"
    />

    <div class="d-flex align-center justify-end mt-4">
      <vfl-button
        :loading="isSaving"
        :disabled="!isFormValid || isSaving"
        class="mt-3"
        @click="handleSaveEquipment"
      >
        {{ getSaveButtonText }}
      </vfl-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VflTextarea from "@/components/vitrue-foundation-library/form/VflTextarea.vue";
import { assessmentLocations } from "@/constants/constants.js";

export default {
  name: "UserProfileAddEquipment",
  components: { VflTextarea },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    defaultLocation: {
      type: String,
      default: assessmentLocations.office
    }
  },
  data() {
    return {
      equipmentName: "",
      equipmentNote: "",
      location: this.defaultLocation,
      locationOptions: [
        {
          text: this.$t("userProfile.equipment.office"),
          value: assessmentLocations.office
        },
        {
          text: this.$t("userProfile.equipment.home"),
          value: assessmentLocations.home
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      adminId: "userId",
      adminName: "userName",
      adminEmail: "userEmail"
    }),
    ...mapGetters("userProfile", ["id"]),
    ...mapGetters("userProfile/equipment", [
      "editingEquipment",
      "isSavingEquipment",
      "isUpdatingEquipment"
    ]),
    isFormValid() {
      return this.equipmentName.trim() && this.location;
    },
    getSaveButtonText() {
      return this.editMode
        ? this.$t("userProfile.equipment.update")
        : this.$t("userProfile.equipment.save");
    },
    isSaving() {
      return this.isSavingEquipment || this.isUpdatingEquipment;
    }
  },
  watch: {
    editMode: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.editingEquipment) {
          this.equipmentName = this.editingEquipment.name || "";
          this.equipmentNote = this.editingEquipment.note || "";
          this.location =
            this.editingEquipment.location || assessmentLocations.office;
        } else {
          this.resetForm();
        }
      }
    },
    defaultLocation: {
      immediate: true,
      handler(newVal) {
        this.location = newVal;
      }
    }
  },
  methods: {
    ...mapActions("userProfile/equipment", [
      "addEquipment",
      "updateEquipment",
      "equipmentActionCompleted"
    ]),
    async handleSaveEquipment() {
      if (!this.isFormValid) return;

      try {
        if (this.editMode && this.editingEquipment) {
          await this.updateEquipment({
            equipmentId: this.editingEquipment.id,
            equipment: {
              name: this.equipmentName,
              note: this.equipmentNote,
              location: this.location
            }
          });

          this.equipmentActionCompleted("update_completed");
        } else {
          await this.addEquipment({
            userId: this.id,
            equipment: {
              name: this.equipmentName,
              location: this.location,
              note: this.equipmentNote
            }
          });
          this.equipmentActionCompleted("create_completed");
        }
      } catch (error) {
        this.$logger.captureException(error);
      }
    },
    resetForm() {
      this.equipmentName = "";
      this.equipmentNote = "";
      this.location = this.defaultLocation;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input {
    padding-top: 0;
  }
}
</style>
