<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="34.4006"
      height="33"
      rx="10"
      transform="matrix(-0.964761 0.263128 0.263128 0.964761 285.611 221.734)"
      fill="#6C8451"
    />
    <path
      d="M268.846 259.016L283.991 254.938C288.608 253.695 291.343 248.945 290.1 244.328L286.022 229.182C284.779 224.566 280.028 221.829 275.411 223.073L260.266 227.15C255.649 228.393 252.914 233.145 254.157 237.762L258.235 252.907C259.478 257.524 264.229 260.259 268.846 259.016Z"
      fill="#7BB63B"
    />
    <path
      d="M276.765 236.242C277.112 237.529 278.011 238.406 278.775 238.2C279.538 237.995 279.876 236.785 279.53 235.498C279.184 234.213 278.284 233.336 277.521 233.542C276.757 233.747 276.419 234.957 276.765 236.242Z"
      fill="#2E2D2D"
    />
    <path
      d="M262.942 239.965C263.289 241.252 264.188 242.129 264.952 241.923C265.715 241.717 266.053 240.508 265.707 239.22C265.361 237.935 264.461 237.059 263.697 237.264C262.934 237.47 262.596 238.68 262.942 239.965Z"
      fill="#2E2D2D"
    />
    <path
      d="M267.915 244.294C266.663 244.631 266.207 246.166 267.065 247.136C268.685 248.966 271.456 249.801 274.217 249.057C276.978 248.314 278.956 246.201 279.437 243.805C279.693 242.535 278.527 241.437 277.276 241.774L267.915 244.294Z"
      fill="#2E2D2D"
    />
  </svg>
</template>

<script>
export default {
  name: "ReturnExcited"
};
</script>
