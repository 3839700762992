import { inviteUserBatch, sendAssessmentReminders } from "@/customApi";
import { fetchMessageTemplates } from "@/services/dashboard/message-template-api-service";
import {
  ExternalNotificationProviders,
  assessmentTypes,
  assessmentLocations
} from "@/constants/constants";
import i18n from "@/plugins/i18n.js";
import mixpanel from "mixpanel-browser";
import sentry from "@/sentry";

const defaultLocations = [
  {
    label: i18n.t(
      `dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.none`
    ),
    type: assessmentLocations.none
  },
  {
    label: i18n.t(
      `dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.home`
    ),
    type: assessmentLocations.home
  },
  {
    label: i18n.t(
      `dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.office`
    ),
    type: assessmentLocations.office
  },
  {
    label: i18n.t(
      `dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.notAtUsualWorkspace`
    ),
    type: assessmentLocations.notAtUsualWorkspace
  }
];

const getInitialState = () => ({
  showDialog: false,
  users: [],
  selectedUsers: {},
  notificationType: "invite",
  selectedAssessmentType: {
    type: assessmentTypes.deskAssessment,
    label: i18n.t(`assessmentTypes.${assessmentTypes.deskAssessment}`)
  },
  selectedLocation: defaultLocations[0],
  sending: false,
  successfulUsers: [],
  failedUsers: [],
  selectedProvider: ExternalNotificationProviders.EMAIL,
  lastUsedProvider: null,
  customMessage: ""
});

export const peopleTableNotificationStore = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    SET_DIALOG_VISIBILITY(state, visible) {
      state.showDialog = visible;
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
    SET_USERS(state, users) {
      state.users = users;

      const selectedUsers = {};
      users.forEach(user => {
        selectedUsers[user.id] = true;
      });

      state.selectedUsers = selectedUsers;
    },
    UPDATE_USER_SELECTION(state, { userId, selected }) {
      state.selectedUsers = {
        ...state.selectedUsers,
        [userId]: selected
      };
    },
    SELECT_ALL_USERS(state) {
      const selectedUsers = {};
      state.users.forEach(user => {
        selectedUsers[user.id] = true;
      });
      state.selectedUsers = selectedUsers;
    },
    DESELECT_ALL_USERS(state) {
      const selectedUsers = {};
      state.users.forEach(user => {
        selectedUsers[user.id] = false;
      });
      state.selectedUsers = selectedUsers;
    },
    SET_NOTIFICATION_TYPE(state, type) {
      state.notificationType = type;
    },
    SET_SELECTED_ASSESSMENT_TYPE(state, assessmentType) {
      state.selectedAssessmentType = assessmentType;
    },
    SET_SELECTED_LOCATION(state, location) {
      state.selectedLocation = location;
    },
    SET_SENDING(state, isSending) {
      state.sending = isSending;
    },
    SET_SUCCESSFUL_USERS(state, users) {
      state.successfulUsers = users;
    },
    SET_FAILED_USERS(state, users) {
      state.failedUsers = users;
    },
    SET_SELECTED_PROVIDER(state, provider) {
      state.selectedProvider = provider;
    },
    SET_LAST_USED_PROVIDER(state, provider) {
      state.lastUsedProvider = provider;
    },
    SET_CUSTOM_MESSAGE(state, message) {
      state.customMessage = message;
    }
  },
  actions: {
    async openDialog(
      { commit, dispatch },
      { users, notificationType, location }
    ) {
      commit("SET_USERS", users);
      commit("SET_NOTIFICATION_TYPE", notificationType);
      commit(
        "SET_SELECTED_LOCATION",
        defaultLocations.find(l => l.type === location) || defaultLocations[0]
      );
      commit("SET_DIALOG_VISIBILITY", true);

      await dispatch("fetchCustomMessage");
    },
    closeDialog({ commit }) {
      commit("SET_DIALOG_VISIBILITY", false);
      commit("RESET_STATE");
    },
    async updateAssessmentType({ commit, dispatch }, assessmentType) {
      commit("SET_SELECTED_ASSESSMENT_TYPE", assessmentType);

      await dispatch("fetchCustomMessage");
    },
    async updateLocation({ commit, dispatch }, location) {
      commit("SET_SELECTED_LOCATION", location);
    },
    updateProvider({ commit }, provider) {
      commit("SET_SELECTED_PROVIDER", provider);
    },
    selectAllUsers({ commit }) {
      commit("SELECT_ALL_USERS");
    },
    deselectAllUsers({ commit }) {
      commit("DESELECT_ALL_USERS");
    },
    updateUserSelection({ commit }, { userId, selected }) {
      commit("UPDATE_USER_SELECTION", { userId, selected });
    },
    async sendNotification({ commit, state, dispatch }) {
      commit("SET_SENDING", true);
      commit("SET_LAST_USED_PROVIDER", state.selectedProvider);

      try {
        if (state.notificationType === "invite") {
          await dispatch("sendInvites");
        } else {
          await dispatch("sendReminders");
        }

        mixpanel.track(`Assessment ${state.notificationType}s sent`, {
          userCount: state.users.length
        });
      } catch (e) {
        sentry.captureException(e);
      } finally {
        commit("SET_SENDING", false);
      }
    },
    async sendInvites({ commit, state, getters }) {
      const successfulUsers = [];
      const failedUsers = [];
        
      const selectedUserIds = Object.keys(getters.selectedUsers).filter(
        userId => getters.selectedUsers[userId]
      );

      const selectedUsers = state.users.filter(user =>
          selectedUserIds.includes(user.id)
      );

      const sendCustomMessage =
        state.customMessage &&
        state.selectedProvider === ExternalNotificationProviders.EMAIL;

      try {
        const emailInvitePurpose =
            state.selectedLocation.type === assessmentLocations.none ||
            state.selectedLocation.type ===
            assessmentLocations.notAtUsualWorkspace
              ? null
              : state.selectedLocation.label;
        
        const request = {
          userIds: selectedUserIds,
          externalNotificationProvider: state.selectedProvider, 
          assessmentType: state.selectedAssessmentType.type,
          message: sendCustomMessage ? state.customMessage : null, 
          location: emailInvitePurpose          
        };
        await inviteUserBatch(request);
        successfulUsers.push(...selectedUsers);
      } catch (e) {
        failedUsers.push(...selectedUsers);
        sentry.captureException(e);
      }
      
      commit("SET_SUCCESSFUL_USERS", successfulUsers);
      commit("SET_FAILED_USERS", failedUsers);
    },
    async sendReminders({ commit, state, getters }) {
      try {
        const selectedUserIds = Object.keys(getters.selectedUsers).filter(
          userId => getters.selectedUsers[userId]
        );

        await sendAssessmentReminders(selectedUserIds, state.selectedProvider);
        commit(
          "SET_SUCCESSFUL_USERS",
          state.users.filter(user => selectedUserIds.includes(user.id))
        );
      } catch (e) {
        sentry.captureException(e);
        commit("SET_FAILED_USERS", state.users);
      }
    },
    async fetchCustomMessage({ commit, state }) {
      try {
        const response = await fetchMessageTemplates(
          state.selectedAssessmentType.type
        );
        commit("SET_CUSTOM_MESSAGE", response?.data?.content || "");
      } catch (e) {
        commit("SET_CUSTOM_MESSAGE", "");
        sentry.captureException(e);
      }
    },
    updateCustomMessage({ commit }, message) {
      commit("SET_CUSTOM_MESSAGE", message);
    }
  },
  getters: {
    showResults: state =>
      state.successfulUsers.length > 0 || state.failedUsers.length > 0,
    showAssessmentTypeDropdown: state => state.notificationType === "invite",
    showLocationDropdown: state =>
      state.notificationType === "invite" &&
      state.selectedAssessmentType.type === assessmentTypes.deskAssessment,
    isProviderDisabled: state => provider => {
      return (
        (provider === ExternalNotificationProviders.SLACK ||
          provider === ExternalNotificationProviders.MSTEAMS) &&
        state.selectedAssessmentType.type !== assessmentTypes.deskAssessment
      );
    },
    customMessage: state => state.customMessage,
    selectedProvider: state => state.selectedProvider,
    selectedAssessmentType: state => state.selectedAssessmentType.type,
    selectedLocation: state => state.selectedLocation.type,
    locations: _ => defaultLocations,
    selectedUsers: state => state.selectedUsers,
    selectedUsersCount: state => {
      return Object.values(state.selectedUsers).filter(selected => selected)
        .length;
    },
    areAllUsersSelected: state => {
      return (
        state.users.length > 0 &&
        state.users.every(user => state.selectedUsers[user.id])
      );
    },
    areNoUsersSelected: state => {
      return !Object.values(state.selectedUsers).some(selected => selected);
    },
    notificationType: state => state.notificationType,
    sending: state => state.sending
  }
};
