<template>
  <div>
    <button
      class="phone-app-button text-small"
      @click="showDialog = true"
      :class="{ 'is-icon-only': !hasText, active: showDialog }"
    >
      <img class="mb-2" width="30" src="/images/icons/phone.svg" />
      <span
        :class="{ 'd-sr-only': !hasText }"
        style="font-weight: 600; line-height: 1.3"
        >{{ $t("phoneApp.sidebarButton") }}</span
      >
    </button>
    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '800px'"
    >
      <PhoneAppQRDialog
        v-model="showDialog"
        @closeDialog="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import PhoneAppQRDialog from "@/components/phone-app/PhoneAppQRDialog.vue";

export default {
  name: "PhoneAppButton",
  components: {
    PhoneAppQRDialog
  },
  props: {
    hasText: Boolean
  },
  data() {
    return {
      showDialog: false
    };
  }
};
</script>

<style scoped lang="scss">
.phone-app-button {
  border: 1px solid hsla(0, 100%, 1000%, 0.1);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.5rem 1rem;
  transition: border-color 0.05s ease-out;
  width: 100%;
  text-align: start;

  &:hover {
    border-color: hsla(0, 100%, 1000%, 0.25);
  }

  &.is-icon-only {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    grid-template-columns: 1fr;
  }

  &.active {
    border-color: hsla(0, 0%, 0%, 0.3);
  }
}

::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>
