<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.157 312.881H202.573V309.755H162.157V312.881Z"
      fill="#C3C4D5"
    />
    <path
      d="M201.872 328.219H162.117V351.555H201.872V328.219Z"
      fill="#C3C4D5"
    />
    <path
      d="M202.574 336.496H161.566V337.439H202.574V336.496Z"
      fill="#C3C4D5"
    />
    <path d="M201.578 326.174H161.162V329.3H201.578V326.174Z" fill="#C3C4D5" />
    <path
      d="M201.468 312.881H161.713V327.908H201.468V312.881Z"
      fill="#C3C4D5"
    />
    <path
      d="M161.864 313.135H201.619V312.627H161.864V313.135Z"
      fill="#A2A6CB"
    />
    <path
      d="M201.468 320.045L191.529 319.849L201.468 319.348V320.045Z"
      fill="#A2A6CB"
    />
    <path
      d="M179.538 323.502L188.595 323.955L194.984 323.739L186.453 323.178L179.538 323.502Z"
      fill="#A2A6CB"
    />
    <path d="M202.17 320.84H161.162V321.563H202.17V320.84Z" fill="#C3C4D5" />
    <path
      d="M201.081 307.027H160.664V310.153H201.081V307.027Z"
      fill="#C3C4D5"
    />
    <path d="M161.77 307.027H201.525V292H161.77V307.027Z" fill="#C3C4D5" />
    <path
      d="M161.068 299.066H202.076V298.343H161.068V299.066Z"
      fill="#C3C4D5"
    />
    <path
      d="M201.526 302.699L184.578 302.79L201.526 303.023V302.699Z"
      fill="#A2A6CB"
    />
    <path
      d="M201.526 333.836L172.438 333.889L201.526 334.027V333.836Z"
      fill="#8C8FB0"
    />
    <path
      d="M111.884 312.881H175.322V309.755H111.884V312.881Z"
      fill="#D4D5E0"
    />
    <path
      d="M173.965 328.219H111.805V351.555H173.965V328.219Z"
      fill="#D4D5E0"
    />
    <path
      d="M158.449 336.361L136.958 335.908L124.619 336.125L145.094 336.685L158.449 336.361Z"
      fill="#A7A9C3"
    />
    <path d="M173.399 326.174H109.961V329.3H173.399V326.174Z" fill="#D4D5E0" />
    <path
      d="M173.185 312.881H111.025V327.908H173.185V312.881Z"
      fill="#D4D5E0"
    />
    <path
      d="M173.185 320.045L153.991 319.849L173.185 319.348V320.045Z"
      fill="#A7A9C3"
    />
    <path
      d="M148.484 324.208L160.823 323.991L145.978 323.566L129.715 323.954L148.484 324.208Z"
      fill="#A7A9C3"
    />
    <path d="M174.541 320.84H109.961V321.563H174.541V320.84Z" fill="#D4D5E0" />
    <path d="M172.438 307.027H109V310.153H172.438V307.027Z" fill="#D4D5E0" />
    <path d="M111.137 307.027H173.297V292H111.137V307.027Z" fill="#D4D5E0" />
    <path
      d="M157.487 296.404L135.996 295.951L123.658 296.167L144.133 296.728L157.487 296.404Z"
      fill="#A7A9C3"
    />
    <path d="M109.78 299.066H174.36V298.343H109.78V299.066Z" fill="#D4D5E0" />
    <path
      d="M173.297 304.23L136.568 304.321L173.297 304.554V304.23Z"
      fill="#A7A9C3"
    />
    <path d="M173.399 339.174H109.961V342.3H173.399V339.174Z" fill="#D4D5E0" />
  </svg>
</template>

<script>
export default {
  name: "ReturnAlreadyReturned"
};
</script>
