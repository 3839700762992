import Answers from "@/components/assessment/new-parent/answers.json";
import i18n from "@/plugins/i18n";
import sentry from "@/sentry.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";

const basePath = "newParentAssessment.report.pain.card";

export class NewParentReportPainService {
  constructor(results) {
    this.results = results;
  }

  getBabyAge() {
    return this.results?.getStarted?.babyAge;
  }

  getParentRole() {
    return this.results?.getStarted?.parentRole;
  }

  getBaseParagraph() {
    const parentRole = this.getParentRole();
    const babyAge = this.getBabyAge();

    if (!parentRole || !babyAge) {
      sentry.captureException(
        `Missing parent role or baby age data: role=${parentRole}, age=${babyAge}`
      );
      return "";
    }

    if (
      parentRole === Answers.getStarted.parentRole.mother &&
      babyAge === Answers.getStarted.babyAge.zeroToSixMonths
    ) {
      return i18n.t(`${basePath}.motherUnderSixMonths`);
    }

    if (
      parentRole === Answers.getStarted.parentRole.mother &&
      (babyAge === Answers.getStarted.babyAge.sixToTwelveMonths ||
        babyAge === Answers.getStarted.babyAge.moreThanAYear)
    ) {
      return i18n.t(`${basePath}.motherOverSixMonths`);
    }

    if (parentRole === Answers.getStarted.parentRole.father) {
      return i18n.t(`${basePath}.father`);
    }

    if (parentRole === Answers.getStarted.parentRole.other) {
      return i18n.t(`${basePath}.other`);
    }

    sentry.captureException(
      `No text mapping found for parent role ${parentRole} and baby age ${babyAge}`
    );
    return "";
  }

  getPainAreaParagraphs() {
    const paragraphs = [];

    if (PainService.hasBackPain(this.results)) {
      const backPainAreas = PainService.getBackPainAreas(this.results);
      const backText = PainService.getAreasText(backPainAreas);
      paragraphs.push(i18n.t(`${basePath}.backPain`, { areas: backText }));
    }

    if (PainService.hasPainArea(this.results, "neck")) {
      paragraphs.push(i18n.t(`${basePath}.neckPain`));
    }

    if (PainService.hasWristPain(this.results)) {
      paragraphs.push(i18n.t(`${basePath}.wristPain`));
    }

    if (PainService.hasUpperLimbPain(this.results)) {
      const upperLimbAreas = PainService.getUpperLimbPainAreas(this.results);
      const upperLimbText = PainService.getAreasText(upperLimbAreas);
      paragraphs.push(
        i18n.t(`${basePath}.upperLimbPain`, { areas: upperLimbText })
      );
    }

    if (PainService.hasLowerLimbPain(this.results)) {
      paragraphs.push(i18n.t(`${basePath}.lowerLimbPain`));
    }

    return paragraphs;
  }

  getHoldingSideParagraph() {
    const babyHoldingSide = this.results?.carrying?.babyHoldingSide;

    if (
      babyHoldingSide === Answers.carrying.babyHoldingSide.left ||
      babyHoldingSide === Answers.carrying.babyHoldingSide.right
    ) {
      return i18n.t(`${basePath}.consistentSideHolding`);
    }
    return null;
  }

  getText() {
    const textArray = [];

    const baseParagraph = this.getBaseParagraph();
    if (baseParagraph) {
      textArray.push(baseParagraph);
    }

    const painParagraphs = this.getPainAreaParagraphs();
    textArray.push(...painParagraphs);

    const holdingSideParagraph = this.getHoldingSideParagraph();
    if (holdingSideParagraph) {
      textArray.push(holdingSideParagraph);
    }

    textArray.push(i18n.t(`${basePath}.finalSentence`));

    return textArray;
  }
}
