<template>
  <div class="vfl-read-more">
    <p v-if="!expanded && text.length > maxLength" :class="textClass">
      {{ text.substring(0, maxLength) }}...
      <vfl-button variant="link" @click="expand">
        {{ $t("readMore.expand") }}
      </vfl-button>
    </p>
    <div v-else-if="text.length > maxLength">
      <p :class="textClass">{{ text }}</p>
      <vfl-button variant="link" @click="collapse">
        {{ $t("readMore.collapse") }}
      </vfl-button>
    </div>
    <p v-else :class="textClass">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "VflReadMore",
  props: {
    text: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 100
    },
    textClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    expand() {
      this.expanded = true;
    },
    collapse() {
      this.expanded = false;
    }
  }
};
</script>

<style scoped lang="scss">
button {
  color: var(--v-vflPrimary-base);
  cursor: pointer;
  font-size: 0.75rem;

  ::v-deep {
    span {
      text-decoration: none !important;
    }
  }
}
</style>
