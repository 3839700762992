export const BACK_PAIN_AREAS = ["midback", "lowerback"];
export const WRIST_AREAS = ["leftwrist", "rightwrist"];
export const UPPER_LIMB_AREAS = [
  "rightshoulder",
  "leftshoulder",
  "rightelbow",
  "leftelbow"
];
export const LOWER_LIMB_AREAS = [
  "lefthip",
  "righthip",
  "gluteals",
  "leftknee",
  "rightknee",
  "lefthamstring",
  "righthamstring",
  "leftcalf",
  "rightcalf",
  "leftankle",
  "rightankle"
];
