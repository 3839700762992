<template>
  <div>
    <v-tooltip bottom color="transparent" :disabled="loading">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <button
            @click="copyLink"
            class="vfl-button-link mr-4 d-flex align-center"
            :class="{ 'is-disabled': disableButton }"
            style="white-space: nowrap; text-decoration: none"
          >
            <span v-if="loading" class="loader"></span>
            <v-icon v-else left color="vflPrimary">mdi-link-variant</v-icon>
            {{
              $t(
                `account.team.userSettings.signUpLink.${
                  teamIdpName ? "sso" : "regular"
                }.button`
              )
            }}
          </button>
        </div>
      </template>
      <vitrue-tooltip :message="tooltipMessage" />
    </v-tooltip>
    <v-snackbar v-model="showCopySuccess" timeout="5000" color="success">
      {{ $t("account.team.userSettings.signUpLink.success") }}
    </v-snackbar>
    <v-snackbar v-model="showCopyFail" timeout="5000" color="error">
      {{ $t("account.team.userSettings.signUpLink.failed") }}
    </v-snackbar>
  </div>
</template>

<script>
import { userTeamMemberInformation, getTeamSettings } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { AssessmentRedirectPages } from "@/services/authorization/url-redirect/redirect-constants.js";

export default {
  components: {
    VitrueTooltip
  },
  props: {
    refreshButton: Boolean,
    assessmentType: String
  },
  data() {
    return {
      loading: true,
      loadingSucceeded: false,
      teamId: null,
      teamIdpName: null,
      allowShareableLink: false,
      showCopySuccess: false,
      showCopyFail: false
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    disableButton() {
      return (
        !this.loadingSucceeded ||
        this.loading ||
        (!this.teamIdpName && !this.allowShareableLink)
      );
    },
    link() {
      const baseUrl = window.location.href.split("#")[0];
      let returnValue = null;
      if (this.teamIdpName) {
        returnValue = `${baseUrl}?teamid=${this.teamId}&idp=${this.teamIdpName}`;
      } else {
        returnValue = `${baseUrl}#/signUp?autojointeamid=${this.teamId}`;
      }

      if (this.assessmentType) {
        const page = AssessmentRedirectPages[this.assessmentType];
        returnValue += `&redirectPage=${page}&redirectAction=new`;
      }
      return returnValue;
    },
    tooltipMessage() {
      if (!this.loadingSucceeded) {
        return this.$t(`account.team.userSettings.signUpLink.loadingFailed`);
      }
      if (this.teamIdpName) {
        return this.$t(`account.team.userSettings.signUpLink.sso.tooltip`);
      }

      return this.$t(
        `account.team.userSettings.signUpLink.regular.${
          this.allowShareableLink ? "tooltip" : "disabledTooltip"
        }`
      );
    }
  },
  methods: {
    copyLink() {
      var component = this;
      navigator.clipboard.writeText(this.link).then(
        function () {
          component.showCopySuccess = true;
        },
        function () {
          component.showCopyFail = true;
        }
      );
    },
    async loadData() {
      try {
        let teamMemberInformation = await userTeamMemberInformation();
        this.teamId = teamMemberInformation.teamId;
        this.teamIdpName = teamMemberInformation.teamIDP;
        let teamSettings = await getTeamSettings();
        this.allowShareableLink = teamSettings.allowShareableLink;
        this.loadingSucceeded = true;
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    async refreshButton(newVal) {
      if (newVal) {
        await this.loadData();
        this.$emit("buttonRefreshed");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.loader {
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: 18px;
    height: 18px;
    border: 1.5px solid var(--v-vflPrimary-base);
    border-radius: 50%;
    border-right-color: transparent;
    animation: iconSpin 0.75s linear infinite;
  }
}

@keyframes iconSpin {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
</style>
