<template>
  <report-pain-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
    :results="results"
    :assessment-id="assessmentId"
    :assessment-type="$options.assessmentTypes.menstruationAssessment"
    :section-title="$t('menstruationAssessment.report.content.pain.title')"
    :pain-area-title="
      $t('menstruationAssessment.report.content.pain.painAreaTitle')
    "
    :info-cards="infoCards"
    :recommendation-title="recommendationTitle"
    :pain-recommendations="painRecommendations"
  />
</template>

<script>
import ReportPainSection from "@/components/assessment/common/report/ReportPainSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import Answers from "@/components/assessment/menstruation/answers.json";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menstruation/report/recommendations/recommendation-service.js";
import BodyAreas from "@/components/painareas/body-areas.json";

export default {
  name: "MenstruationReportPain",
  components: {
    ReportPainSection
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      painRecommendations: {}
    };
  },
  created() {
    this.painRecommendations = getRecommendationsByType(
      RecommendationSections.PAIN,
      this.results
    );
  },
  computed: {
    infoCards() {
      return [
        {
          title: this.$t(
            "menstruationAssessment.report.content.pain.cramps.title"
          ),
          illustration: "/images/illustrations/period-cramps.svg",
          text: this.$t(
            "menstruationAssessment.report.content.pain.cramps.text"
          ),
          showCondition: this.results?.symptoms?.menstruationSymptoms?.includes(
            Answers.symptoms.menstruationSymptoms.abdominalCramps
          ),
          className: "crampsCard"
        },
        {
          title: this.$t(
            "menstruationAssessment.report.content.pain.general.title"
          ),
          illustration: "/images/illustrations/menopause-pain.png",
          text: this.$t(
            "menstruationAssessment.report.content.pain.general.text"
          ),
          showCondition: true,
          className: null
        }
      ];
    },
    recommendationTitle() {
      const painAreaKeys = Object.keys(this.results.pain.areas);
      if (painAreaKeys.length > 1) {
        return this.$t(
          "menstruationAssessment.report.recommendations.pain.title.generic"
        );
      }

      const translatedArea = this.$t(
        BodyAreas[painAreaKeys[0]].shortenedName
      ).toLowerCase();
      return this.$t(
        "menstruationAssessment.report.recommendations.pain.title.withArea",
        { area: translatedArea }
      );
    }
  }
};
</script>
