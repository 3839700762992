<template>
  <section>
    <end-of-report-sign-up
      :assessmentType="assessmentType"
      :assessmentId="assessmentId"
    />
    <short-feedback
      v-model="quickFeedbackSubmitted"
      v-show="displayResultsFeedback"
      :assessmentId="assessmentId"
      urlSuffix="report"
      bottom-offset="5rem"
      trigger-on-scroll
    />
    <template v-for="section in configuredSections">
      <component
        :key="section.id"
        :is="section.component"
        v-bind="section.props"
        :results="results"
        :assessmentId="assessmentId"
        :sectionNumber="section.sectionNumber"
        :backgroundColor="section.backgroundColor"
      />
    </template>

    <report-footer
      :assessmentId="assessmentId"
      :assessmentType="assessmentType"
      :reportContext="reportContext"
    />
  </section>
</template>

<script>
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";

const ReportSectionId = {
  HEADER: "header",
  PAIN: "pain"
};

export default {
  name: "ReportLayout",
  components: {
    ReportFooter,
    EndOfReportSignUp,
    ShortFeedback
  },
  props: {
    results: Object,
    assessmentId: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  data() {
    return {
      quickFeedbackSubmitted: false
    };
  },
  computed: {
    backgroundColors() {
      return ["#FFFFFF", "var(--v-vflBeigeLight2-base)"];
    },
    visibleSections() {
      return this.sectionConfig.filter(section => section.isVisible(this));
    },
    sectionNumbers() {
      const numbers = new Map();
      let currentNumber = 0;

      this.visibleSections.forEach(section => {
        if (!section.skipNumbering) {
          currentNumber++;
          numbers.set(section.id, currentNumber);
        }
      });

      return numbers;
    },
    configuredSections() {
      let contentIndex = -1;

      return this.visibleSections.map(section => {
        const isHeader = section.id === ReportSectionId.HEADER;
        if (!isHeader) contentIndex++;

        return {
          ...section,
          backgroundColor: this.getBackgroundColor(isHeader, contentIndex),
          sectionNumber: this.sectionNumbers.get(section.id),
          props: this.getSectionProps(section)
        };
      });
    },
    displayResultsFeedback() {
      return this.$route.name === this.routeName;
    }
  },
  methods: {
    getBackgroundColor(isHeader, contentIndex) {
      if (isHeader) return null;
      return this.backgroundColors[contentIndex % 2];
    },
    getSectionProps(section) {
      if (section.id === ReportSectionId.PAIN) {
        return { assessmentId: this.assessmentId };
      }
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin-left: -48px;
  margin-right: -48px;
  margin-top: -16px;

  @media (max-width: 1000px) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.patient-report-dialog-open section {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
</style>
