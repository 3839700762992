<template>
  <report-header
    background-color="#fff3df"
    max-width="75ch"
    :title="$t('newParentAssessment.report.header.title')"
    :content="$t('newParentAssessment.report.header.content')"
  >
    <template #background>
      <img
        class="background-image"
        src="/images/new-parent-assessment/report/person-sitting-with-child.svg"
      />
    </template>
  </report-header>
</template>

<script>
import ReportHeader from "@/components/assessment/common/report/ReportHeader.vue";

export default {
  name: "NewParentReportHeader",
  components: { ReportHeader },
  props: {
    results: {
      type: Object,
      required: true
    }
  }
};
</script>
