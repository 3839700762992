<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M519.661 174.89C519.311 172.188 519.151 169.594 521.002 167.564C521.263 167.277 521.56 167.011 521.847 166.753C522.189 166.446 522.512 166.156 522.781 165.834C523.715 164.716 523.836 163.284 523.091 162.186C522.318 161.049 520.562 160.354 519.004 160.569C517.395 160.792 516.119 162.031 516.275 163.221L515.587 163.274C515.394 161.799 516.903 160.322 518.881 160.049C520.754 159.789 522.782 160.586 523.703 161.942C524.561 163.205 524.427 164.844 523.362 166.118C523.068 166.472 522.714 166.789 522.372 167.096C522.083 167.355 521.81 167.6 521.568 167.866C519.852 169.748 520.012 172.237 520.349 174.837L519.661 174.89Z"
      fill="#888886"
    />
    <path
      d="M501.404 200.347L494.407 227.621L490.96 226.314L485 224.051L498.337 180.42L501.36 200.065L501.404 200.347Z"
      fill="#C4D7D0"
    />
    <path
      d="M557.402 224.047L550.703 226.59L547.998 227.618L540.992 200.346L541.036 200.064L544.054 180.422L557.402 224.047Z"
      fill="#C4D7D0"
    />
    <path
      d="M534.927 228.236H497.945V181.513C497.945 179.784 499.807 178.383 502.103 178.383C507.902 178.262 513.701 178.141 519.5 178.02C526.388 178.141 533.277 178.262 540.165 178.383C542.462 178.383 544.323 179.785 544.323 181.513V218.84C544.323 224.029 540.116 228.236 534.927 228.236Z"
      fill="#ABC6BB"
    />
    <path
      d="M502.117 182.666C507.851 183.005 513.689 181.1 518.12 177.443C519.479 176.322 521.376 174.985 522.785 176.042C523.464 176.553 523.698 177.446 524.076 178.208C525.715 181.513 529.999 182.285 533.669 182.654C535.52 182.84 537.371 183.027 539.222 183.213C540.215 183.312 541.252 183.407 542.184 183.049C543.116 182.69 543.897 181.728 543.7 180.749C538.587 177.354 530.517 179.722 526.592 175.004C525.979 174.267 525.526 173.403 524.87 172.704C522.784 170.478 518.689 170.68 516.832 173.1C516.085 174.073 514.576 174.692 513.576 175.402C509.916 178.002 505.345 178.855 501.116 180.361C500.74 180.495 500.316 180.686 500.21 181.072C500.078 181.555 500.54 181.999 501 182.2C501.46 182.4 501.799 182.278 502.117 182.666Z"
      fill="#BA8D7B"
    />
    <path
      d="M541.29 208.631H500.965V190.676H501.012V178.564C501.371 178.564 501.742 178.617 502.104 178.732C502.293 178.79 502.469 178.864 502.634 178.956C503.947 179.688 505.46 180.672 507.237 181.655C511.014 183.75 515.997 185.846 522.846 185.581C527.938 185.384 531.818 183.568 534.923 181.74C536.766 180.654 538.335 179.562 539.721 178.802C540.018 178.641 540.336 178.54 540.66 178.485C540.822 178.455 540.987 178.437 541.146 178.432V190.677C541.196 196.663 541.243 202.647 541.29 208.631Z"
      fill="#C4D7D0"
    />
    <path
      opacity="0.36"
      d="M541.29 208.631H500.965V190.676H501.012V178.564C501.371 178.564 501.742 178.617 502.104 178.732C502.293 178.79 502.469 178.864 502.634 178.956C503.947 179.688 505.46 180.672 507.237 181.655V190.676C510.06 191.803 514.543 193.196 520.188 193.331C526.755 193.487 531.927 191.871 534.923 190.676V181.74C536.766 180.654 538.335 179.562 539.721 178.802C540.018 178.641 540.336 178.54 540.66 178.485C540.822 178.455 540.987 178.437 541.146 178.432V190.677C541.196 196.663 541.243 202.647 541.29 208.631Z"
      fill="#C4D7D0"
    />
    <path
      d="M541.29 211.081H500.965V193.126H501.012V178.506H507.237V193.126C510.06 194.253 514.543 195.645 520.188 195.781C526.755 195.937 531.927 194.321 534.923 193.126V178.506H541.146V193.126C541.196 199.113 541.243 205.096 541.29 211.081Z"
      fill="#8DAA56"
    />
    <path
      d="M544.32 260.535C543.879 266.154 544.544 278.569 538.654 279.717C537.683 279.906 536.043 279.894 534.274 279.782C530.554 279.543 526.239 278.858 526.239 278.64C526.856 268.994 525.753 252.831 524.046 245.246C523.631 243.404 523.18 242.07 522.712 241.455C522.704 241.44 522.692 241.426 522.68 241.411C522.671 241.461 522.665 241.517 522.659 241.582C522.512 242.951 522.698 247.472 522.854 253.235C523.048 260.549 523.192 269.863 522.542 277.245C522.515 277.565 514.706 277.586 511.692 277.001C509.661 276.606 508.074 275.255 506.803 273.459C504.389 270.048 503.109 265.032 502.058 261.933C499.238 253.615 494.479 215.309 501.007 208.739C502.782 206.955 515.922 206.961 520.34 208.641C524.758 206.961 537.898 206.955 539.673 208.739C546.202 215.309 543.935 227.968 543.955 236.331C543.976 245.202 545.006 251.778 544.32 260.535Z"
      fill="#8DAA56"
    />
    <path
      d="M534.924 202.102H509.004V222.131H534.924V202.102Z"
      fill="#819B48"
    />
    <path
      d="M532.582 220.36H531.258V220.065H532.288V219.035H532.582V220.36Z"
      fill="white"
    />
    <path
      d="M528.599 220.361H525.942V220.066H528.599V220.361ZM523.284 220.361H520.627V220.066H523.284V220.361ZM517.97 220.361H515.312V220.066H517.97V220.361Z"
      fill="white"
    />
    <path
      d="M512.657 220.36H511.332V219.035H511.626V220.065H512.657V220.36Z"
      fill="white"
    />
    <path
      d="M511.626 216.266H511.332V213.498H511.626V216.266ZM511.626 210.731H511.332V207.963H511.626V210.731Z"
      fill="white"
    />
    <path
      d="M511.626 205.196H511.332V203.871H512.657V204.165H511.626V205.196Z"
      fill="white"
    />
    <path
      d="M528.599 204.165H525.942V203.871H528.599V204.165ZM523.284 204.165H520.627V203.871H523.284V204.165ZM517.97 204.165H515.312V203.871H517.97V204.165Z"
      fill="white"
    />
    <path
      d="M532.582 205.196H532.288V204.165H531.258V203.871H532.582V205.196Z"
      fill="white"
    />
    <path
      d="M532.583 216.266H532.289V213.498H532.583V216.266ZM532.583 210.731H532.289V207.963H532.583V210.731Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M534.281 279.782C530.56 279.543 526.245 278.858 526.245 278.639C526.863 268.994 525.759 252.831 524.052 245.246C523.637 243.403 523.187 242.07 522.719 241.455C522.86 241.161 523.019 240.893 523.204 240.66C523.578 240.186 524.049 239.854 524.635 239.694C529.383 238.432 532.135 249.888 532.835 253.061C534.222 259.351 534.34 265.882 534.448 272.352C534.493 274.828 534.531 277.33 534.281 279.782Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.15"
      d="M524.071 244.483C524.062 244.736 524.053 244.992 524.048 245.246C523.633 243.403 523.182 242.07 522.714 241.455C522.706 241.44 522.694 241.425 522.682 241.41C522.673 241.46 522.667 241.516 522.661 241.581C522.514 242.95 522.699 247.471 522.855 253.234C523.05 260.549 523.194 269.862 522.543 277.244C522.517 277.565 514.708 277.586 511.694 277C509.663 276.605 508.076 275.254 506.805 273.459H506.902C509.183 273.464 511.482 273.98 513.728 273.574C516.295 273.108 518.602 271.384 519.774 269.053C520.972 266.666 520.975 263.878 520.951 261.208C520.904 255.975 520.789 250.738 520.813 245.508C520.831 241.925 522.237 239.98 523.2 240.66C523.741 241.042 524.142 242.258 524.071 244.483Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.09"
      d="M535.17 192.493C535.106 193.187 535.032 193.909 534.685 194.515C534.149 195.442 533.081 195.907 532.074 196.269C524.118 199.148 515.267 199.504 507.105 197.273C506.213 197.031 505.256 196.702 504.756 195.924C504.476 195.489 504.376 194.962 504.288 194.453C503.364 189.172 502.692 183.847 502.277 178.502H507.234V193.122C510.057 194.249 514.54 195.641 520.185 195.777C526.752 195.933 531.924 194.317 534.92 193.122V178.502H535.983C537.702 181.513 535.48 189.163 535.17 192.493Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.36"
      d="M501.405 200.347L494.408 227.621L490.961 226.314C491.656 224.001 492.395 222.037 492.659 221.384C495.535 214.343 498.405 207.296 501.278 200.253C501.301 200.191 501.331 200.126 501.36 200.064L501.405 200.347Z"
      fill="#C4D7D0"
    />
    <path
      opacity="0.36"
      d="M550.703 226.591L547.998 227.618L540.992 200.347L541.036 200.064C541.504 199.894 542.005 199.826 542.514 199.888C542.549 203.706 543.614 207.509 545.569 210.787C547.179 213.486 549.431 215.974 549.89 219.085C550.079 220.366 549.943 221.67 549.981 222.964C550.002 223.735 550.379 225.131 550.703 226.591Z"
      fill="#C4D7D0"
    />
  </svg>
</template>

<script>
export default {
  name: "GetStartedOneChild"
};
</script>
