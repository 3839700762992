import { LinkTypes } from "@/services/recommendations/link-types.js";
import Cards from "./RecommendationCards.json";
import sentry from "@/sentry";
import constants from "@/components/common/assessments/answer-constants";
import { injectHighestPainAreaIntoLocale } from "./recommendation-text-helper.js";

export default function getHealthRecommendations(results) {
  const recommendations = getAllRecommendations();
  return recommendations.filter(r => {
    try {
      return r.showAnswer(results);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
}

function getAllRecommendations() {
  return [
    {
      id: "physical_labour_quit_smoking",
      showAnswer: results =>
        results.health?.smoker === constants.YES_NO_QUESTION.YES,
      json: Cards.quitSmoking,
      linkType: () => LinkTypes.REGULAR,
      subheading: results =>
        injectHighestPainAreaIntoLocale(results, Cards.quitSmoking.heading)
    },
    {
      id: "physical_labour_exercise_often",
      showAnswer: results =>
        results.health?.exercise < constants.HEALTH.EXERCISE.TWICE_A_WEEK,
      json: Cards.exerciseOften,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_mindful_at_work",
      showAnswer: () => true,
      json: Cards.beMindful,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
