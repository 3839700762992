<template>
  <VflAlert v-if="shouldShow" :type="type" :message="message" :color="color" />
</template>

<script>
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import { calculatePercentageStanding } from "@/components/assessment/desk/helpers/index.js";

export default {
  name: "StandingDeskAlert",
  components: { VflAlert },
  props: {
    type: String,
    color: String,
    results: Object
  },
  computed: {
    percentageStanding() {
      return calculatePercentageStanding(this.results);
    },
    shouldShow() {
      return (
        this.results?.setup?.standingdesk === 1 && this.percentageStanding >= 60
      );
    },
    message() {
      if (this.percentageStanding >= 100) {
        return this.$t("deskAssessment.setup.speechBubbleText.hoursSeating");
      }

      return this.$t(
        "deskAssessment.setup.speechBubbleText.standingDeskSeating"
      );
    }
  }
};
</script>
