<template>
  <div class="action-buttons d-flex">
    <vfl-icon-button
      variant="ghost"
      class="mr-1"
      @click="handleEdit"
      :screen-reader="$t('buttons.edit')"
      :disabled="isDeletingEquipment"
    >
      <v-icon small color="vflPrimary">mdi-pencil-outline</v-icon>
    </vfl-icon-button>
    <vfl-icon-button
      variant="ghost"
      @click="handleDelete"
      :screen-reader="$t('buttons.delete')"
      :disabled="isDeletingEquipment"
    >
      <v-icon small color="vflPrimary">mdi-trash-can-outline</v-icon>
    </vfl-icon-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileEquipmentItemActions",
  props: {
    item: {
      type: Object,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("userProfile/equipment", ["isDeletingEquipment"])
  },
  methods: {
    ...mapActions("userProfile/equipment", [
      "openEquipmentDialog",
      "openDeleteEquipmentConfirmDialog"
    ]),
    handleEdit() {
      if (!this.item.adminSource) return;

      this.openEquipmentDialog({
        equipment: this.item,
        location: this.location
      });
    },
    handleDelete() {
      if (!this.item.adminSource) return;

      this.openDeleteEquipmentConfirmDialog(this.item);
    }
  }
};
</script>
