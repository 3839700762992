<template>
  <div class="report-header pa-8" :style="{ backgroundColor }">
    <slot name="background" class="background-image" />

    <div class="content">
      <div class="content-inner" :style="{ maxWidth }">
        <h1
          class="text-overline vflNeutralDark1--text mb-1"
          style="font-size: 1rem !important"
        >
          {{ title }}
        </h1>
        <h3
          class="text-h2 text-body-darkest"
          style="line-height: 1.2 !important"
          :class="$vuetify.breakpoint.mdAndUp ? 'text-h2' : 'text-h4'"
        >
          {{ content }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportHeader",
  props: {
    backgroundColor: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: "65ch"
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.report-header {
  align-items: center;
  display: flex;
  min-height: 400px;
  overflow: hidden;
  position: relative;

  :deep(.background-image) {
    bottom: 0;
    position: absolute;
    right: 0;

    @media screen and (max-width: 1225px) {
      opacity: 0.4;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .content {
    flex-grow: 1;
    margin: -2rem auto 0;
    max-width: 1320px;

    &-inner {
      position: relative;
    }
  }
}
</style>
