import PregnancyReportHeader from "@/components/assessment/pregnancy/report/PregnancyReportHeader.vue";
import PregnancyReportIntroduction from "@/components/assessment/pregnancy/report/PregnancyReportIntroduction.vue";
import PregnancyReportPain from "@/components/assessment/pregnancy/report/PregnancyReportPain.vue";
import PregnancyReportNoPain from "@/components/assessment/pregnancy/report/PregnancyReportNoPain.vue";
import PregnancyReportBalance from "@/components/assessment/pregnancy/report/PregnancyReportBalance.vue";
import PregnancyReportCirculation from "@/components/assessment/pregnancy/report/PregnancyReportCirculation.vue";
import PregnancyReportPostnatal from "@/components/assessment/pregnancy/report/PregnancyReportPostnatal.vue";

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: PregnancyReportHeader,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "introduction",
    component: PregnancyReportIntroduction,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "pain",
    component: PregnancyReportPain,
    isVisible: vm => vm.hasPain
  },
  {
    id: "noPain",
    component: PregnancyReportNoPain,
    isVisible: vm => !vm.hasPain
  },
  {
    id: "balance",
    component: PregnancyReportBalance,
    isVisible: () => true
  },
  {
    id: "circulation",
    component: PregnancyReportCirculation,
    isVisible: () => true
  },
  {
    id: "postnatalRecovery",
    component: PregnancyReportPostnatal,
    isVisible: () => true
  }
];
