<template>
  <v-dialog v-if="showDialog" v-model="showDialog" max-width="650">
    <v-card
      class="text-center rounded-lg"
      :class="$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'pa-6'"
    >
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" class="mb-8"
          ><p id="heading" class="text-h5 mb-4">{{ heading }}</p>
          <p id="subheading" class="text-subtitle-1 my-0 vitrueGrey--text">
            {{ subheading }}
          </p></v-col
        >
        <v-col cols="12"
          ><v-row
            ><v-col>
              <button
                id="declineButton"
                class="vfl-button-primary-outline is-full-width is-medium"
                @click="cancel()"
              >
                {{ declineText }}
              </button></v-col
            ><v-col>
              <button
                id="confirmButton"
                class="vfl-button-primary is-full-width is-medium"
                @click="agree()"
              >
                {{ confirmText }}
              </button></v-col
            ></v-row
          ></v-col
        ></v-row
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PainPersonQuestionDialog",
  data() {
    return {
      showDialog: false
    };
  },
  props: {
    heading: String,
    subheading: String,
    confirmText: String,
    declineText: String
  },
  methods: {
    open() {
      this.showDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.showDialog = false;
    },
    cancel() {
      this.resolve(false);
      this.showDialog = false;
    }
  }
};
</script>
