import sentry from "@/sentry";
import { getAssessmentHistoryData } from "@/customApi";

export async function getAssessmentHistory(isSimpleUser) {
  try {
    return await getAssessmentHistoryData(isSimpleUser);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
}
