export const Theme = {
  WELCOME: "welcome",
  TRIMESTER: "trimester",
  TRIMESTER_OUTRO: "trimesterOutro",
  PAIN: "pain",
  PAIN_OUTRO: "painOutro",
  BALANCE: "balance",
  BALANCE_OUTRO: "balanceOutro",
  CIRCULATION: "circulation",
  CIRCULATION_OUTRO: "circulationOutro",
  LIFESTYLE: "lifestyle",
  REPORT: "report"
};

export const THEME_ORDER = [
  Theme.WELCOME,
  Theme.TRIMESTER,
  Theme.TRIMESTER_OUTRO,
  Theme.PAIN,
  Theme.PAIN_OUTRO,
  Theme.BALANCE,
  Theme.BALANCE_OUTRO,
  Theme.CIRCULATION,
  Theme.CIRCULATION_OUTRO,
  Theme.LIFESTYLE,
  Theme.REPORT
];

export const AssessmentSteps = {
  Welcome: {
    THEME: Theme.WELCOME,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    BACKGROUND: {
      backgroundColor: "#F8F5F0"
    }
  },
  Trimester: {
    THEME: Theme.TRIMESTER,
    STEPPER: true,
    STEPPER_GROUP: Theme.TRIMESTER,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #F8F5F0 66vh, #EEE8DF 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  TrimesterOutro: {
    THEME: Theme.TRIMESTER_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.TRIMESTER,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F8F5F0"
    }
  },
  Pain: {
    THEME: Theme.PAIN,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 550px, #DBEEEF 550px)"
    }
  },
  PainOutro: {
    THEME: Theme.PAIN_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F3F9FA"
    }
  },
  Balance: {
    THEME: Theme.BALANCE,
    STEPPER: true,
    STEPPER_GROUP: Theme.BALANCE,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F7FBF3 550px, #DEE6D7 550px)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: "auto"
    }
  },
  BalanceOutro: {
    THEME: Theme.BALANCE_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.BALANCE,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F7FBF3"
    }
  },
  Circulation: {
    THEME: Theme.CIRCULATION,
    STEPPER: true,
    STEPPER_GROUP: Theme.CIRCULATION,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #FFEDE8 550px, #FFDED6 550px)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: "auto",
      "--left-offset": "40px"
    }
  },
  CirculationOutro: {
    THEME: Theme.CIRCULATION_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.CIRCULATION,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#FFEDE8"
    }
  },
  Lifestyle: {
    THEME: Theme.LIFESTYLE,
    STEPPER: true,
    STEPPER_GROUP: Theme.LIFESTYLE,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #FDF9E6 560px, #F6EDBE 560px)"
    }
  },
  Report: {
    THEME: Theme.REPORT,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    BACKGROUND: {
      backgroundColor: "white"
    }
  }
};

export const getStepByIndex = index => {
  const steps = Object.values(AssessmentSteps);
  return index >= 0 && index < steps.length ? steps[index] : null;
};
