<template>
  <div class="introduction-container">
    <div class="introduction-content">
      <img :src="illustration" alt="" class="mb-4 illustration" />
      <div class="introduction-text vflTextDark--text text-body-1">
        <p
          v-for="(p, index) in text"
          :key="`introduction-paragraph-${index}`"
          class="mb-8"
        >
          {{ p }}
        </p>
        <vfl-callout
          icon="Lightbulb"
          :icon-width="25"
          backgroundColor="vflNeutralLight2"
          >{{ $t("pregnancyAssessment.report.content.introduction.callout") }}
        </vfl-callout>
      </div>
    </div>
  </div>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import { PregnancyReportIntroductionService } from "./pregnancy-report-introduction-service.js";

export default {
  name: "PregnancyReportIntroduction",
  components: { VflCallout },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pregnancyReportIntroductionService: null
    };
  },
  created() {
    this.pregnancyReportIntroductionService =
      new PregnancyReportIntroductionService(this.results);
  },
  computed: {
    text() {
      return this.pregnancyReportIntroductionService.getIntroductionText();
    },
    illustration() {
      return this.pregnancyReportIntroductionService.getIllustration();
    }
  }
};
</script>

<style lang="scss" scoped>
.illustration {
  max-width: 300px;
  width: 100%;
}

.introduction-container {
  background: white;
  padding: 80px 60px;
}

.introduction-content {
  max-width: 1320px;
  display: flex;
  margin: auto;

  .introduction-text {
    margin-left: 20px;
  }

  @media screen and (max-width: 1025px) {
    display: block;

    .introduction-text {
      margin-left: 0px;
    }
  }
}
</style>
