<script>
import { assessmentTypes } from "@/constants/constants.js";
import { routes } from "@/router/route-constants.js";
import ReportLayout from "@/components/assessment/common/report/ReportLayout.vue";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";
import Answers from "@/components/assessment/menstruation/answers.json";
import { REPORT_SECTION_CONFIG } from "@/components/assessment/menstruation/report/report.js";

export default {
  name: "MenstruationReport",
  extends: ReportLayout,
  data() {
    return {
      sectionConfig: REPORT_SECTION_CONFIG,
      routeName: routes.MENSTRUATION_ASSESSMENT
    };
  },
  computed: {
    assessmentType() {
      return assessmentTypes.menstruationAssessment;
    },
    hasPain() {
      return PainAssessmentService.hasPain(this.results);
    },
    hasSymptoms() {
      const symptoms = this.results?.symptoms?.menstruationSymptoms;
      const NONE_ANSWER = Answers.symptoms.menstruationSymptoms.noSymptoms;

      if (!Array.isArray(symptoms)) {
        return false;
      }

      if (symptoms.length === 1 && symptoms[0] === NONE_ANSWER) {
        return false;
      }

      return symptoms.length > 0;
    }
  }
};
</script>
