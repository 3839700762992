<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5289_52090)">
      <path
        d="M401.438 238.428C401.438 239.026 401.427 239.624 401.403 240.211C401.31 243.023 400.982 245.79 400.443 248.462C400.314 249.118 400.161 249.763 399.997 250.408C395.203 270.158 378.77 285.349 358.374 288.338C355.972 288.689 353.51 288.877 351.002 288.877C347.006 288.877 343.137 288.407 339.398 287.529H339.387C337.606 287.107 335.847 286.591 334.136 285.981C331.533 285.067 329.013 283.931 326.623 282.594C320.856 279.429 315.78 275.139 311.677 270.052C309.346 267.158 307.329 263.993 305.688 260.617C305.524 260.289 305.36 259.961 305.208 259.62C304.106 257.288 303.204 254.85 302.477 252.329C302.383 251.967 302.289 251.614 302.184 251.263C301.116 247.161 300.543 242.871 300.543 238.428C300.543 233.927 301.129 229.579 302.242 225.442V225.43C304.001 218.784 307.095 212.678 311.221 207.414V207.402C313.811 204.074 316.811 201.085 320.152 198.518C321.606 197.37 323.118 196.327 324.7 195.365H324.712C324.712 195.365 324.712 195.353 324.723 195.353C326.259 194.416 327.853 193.549 329.482 192.774C336.011 189.692 343.302 187.98 351.002 187.98C356.43 187.98 361.656 188.836 366.556 190.43H366.568C386.811 196.994 401.438 215.995 401.438 238.428Z"
        fill="#A9B879"
      />
      <path
        d="M313.072 281.084C313.072 283.417 312.884 285.714 312.533 287.953C312.158 290.402 311.571 292.782 310.798 295.079C309.497 299.041 307.633 302.768 305.312 306.144C302.651 310.024 299.393 313.458 295.653 316.306C288.188 322.037 278.857 325.438 268.718 325.438C257.372 325.438 247.034 321.182 239.193 314.173C237.962 313.082 236.802 311.911 235.723 310.692C235.184 310.094 234.668 309.484 234.165 308.851C228.668 302.03 225.15 293.555 224.495 284.295C224.424 283.229 224.377 282.174 224.377 281.084C224.377 276.184 225.163 271.485 226.628 267.089V267.078C227.94 263.151 229.781 259.493 232.043 256.152C240.025 244.42 253.469 236.73 268.718 236.73C273.513 236.73 278.119 237.493 282.433 238.9H282.444C289.923 241.325 296.522 245.686 301.655 251.382C301.936 251.687 302.206 252.003 302.476 252.331C304.175 254.313 305.687 256.445 307.024 258.708C307.141 258.907 307.257 259.107 307.363 259.318C309.238 262.647 310.716 266.257 311.676 270.054C312.591 273.582 313.072 277.286 313.072 281.084Z"
        fill="#A9B879"
      />
      <path
        d="M284.695 224.894C284.695 249.387 264.84 269.242 240.347 269.242C215.855 269.242 196 249.387 196 224.894C196 200.401 215.855 180.546 240.347 180.546C264.84 180.546 284.695 200.401 284.695 224.894Z"
        fill="#A9B879"
      />
      <path
        d="M325.626 186.341C325.626 187.642 325.567 188.931 325.45 190.21C325.31 191.956 325.052 193.678 324.712 195.367H324.7C324.102 198.332 323.188 201.18 322.016 203.9C320.715 206.912 319.097 209.749 317.187 212.374C313.272 217.777 308.161 222.266 302.242 225.442C296.839 228.337 290.779 230.154 284.332 230.588C283.324 230.659 282.304 230.693 281.284 230.693C264.653 230.693 250.165 221.539 242.569 208.002C238.971 201.59 236.932 194.205 236.932 186.341C236.932 184.418 237.049 182.52 237.283 180.657C240.084 158.855 258.71 142 281.284 142C294.529 142 306.427 147.813 314.549 157.015C315.077 157.612 315.581 158.234 316.073 158.855C322.062 166.415 325.626 175.956 325.626 186.341Z"
        fill="#A9B879"
      />
      <path
        d="M356.538 244.413C356.538 279.094 328.423 307.209 293.742 307.209C259.06 307.209 230.945 279.094 230.945 244.413C230.945 209.731 259.06 181.616 293.742 181.616C328.423 181.616 356.538 209.731 356.538 244.413Z"
        fill="#A9B879"
      />
      <path
        d="M366.99 185.24C366.99 187.01 366.85 188.744 366.568 190.432C364.879 200.945 358.105 209.76 348.845 214.273C344.567 216.371 339.773 217.543 334.698 217.543C331.451 217.543 328.31 217.062 325.356 216.159C322.437 215.293 319.695 214.004 317.186 212.375C314.994 210.968 312.99 209.303 311.22 207.417C305.735 201.626 302.395 193.819 302.395 185.24C302.395 184.232 302.441 183.223 302.535 182.24C303.321 173.671 307.47 166.052 313.647 160.766C314.419 160.074 315.228 159.441 316.073 158.855C321.336 155.128 327.759 152.948 334.698 152.948C352.525 152.948 366.99 167.411 366.99 185.24Z"
        fill="#A9B879"
      />
      <path
        d="M358.373 288.338C358.467 289.427 358.525 290.529 358.525 291.643C358.525 311.674 342.292 327.92 322.261 327.92C311.746 327.92 302.287 323.455 295.653 316.304C288.187 322.036 278.856 325.436 268.718 325.436C257.371 325.436 247.034 321.18 239.192 314.171C237.962 313.081 236.801 311.909 235.723 310.69C235.184 310.093 234.668 309.483 234.164 308.849C235.148 307.338 236.321 305.802 237.656 304.231C241.83 299.285 246.717 294.879 252.343 291.643C253.046 291.233 253.761 290.846 254.488 290.471C264.322 285.489 275.482 284.329 286.616 284.88C290.742 285.079 294.868 285.513 298.911 286.087C303.435 286.72 307.984 287.506 312.532 287.951C316.634 288.373 320.748 288.526 324.84 288.091C328.003 287.752 331.133 287.037 334.134 285.981C338.166 284.599 341.987 282.606 345.422 280.086C346.793 279.078 348.094 277.999 349.337 276.839C350.707 275.551 351.986 274.178 353.228 272.771C355.853 269.819 358.315 266.7 361.128 263.922C370.365 254.827 383.41 249.716 396.361 250.115C397.569 250.15 398.776 250.244 399.996 250.408C395.201 270.158 378.768 285.349 358.373 288.338Z"
        fill="#96A85D"
      />
      <path
        d="M218.032 217.657C218.78 219.332 219.241 220.456 219.624 221.491C219.99 222.51 220.244 223.391 220.384 224.294C220.536 225.195 220.567 226.11 220.533 227.192C220.489 228.292 220.394 229.504 220.191 231.333C218.335 231.367 216.88 230.519 215.802 229.409C214.732 228.282 214.05 226.839 213.821 225.33C213.581 223.823 213.788 222.242 214.457 220.841C215.144 219.455 216.264 218.201 218.032 217.657Z"
        fill="#6A734C"
      />
      <path
        d="M228.207 234.962C229.668 236.21 230.565 237.297 231.343 238.359C232.096 239.419 232.675 240.445 233.123 241.526C233.581 242.603 233.904 243.733 234.127 245.015C234.334 246.315 234.471 247.716 234.323 249.639C232.388 249.541 230.911 248.769 229.691 247.778C228.488 246.77 227.571 245.495 226.991 244.081C226.402 242.671 226.145 241.122 226.276 239.558C226.433 237.995 226.922 236.404 228.207 234.962Z"
        fill="#6A734C"
      />
      <path
        d="M233.187 217.246C234.377 218.52 235.038 219.491 235.571 220.411C236.079 221.324 236.406 222.16 236.576 223.03C236.756 223.896 236.774 224.794 236.651 225.83C236.51 226.883 236.265 228.031 235.641 229.668C233.894 229.465 232.695 228.672 231.777 227.715C230.877 226.744 230.295 225.564 230.058 224.317C229.81 223.073 229.902 221.762 230.365 220.521C230.852 219.287 231.656 218.099 233.187 217.246Z"
        fill="#6A734C"
      />
      <path
        d="M370.475 260.813C371.519 262.221 371.778 263.622 371.739 264.939C371.672 266.253 371.249 267.484 370.546 268.527C369.852 269.574 368.873 270.433 367.682 270.99C366.478 271.527 365.08 271.816 363.377 271.373C363.39 269.62 363.597 268.454 363.87 267.417C364.154 266.399 364.523 265.563 365.032 264.82C365.534 264.071 366.17 263.414 367.006 262.767C367.867 262.124 368.866 261.489 370.475 260.813Z"
        fill="#6A734C"
      />
      <path
        d="M331.228 174.021C332.86 174.01 333.967 174.518 334.853 175.176C335.718 175.849 336.321 176.714 336.64 177.671C336.968 178.627 337.011 179.679 336.726 180.737C336.419 181.796 335.838 182.871 334.521 183.846C333.077 183.081 332.259 182.422 331.604 181.77C330.975 181.118 330.567 180.482 330.34 179.783C330.105 179.085 330.05 178.329 330.16 177.429C330.291 176.514 330.545 175.496 331.228 174.021Z"
        fill="#6A734C"
      />
      <path
        d="M340.077 178.929C341.804 179.545 342.881 180.669 343.635 181.908C344.367 183.162 344.733 184.569 344.731 185.975C344.738 187.382 344.376 188.791 343.643 190.044C342.891 191.285 341.811 192.407 340.076 193.02C339.231 191.397 338.817 190.199 338.508 189.078C338.22 187.967 338.079 186.973 338.086 185.975C338.085 184.977 338.229 183.98 338.517 182.87C338.829 181.747 339.24 180.549 340.077 178.929Z"
        fill="#6A734C"
      />
      <path
        d="M263.867 540.43L290.66 234.914L301.267 229.989L293.079 540.43H263.867Z"
        fill="#3B2723"
      />
      <path
        d="M0 461.444C0 461.444 46.6073 426.806 100.5 423.5C263.5 413.499 405.102 529.506 539.083 620.749C579.414 648.214 584.248 650.715 604.06 672.684C645.392 718.517 622.881 822.999 622.881 822.999H0V461.444Z"
        fill="#EEE8DF"
      />
      <path
        d="M401.575 636.208C545.175 636.208 661.586 621.775 661.586 603.971C661.586 586.167 545.175 571.734 401.575 571.734C257.975 571.734 141.564 586.167 141.564 603.971C141.564 621.775 257.975 636.208 401.575 636.208Z"
        fill="#E7DCCB"
      />
      <path
        d="M185 83H370C370 83 354.172 38.3883 321.586 52.945C307.712 16.1869 270.192 19.8533 261.347 40.0649C250.081 26.7654 226.153 30.2202 225.035 52.945C211.163 41.8443 185.186 57.7621 185 83Z"
        fill="white"
      />
      <path
        d="M460 133H623C623 133 608.036 89.5243 580.18 110.197C570.674 73.1148 547.617 102.844 547.617 102.844C547.617 102.844 554.299 75.8848 533.877 74.18C513.454 72.4752 504.703 82.918 506.584 103.909C489.08 92.8281 479.199 98.2622 480.422 116.803C468.753 110.517 460 133 460 133Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M547.065 374.244C540.589 378.147 534.226 380.033 531.24 379.263C523.75 377.333 523.062 349.935 528.857 339.134C534.651 328.333 564.442 323.625 565.943 345.025C566.464 352.453 563.346 359.081 558.738 364.49L567 403H543L547.065 374.244Z"
        fill="#B28B67"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M560.582 369.308C563.594 355.906 572.175 346.771 570.865 340.782C569.554 334.792 563.879 333.332 563.879 333.332C563.879 333.332 560.943 321.457 544.944 323.23C528.945 325.004 519.181 331.648 523.398 346.268C527.274 346.268 531.954 344.866 538.424 347.268C541.504 348.411 542.83 354.358 542.83 354.358H545.754C545.754 354.358 550.011 347.301 554.149 349.171C558.287 351.042 556.03 358.259 556.03 358.259L557.471 369.308H560.582Z"
        fill="#1D292B"
      />
      <path
        d="M575.037 588.426H622.354C622.573 588.426 622.753 588.34 622.765 588.229C622.919 586.723 624.566 576.884 641.476 570.075C641.777 569.954 641.625 569.707 641.241 569.702C638.236 569.662 628.943 570.322 615.915 578.56C615.661 578.721 615.15 578.619 615.185 578.414C615.513 576.515 616.836 571.035 621.562 567.301C621.804 567.109 621.365 566.887 620.992 567.013C617.086 568.328 607.795 571.832 604.811 576.282C604.663 576.502 604.02 576.449 604.013 576.217C603.916 572.64 602.916 564.83 596.445 559.893C596.21 559.715 595.658 559.814 595.694 560.028C596.237 563.234 597.853 575.478 591.559 578.987C591.324 579.118 590.898 579.058 590.843 578.884C590.321 577.22 587.745 570.858 578.444 568.833C578.069 568.751 577.709 568.965 577.933 569.138C579.731 570.525 583.885 574.392 581.493 579.034C581.388 579.238 580.818 579.245 580.7 579.042C579.994 577.826 577.598 575.52 569.476 573.863C569.114 573.79 568.769 573.989 568.965 574.16C571.087 576.022 577.848 582.588 574.647 588.157C574.569 588.291 574.762 588.426 575.037 588.426Z"
        fill="#6A734C"
      />
      <path
        d="M590.603 414.777C588.903 414.777 587.512 416.114 587.512 417.747V578.475C587.512 580.108 588.903 581.445 590.603 581.445H598.905C600.605 581.445 601.996 580.108 601.996 578.475V417.747C601.996 416.114 600.605 414.777 598.905 414.777H590.603Z"
        fill="#C9AB7E"
      />
      <path
        d="M589.493 414.777H590.856V581.445H589.493C588.307 581.445 587.336 580.108 587.336 578.475V417.747C587.336 416.114 588.307 414.777 589.493 414.777Z"
        fill="#966F60"
      />
      <path
        d="M617.503 501.879C615.803 501.879 614.412 502.606 614.412 503.495V590.971C614.412 591.86 615.803 592.587 617.503 592.587H625.806C627.506 592.587 628.897 591.86 628.897 590.971V503.495C628.897 502.606 627.506 501.879 625.806 501.879H617.503Z"
        fill="#C9AB7E"
      />
      <path
        d="M617.503 501.879H619.327V592.588H617.503C615.803 592.588 614.412 591.86 614.412 590.971V503.496C614.412 502.606 615.803 501.879 617.503 501.879Z"
        fill="#966F60"
      />
      <path
        d="M338.671 502.104C336.971 502.104 335.58 502.833 335.58 503.724V591.415C335.58 592.306 336.971 593.035 338.671 593.035H346.973C348.674 593.035 350.065 592.306 350.065 591.415V503.724C350.065 502.833 348.674 502.104 346.973 502.104H338.671Z"
        fill="#C9AB7E"
      />
      <path
        d="M344.891 502.104H346.973C348.673 502.104 350.064 502.833 350.064 503.724V591.415C350.064 592.306 348.673 593.035 346.973 593.035H344.891V502.104Z"
        fill="#966F60"
      />
      <path
        d="M365.314 415.227C363.614 415.227 362.223 416.563 362.223 418.197V578.924C362.223 580.557 363.614 581.894 365.314 581.894H373.616C375.316 581.894 376.707 580.557 376.707 578.924V418.197C376.707 416.563 375.316 415.227 373.616 415.227H365.314Z"
        fill="#C9AB7E"
      />
      <path
        d="M373.473 415.227H373.615C375.315 415.227 376.706 416.563 376.706 418.197V578.924C376.706 580.557 375.315 581.894 373.615 581.894H373.473V415.227Z"
        fill="#966F60"
      />
      <path
        d="M332.25 426.707C332.25 429.05 334.631 430.966 337.541 430.966H623.831C626.741 430.966 629.121 429.05 629.121 426.707V415.269C629.121 412.926 626.741 411.01 623.831 411.01H337.541C334.631 411.01 332.25 412.926 332.25 415.269V426.707Z"
        fill="#ECD4B1"
      />
      <path
        d="M332.922 413.2C333.829 411.896 335.562 411.01 337.541 411.01H623.831C625.81 411.01 627.543 411.896 628.45 413.2H332.922Z"
        fill="#DAAC68"
      />
      <path
        d="M319.388 505.038C316.486 507.852 317.419 510.155 321.461 510.155H639.91C643.953 510.155 644.848 507.892 641.899 505.127L638.866 502.283C635.918 499.518 630.198 497.256 626.155 497.256H334.763C330.72 497.256 325.039 499.558 322.136 502.373L319.388 505.038Z"
        fill="#C9AB7E"
      />
      <path
        d="M314.112 521.107C314.112 523.784 316.832 525.974 320.158 525.974H641.214C644.54 525.974 647.261 523.784 647.261 521.107V510.885C647.261 508.208 644.54 506.018 641.214 506.018H320.158C316.832 506.018 314.111 508.208 314.111 510.885L314.112 521.107Z"
        fill="#ECD4B1"
      />
      <path
        d="M332.25 485.117C332.25 487.46 334.631 489.376 337.541 489.376H623.831C626.741 489.376 629.121 487.46 629.121 485.117V473.679C629.121 471.336 626.741 469.42 623.831 469.42H337.541C334.631 469.42 332.25 471.336 332.25 473.679V485.117Z"
        fill="#ECD4B1"
      />
      <path
        d="M332.922 471.61C333.829 470.307 335.562 469.42 337.541 469.42H623.831C625.81 469.42 627.543 470.307 628.45 471.61H332.922Z"
        fill="#DAAC68"
      />
      <path
        d="M332.25 455.91C332.25 458.253 334.631 460.169 337.541 460.169H623.831C626.741 460.169 629.121 458.253 629.121 455.91V444.472C629.121 442.129 626.741 440.213 623.831 440.213H337.541C334.631 440.213 332.25 442.129 332.25 444.472V455.91Z"
        fill="#ECD4B1"
      />
      <path
        d="M332.922 442.403C333.829 441.099 335.562 440.213 337.541 440.213H623.831C625.81 440.213 627.543 441.099 628.45 442.403H332.922Z"
        fill="#DAAC68"
      />
      <path
        d="M591.951 420.987C591.951 422.398 593.167 423.542 594.667 423.542C596.167 423.542 597.383 422.398 597.383 420.987C597.383 419.576 596.167 418.432 594.667 418.432C593.167 418.432 591.951 419.576 591.951 420.987Z"
        fill="#966F60"
      />
      <path
        d="M591.951 450.192C591.951 451.604 593.167 452.748 594.667 452.748C596.167 452.748 597.383 451.604 597.383 450.192C597.383 448.781 596.167 447.637 594.667 447.637C593.167 447.637 591.951 448.781 591.951 450.192Z"
        fill="#966F60"
      />
      <path
        d="M591.951 479.395C591.951 480.807 593.167 481.951 594.667 481.951C596.167 481.951 597.383 480.807 597.383 479.395C597.383 477.984 596.167 476.84 594.667 476.84C593.167 476.84 591.951 477.984 591.951 479.395Z"
        fill="#966F60"
      />
      <path
        d="M366.75 420.987C366.75 422.398 367.966 423.542 369.466 423.542C370.966 423.542 372.182 422.398 372.182 420.987C372.182 419.576 370.966 418.432 369.466 418.432C367.966 418.432 366.75 419.576 366.75 420.987Z"
        fill="#966F60"
      />
      <path
        d="M366.75 450.192C366.75 451.604 367.966 452.748 369.466 452.748C370.966 452.748 372.182 451.604 372.182 450.192C372.182 448.781 370.966 447.637 369.466 447.637C367.966 447.637 366.75 448.781 366.75 450.192Z"
        fill="#966F60"
      />
      <path
        d="M369.466 481.951C370.966 481.951 372.182 480.807 372.182 479.395C372.182 477.984 370.966 476.84 369.466 476.84C367.966 476.84 366.75 477.984 366.75 479.395C366.75 480.807 367.966 481.951 369.466 481.951Z"
        fill="#966F60"
      />
      <path
        d="M515.123 434.203C515.496 435.267 514.544 437.786 515.097 438.047C513.727 438.156 509.826 437.455 508.492 437.14C506.701 436.716 504.815 436.44 502.974 436.452C501.331 436.463 495.035 436.345 498.039 433.666C499.894 432.012 502.422 432.059 504.746 432.141C506.426 432.201 508.106 432.268 509.784 432.375C510.568 432.425 511.705 432.426 512.455 432.599C513.724 432.893 514.624 432.781 515.123 434.203Z"
        fill="#96714E"
      />
      <path
        d="M553.986 412.714C555.788 405.706 564.958 387.229 574.486 399.206C579.328 405.291 572.982 412.794 570.252 418.323C560.181 438.722 555.799 453.69 530.545 446.345C525.219 444.796 513.714 444.4 510.964 439.25C504.872 427.839 519.725 428.957 525.761 430.885C545.779 437.278 548.853 432.313 553.986 412.714Z"
        fill="#F2BE66"
      />
      <path
        d="M394.48 489.905L398.716 497.225L420.558 484.562L414.156 474.939L394.48 489.905Z"
        fill="#B28B67"
      />
      <path
        d="M504.622 442.613C500.55 440.552 496.343 438.635 491.833 437.438C488.359 436.516 481.891 435.536 474.331 440.492C473.53 441.017 412.514 465.308 406.789 479.793C406.762 479.863 407.016 479.939 407.037 479.957C409.976 482.552 413 494 413 494C428.741 492.918 453.5 486.005 477.172 465.738C479.15 464.045 481.469 463.565 483 462.5C483.265 462.756 494.349 468.458 505.759 477.282C518.497 485.944 523.72 488.11 536.59 486.005C563.209 479.32 569.632 457.32 570.215 456.646C570.065 456.507 510.466 445.746 504.622 442.613Z"
        fill="#1F3535"
      />
      <path
        d="M494.647 605.638L506.3 606.888L510.002 572.099L494.002 571.389L494.647 605.638Z"
        fill="#B28B67"
      />
      <path
        d="M586.3 468.75C586.231 468.814 575.185 468.564 562.512 468.145C554.481 468.747 547.613 471.411 542.538 472.187C531.707 473.843 507.603 481.27 503.099 483.014C496.85 485.434 485.879 490.657 478.744 496.484C478.314 496.836 476.853 498.173 476.763 500.152C476.575 502.631 477.723 510.967 477.309 512.688C477.309 512.688 478.743 548 488.7 582.725C489.115 583.61 490.248 584.153 491.252 583.892C494.598 583.021 505.119 581.331 510.85 584.003C511.838 584.464 513.368 583.929 513.568 582.951C516.5 555 508.849 516.614 507.893 511.795C508.777 511.774 540.765 510.329 573.676 503.976C591.225 498.374 592.829 480.262 586.3 468.75Z"
        fill="#235152"
      />
      <path
        d="M482.568 369.301C484.01 368.471 485.532 369.112 485.95 370.725L506.463 449.796C506.882 451.409 505.893 452.446 504.266 452.1L476.945 446.301C475.318 445.956 473.583 444.371 473.088 442.781L456.042 387.971C455.547 386.38 456.322 384.4 457.764 383.57L482.568 369.301Z"
        fill="white"
      />
      <path
        d="M482.704 404.685C484.275 405.62 484.74 405.135 483.738 403.606L464.687 374.555C463.685 373.027 461.935 372.948 460.799 374.379L453.556 383.505C452.42 384.936 452.775 386.872 454.345 387.807L482.704 404.685Z"
        fill="#8A9663"
      />
      <path
        d="M482.704 407.029C484.274 407.964 484.739 407.478 483.737 405.95L464.687 376.899C463.684 375.371 462.601 374.8 460.799 376.723L454.728 384.09C453.592 385.522 453.947 387.458 455.517 388.392L482.704 407.029Z"
        fill="white"
      />
      <path
        d="M480.459 368.139C481.945 367.286 483.514 367.945 483.945 369.604L505.089 450.936C505.52 452.595 504.501 453.661 502.824 453.306L474.663 447.341C472.986 446.986 471.197 445.356 470.687 443.72L453.117 387.343C452.607 385.707 453.406 383.67 454.892 382.817L480.459 368.139Z"
        fill="#A9B879"
      />
      <path
        d="M514.308 439.236C514.457 440.588 512.652 443.29 513.231 443.747C511.591 443.502 507.196 441.616 505.714 440.882C503.724 439.896 501.583 439.057 499.415 438.57C497.481 438.136 490.113 436.283 494.374 433.952C497.004 432.513 499.963 433.256 502.672 433.986C504.63 434.514 506.587 435.051 508.53 435.633C509.438 435.905 510.774 436.216 511.609 436.624C513.021 437.315 514.109 437.429 514.308 439.236Z"
        fill="#B28B67"
      />
      <path
        d="M586.764 470.792C584.603 471.555 576.84 474.481 559.516 476.519C550.946 477.527 544.367 475.158 538.815 468.5C532.756 461.232 535.547 453.961 535.499 444.5C535.465 437.835 536.483 434.144 536.999 427.5C537.942 415.351 534.41 407.712 538.815 396.35C540.788 391.262 544.739 383.784 545.608 384.139C550.776 388.649 560.906 383.616 562.5 382C563.207 381.674 565.339 383.943 567 385.501C591 407.999 590.088 439.833 588.342 466.713C588.234 468.375 588.814 469.64 586.764 470.792Z"
        fill="#FCD497"
      />
      <path
        d="M551.947 417.3C553.749 410.292 562.919 391.815 572.447 403.792C577.289 409.877 570.943 417.38 568.213 422.909C558.142 443.308 553.76 458.276 528.506 450.931C523.18 449.382 511.675 448.986 508.925 443.836C502.833 432.425 517.686 433.543 523.722 435.471C543.74 441.864 546.814 436.899 551.947 417.3Z"
        fill="#FCDFB3"
      />
      <path
        d="M249.645 438.009C250.105 437.931 250.542 437.694 250.864 437.306C251.576 436.446 251.458 435.173 250.599 434.459C249.807 433.802 230.92 418.395 207.048 422.425C205.947 422.611 205.205 423.654 205.39 424.756C205.576 425.858 206.62 426.6 207.722 426.414C229.726 422.699 247.836 437.424 248.017 437.573C248.489 437.963 249.084 438.103 249.645 438.009Z"
        fill="#4D6565"
      />
      <path
        d="M151.99 596.115C151.99 610.021 163.262 621.293 177.169 621.293C191.073 621.293 202.347 610.021 202.347 596.115C202.347 582.208 191.073 570.936 177.169 570.936C163.262 570.936 151.99 582.208 151.99 596.115Z"
        fill="#0D1F26"
      />
      <path
        d="M160.807 596.114C160.807 605.151 168.131 612.477 177.169 612.477C186.206 612.477 193.531 605.151 193.531 596.114C193.531 587.078 186.205 579.752 177.169 579.752C168.131 579.752 160.807 587.077 160.807 596.114Z"
        fill="#E7DCCB"
      />
      <path
        d="M92.2468 621.434C101.306 621.434 108.65 614.52 108.65 605.992C108.65 597.464 101.306 590.551 92.2468 590.551C83.1876 590.551 75.8438 597.464 75.8438 605.992C75.8438 614.52 83.1876 621.434 92.2468 621.434Z"
        fill="#0D1F26"
      />
      <path
        d="M110.229 580.702L74.5332 584.788L60.8828 552.733L135.898 547.047L110.229 580.702Z"
        fill="#0D1F26"
      />
      <path
        d="M200.393 476.844L185.257 494.795H175.954L174.135 477.199L200.393 476.844Z"
        fill="#4D6565"
      />
      <path
        d="M182.955 493.246C183.55 493.246 184.14 492.987 184.545 492.489L185.547 491.244L229.225 428.651C229.872 427.725 229.646 426.449 228.72 425.802C227.793 425.156 226.52 425.382 225.873 426.308L182.236 488.84L181.37 489.909C180.658 490.785 180.791 492.075 181.668 492.788C182.047 493.096 182.503 493.246 182.955 493.246Z"
        fill="#4D6565"
      />
      <path
        d="M69.6432 586.81C69.7109 586.81 69.7791 586.807 69.8487 586.8L111.267 582.63L111.797 581.977L184.51 492.498C185.213 491.631 185.081 490.357 184.214 489.652C183.348 488.947 182.073 489.08 181.37 489.947L109.189 578.774L69.4435 582.776C68.332 582.887 67.5213 583.879 67.6334 584.99C67.7389 586.033 68.6175 586.81 69.6432 586.81Z"
        fill="#4D6565"
      />
      <path
        d="M177.166 600.562C177.701 600.562 178.245 600.429 178.745 600.147C180.304 599.274 180.859 597.302 179.985 595.743L148.739 540.027C147.863 538.468 145.891 537.913 144.335 538.787C142.775 539.661 142.22 541.633 143.093 543.191L174.34 598.909C174.934 599.966 176.033 600.562 177.166 600.562Z"
        fill="#4D6565"
      />
      <path
        d="M94.067 605.059L89.6863 583.629L80.1582 585.414L89.8193 606.837C90.7175 608.829 92.7641 608.093 92.632 608.088C93.7135 607.637 94.3283 606.337 94.067 605.059Z"
        fill="#4D6565"
      />
      <path
        d="M60.878 553.911C60.9159 553.911 60.9546 553.91 60.9922 553.908L137.119 548.586C138.01 548.524 138.683 547.75 138.62 546.859C138.558 545.968 137.795 545.298 136.892 545.357L60.7664 550.679C59.8757 550.742 59.2025 551.515 59.2658 552.406C59.3254 553.259 60.0364 553.911 60.878 553.911Z"
        fill="#4D6565"
      />
      <path
        d="M148.027 534.186L154.45 548.993L148.787 554.588L139.369 544.839L148.027 534.186Z"
        fill="#4D6565"
      />
      <path
        d="M161.654 503.328C164.803 505.248 168.138 506.142 171.751 506.522C174.096 506.768 174.691 503.339 172.405 502.779C171.562 502.477 168.101 501.842 164.636 498.97C160.225 495.363 157.547 489.851 156.018 484.291C154.999 480.573 154.558 476.641 154.583 475.803C154.663 473.099 152.536 470.841 149.832 470.76C146.731 470.669 144.326 473.447 144.86 476.505C145.18 478.339 152.519 497.868 161.654 503.328Z"
        fill="#4D6565"
      />
      <path
        d="M215.971 454.141H67.5527L71.071 467.51C72.5736 473.218 77.7349 477.195 83.6361 477.195H197.387C202.549 477.195 207.221 474.14 209.291 469.411L215.971 454.141Z"
        fill="#E8C899"
      />
      <path
        d="M79.8982 454.141L81.2973 459.455C82.7988 465.163 87.9596 469.141 93.8608 469.141H207.613C208.24 469.141 208.859 469.095 209.467 469.008L209.291 469.411C207.222 474.14 202.55 477.195 197.387 477.195H83.6361C77.7349 477.195 72.5739 473.218 71.071 467.51L67.5527 454.141H79.8982Z"
        fill="#A58351"
      />
      <path
        d="M197.387 477.197C157.784 476.136 85.5896 476.691 84.3893 476.694C79.2684 476.824 75.4187 474.61 73.0978 471.184C71.449 468.796 71.6811 468.33 67.5527 454.143C68.8215 459.523 69.3559 461.637 70.7418 467.324C72.1094 473.088 76.7892 477.605 84.3893 477.699C84.463 477.7 155.533 478.32 197.387 477.197Z"
        fill="#B99A6B"
      />
      <path
        d="M129.844 362.605L124.586 363.964L128.226 458.995H129.844V362.605Z"
        fill="#C9AB7E"
      />
      <path
        d="M129.844 362.605C129.844 362.605 141.473 363.134 143.799 365.46C146.124 367.786 136.822 376.28 134.799 385.076C132.776 393.874 129.844 458.994 129.844 458.994L129.844 362.605Z"
        fill="#E2BD86"
      />
      <path
        d="M124.586 363.965L73.2168 384.482L128.226 458.996L124.586 363.965Z"
        fill="#E2BD86"
      />
      <path
        d="M59.2637 431.001L73.2178 384.48L128.227 458.994L59.2637 431.001Z"
        fill="#CAA977"
      />
      <path
        d="M67.5551 454.142L128.227 458.995L59.2637 431.002L67.5551 454.142Z"
        fill="#B7945F"
      />
      <path
        d="M215.972 454.141C215.972 454.141 201.368 438.242 179.391 434.597C157.415 430.951 151.751 431.002 151.751 431.002L130.057 454.385L215.972 454.141Z"
        fill="#E2BD86"
      />
      <path
        d="M145.685 423.4L151.751 431.002L130.057 454.386C130.057 454.386 136.113 423.4 145.685 423.4Z"
        fill="#E8C899"
      />
      <path
        d="M90.5938 605.993C90.5938 606.906 91.3339 607.647 92.2472 607.647C93.1597 607.647 93.8998 606.906 93.8998 605.993C93.8998 605.08 93.1597 604.34 92.2472 604.34C91.3336 604.34 90.5938 605.08 90.5938 605.993Z"
        fill="#FFF7CF"
      />
      <path
        d="M122.502 458.994C122.502 462.155 125.064 464.719 128.226 464.719C131.388 464.719 133.95 462.155 133.95 458.994C133.95 455.833 131.388 453.27 128.226 453.27C125.064 453.27 122.502 455.833 122.502 458.994Z"
        fill="#EEE8DF"
      />
      <path
        d="M124.586 458.993C124.586 461.004 126.215 462.634 128.226 462.634C130.237 462.634 131.867 461.004 131.867 458.993C131.867 456.984 130.237 455.354 128.226 455.354C126.215 455.354 124.586 456.984 124.586 458.993Z"
        fill="#ECD4B1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M515.201 607.431C515.264 607.001 515.184 606.562 514.975 606.181L510.451 597.945C509.833 596.82 508.322 596.556 507.231 597.23C502.772 599.988 496.532 601.134 494.108 601.493C493.47 601.588 492.905 601.964 492.572 602.517C486.948 611.884 476.963 619.823 472.858 622.851C471.89 623.566 471.716 624.966 472.506 625.873L473.772 627.327C474.36 628.001 475.324 628.203 476.133 627.822L500.109 616.525L513.92 610.018C514.527 609.732 514.95 609.16 515.047 608.496L515.201 607.431Z"
        fill="#213133"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M407.095 504.748C407.374 504.414 407.536 503.999 407.557 503.565L408.007 494.421C408.07 493.14 406.921 492.123 405.638 492.122C400.523 492.12 394.769 489.896 392.553 488.949C391.959 488.696 391.28 488.719 390.707 489.016C381.228 493.93 368.867 495.436 363.855 495.862C362.656 495.964 361.772 497.066 361.97 498.252L362.269 500.045C362.416 500.928 363.131 501.605 364.02 501.704L389.824 504.581L404.684 506.238C405.351 506.313 406.011 506.047 406.441 505.532L407.095 504.748Z"
        fill="#213133"
      />
    </g>
    <defs>
      <clipPath id="clip0_5289_52090">
        <rect width="708" height="704" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LifestyleBase"
};
</script>
