import { getPainRecommendations } from "./pain-recommendations.js";
import {
  getBalanceRecommendations,
  getDefaultBalanceTab
} from "./balance-recommendations.js";
import { getPostnatalRecommendations } from "./postnatal-recommendations.js";
import { getCirculationRecommendations } from "./circulation-recommendations.js";

const recommendationMap = {
  pain: getPainRecommendations,
  balance: getBalanceRecommendations,
  circulation: getCirculationRecommendations,
  postnatal: getPostnatalRecommendations
};

const defaultTabMap = {
  balance: getDefaultBalanceTab
};

export const RecommendationSections = {
  PAIN: "pain",
  BALANCE: "balance",
  CIRCULATION: "circulation",
  POSTNATAL: "postnatal"
};

export const getRecommendationsByType = (type, results) => {
  const getRecommendations = recommendationMap[type];

  if (!getRecommendations) {
    throw new Error(`Unknown recommendation type: ${type}`);
  }

  return getRecommendations(results);
};

export const getDefaultTab = (type, results) => {
  const defaultTab = defaultTabMap[type];

  if (!defaultTab) {
    throw new Error(`Default tab not found: ${type}`);
  }

  return defaultTab(results);
};

export const getAllRecommendations = results => ({
  pain: getPainRecommendations(results),
  balance: getBalanceRecommendations(results),
  circulation: getCirculationRecommendations(results),
  postnatal: getPostnatalRecommendations(results)
});
