import { AddAnswersToResults } from "@/components/common/assessments/get-assessment-service";
import { getPreviousAssessmentAnswers } from "@/customApi";
import sentry from "@/sentry";

export async function getReusableAnswers(assessmentId, schema, results) {
  try {
    const answers = await getPreviousAssessmentAnswers(assessmentId);
    await AddAnswersToResults(answers, schema, results);
  } catch (error) {
    sentry.captureException(error);
  }
}
