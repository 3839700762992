import Answers from "@/components/assessment/new-parent/answers.json";
import i18n from "@/plugins/i18n";

const basePath = "newParentAssessment.report.lifestyle.cards";
const imagePath = "/images/new-parent-assessment/report/";

export class NewParentReportLifestyleService {
  constructor(results) {
    this.results = results;
  }

  getBreastfeedingCard() {
    // Return null if not breastfeeding
    if (
      this.results.lifestyle?.breastfeeding !==
      Answers.lifestyle.breastfeeding.yes
    ) {
      return null;
    }

    const textArray = [];

    // Extract key values with null safety
    const neckPosition = this.results.lifestyle?.breastfeedingNeckPosition;
    const expressBreastmilk = this.results.lifestyle?.expressBreastmilk;
    const expressingActivity =
      this.results.lifestyle?.expressBreastmilkActivity;

    // Determine if expressing milk
    const isExpressing = [
      Answers.lifestyle.expressBreastmilk.regularly,
      Answers.lifestyle.expressBreastmilk.occasionally
    ].includes(expressBreastmilk);

    // Section path based on expressing status
    const sectionPath = isExpressing
      ? "expressBreastmilk"
      : "doesntExpressBreastmilk";

    // Add intro text for non-expressing users
    if (!isExpressing) {
      textArray.push(
        i18n.t(`${basePath}.breastfeeding.doesntExpressBreastmilk.intro`)
      );
    }

    // Determine neck position text key
    const isNeckDown =
      neckPosition === Answers.lifestyle.breastfeedingNeckPosition.down;
    const neckPositionKey = isNeckDown ? "down" : "straightOrHeadrest";

    // Add neck position text
    textArray.push(
      i18n.t(
        `${basePath}.breastfeeding.${sectionPath}.neckPosition.${neckPositionKey}`
      )
    );

    // Add activity text for expressing users
    if (isExpressing && expressingActivity) {
      // Map of activity values to translation keys
      const activityTranslationMap = {
        [Answers.lifestyle.expressBreastmilkActivity.focus]: "pump",
        [Answers.lifestyle.expressBreastmilkActivity.scrollPhone]:
          "scrollingPhone",
        [Answers.lifestyle.expressBreastmilkActivity.watchTv]: "watchingTv",
        [Answers.lifestyle.expressBreastmilkActivity.podcastOrMusic]:
          "podcastOrMusic"
      };

      const activityKey = activityTranslationMap[expressingActivity];
      if (activityKey) {
        textArray.push(
          i18n.t(
            `${basePath}.breastfeeding.expressBreastmilk.activity.${activityKey}`
          )
        );
      }
    }

    return {
      title: i18n.t(`${basePath}.breastfeeding.title`),
      text: textArray,
      illustration: `${imagePath}person-breastfeeding.svg`,
      priority: 1
    };
  }

  getPhysicalActivityCard() {
    const hoursActivePerWeek = this.results.lifestyle?.hoursActivePerWeek;
    if (!hoursActivePerWeek) {
      return null;
    }

    const hoursActivePerWeekKey = {
      [Answers.lifestyle.hoursActivePerWeek.lessThanOne]: "lessThanTwoHours",
      [Answers.lifestyle.hoursActivePerWeek.oneToTwo]: "lessThanTwoHours",
      [Answers.lifestyle.hoursActivePerWeek.twoToThree]: "moreThanTwoHours",
      [Answers.lifestyle.hoursActivePerWeek.moreThan3]: "moreThanTwoHours"
    }[hoursActivePerWeek];

    return {
      title: i18n.t(`${basePath}.physicalActivity.title`),
      text: [
        ...i18n.t(`${basePath}.physicalActivity.text.${hoursActivePerWeekKey}`)
      ],
      illustration: `${imagePath}person-throwing-child.svg`,
      priority: 1
    };
  }

  getSleepDeprivationCard() {
    const suffersFromSleepDeprivation =
      this.results.lifestyle.totalHoursSleep ===
        Answers.lifestyle.totalHoursSleep.fourToSix ||
      this.results.lifestyle.totalHoursSleep ===
        Answers.lifestyle.totalHoursSleep.lessThanFour;

    if (suffersFromSleepDeprivation) {
      return {
        title: i18n.t(`${basePath}.sleepDeprivation.title`),
        text: [...i18n.t(`${basePath}.sleepDeprivation.text`)],
        illustration: `${imagePath}poor-sleep.svg`,
        priority: 1
      };
    }
    return null;
  }

  getAllCards() {
    const cards = [];

    const breastfeedingCard = this.getBreastfeedingCard();
    if (breastfeedingCard) cards.push(breastfeedingCard);

    const physicalActivityCard = this.getPhysicalActivityCard();
    if (physicalActivityCard) cards.push(physicalActivityCard);

    const sleepDeprivationCard = this.getSleepDeprivationCard();
    if (sleepDeprivationCard) cards.push(sleepDeprivationCard);

    return cards;
  }

  getCards() {
    const allCards = this.getAllCards();

    // Sort cards by priority
    const sortedCards = [...allCards].sort((a, b) => a.priority - b.priority);

    return sortedCards;
  }
}
