<template>
  <vfl-button variant="link" size="small" :to="backRoute">
    <template #startDecorator
      ><v-icon color="vflPrimary">mdi-chevron-left</v-icon></template
    >
    <span v-if="backText">{{ backText }}</span>
    <span v-else class="s-sr-only">{{ $t("buttons.goBack") }}</span>
  </vfl-button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BackButton",
  computed: {
    ...mapGetters("layout", ["backButton"]),
    ...mapGetters("routerStore", ["getPreviousRoute"]),
    backText() {
      return this.backButton.text || "";
    },
    backRoute() {
      if (this.backButton?.route) {
        return {
          name: this.backButton.route
        };
      } else {
        return this.getPreviousRoute() || "";
      }
    }
  }
};
</script>
