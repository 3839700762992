<template>
  <svg
    width="641"
    height="638"
    viewBox="0 0 641 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M399.444 414.281C359.739 427.465 354.959 428.326 318.801 441.045L272.638 448.751C272.638 448.751 249.967 496.409 273.332 506.893C278.944 509.405 303.252 504.658 315.259 500.352C360.972 483.956 388.086 467.023 406.335 458.6C420.246 452.182 419.11 444.896 420.467 438.989C424.478 421.563 410.811 410.5 399.444 414.281Z"
      fill="#17282F"
    />
    <path
      d="M92.6053 538.678L91.5334 538.949C85.1667 540.835 74.3377 546.438 69.1118 548.504C65.2384 550.036 58.9406 554.259 59.8469 558.463C60.6197 561.996 64.4103 564.402 70.8461 561.674C76.7942 559.149 81.5647 558.026 84.564 557.139C90.2132 555.478 92.3937 556.757 92.7939 557.056L92.6053 538.678Z"
      fill="#B28B67"
    />
    <path
      d="M219.815 519.489C219.815 519.489 234.996 520.533 236.909 534.426C238.97 549.418 228.284 554.271 223.812 555.026C177.465 562.851 93.9517 558.425 93.9517 558.425C93.9517 558.425 90.8558 556.999 89.5953 547.803C88.3118 538.432 90.9892 536.836 90.9892 536.836C126.246 531.021 196.427 522.976 219.815 519.489Z"
      fill="#223A43"
    />
    <path
      d="M255.405 445.72C232.826 455.422 227.482 496.449 203.05 528.214C203.05 528.214 196.02 540.966 204.913 551.252C213.897 561.621 227.638 554.196 232.721 550.382C241.245 543.988 248.689 536.14 260.691 523.204C291.287 490.266 308.023 476.116 318.801 449.614L255.405 445.72Z"
      fill="#223A43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M299.974 282.961C307.191 287.311 314.282 289.413 317.61 288.555C325.955 286.404 326.722 255.875 320.265 243.84C313.808 231.804 280.614 226.559 278.94 250.405C278.36 258.681 281.835 266.067 286.969 272.094L277.762 315.01H304.504L299.974 282.961Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M322.791 249.24C322.526 247.68 321.878 245.948 321.356 244.472C320.683 242.569 319.663 240.853 318.542 239.237C316.43 236.193 313.897 233.444 311.1 231.162C305.991 226.995 299.488 224.592 293.093 225.372C289.864 225.766 286.73 226.998 284.062 229.048C281.652 230.9 279.333 233.627 276.212 233.823C272.816 234.036 269.703 231.038 266.98 229.187C263.909 227.1 260.642 225.579 257.053 224.988C251.04 223.998 245.392 226.029 241.248 230.962C236.838 236.212 233.633 243.91 236.529 250.866C237.067 252.159 237.76 253.249 238.753 254.152C239.665 254.98 241.057 255.862 241.426 257.186C241.819 258.593 240.646 260.408 240.192 261.677C239.54 263.496 239.005 265.406 239.196 267.383C239.508 270.63 241.548 273.729 243.703 275.863C245.896 278.035 248.594 279.28 251.476 279.854C253.401 280.238 255.368 280.449 257.324 280.305C258.295 280.234 259.152 279.964 260.093 279.75C261.008 279.541 261.519 279.784 262.295 280.286C265.901 282.617 269.778 283.519 273.942 283.172C277.456 282.879 281.506 282.028 284.372 279.62C287.556 276.946 287.492 273.259 286.743 269.319C287.537 269.713 289.733 269.956 288.421 268.388C287.901 267.767 286.919 267.515 286.252 267.149C285.475 266.724 284.687 266.175 284.084 265.481C281.649 262.68 284.108 256.98 287.169 256.041C291.715 254.646 292.733 261.076 295.979 262.739C297.845 263.695 299.798 262.388 301.196 261.054C303.069 259.266 304.345 256.897 305.447 254.486C306.355 252.501 307.2 250.488 308.125 248.513C308.557 247.591 310.053 244.983 309.302 243.94C312.559 243.291 316.186 244.644 318.991 246.419C320.15 247.152 321.034 247.961 321.652 249.257C321.784 249.534 322.073 250.359 322.435 250.393C323.109 250.456 322.857 249.632 322.791 249.24Z"
      fill="#070707"
    />
    <path
      d="M382.516 349.07C387.379 350.691 392.609 348.156 394.23 343.292C395.842 338.429 393.161 333.473 388.285 331.848L329.278 310.178C324.415 308.566 319.158 311.194 317.537 316.053C315.929 320.92 318.553 326.176 323.416 327.793L382.516 349.07Z"
      fill="#B28B67"
    />
    <path
      d="M298.072 304.993C298.072 304.993 308.439 297.674 320.688 302.073C331.297 305.889 342.68 313.195 342.68 313.195L333.466 332.096C333.466 332.096 300.215 321.3 298.072 304.993Z"
      fill="#C86E01"
    />
    <path
      d="M453.323 355.775C458.403 356.438 460.399 346.959 455.307 346.292L388.335 332.202C383.247 331.526 378.596 334.909 377.933 339.993C377.253 345.073 380.848 349.534 385.919 350.197L453.323 355.775Z"
      fill="#B28B67"
    />
    <path
      d="M455.306 346.29C464.1 347.593 469.489 348.321 478.345 349.947C483.208 350.844 486.503 350.53 486.216 353.441C485.995 355.618 479.895 354.629 477.709 354.377C469.515 353.432 469.458 353.675 469.387 354.536C469.308 355.415 473.999 355.892 476.066 357.443C477.351 358.406 476.494 361.259 472.501 360.42C463.38 358.489 453.322 355.773 453.322 355.773L455.306 346.29Z"
      fill="#B28B67"
    />
    <path
      d="M198.749 352.153C193.89 353.774 188.656 351.247 187.039 346.38C185.418 341.517 188.113 336.556 192.98 334.94L251.992 313.27C256.855 311.649 262.107 314.286 263.719 319.149C265.345 324.012 262.716 329.268 257.844 330.885L198.749 352.153Z"
      fill="#B28B67"
    />
    <path
      d="M283.2 308.084C283.2 308.084 272.82 300.76 260.585 305.16C249.966 308.98 238.588 316.277 238.588 316.277L247.811 335.178C247.811 335.178 281.067 324.387 283.2 308.084Z"
      fill="#FEB760"
    />
    <path
      d="M127.94 358.845C122.864 359.512 120.872 350.038 125.948 349.371L192.936 335.272C198.016 334.605 202.676 337.984 203.338 343.068C204.005 348.147 200.423 352.604 195.344 353.275L127.94 358.845Z"
      fill="#B28B67"
    />
    <path
      d="M125.952 349.373C117.18 350.681 111.778 351.396 102.926 353.026C98.0541 353.927 94.7724 353.609 95.0683 356.515C95.2759 358.706 101.371 357.704 103.567 357.456C111.751 356.507 111.809 356.75 111.884 357.615C111.959 358.494 107.264 358.971 105.205 360.522C103.929 361.494 104.781 364.347 108.774 363.499C117.882 361.569 127.944 358.848 127.944 358.848L125.952 349.373Z"
      fill="#B28B67"
    />
    <path
      d="M328.625 340.305C327.548 328.679 323.334 323.97 320.392 317.672C315.485 307.159 318.762 302.685 315.061 300.927C313.749 300.295 307.114 299.792 305.074 299.8C301.416 299.823 288.386 299.929 284.746 299.867C281.133 299.8 268.359 300.803 261.239 307.415C256.654 311.682 263.108 356.696 264.808 368.459C272.772 423.416 248.182 451.589 248.182 451.589C248.182 451.589 270.448 454.17 291.094 451.589C309.309 449.312 314.806 449.04 326.781 440.748C335.753 434.535 342.396 430.614 345.5 420C348.546 409.584 345.769 399.808 341.5 392C335.314 380.685 323.598 371.886 323.983 367.836C324.844 358.781 329.946 354.523 328.625 340.305Z"
      fill="#FF9B21"
    />
    <path
      d="M385.713 560.681C385.713 560.681 386.026 566.98 394.861 569.277C401.022 570.88 412.705 571.635 418.032 572.532C421.963 573.19 429.256 572.841 430.594 568.932C431.707 565.646 429.79 561.785 423.138 560.836C416.981 559.97 412.511 558.521 409.605 557.766C403.478 556.176 402.754 553.676 402.754 553.676L403.531 543.335H386.879L386.327 552.275L385.713 560.681Z"
      fill="#B28B67"
    />
    <path
      d="M387.961 428.454C387.961 440.716 384.803 525.664 384.803 545.254C384.803 545.254 386.667 547.82 396.208 547.82C403.566 547.82 406.424 545.254 406.424 545.254C406.424 545.254 421.703 465.368 422.007 428.454C422.056 423.043 413.982 414.311 398.668 416.237C386.455 417.774 387.961 426.833 387.961 428.454Z"
      fill="#17282F"
    />
    <path
      opacity="0.5"
      d="M262.637 470.111C250.742 491.944 225.416 536.152 219.272 538.32C213.129 540.488 139.018 545.547 102.73 547.806"
      stroke="white"
      stroke-width="0.903424"
    />
    <path
      d="M320 416C320 416 321.203 418.093 322.133 417.997C322.972 417.91 324 416 324 416"
      stroke="#DB841B"
    />
  </svg>
</template>

<script>
export default {
  name: "PregnancyExerciseTwiceOverlay"
};
</script>
