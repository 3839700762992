<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>
      {{ $t("newParentAssessment.report.caringForChild.title") }}
    </template>
    <template #content>
      <div class="card-grid mt-8">
        <report-info-card
          v-for="(card, index) in cards"
          :key="index"
          :title="card.title"
          :illustration="card.illustration"
          :text="card.text"
          :assessment-type="$options.assessmentTypes.newParentAssessment"
          :use-html="card.useHtml"
          image-background-colour="#F8F5F0"
          :image-width="275"
        />
      </div>
      <recommendation-container
        class="mt-10"
        :title="
          $t('newParentAssessment.recommendations.liftingAndCarrying.title')
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="recommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { NewParentReportCaringForChildService } from "@/components/assessment/new-parent/report/new-parent-report-caring-for-child-service.js";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import { getRecommendationsByType } from "@/components/assessment/new-parent/report/recommendations/recommendation-service.js";
import { RecommendationSections } from "@/components/assessment/new-parent/report/recommendations/constants.js";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";

export default {
  name: "NewParentReportCaringForChild",
  components: {
    ReportSection,
    ReportInfoCard,
    RecommendationContainer
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      caringForChildService: null
    };
  },
  created() {
    this.caringForChildService = new NewParentReportCaringForChildService(
      this.results
    );
  },
  computed: {
    cards() {
      return this.caringForChildService.getCards();
    },
    recommendations() {
      return getRecommendationsByType(
        RecommendationSections.LIFTING_AND_CARRYING,
        this.results
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-gap: 1rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
