<template>
  <section style="width: 100%">
    <div class="desk-assessment-report report-container">
      <short-feedback
        v-model="quickFeedbackSubmitted"
        v-show="displayResultsFeedback"
        :assessmentId="assessmentId"
        @scrolledToBottom="readReport"
        urlSuffix="report"
        bottom-offset="5rem"
        trigger-on-scroll
      />
      <end-of-report-sign-up
        :assessmentType="$options.assessmentTypes.deskAssessment"
        :assessmentId="assessmentId"
        :errorMessage="signUpErrorMessage"
      />
      <div
        style="
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
        "
      >
        <canvas
          id="confetti-canvas"
          class="confetti-canvas"
          style="z-index: 5; pointer-events: none"
          :width="reportWidth"
          :height="reportHeight"
        >
        </canvas>
      </div>

      <v-row justify="center">
        <v-col cols="12">
          <p
            id="pdfHeader"
            class="text-subtitle-2 pdf-header mb-1 justify-space-between pdf-header"
            v-show="false"
          >
            <span>{{ assessmentEmail || userEmail }}</span>
            <span>
              {{
                $t("deskAssessment.results.report.pdfDownloadText", {
                  completedAt: completedAtDate
                })
              }}</span
            >
          </p>
          <vfl-panels flat v-model="panelDefaultExpansion" multiple hover>
            <template v-slot:panels
              ><assessment-report-panel
                v-for="panel in visiblePanels"
                :key="panel.id"
                :id="panel.id"
                :title="panel.title"
                :score="panel.score"
                :goodScore="panel.goodScore"
                :assessmentType="$options.assessmentTypes.deskAssessment"
              >
                <template v-slot:content>
                  <component
                    :is="panelComponentMap[panel.id].component"
                    v-bind="panelComponentMap[panel.id].props"
                  />
                </template>
              </assessment-report-panel>
            </template>
          </vfl-panels>
        </v-col>
      </v-row>
    </div>
    <report-footer
      v-if="viewingOwnResults"
      class="no-print"
      id="endOfReportFooter"
      :assessmentId="assessmentId"
      :assessmentType="$options.assessmentTypes.deskAssessment"
      @closeReport="$emit('closeReport')"
      :results="results"
      :reportContext="reportContext"
    />

    <AiAssistantContainer
      v-if="showAiAssistant"
      :type="$options.assistantTypes.REPORT"
    />
  </section>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import {
  storeDeskAssessmentScore,
  updateLearningManagementSystemsOnAssessmentCompletion,
  addAssessmentRecommendations
} from "@/customApi";
import {
  getDeskFixedRecommendations,
  getCustomTeamRecommendations,
  getSetupRecommendations
} from "@/services/recommendations/recommendations-service.js";
import { mapActions, mapGetters } from "vuex";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import { viewingOwnResults } from "@/services/privacy-service.js";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";
import TeamService from "@/services/teamService.js";
import PostMessageService from "@/services/post-message-service.js";
import { EventBus } from "@/services/events/event-bus";
import { assessmentTypes } from "@/constants/constants.js";

import AssessmentReportPanel from "@/components/common/report/AssessmentReportPanel.vue";
import VflPanels from "@/components/vitrue-foundation-library/expansion-panel/VflPanels.vue";

const DeskAssessmentSummaryContent = () =>
  import("@/components/deskassessment/report/content/SummaryContent.vue");
const PainContent = () =>
  import("@/components/deskassessment/report/content/PainContent.vue");
const ScreenAssessmentContent = () =>
  import(
    "@/components/deskassessment/report/content/ScreenAssessmentContent.vue"
  );
const WorkSetupContent = () =>
  import("@/components/deskassessment/report/content/WorkSetupContent.vue");
const RecommendationsContent = () =>
  import(
    "@/components/deskassessment/report/content/RecommendationsContent.vue"
  );
import { getConditionsByCategory } from "@/components/assessment/pre-existing-conditions/get-conditions-service.js";
import AiAssistantContainer from "@/components/AiAssistant/AiAssistantContainer.vue";
import { AssistantTypes } from "@/components/AiAssistant/constants.js";

import ResultsHelperV1 from "@/services/deskassessment/desk-assessment-results-util.js";
import EquipmentScoreHelper from "@/components/assessment/desk/results-helpers/equipment-score-helper.js";
import OverallScoreHelper from "@/components/assessment/desk/results-helpers/overall-score-helper.js";
import DeskSetupScoreHelper from "@/components/assessment/desk/results-helpers/desk-setup-score-helper.js";

export default {
  name: "Report",
  props: {
    results: {
      type: Object
    },
    assessmentId: String,
    userName: String,
    assessmentEmail: String,
    signUpErrorMessage: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  components: {
    AiAssistantContainer,
    EndOfReportSignUp,
    ReportFooter,
    ShortFeedback,
    AssessmentReportPanel,
    VflPanels,
    DeskAssessmentSummaryContent,
    PainContent,
    ScreenAssessmentContent,
    WorkSetupContent,
    RecommendationsContent
  },
  data() {
    return {
      panelDefaultExpansion: [0, 1, 2, 3, 4, 5],
      toTopVisible: false,
      reportHeight: 0,
      reportWidth: 0,
      nearBottomOfPage: false,
      quickFeedbackSubmitted: false,
      customTeamRecommendations: [],
      deskFixedRecommendations: [],
      setupRecommendations: [],
      recommendationsLoaded: false,
      healthConditions: null
    };
  },
  assessmentTypes: assessmentTypes,
  assistantTypes: AssistantTypes,
  async beforeMount() {
    this.updateAssessmentScore();
    await this.getRecommendations();
    await this.saveAssessmentRecommendations();
  },
  created() {
    this.handleHealthConditions();

    if (this.showAiAssistant) {
      const assessmentInfo = {
        id: this.assessmentId,
        userName: this.userName
      };
      this.updateAssessmentInfo(assessmentInfo);
      this.setAssistantType(AssistantTypes.REPORT);
    }

    this.$emit("handleVisualDeskPlannerVisibility", this.showVisualDeskPlanner);
  },
  mounted() {
    this.$nextTick(() => {
      this.setupConfetti();
    });
    EventBus.$on("downloadReport", () => {
      window.print();
    });
  },
  beforeDestroy() {
    if (this.showAiAssistant) {
      this.resetAiAssistant();
    }

    EventBus.$off("downloadReport");
  },
  watch: {
    async signedIn(newVal) {
      if (newVal) {
        await TeamService.updateEndUserInfo();
        await this.getRecommendations();
      }
    }
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "customRecommendations",
      "customRecommendationOrder",
      "redirectReportAssessment",
      "currentUserTeamAndSubscriptionBasicInformation",
      "userEmail",
      "enablePreExistingConditions",
      "isSimpleUser"
    ]),
    ...mapGetters({
      isV2: "deskAssessmentSchemaStore/schemaVersion"
    }),
    utilityService() {
      if (this.isV2) {
        return {
          calculateSetupAndHabitsPercentage: results =>
            EquipmentScoreHelper.getStandaloneSetupPercentageScore(results),
          getPercentageScore: (score, total) =>
            EquipmentScoreHelper.getPercentageScore(score, total),
          calculateDeskSetupScore: results =>
            DeskSetupScoreHelper.calculateDeskSetupScore(results),
          getMaxWebcamScore: results =>
            DeskSetupScoreHelper.getMaxWebcamScore(results),
          getOverallScore: results =>
            OverallScoreHelper.getOverallScore(results),
          getVisualDeskPlannerScore: results =>
            EquipmentScoreHelper.getVisualDeskPlannerScore(results)
        };
      }
      return ResultsHelperV1;
    },
    visiblePanels() {
      this.recommendationsLoaded; // mentioned to recalculate computed property once recs are loaded.
      let panels = [];
      var panelIndex = 1;

      panels.push({
        id: "summaryPanel",
        title: this.$t("deskAssessment.results.report.summaryTitle"),
        type: "DeskAssessmentSummary"
      });

      var displayPainSection = Object.keys(this.results.pain.areas).length > 0;
      if (displayPainSection) {
        panels.push({
          id: "painPanel",
          title: this.$t("deskAssessment.results.report.painTitle", {
            0: panelIndex
          }),
          type: "Pain"
        });
        panelIndex++;
      }

      let displayScreenAssessmentPanel =
        this.results.webcam.pictureTaken ||
        !isNaN(this.results.setup.elevationEyeLevel);
      if (displayScreenAssessmentPanel) {
        panels.push({
          id: "screenAssessmentPanel",
          title: this.$t(
            "deskAssessment.results.report.vitrueAssessmentTitle",
            {
              0: panelIndex
            }
          ),
          type: "ScreenAssessment",
          score: this.results.webcam.pictureTaken
            ? this.webcamScore
            : undefined,
          goodScore: this.results.webcam.pictureTaken
            ? this.webcamScore >= 50
            : undefined
        });
        panelIndex++;
      }

      this.$emit(
        "handleScreenAssessmentPanelVisibility",
        displayScreenAssessmentPanel
      );

      panels.push({
        id: "setupPanel",
        title: this.$t("deskAssessment.results.report.workSetupTitle", {
          0: panelIndex
        }),
        type: "WorkSetup",
        score: this.setupScore,
        goodScore: this.setupScore >= 50,
        showPregnancyContent: this.showPregnancyContent
      });

      panels.push({
        id: "recommendationsPanel",
        title: this.$t("deskAssessment.results.report.recommendationsTitle"),
        type: "PersonalisedDeskAssessmentRecommendations"
      });

      if (this.hasFixedRecommendations || this.hasCustomTeamRecommendations) {
        panels.push({
          id: "fixedRecommendationsPanel",
          title: this.$t("deskAssessment.results.report.extraContentTitle"),
          type: "FixedDeskAssessmentRecommendations"
        });
      }
      return panels;
    },
    panelComponentMap() {
      return {
        summaryPanel: {
          component: DeskAssessmentSummaryContent,
          props: {
            results: this.results,
            healthConditions: this.healthConditions
          }
        },
        painPanel: {
          component: PainContent,
          props: {
            results: this.results,
            showPregnancyContent: this.showPregnancyContent,
            assessmentType: this.$options.assessmentTypes.deskAssessment
          }
        },
        screenAssessmentPanel: {
          component: ScreenAssessmentContent,
          props: { results: this.results }
        },
        setupPanel: {
          component: WorkSetupContent,
          props: {
            results: this.results,
            showPregnancyContent: this.showPregnancyContent
          }
        },
        recommendationsPanel: {
          component: RecommendationsContent,
          props: {
            results: this.results,
            assessmentId: this.assessmentId,
            showPregnancyContent: this.showPregnancyContent,
            cards: this.setupRecommendations,
            exerciseCards: this.exerciseRecommendations
          }
        },
        fixedRecommendationsPanel: {
          component: RecommendationsContent,
          props: {
            results: this.results,
            assessmentId: this.assessmentId,
            constantRecommendations: true,
            showPregnancyContent: this.showPregnancyContent,
            cards: this.hasCustomTeamRecommendations
              ? this.customTeamRecommendations
              : this.deskFixedRecommendations
          }
        }
      };
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    permissionToViewPersonalData() {
      if (!this.signedIn) {
        return true;
      }
      return this.viewingOwnResults;
    },
    showPregnancyContent() {
      return (
        this.permissionToViewPersonalData && this.results.health?.pregnancy == 1
      );
    },
    canShowFeedback() {
      return this.$route.path.includes("deskassessment");
    },
    setupScore() {
      return this.utilityService.calculateSetupAndHabitsPercentage(
        this.results
      );
    },
    webcamScore() {
      const score = this.utilityService.getPercentageScore(
        this.utilityService.calculateDeskSetupScore(this.results),
        this.utilityService.getMaxWebcamScore(this.results)
      );
      return score;
    },
    hasFixedRecommendations() {
      return (
        this.deskFixedRecommendations &&
        this.deskFixedRecommendations.length > 0
      );
    },
    hasCustomTeamRecommendations() {
      return (
        this.customTeamRecommendations &&
        this.customTeamRecommendations.length > 0
      );
    },
    displayResultsFeedback() {
      return this.canShowFeedback;
    },
    completedAtDate() {
      let date = new Date(this.results.completedAt);
      if (isNaN(date)) {
        date = new Date();
      }
      return date.toLocaleDateString();
    },
    showAiAssistant() {
      return !this.viewingOwnResults && !this.isSimpleUser;
    },
    showVisualDeskPlanner() {
      return !!this.results?.setup?.deskItems?.length;
    }
  },
  methods: {
    ...mapActions({
      resetAiAssistant: "ai/reset",
      updateAssessmentInfo: "ai/updateAssessmentInfo",
      setAssistantType: "ai/setAssistantType"
    }),
    async getRecommendations() {
      try {
        this.recommendationsLoaded = false;

        const featureFlags = {
          ...this.customRecommendations,
          ...this.customRecommendationOrder,
          pregnancyExerciseVideos: this.showPregnancyContent
        };

        this.customTeamRecommendations = await getCustomTeamRecommendations();
        this.deskFixedRecommendations =
          getDeskFixedRecommendations(featureFlags);
        this.setupRecommendations = getSetupRecommendations(
          this.results,
          featureFlags
        );
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.recommendationsLoaded = true;
      }
    },
    async updateAssessmentScore() {
      if (this.results.completedAt) {
        return;
      }
      await this.saveScoreToDb();
      await this.updateLearningManagementSystems();
    },
    async saveScoreToDb() {
      const overallScore = this.utilityService.getOverallScore(this.results);

      let setupScore = null;
      if (this.results.setup.deskItems.length > 0) {
        setupScore = Math.round(
          this.utilityService.getVisualDeskPlannerScore(this.results.setup, 500)
        );
      }
      const scoreDto = {
        AssessmentId: this.assessmentId,
        Score: overallScore,
        SetupScore: setupScore
      };

      try {
        await storeDeskAssessmentScore(scoreDto);
      } catch (err) {
      } finally {
        this.postMessage(overallScore);
      }
    },
    async updateLearningManagementSystems() {
      if (window.opener) {
        window.opener.postMessage("completed", "*");
      }
      updateLearningManagementSystemsOnAssessmentCompletion(this.assessmentId);
    },
    setupConfetti() {
      const overallScore = this.utilityService.getOverallScore(this.results);
      let selfAssessment = this.signedIn && !this.results.completedAt;
      let justCompletedReport = selfAssessment || this.redirectReportAssessment;
      if (overallScore > 75 && justCompletedReport) {
        this.reportHeight = window.innerHeight;
        this.reportWidth = window.innerWidth;

        var particlesPerFrame = 2;
        if (this.$vuetify.breakpoint.smAndDown) {
          particlesPerFrame = 0.5;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          particlesPerFrame = 1;
        }

        this.$confetti.start({
          canvasElement: document.getElementById("confetti-canvas"),
          particlesPerFrame: particlesPerFrame,
          particles: [
            {
              type: "rect"
            }
          ]
        });

        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    },
    postMessage(score) {
      const data = {
        action: "complete",
        score
      };
      PostMessageService.sendMessage(data);
    },
    readReport() {
      if (this.results.completedAt) {
        return;
      }
      this.$mixpanel.track(assessmentTypes.deskAssessment, {
        "Assessment Id": this.assessmentId,
        step: "Scrolled to end of report"
      });
    },
    async saveAssessmentRecommendations() {
      if (this.results.completedAt) {
        return;
      }

      const allRecommendations = [
        ...this.setupRecommendations,
        ...this.deskFixedRecommendations,
        ...this.customTeamRecommendations
      ];

      let dto = {
        assessmentId: this.assessmentId,
        recommendations: allRecommendations.map(x => x.id)
      };

      await addAssessmentRecommendations(dto);
    },
    handleHealthConditions() {
      if (!this.permissionToViewPersonalData) return;
      if (!this.enablePreExistingConditions) return;

      this.healthConditions = getConditionsByCategory(this.results);
    }
  }
};
</script>

<style lang="scss">
.confetti-canvas {
  position: -webkit-sticky; /* Required for Safari */
  position: sticky;
  top: 0px;
}

@media print {
  main {
    padding: 0 !important;
  }

  .v-expansion-panels {
    > div:not(:first-of-type) {
      page-break-before: always;
    }
  }

  .v-expansion-panel-content__wrap {
    > .row {
      > [class^="col-"] {
        page-break-inside: avoid;
      }
    }
  }

  .v-expansion-panel[aria-expanded="false"] {
    .v-expansion-panel-content {
      display: block !important;
    }
  }

  .v-expansion-panel-header__icon {
    display: none;
  }

  .v-expansion-panel-content__wrap {
    > .row {
      > [class^="col-"] {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  .pdf-header {
    display: flex !important;

    + .row {
      margin-top: 1rem;
    }
  }
}
</style>
