import { ImageService } from "@/components/assessment/common/dynamic-images/image-service.js";
import { Theme } from "./steps.js";
import Answers from "@/components/assessment/pregnancy/answers.json";

// trimester
import TrimesterBase from "@/components/assessment/common/dynamic-images/svgs/pregnancy/trimester/TrimesterBase.vue";
import UnansweredTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/trimester/UnansweredTrimester.vue";
import FirstTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/trimester/FirstTrimester.vue";
import SecondTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/trimester/SecondTrimester.vue";
import ThirdTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/trimester/ThirdTrimester.vue";

// balance
import BalanceBase from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/BalanceBase.vue";
import FirstTrimesterBalance from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/FirstTrimesterBalance.vue";
import SecondTrimesterBalance from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/SecondTrimesterBalance.vue";
import ThirdTrimesterBalance from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/ThirdTrimesterBalance.vue";
import StraightPlank from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/StraightPlank.vue";
import SlightlyLeftTiltedPlank from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/SlightlyLeftTiltedPlank.vue";
import SlightlyRightTiltedPlank from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/SlightlyRightTiltedPlank.vue";
import ModeratelyLeftTiltedPlank from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/ModeratelyLeftTiltedPlank.vue";
import ModeratelyRightTiltedPlank from "@/components/assessment/common/dynamic-images/svgs/pregnancy/balance/ModeratelyRightTiltedPlank.vue";

//circulation
import CirculationBase from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationBase.vue";
import CirculationFirstTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationFirstTrimester.vue";
import CirculationSecondTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationSecondTrimester.vue";
import CirculationThirdTrimester from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationThirdTrimester.vue";
import ChangingLegPosition from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/ChangingLegPosition.vue";
import CirculationRoutineChange from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationRoutineChange.vue";
import CirculationSitting from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationSitting.vue";
import CirculationStanding from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationStanding.vue";
import CirculationSittingStanding from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CirculationSittingStanding.vue";
import CrossLegged from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/CrossLegged.vue";
import FlatFeet from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/FlatFeet.vue";
import MildSwelling from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/MildSwelling.vue";
import ModerateSwelling from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/ModerateSwelling.vue";
import NoSwelling from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/NoSwelling.vue";
import SignificantSwelling from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/SignificantSwelling.vue";
import StretchedLegs from "@/components/assessment/common/dynamic-images/svgs/pregnancy/circulation/StretchedLegs.vue";

// life style
import LifestyleBase from "@/components/assessment/common/dynamic-images/svgs/lifestyle/LifestyleBase.vue";
import PregnancyLifestyleUnanswered from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PregnancyExerciseUnanswered.vue";
import PregnancyLifestyleExerciseOnce from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PregnancyExerciseOnce.vue";
import PregnancyLifestyleExerciseTwice from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PregnancyExerciseTwice.vue";
import PregnancyLifestyleExerciseThreeMore from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PregnancyExerciseThreeMore.vue";
import PregnancyLifestyleExerciseNever from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PregnancyExerciseNever.vue";
import LifestyleWeightlifting from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Weightlifting.vue";
import LifestyleExerciseClass from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/ExerciseClass.vue";
import LifestylePrenatalYoga from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/PrenatalYoga.vue";
import LifestyleRunning from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Running.vue";
import LifestyleSwimming from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Swimming.vue";
import LifestyleWalking from "@/components/assessment/common/dynamic-images/svgs/lifestyle/overlays/Walking.vue";

const baseComponents = {
  [Theme.TRIMESTER]: TrimesterBase,
  [Theme.BALANCE]: BalanceBase,
  [Theme.CIRCULATION]: CirculationBase,
  [Theme.LIFESTYLE]: LifestyleBase
};

export class PregnancyImageService extends ImageService {
  registerComponents(vm) {
    const components = {
      TrimesterBase,
      UnansweredTrimester,
      FirstTrimester,
      SecondTrimester,
      ThirdTrimester,

      LifestyleBase,
      PregnancyLifestyleUnanswered,
      PregnancyLifestyleExerciseOnce,
      PregnancyLifestyleExerciseTwice,
      PregnancyLifestyleExerciseThreeMore,
      PregnancyLifestyleExerciseNever,
      LifestyleWeightlifting,
      LifestyleExerciseClass,
      LifestylePrenatalYoga,
      LifestyleRunning,
      LifestyleSwimming,
      LifestyleWalking,

      BalanceBase,
      FirstTrimesterBalance,
      SecondTrimesterBalance,
      ThirdTrimesterBalance,
      StraightPlank,
      SlightlyLeftTiltedPlank,
      SlightlyRightTiltedPlank,
      ModeratelyLeftTiltedPlank,
      ModeratelyRightTiltedPlank,

      CirculationBase,
      CirculationFirstTrimester,
      CirculationSecondTrimester,
      CirculationThirdTrimester,
      ChangingLegPosition,
      CirculationRoutineChange,
      CirculationSitting,
      CirculationStanding,
      CirculationSittingStanding,
      CrossLegged,
      FlatFeet,
      MildSwelling,
      ModerateSwelling,
      NoSwelling,
      SignificantSwelling,
      StretchedLegs
    };

    Object.entries(components).forEach(([name, component]) => {
      vm.$options.components[name] = component;
    });
  }

  getBaseComponent(schema) {
    return schema?.illustration?.base || baseComponents[this.currentTheme];
  }

  getOverlayComponents(currentTheme, results, schema) {
    const overlayHandlers = {
      [Theme.TRIMESTER]: () => this.getTrimesterOverlays(results, schema),
      [Theme.BALANCE]: () => this.getBalanceOverlays(results),
      [Theme.CIRCULATION]: () => this.getCirculationOverlays(results, schema),
      [Theme.LIFESTYLE]: () => this.getLifestyleOverlays(results, schema)
    };

    return overlayHandlers[currentTheme]?.() || [];
  }

  getTrimesterOverlays(results, schema) {
    const overlays = [];
    const { pregnancyTrimester } = results.trimester;

    if (!pregnancyTrimester && schema?.illustration?.overlays) {
      return schema.illustration.overlays;
    }

    if (pregnancyTrimester) {
      const option = schema.questions[0].options.find(
        opt => opt.value === pregnancyTrimester
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    }

    return overlays;
  }

  getBalanceOverlays(results) {
    const overlays = [];
    this.addTrimesterBalanceOverlay(
      overlays,
      results.trimester.pregnancyTrimester
    );
    this.handlePlankOverlay(overlays, results.balance);
    return overlays;
  }

  addTrimesterBalanceOverlay(overlays, trimester) {
    const trimesterOverlays = {
      [Answers.trimester.pregnancyTrimester.first]: "FirstTrimesterBalance",
      [Answers.trimester.pregnancyTrimester.second]: "SecondTrimesterBalance",
      [Answers.trimester.pregnancyTrimester.third]: "ThirdTrimesterBalance"
    };

    const overlay = trimesterOverlays[trimester];
    if (overlay) {
      this.addUniqueOverlays(overlays, [overlay]);
    }
  }

  // method created to give more feedback to the user depending on answers selected for balance section.
  handlePlankOverlay(overlays, balanceResults) {
    // Weighting for relevant questions in balance section
    const QUESTION_WEIGHTS = {
      steadyWhilstWalking: 1.2,
      differenceUsingStairs: 1.0,
      nearFallsLastMonth: 0.8
    };

    // Calculate weighted scores
    const getQuestionScore = (value, questionType) => {
      if (value === undefined) return 0;
      const baseScore = value === 1 ? 0 : value === 2 ? 1 : 2;
      return baseScore * QUESTION_WEIGHTS[questionType];
    };

    // Further calculations for determining direction usingall questions
    const getDirectionInfluence = () => {
      let directionScore = 0;

      // Use all three questions to influence direction
      const { steadyWhilstWalking, differenceUsingStairs, nearFallsLastMonth } =
        balanceResults;

      if (steadyWhilstWalking) {
        directionScore +=
          steadyWhilstWalking * (steadyWhilstWalking % 2 ? 1 : -1);
      }

      if (differenceUsingStairs) {
        directionScore +=
          differenceUsingStairs * (differenceUsingStairs % 3 === 0 ? -1 : 1);
      }

      if (nearFallsLastMonth) {
        directionScore +=
          nearFallsLastMonth * (nearFallsLastMonth % 2 ? 1 : -1);
      }

      // Add timestamp influence but only count the three relevant questions
      // timestamp added to increase chance of direction changing
      const answeredQuestions = [
        steadyWhilstWalking,
        differenceUsingStairs,
        nearFallsLastMonth
      ].filter(Boolean).length;

      const timestamp = Date.now();
      directionScore += (timestamp % 2 ? 1 : -1) * answeredQuestions;

      return directionScore >= 0 ? "Right" : "Left";
    };

    // Calculate scores using only the three relevant questions in balance section
    const totalPossibleScore = Object.values(QUESTION_WEIGHTS).reduce(
      (sum, weight) => sum + weight * 2,
      0
    );
    const totalActualScore = Object.entries(QUESTION_WEIGHTS).reduce(
      (sum, [key, weight]) => sum + getQuestionScore(balanceResults[key], key),
      0
    );

    const percentageScore = (totalActualScore / totalPossibleScore) * 100;
    const tiltDirection = getDirectionInfluence();

    let plankOverlay;
    if (percentageScore <= 15) {
      plankOverlay = "StraightPlank";
    } else if (percentageScore <= 50) {
      plankOverlay = `Slightly${tiltDirection}TiltedPlank`;
    } else {
      plankOverlay = `Moderately${tiltDirection}TiltedPlank`;
    }

    this.addUniqueOverlays(overlays, [plankOverlay]);
  }

  getCirculationOverlays(results, schema) {
    const overlays = [];
    this.addTrimesterCirculationOverlay(
      overlays,
      results.trimester.pregnancyTrimester
    );
    this.handleCirculationQuestionOverlays(
      overlays,
      results.circulation,
      schema
    );
    return overlays;
  }

  addTrimesterCirculationOverlay(overlays, trimester) {
    const trimesterOverlays = {
      [Answers.trimester.pregnancyTrimester.first]: "CirculationFirstTrimester",
      [Answers.trimester.pregnancyTrimester.second]:
        "CirculationSecondTrimester",
      [Answers.trimester.pregnancyTrimester.third]: "CirculationThirdTrimester"
    };

    const overlay = trimesterOverlays[trimester];
    if (overlay) {
      this.addUniqueOverlays(overlays, [overlay]);
    }
  }

  handleCirculationQuestionOverlays(overlays, results, schema) {
    const questionTypes = [
      "timeSpentSittingOrStanding",
      "mostComfortableSittingPosition",
      "feetOrLegSwellingWhilstSeated"
    ];

    questionTypes.forEach((type, index) => {
      const selectedValue = results[type];
      if (selectedValue) {
        const option = schema.questions[index].options.find(
          opt => opt.value === selectedValue
        );
        this.addUniqueOverlays(overlays, option?.illustration?.overlays);
      }
    });
  }

  getLifestyleOverlays(results, schema) {
    const overlays = [];
    const lifestyleResults = results.lifestyle;

    if (!lifestyleResults.physicalActivity && schema?.illustration?.overlays) {
      return schema.illustration.overlays;
    }

    this.handlePhysicalActivityOverlays(overlays, lifestyleResults, schema);
    this.handleExerciseTypeOverlays(overlays, lifestyleResults, schema);

    return overlays;
  }

  handlePhysicalActivityOverlays(overlays, results, schema) {
    const selectedActivity = results.physicalActivity;
    if (selectedActivity) {
      const option = schema.questions[0].options.find(
        opt => opt.value === selectedActivity
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    }
  }

  handleExerciseTypeOverlays(overlays, results, schema) {
    if (!results.exerciseTypes) return;

    const exerciseTypeQuestion = schema.questions.find(
      q => q.name === "exerciseTypes"
    );
    results.exerciseTypes.forEach(exerciseType => {
      const option = exerciseTypeQuestion.options?.find(
        opt => opt.value === exerciseType
      );
      this.addUniqueOverlays(overlays, option?.illustration?.overlays);
    });
  }
}
