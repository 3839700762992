<template>
  <div
    class="auth-container"
    :class="isReportPageSignUp ? 'is-modal' : 'is-page'"
  >
    <div
      class="auth-container-inner"
      :class="isReportPageSignUp ? 'is-modal' : 'is-page'"
    >
      <div v-if="!isReportPageSignUp" class="d-flex flex-column">
        <auth-layout-image class="mx-auto d-none d-md-block" />

        <h1 class="text-h3 text-body-darkest mt-6 mb-12 d-none d-md-block">
          {{ $t("authentication.title") }}
        </h1>

        <div class="align-center d-none d-md-flex">
          <p class="text-h5 mb-0">
            {{ $t("authentication.trustpilot.title") }}
          </p>
          <img
            class="ml-4"
            src="/images/trustpilot-stars-logo.svg"
            :alt="$t('authentication.trustpilot.image')"
          />
        </div>
      </div>

      <div class="right">
        <loading-symbol
          :size="120"
          :color="$vuetify.theme.currentTheme.primary"
          v-if="loading"
        />
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>
<script>
import AuthLayoutImage from "@/components/authentication/AuthLayoutImage.vue";
import LoadingSymbol from "@/components/common/loaders/LoadingSymbol.vue";
import { mapActions } from "vuex";

export default {
  name: "AuthLayout",
  components: { LoadingSymbol, AuthLayoutImage },
  props: {
    loading: Boolean,
    isReportPageSignUp: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("layout", ["setAuthPageHeader", "setFullscreen"]),
    initialiseLayout() {
      if (this.isReportPageSignUp) return;

      this.setAuthPageHeader(true);
      this.setFullscreen(true);
    },
    resetLayout() {
      if (this.isReportPageSignUp) return;

      this.setAuthPageHeader(false);
      this.setFullscreen(false);
    }
  },
  mounted() {
    this.initialiseLayout();
  },
  beforeDestroy() {
    this.resetLayout();
  }
};
</script>
<style lang="scss" scoped>
.auth-container {
  &.is-page {
    background-image: linear-gradient(
      170deg,
      var(--v-vflBeigeLight1-base) 50%,
      #f1ebe3 50%
    );

    display: grid;
    min-height: calc(100vh - var(--topbar-height));
    place-content: center;
  }
}

.auth-container-inner {
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;

  &.is-page {
    align-items: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    padding: 0 1rem 1rem;

    @media screen and (max-width: 959px) {
      display: block;
    }
  }
}
</style>
