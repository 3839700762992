import v1Schema from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import v2Schema from "@/components/assessment/desk/question-schema.json";
import DeskAssessmentWindowScoring from "./desk-assessment-window-scoring";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import router from "@/router";
import { PositiveText, NegativeText } from "./sims-report-constants.js";
import { EquipmentScoreHelper } from "@/components/assessment/desk/results-helpers/results-helper.js";
import store from "@/store.js";

export default {
  shoulderDistanceSupplementaryText(results) {
    if (results.shoulderHeightDifference === 0) {
      return "";
    }
    let side = results.rightShoulderHigher
      ? router.app.$t("deskAssessment.webcam.measurement.left")
      : router.app.$t("deskAssessment.webcam.measurement.right");
    return router.app.$t(
      "deskAssessment.webcam.measurement.shoulderHeight.supplementaryText",
      { 0: side }
    );
  },
  getVisualDeskPlannerPositiveText(results, canvasHeight) {
    let positiveText = [];

    const inputDeviceText = getPositiveInputDeviceText(results);
    if (inputDeviceText) {
      positiveText.push(inputDeviceText);
    }

    const keyboardText = getPositiveKeyboardText(results);
    if (keyboardText) {
      positiveText.push(keyboardText);
    }

    const screensText = getPositiveScreenText(results);
    if (screensText) {
      positiveText.push(screensText);
    }

    const deskText = getPositiveDeskText(results);
    if (deskText) {
      positiveText.push(deskText);
    }

    const hasLaptopWithStand = results.deskItems.find(
      i => i.id === "laptopWithStand"
    );
    if (hasLaptopWithStand) {
      positiveText.push(PositiveText.LAPTOP_STAND);
    }

    const chairText = getPositiveChairText(results);
    if (chairText) {
      positiveText.push(chairText);
    }

    const windowText = getWindowText(results, canvasHeight);
    if (windowText) {
      positiveText.push(windowText);
    }

    const hasWater =
      results.deskItems.filter(i => i.id === "water").length === 1;
    if (hasWater) {
      positiveText.push(PositiveText.WATER);
    }

    const hasPlants = results.deskItems.find(i => i.id === "plants");
    const atOffice = results.workingfromhome === 2;
    if (hasPlants && ((!store.getters.nDiNbS && !store.getters.aLIxe) || !atOffice)) {
      positiveText.push(PositiveText.PLANTS);
    }

    const hasLamp = results.deskItems.find(i => i.id === "lamp");
    if (hasLamp) {
      positiveText.push(PositiveText.LAMP);
    }

    const hasWristRest = results.deskItems.find(i => i.id === "wristRest");
    if (hasWristRest) {
      positiveText.push(PositiveText.WRIST_REST);
    }

    positiveText = positiveText.sort((a, b) => {
      return a.priority - b.priority;
    });

    return positiveText
      .slice(0, 3)
      .map(a =>
        router.app.$t(`deskAssessment.setup.sims.positiveText.${a.text}`)
      );
  },
  getVisualDeskPlannerNegativeText(results) {
    let negativeText = [];

    const workAreaText = getNegativeWorkAreaText(results);
    if (workAreaText) {
      negativeText.push(workAreaText);
    }

    const seatingText = getNegativeSeatingText(results);
    if (seatingText) {
      negativeText.push(seatingText);
    }

    const screenText = getNegativeScreenText(results);
    if (screenText) {
      negativeText.push(screenText);
    }

    const keyboardText = getNegativeKeyboardText(results);
    if (keyboardText) {
      negativeText.push(keyboardText);
    }

    const laptopStandText = getLaptopStandText(results);
    if (laptopStandText) {
      negativeText.push(laptopStandText);
    }

    const clutterText = getNegativeClutterText(results);
    if (clutterText) {
      negativeText.push(clutterText);
    }

    const inputDeviceText = getNegativeInputDeviceText(results);
    if (inputDeviceText) {
      negativeText.push(inputDeviceText);
    }

    const lightingText = getNegativeLightingText(results);
    if (lightingText) {
      negativeText.push(lightingText);
    }

    const hasPlants = results.deskItems.find(i => i.id === "plants");
    const atOffice = results.workingfromhome === 2;
    if (
      ((store.getters.nDiNbS || store.getters.aLIxe) && !atOffice && !hasPlants) ||
      (!store.getters.nDiNbS && !store.getters.aLIxe && !hasPlants)
    ) {
      negativeText.push(NegativeText.PLANTS);
    }

    const waterText = getNegativeWaterText(results);
    if (waterText) {
      negativeText.push(waterText);
    }

    negativeText = negativeText.sort((a, b) => {
      return a.priority - b.priority;
    });

    const isInfoItem = type => ["water", "plants"].includes(type);

    return negativeText.slice(0, 3).map(a => ({
      text: router.app.$t(`deskAssessment.setup.sims.negativeText.${a.text}`),
      type: a.text,
      icon: isInfoItem(a.text) ? "mdi-information" : "mdi-close-circle",
      color: isInfoItem(a.text) ? "vflPurpleLight1" : "vflRedLight1"
    }));
  },

  getValueFromWorkArea(area, isV2Schema = false) {
    let question = this.getWorkAreaQuestion(isV2Schema);
    return question.options.find(x => x.label === area).value;
  },
  getWorkAreaFromValue(value, isV2Schema = false) {
    let question = this.getWorkAreaQuestion(isV2Schema);
    return question.options.find(x => x.value === value).label;
  },
  getWorkAreaQuestion(isV2Schema = false) {
    if (isV2Schema) {
      return v2Schema.setup.pages
        .find(x => x.name === "sims")
        .questions.find(x => x.name === "workarea");
    }

    return v1Schema
      .find(x => x.theme === "setup")
      .pages.find(x => x.name === "sims")
      .questions.find(x => x.name === "workarea");
  }
};

function getPositiveInputDeviceText(results) {
  const hasTrackpad = results.deskItems.find(i => i.id === "trackpad");
  const hasRollerMouse = results.deskItems.find(i => i.id === "rollerMouse");
  const hasErgoMouse = results.deskItems.find(i => i.id === "rollerMouse");
  const hasRegularMouse = results.deskItems.find(i => i.id === "mouse");

  if (hasTrackpad) {
    return PositiveText.TRACKPAD;
  } else if (hasRollerMouse) {
    return PositiveText.ROLLER_MOUSE;
  } else if (hasErgoMouse) {
    return PositiveText.ERGONOMIC_MOUSE;
  } else if (hasRegularMouse) {
    return PositiveText.MOUSE;
  }
  return null;
}

function getPositiveScreenText(results) {
  const verticalScreens = results.deskItems.filter(
    i => i.id === "computerScreenVertical"
  ).length;
  const ultraWideScreens = results.deskItems.filter(
    i => i.id === "computerScreenUltraWide"
  ).length;
  const regularScreens = results.deskItems.filter(
    i => i.id === "computerScreen"
  ).length;

  if (
    (verticalScreens === 1 && (ultraWideScreens > 0 || regularScreens > 0)) ||
    verticalScreens > 1
  ) {
    return PositiveText.VERTICAL_SCREEN_AND_OTHER;
  } else if (
    ultraWideScreens === 1 &&
    regularScreens === 0 &&
    verticalScreens === 0
  ) {
    return PositiveText.ULTRA_WIDE_SCREEN;
  } else if (regularScreens > 0) {
    return PositiveText.REGULAR_SCREEN;
  }
  return null;
}

function getPositiveKeyboardText(results) {
  const hasErgonomicKeyboard = results.deskItems.find(
    i => i.id === "ergonomicKeyboard"
  );
  const hasRegularKeyboard = results.deskItems.find(i => i.id === "keyboard");
  if (hasErgonomicKeyboard) {
    return PositiveText.ERGONOMIC_KEYBOARD;
  } else if (hasRegularKeyboard) {
    return PositiveText.KEYBOARD;
  }
  return null;
}

function getPositiveDeskText(results) {
  if (results.deskType === 3 || results.deskType === 4) {
    return PositiveText.CORNER_DESK;
  } else if (results.deskType === 2) {
    return PositiveText.CURVED_DESK;
  }
  return null;
}

function getPositiveChairText(results) {
  const hasChairWithArmrest = results.deskItems.find(
    i => i.id === "chairWithArmrest"
  );
  if (hasChairWithArmrest) {
    return PositiveText.CHAIR_WITH_ARMRESTS;
  }
  return null;
}

function getWindowText(results, canvasHeight) {
  const windows = results.deskItems.filter(i => i.id.includes("window"));
  if (windows.length === 0) {
    return null;
  }

  if (windows.length > 1) {
    return PositiveText.WINDOW_MULTIPLE;
  }

  const windowState = DeskAssessmentWindowScoring.classifyWindows(
    results.deskItems,
    canvasHeight
  );

  if (windowState === "monitorAdjacentToWindow") {
    return PositiveText.WINDOW_MONITOR_ADJACENT;
  } else if (windowState === "monitorFacingWindow") {
    return PositiveText.WINDOW_MONITOR_FACING;
  } else if (windowState === "monitorFacingAwayFromWindow") {
    return PositiveText.WINDOW_MONITOR_FACING_AWAY;
  }
  return PositiveText.WINDOW_UNKNOWN;
}

function getNegativeWorkAreaText(results) {
  if (results.workarea === 4) {
    return NegativeText.PHONE_BOOTH;
  } else if (results.workarea === 2) {
    return NegativeText.SOFA;
  } else if (results.workarea === 3) {
    return NegativeText.BED;
  }
  return null;
}

function getNegativeScreenText(results) {
  const verticalScreens = results.deskItems.filter(
    i => i.id === "computerScreenVertical"
  ).length;
  const ultraWideScreens = results.deskItems.filter(
    i => i.id === "computerScreenUltraWide"
  ).length;
  const regularScreens = results.deskItems.filter(
    i => i.id === "computerScreen"
  ).length;

  const onlyHasVerticalScreen =
    verticalScreens === 1 && ultraWideScreens === 0 && regularScreens === 0;
  const hasUltrawideScreenAndOtherOfAnyType =
    ultraWideScreens > 1 ||
    (ultraWideScreens === 1 && (regularScreens > 0 || verticalScreens > 0));

  if (onlyHasVerticalScreen) {
    return NegativeText.ONLY_VERTICAL_SCREEN;
  } else if (hasUltrawideScreenAndOtherOfAnyType) {
    return NegativeText.ULTRA_WIDE_SCREEN_AND_OTHER;
  }
  return null;
}

function getNegativeSeatingText(results) {
  const utilityService = getUtilityService();

  if (
    utilityService.isMainSeatingOption(
      results.deskItems,
      "stool",
      results.workarea
    )
  ) {
    return NegativeText.STOOL;
  } else if (
    utilityService.isMainSeatingOption(
      results.deskItems,
      "beanBag",
      results.workarea
    )
  ) {
    return NegativeText.BEAN_BAG;
  } else if (
    utilityService.isMainSeatingOption(
      results.deskItems,
      "gymBall",
      results.workarea
    )
  ) {
    return NegativeText.GYM_BALL;
  } else if (
    utilityService.isMainSeatingOption(
      results.deskItems,
      "chairNoArmrest",
      results.workarea
    )
  ) {
    return NegativeText.CHAIR_NO_ARMREST;
  }
  return null;
}

function getNegativeKeyboardText(results) {
  const hasKeyboard = results.deskItems.find(
    i => i.id === "ergonomicKeyboard" || i.id === "keyboard"
  );

  if (!hasKeyboard) {
    return NegativeText.NO_KEYBOARD;
  }
  return null;
}

function getNegativeInputDeviceText(results) {
  const numberOfInputDevices = results.deskItems.filter(
    i =>
      i.id === "mouse" ||
      i.id === "ergonomicMouse" ||
      i.id === "rollerMouse" ||
      i.id === "trackpad"
  ).length;

  if (numberOfInputDevices === 0) {
    return NegativeText.NO_MOUSE;
  } else if (numberOfInputDevices > 1) {
    return NegativeText.MULTIPLE_INPUT_DEVICES;
  }
  return null;
}

function getLaptopStandText(results) {
  const usesOtherScreens =
    results.deskItems.filter(
      i =>
        i.id === "computerScreen" ||
        i.id === "computerScreenVertical" ||
        i.id === "computerScreenUltraWide"
    ).length > 0;
  const hasLaptopButNoStand =
    results.deskItems.filter(i => i.id === "laptop").length > 0 &&
    results.deskItems.filter(i => i.id === "laptopWithStand").length === 0;

  if (usesOtherScreens && hasLaptopButNoStand) {
    return NegativeText.LAPTOP_STAND;
  }
  return null;
}

function getNegativeClutterText(results) {
  const hasClutter = results.deskItems.find(x => x.id === "clutter");
  if (hasClutter) {
    return NegativeText.CLUTTER;
  }
  return null;
}

function getNegativeLightingText(results) {
  const poorLighting =
    results.deskItems.filter(x => x.id === "lamp" || x.id === "window")
      .length === 0 && results.brightnesslevel < 0;
  if (poorLighting) {
    return NegativeText.LIGHTING;
  }
  return null;
}

function getNegativeWaterText(results) {
  const waters = results.deskItems.filter(x => x.id === "water").length;
  if (waters === 0) {
    return NegativeText.NO_WATER;
  } else if (waters > 1) {
    return NegativeText.MULTIPLE_WATERS;
  }
  return null;
}

function getUtilityService() {
  const isV2 = store.getters["deskAssessmentSchemaStore/schemaVersion"];
  return isV2 ? EquipmentScoreHelper : DeskAssessmentResults;
}
