<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M565.588 611.676H544.088L547.749 639.134C547.794 639.469 548.08 639.72 548.418 639.72H560.971C561.296 639.72 561.572 639.48 561.619 639.159L565.588 611.676Z"
      fill="#966F60"
    />
    <path
      d="M544.555 609.492H565.12L564.521 607.294C564.323 606.568 563.664 606.064 562.911 606.064H546.789C546.021 606.064 545.349 606.578 545.148 607.318L544.555 609.492Z"
      fill="#966F60"
    />
    <path
      d="M543.415 613.547H566.266C566.496 613.547 566.681 613.361 566.681 613.132V609.911C566.681 609.682 566.495 609.496 566.266 609.496H543.415C543.186 609.496 543 609.682 543 609.911V613.132C543 613.361 543.186 613.547 543.415 613.547Z"
      fill="#3B2723"
    />
    <path
      d="M546.194 627.552H563.279L563.796 624.135H545.779L546.194 627.552Z"
      fill="white"
    />
    <path
      d="M597.361 611.676H575.861L579.523 639.134C579.567 639.469 579.853 639.72 580.191 639.72H592.744C593.069 639.72 593.345 639.48 593.392 639.159L597.361 611.676Z"
      fill="#966F60"
    />
    <path
      d="M576.328 609.492H596.894L596.294 607.294C596.096 606.568 595.437 606.064 594.685 606.064H578.562C577.795 606.064 577.123 606.578 576.921 607.318L576.328 609.492Z"
      fill="#966F60"
    />
    <path
      d="M575.186 613.547H598.038C598.267 613.547 598.453 613.361 598.453 613.132V609.911C598.453 609.682 598.267 609.496 598.038 609.496H575.186C574.957 609.496 574.771 609.682 574.771 609.911V613.132C574.771 613.361 574.957 613.547 575.186 613.547Z"
      fill="#3B2723"
    />
    <path
      d="M577.965 627.552H595.05L595.568 624.135H577.551L577.965 627.552Z"
      fill="white"
    />
    <path
      d="M629.135 611.676H607.635L611.296 639.134C611.341 639.469 611.627 639.72 611.965 639.72H624.517C624.843 639.72 625.119 639.48 625.165 639.159L629.135 611.676Z"
      fill="#966F60"
    />
    <path
      d="M608.102 609.492H628.667L628.067 607.294C627.87 606.568 627.21 606.064 626.458 606.064H610.335C609.568 606.064 608.896 606.578 608.694 607.318L608.102 609.492Z"
      fill="#966F60"
    />
    <path
      d="M606.962 613.547H629.813C630.042 613.547 630.228 613.361 630.228 613.132V609.911C630.228 609.682 630.042 609.496 629.813 609.496H606.962C606.733 609.496 606.547 609.682 606.547 609.911V613.132C606.547 613.361 606.733 613.547 606.962 613.547Z"
      fill="#3B2723"
    />
    <path
      d="M609.739 627.552H626.824L627.341 624.135H609.324L609.739 627.552Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "LifestyleSleepLessThanFour"
};
</script>
