<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="34.4006"
      height="33"
      rx="10"
      transform="matrix(-0.964761 0.263128 0.263128 0.964761 285.611 221.734)"
      fill="#BE9523"
    />
    <path
      d="M268.844 259.016L283.99 254.938C288.607 253.695 291.342 248.945 290.099 244.328L286.021 229.182C284.778 224.565 280.027 221.83 275.41 223.073L260.265 227.151C255.648 228.394 252.913 233.145 254.156 237.762L258.234 252.907C259.477 257.524 264.228 260.259 268.844 259.016Z"
      fill="#F9BA0B"
    />
    <path
      d="M275.999 241.404C276.436 243.027 277.571 244.133 278.535 243.874C279.497 243.615 279.924 242.088 279.487 240.465C279.05 238.841 277.915 237.736 276.952 237.995C275.988 238.254 275.562 239.781 275.999 241.404Z"
      fill="white"
    />
    <path
      d="M264.473 244.507C264.91 246.131 266.045 247.237 267.008 246.977C267.971 246.718 268.398 245.192 267.96 243.568C267.523 241.945 266.388 240.839 265.425 241.098C264.462 241.358 264.036 242.884 264.473 244.507Z"
      fill="white"
    />
    <path
      d="M276.462 241.28C276.783 242.471 277.616 243.283 278.323 243.093C279.03 242.902 279.343 241.782 279.022 240.59C278.702 239.398 277.868 238.586 277.161 238.777C276.454 238.967 276.141 240.088 276.462 241.28Z"
      fill="#2E2D2D"
    />
    <path
      d="M264.923 244.387C265.244 245.579 266.077 246.391 266.784 246.2C267.491 246.01 267.804 244.889 267.483 243.697C267.163 242.506 266.329 241.694 265.622 241.884C264.915 242.075 264.602 243.195 264.923 244.387Z"
      fill="#2E2D2D"
    />
    <path
      d="M258.935 239.063C259.318 240.488 259.998 240.739 260.783 240.527C261.568 240.316 262.161 239.722 261.777 238.298C261.394 236.874 260.017 234.087 259.232 234.298C258.447 234.509 258.551 237.639 258.935 239.063Z"
      fill="white"
    />
    <path
      d="M267.11 233.571C267.409 234.679 267.937 234.874 268.548 234.71C269.158 234.545 269.619 234.084 269.321 232.976C269.023 231.868 267.952 229.7 267.342 229.865C266.731 230.029 266.812 232.463 267.11 233.571Z"
      fill="white"
    />
    <path
      d="M278.299 233.92C278.596 235.026 279.124 235.221 279.733 235.057C280.342 234.893 280.802 234.432 280.505 233.327C280.207 232.221 279.139 230.058 278.53 230.222C277.921 230.386 278.001 232.815 278.299 233.92Z"
      fill="white"
    />
    <path
      d="M273.01 233.659C273.215 234.419 273.577 234.553 273.996 234.44C274.414 234.327 274.731 234.011 274.526 233.251C274.322 232.491 273.587 231.004 273.168 231.116C272.75 231.229 272.805 232.899 273.01 233.659Z"
      fill="white"
    />
    <path
      d="M264.935 237.321C265.139 238.081 265.502 238.215 265.92 238.102C266.339 237.99 266.656 237.673 266.451 236.913C266.246 236.153 265.512 234.666 265.093 234.779C264.675 234.891 264.73 236.561 264.935 237.321Z"
      fill="white"
    />
    <path
      d="M287.381 245.96C287.452 245.94 287.52 245.905 287.579 245.852C287.781 245.672 287.8 245.362 287.62 245.16L285.676 242.974C285.496 242.771 285.186 242.753 284.983 242.933C284.781 243.113 284.763 243.423 284.943 243.626L286.886 245.812C287.014 245.955 287.207 246.006 287.381 245.96Z"
      fill="#EB1A48"
    />
    <path
      d="M285.328 246.512C285.4 246.493 285.468 246.458 285.526 246.405C285.729 246.225 285.747 245.915 285.567 245.713L283.624 243.526C283.444 243.324 283.133 243.306 282.931 243.486C282.728 243.665 282.71 243.976 282.89 244.178L284.834 246.364C284.962 246.508 285.155 246.559 285.328 246.512Z"
      fill="#EB1A48"
    />
    <path
      d="M283.276 247.065C283.347 247.046 283.415 247.01 283.474 246.958C283.676 246.778 283.694 246.468 283.514 246.265L281.571 244.079C281.39 243.877 281.081 243.858 280.878 244.038C280.676 244.218 280.657 244.528 280.837 244.731L282.781 246.917C282.909 247.061 283.102 247.112 283.276 247.065Z"
      fill="#EB1A48"
    />
    <path
      d="M266.775 251.508C266.846 251.489 266.914 251.454 266.973 251.401C267.175 251.221 267.193 250.911 267.013 250.709L265.07 248.522C264.89 248.32 264.58 248.302 264.377 248.482C264.175 248.662 264.156 248.972 264.337 249.174L266.28 251.36C266.408 251.504 266.601 251.555 266.775 251.508Z"
      fill="#EB1A48"
    />
    <path
      d="M264.722 252.061C264.793 252.042 264.861 252.007 264.92 251.954C265.122 251.774 265.141 251.464 264.96 251.261L263.017 249.075C262.837 248.873 262.526 248.855 262.325 249.034C262.122 249.214 262.104 249.525 262.284 249.727L264.227 251.913C264.355 252.057 264.548 252.108 264.722 252.061Z"
      fill="#EB1A48"
    />
    <path
      d="M262.669 252.614C262.74 252.595 262.808 252.559 262.867 252.507C263.07 252.327 263.088 252.017 262.908 251.814L260.964 249.628C260.784 249.426 260.474 249.407 260.272 249.587C260.069 249.767 260.051 250.077 260.231 250.28L262.175 252.466C262.302 252.61 262.495 252.661 262.669 252.614Z"
      fill="#EB1A48"
    />
  </svg>
</template>

<script>
export default {
  name: "ReturnAnxious"
};
</script>
