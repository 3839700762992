<template>
  <user-profile-block has-tabs>
    <template #title
      ><span>{{ $t("userProfile.equipment.title") }}</span>
      <button
        v-if="!isLoading"
        @click="openAddEquipmentModal"
        class="vfl-button-link ml-auto"
        style="text-decoration: none"
      >
        <v-icon size="14" color="vflPrimary">mdi-plus</v-icon
        >{{ $t("userProfile.equipment.addEquipment") }}
      </button>
    </template>
    <template #content>
      <div v-if="isLoading">
        <v-skeleton-loader type="text@3" />
      </div>
      <template v-else>
        <vfl-toggle
          :selected-value.sync="selectedLocation"
          :options="locationOptions"
          filter-id-prefix="equipment-location"
          class="mb-4"
        />

        <user-profile-equipment-list
          v-if="currentLocationHasEquipment && sortedEquipment.length"
          :equipment="sortedEquipment"
          :location="selectedLocation"
        />
      </template>

      <user-profile-add-equipment-dialog
        v-if="equipmentDialogOpen"
        :default-location="selectedLocation"
      />

      <user-profile-confirm-equipment-delete-dialog
        v-if="deleteEquipmentConfirmDialogOpen"
      />
    </template>
  </user-profile-block>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import UserProfileAddEquipmentDialog from "@/components/user-profile/user-profile-equipment/UserProfileAddEquipmentDialog.vue";
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";
import UserProfileConfirmEquipmentDeleteDialog from "@/components/user-profile/user-profile-equipment/UserProfileConfirmEquipmentDeleteDialog.vue";
import { assessmentLocations } from "@/constants/constants.js";
import UserProfileEquipmentList from "@/components/user-profile/user-profile-equipment/UserProfileEquipmentList.vue";

export default {
  name: "UserProfileEquipment",
  components: {
    UserProfileEquipmentList,
    VflIconButton,
    UserProfileAddEquipmentDialog,
    UserProfileBlock,
    UserProfileConfirmEquipmentDeleteDialog
  },
  data() {
    return {
      selectedLocation: assessmentLocations.office
    };
  },
  computed: {
    ...mapGetters("userProfile/equipment", [
      "homeEquipment",
      "officeEquipment",
      "isDeletingEquipment",
      "equipmentDialogOpen",
      "deleteEquipmentConfirmDialogOpen",
      "loadingStates"
    ]),
    isLoading() {
      return (
        this.loadingStates.homeEquipment || this.loadingStates.officeEquipment
      );
    },
    hasHomeEquipment() {
      return this.homeEquipment?.length > 0;
    },
    hasOfficeEquipment() {
      return this.officeEquipment?.length > 0;
    },
    currentLocationEquipment() {
      return this.selectedLocation === assessmentLocations.home
        ? this.homeEquipment
        : this.officeEquipment;
    },
    currentLocationHasEquipment() {
      return this.selectedLocation === assessmentLocations.home
        ? this.hasHomeEquipment
        : this.hasOfficeEquipment;
    },
    locationOptions() {
      return [
        {
          value: assessmentLocations.office,
          text: this.$t("userProfile.equipment.office")
        },
        {
          value: assessmentLocations.home,
          text: this.$t("userProfile.equipment.home")
        }
      ];
    },
    sortedEquipment() {
      return this.currentLocationEquipment
        .filter(item => item.adminSource)
        .sort((a, b) => {
          const dateA = new Date(a.addedOn || 0);
          const dateB = new Date(b.addedOn || 0);
          return dateB - dateA;
        });
    }
  },
  methods: {
    ...mapActions("userProfile/equipment", [
      "deleteEquipment",
      "openEquipmentDialog",
      "openDeleteEquipmentConfirmDialog"
    ]),
    openAddEquipmentModal() {
      this.openEquipmentDialog({
        equipment: null,
        location: this.selectedLocation
      });
    }
  }
};
</script>

<style scoped lang="scss">
.equipment-item {
  padding: 8px 12px;
  border-radius: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);

    .action-buttons {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.equipment-count {
  font-weight: 500;
  margin-left: 4px;
}

.equipment-details {
  color: var(--v-vflGreyLight-base);
}

.action-buttons {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s ease-out;

  i {
    color: var(--v-vflPrimary-base);
  }

  .is-disabled {
    cursor: default;
    opacity: 0.6 !important;
    pointer-events: auto;

    &:hover {
      background: none;
    }
  }
}
</style>
