<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>
      {{ $t("newParentAssessment.report.lifestyle.title") }}
    </template>
    <template #content>
      <div
        :class="[
          'mt-8',
          { 'card-grid': showGridLayout, 'card-flex': !showGridLayout }
        ]"
      >
        <report-info-card
          v-for="(card, index) in cards"
          :class="showGridLayout ? '' : 'card-item'"
          :key="index"
          :title="card.title"
          :illustration="card.illustration"
          :text="card.text"
          :assessment-type="$options.assessmentTypes.newParentAssessment"
          :use-html="card.useHtml"
          image-background-colour="#F8F5F0"
          :image-width="275"
        />
      </div>
      <recommendation-container
        class="mt-10"
        :title="$t('newParentAssessment.recommendations.lifestyle.title')"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="recommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { NewParentReportLifestyleService } from "@/components/assessment/new-parent/report/new-parent-report-lifestyle-service.js";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import { getRecommendationsByType } from "@/components/assessment/new-parent/report/recommendations/recommendation-service.js";
import { RecommendationSections } from "@/components/assessment/new-parent/report/recommendations/constants.js";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";

export default {
  name: "NewParentReportCaringForChild",
  components: {
    ReportSection,
    ReportInfoCard,
    RecommendationContainer
  },
  assessmentTypes: assessmentTypes,
  data() {
    return {
      lifestyleService: null
    };
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.lifestyleService = new NewParentReportLifestyleService(this.results);
  },
  computed: {
    recommendations() {
      return getRecommendationsByType(
        RecommendationSections.LIFESTYLE,
        this.results
      );
    },
    cards() {
      return this.lifestyleService.getCards();
    },
    showGridLayout() {
      return this.cards.length < 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-gap: 1rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.card-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: start;
}

.card-item {
  flex: 1 1 350px;
  min-width: 350px;
  max-width: 450px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}
</style>
