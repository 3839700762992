import store from "@/store";
import { camelCase } from "lodash";
import { updateTeamFeatureFlag } from "@/customApi";

export async function SetFeatureFlag(feature, value, storeFeature = null) {
  try {
    await updateTeamFeatureFlag(feature, value);

    // Use the storeFeature if provided, otherwise fall back to camelCase
    const featureToCommit = storeFeature || camelCase(feature);

    store.commit("setFeatureFlag", {
      feature: featureToCommit,
      value: value
    });
  } catch (err) {
    throw Error(err);
  }
}
