import BalanceRecommendations from "@/assets/json/recommendations/womens-health/BalanceRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import Answers from "@/components/assessment/pregnancy/answers.json";

const cardGroups = {
  firstTrimester: {
    titleKey:
      "pregnancyAssessment.report.content.balance.recommendationGroups.firstTrimester",
    cards: {
      bridge_pose_pregnancy: {
        showConditions: () => true
      },
      sumo_squat_pregnancy: {
        showConditions: () => true
      },
      modified_plank_pregnancy: {
        showConditions: () => true
      }
    }
  },
  secondTrimester: {
    titleKey:
      "pregnancyAssessment.report.content.balance.recommendationGroups.secondTrimester",
    cards: {
      side_stepping_pregnancy: {
        showConditions: () => true
      },
      leg_lower_lifts_pregnancy: {
        showConditions: () => true
      },
      reverse_lunge_pregnancy: {
        showConditions: () => true
      }
    }
  },
  thirdTrimester: {
    titleKey:
      "pregnancyAssessment.report.content.balance.recommendationGroups.thirdTrimester",
    cards: {
      modified_side_plank_pregnancy: {
        showConditions: () => true
      },
      regular_squats_pregnancy: {
        showConditions: () => true
      },
      bird_dogs_pregnancy: {
        showConditions: () => true
      }
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, BalanceRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getBalanceRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};

export const getDefaultBalanceTab = results => {
  const trimester = results?.trimester?.pregnancyTrimester;

  const defaultTabs = {
    [Answers.trimester.pregnancyTrimester.first]: "firstTrimester",
    [Answers.trimester.pregnancyTrimester.second]: "secondTrimester",
    [Answers.trimester.pregnancyTrimester.third]: "thirdTrimester"
  };

  return (
    defaultTabs[trimester] ||
    defaultTabs[Answers.trimester.pregnancyTrimester.first]
  );
};
