<template>
  <div>
    <div v-for="item in equipment" :key="item.id" class="equipment-item">
      <p class="text-body-dark mb-0">
        {{ item.name }}
      </p>
      <div v-if="item.note">
        <vfl-read-more :text="item.note" text-class="text-caption mb-0" />
      </div>
      <div class="equipment-footer d-flex align-center mt-n2">
        <p class="equipment-details mb-0 text-caption d-flex align-center">
          <span v-if="item.adminSource">{{ getAuthor(item) }}</span
          ><span class="separator"></span>
          <span>{{ getFormattedDate(item) }}</span>
        </p>

        <user-profile-equipment-item-actions
          v-if="hasAdminSource(item)"
          class="ml-auto item-actions"
          :location="location"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateWithOrdinal } from "@/services/date-formatting-service.js";
import { mapGetters } from "vuex";
import UserProfileEquipmentItemActions from "./UserProfileEquipmentItemActions.vue";

export default {
  name: "UserProfileEquipmentList",
  components: {
    UserProfileEquipmentItemActions
  },
  props: {
    equipment: {
      type: Array,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  methods: {
    getFormattedDate(item) {
      return item.addedOn ? formatDateWithOrdinal(item.addedOn) : "";
    },
    hasAdminSource(item) {
      return item.adminSource || item.source === "admin";
    },
    getAuthor({ adminSource }) {
      if (!adminSource) return;

      const { name, email } = adminSource;

      if (this.userEmail === email) {
        return this.$t("userProfile.notes.you");
      }

      return name || email;
    }
  }
};
</script>

<style scoped lang="scss">
.equipment-item {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  position: relative;

  + .equipment-item {
    margin-top: 1rem;
  }

  &:hover {
    &:before {
      background-color: hsla(0, 0%, 0%, 0.04);
      border-radius: 12px;
      bottom: -0.25rem;
      content: "";
      left: -0.25rem;
      pointer-events: none;
      position: absolute;
      right: -0.25rem;
      top: -0.25rem;
      z-index: 0;
    }
  }
}

.equipment-details {
  color: var(--v-vflGreyLight-base);

  .separator {
    background: currentColor;
    border-radius: 999px;
    display: inline-block;
    height: 2px;
    margin: 0 0.25rem;
    width: 2px;
  }
}

.item-actions {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s ease-out;
}

.equipment-item:hover .item-actions {
  opacity: 1;
  pointer-events: auto;
}
</style>
