<template>
  <report-pain-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
    :results="results"
    :assessment-id="assessmentId"
    :assessment-type="$options.assessmentTypes.newParentAssessment"
    :section-title="$t('newParentAssessment.report.pain.title')"
    :pain-area-title="$t('newParentAssessment.report.pain.painAreaTitle')"
    :info-cards="infoCards"
    :recommendation-title="$t('newParentAssessment.recommendations.pain.title')"
    :pain-recommendations="recommendations"
    :image-width="275"
    image-background-colour="#F8F5F0"
  />
</template>

<script>
import ReportPainSection from "@/components/assessment/common/report/ReportPainSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { NewParentReportPainService } from "@/components/assessment/new-parent/report/new-parent-report-pain-service.js";
import { getRecommendationsByType } from "@/components/assessment/new-parent/report/recommendations/recommendation-service.js";
import { RecommendationSections } from "@/components/assessment/new-parent/report/recommendations/constants.js";

export default {
  name: "NewParentReportPain",
  components: {
    ReportPainSection
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  computed: {
    painService() {
      return new NewParentReportPainService(this.results);
    },
    infoCards() {
      const painTextArray = this.painService.getText();

      return [
        {
          title: this.$t("newParentAssessment.report.pain.card.title"),
          illustration:
            "/images/new-parent-assessment/report/new-parent-pain.svg",
          text: painTextArray,
          showCondition: true,
          className: null,
          useHtml: true
        }
      ];
    },
    recommendationTitle() {
      return "";
    },
    recommendations() {
      return getRecommendationsByType(
        RecommendationSections.PAIN,
        this.results
      );
    }
  }
};
</script>
