<template>
  <div class="assessment-configuration-container px-6 py-8">
    <h3 class="text-h5 pb-4">
      {{ $t("assessmentConfiguration.title") }}
    </h3>
    <p>{{ $t("assessmentConfiguration.description") }}</p>

    <div class="cards">
      <div
        class="vfl-card-light pa-4"
        v-for="(assessment, index) in assessments"
        :key="assessment.id"
      >
        <div class="d-flex justify-space-between align-center">
          <h3
            class="text-body-1 text-body-bold text-body-darkest"
            v-html="assessment.title"
          ></h3>
          <v-switch
            v-model="assessment.active"
            @change="updateAssessment(assessment)"
            hide-details
            dense
            :disabled="assessment.disabled || assessment.loading"
            :loading="assessment.loading"
            class="mt-0"
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SetFeatureFlag } from "@/services/feature-flag-service.js";
import { mapActions, mapGetters } from "vuex";
import { assessmentTypes } from "@/constants/constants.js";
import { sendEvent } from "@/services/analytics/analytics.js";
import { ASSESSMENT_CONFIG } from "@/components/dashboard/assessment-configuration/assessment-config.js";

export default {
  name: "AssessmentConfiguration",
  data() {
    return {
      assessments: ASSESSMENT_CONFIG.map(config => ({
        id: config.id,
        title: this.$t(
          `assessmentConfiguration.${config.translationKey}.title`
        ),
        description: this.$t(
          `assessmentConfiguration.${config.translationKey}.description`
        ),
        active: config.alwaysEnabled || false,
        disabled: config.alwaysEnabled || false,
        featureFlag: config.featureFlag,
        apiFeatureFlag: config.apiFeatureFlag,
        invertValue: config.invertValue || false,
        loading: false
      }))
    };
  },
  computed: {
    ...mapGetters(["featureFlags"]),
    resilienceAssessmentActive() {
      // For resilience, we need the opposite of hideBurnoutCompletely
      return !(this.featureFlags.hideBurnoutCompletely || false);
    }
  },
  created() {
    this.getAssessmentConfiguration();
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"]),
    getAssessmentConfiguration() {
      this.assessments.forEach(assessment => {
        if (assessment.id === assessmentTypes.deskAssessment) {
          assessment.active = true;
        } else if (assessment.id === assessmentTypes.burnoutAssessment) {
          assessment.active = this.resilienceAssessmentActive;
        } else if (assessment.featureFlag) {
          assessment.active =
            this.featureFlags[assessment.featureFlag] || false;
        }
      });
    },
    updateAssessment(assessment) {
      if (assessment.id === assessmentTypes.deskAssessment) return;

      const apiFeatureName = assessment.apiFeatureFlag;
      const storeFeatureName = assessment.featureFlag;
      let value = assessment.active;

      // For resilience, we need to set hideBurnoutCompletely (which is the inverse of showing)
      if (assessment.id === assessmentTypes.burnoutAssessment) {
        value = !value;
      }

      assessment.loading = true;

      SetFeatureFlag(apiFeatureName, value, storeFeatureName)
        .then(() => {
          this.onUpdateSuccess(assessment);
        })
        .catch(error => {
          this.onUpdateError(error, assessment);
        })
        .finally(() => {
          assessment.loading = false;
        });
    },
    onUpdateSuccess(assessment) {
      this.handleSuccessSnackbar(assessment);
      this.sendEvent("Toggled assessment", assessment);
    },
    onUpdateError(error, assessment) {
      this.handleErrorSnackbar();
      this.sendEvent("Toggled assessment failed", assessment);

      // Revert local change if api call fails
      assessment.active = !assessment.active;

      this.$logger.captureException("Failed to toggle assessment", {
        error,
        assessment
      });
    },
    handleSuccessSnackbar(assessment) {
      const path = assessment.active
        ? "assessmentConfiguration.snackbar.enabled"
        : "assessmentConfiguration.snackbar.disabled";

      const message = this.$t(path, { assessment: assessment.title });

      this.showSnackbar({
        message,
        type: "success"
      });
    },
    handleErrorSnackbar(assessment) {
      const message = this.$t("assessmentConfiguration.snackbar.error", {
        assessment: assessment.title
      });

      this.showSnackbar({
        message,
        type: "error"
      });
    },
    sendEvent(message, assessment) {
      sendEvent(message, {
        assessmentId: assessment.id,
        active: assessment.active
      });
    }
  }
};
</script>

<style scoped lang="scss">
.assessment-configuration-container {
  background-color: white;

  .cards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
