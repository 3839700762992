<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row
      class="fill-height"
      align-content="stretch"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        id="title"
        class="text-h6 justify-center pa-0 text-center"
        align-self="start"
      >
        {{
          $t("deskAssessment.results.painContent.importantFactors.title", {
            painArea: painAreaText
          })
        }}
      </v-col>
      <v-col
        v-if="showIconView"
        id="iconContainer"
        cols="12"
        class="py-3 text-body-2"
        :class="viewingOwnResults ? '' : 'blur no-print'"
        align-self="stretch"
      >
        <v-row no-gutters>
          <v-col
            cols="6"
            md="4"
            v-for="(factor, index) in painFactors"
            :key="factor.id"
            :id="`hoverIcon${index}`"
            class="text-center pa-2"
            :class="hoverable(factor) ? 'hoverOver' : ''"
            @mouseover="hoverOverItem(factor)"
            @click="hoverOverItem(factor)"
            @mouseleave="hoveredItem = null"
          >
            <v-row justify="center" class="pb-2"
              ><v-col cols="auto"
                ><v-img
                  :id="`hoverIconImage${index}`"
                  :src="'/images/icons/painContent/' + factor.icon.image"
                  height="40px"
                  width="40px"
                >
                </v-img></v-col
            ></v-row>

            <span class="text-xsmall">{{ $t(factor.icon.title) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="showIconView && viewingOwnResults"
        cols="12"
        id="itemDescription"
        class="text-body-2 vitrueDarkGrey--text px-2"
        :class="viewingOwnResults ? '' : 'blur no-print'"
        style="min-height: 250px"
      >
        <p
          v-for="(description, descriptionIndex) in descriptions"
          :key="descriptionIndex"
        >
          <i18n :path="description.mainText">
            <template
              v-for="(extra, index) in description.extraText"
              :slot="extra.id"
            >
              <span
                :key="descriptionIndex + extra.id + index"
                :class="extra.bold ? 'font-weight-bold' : ''"
                style="text-transform: lowercase"
                >{{ $t(extra.text) }}</span
              >
            </template>
          </i18n>
        </p>
      </v-col>
      <v-col
        v-if="!viewingOwnResults"
        cols="12"
        id="notViewingOwnResults"
        align-self="end"
        class="text-caption primary--text notAuthorizedContainer pa-2"
      >
        <v-row justify="start" no-gutters>
          <v-col cols="auto" class="px-2"
            ><div class="vertical-bar divider" style="width: 3px" /></v-col
          ><v-col>{{
            $t(
              "deskAssessment.results.painContent.importantFactors.notViewingOwnResults"
            )
          }}</v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="showAbsSpecialCase"
        id="specialCasedContainer"
        cols="10"
        class="text-body-2"
        :class="viewingOwnResults ? '' : 'blur no-print'"
      >
        <v-row justify="center" class="py-4"
          ><v-col cols="auto"
            ><v-img
              src="/images/icons/painContent/orangeWarning.svg"
              height="100px"
              width="100px" /></v-col
        ></v-row>
        <p id="specialCaseText1">
          {{
            $t(
              "deskAssessment.results.painContent.importantFactors.hoverText.abs.1"
            )
          }}
        </p>
        <p id="specialCaseText2">
          {{
            $t(
              "deskAssessment.results.painContent.importantFactors.hoverText.abs.2"
            )
          }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import PainHelper from "@/components/assessment/desk/results-helpers/pain-helper";
import { getPainFactors } from "@/services/deskassessment/report/pain-factors.js";
import DefaultAreas from "@/assets/json/DeskAssessment/PainFactorDefaults.json";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hoveredItem: null
    };
  },
  props: {
    results: Object,
    painArea: String,
    painAreaGroup: String,
    assessmentType: String
  },
  computed: {
    ...mapGetters({
      isV2: "deskAssessmentSchemaStore/schemaVersion"
    }),
    utilityService() {
      return this.isV2 ? PainHelper : DeskAssessmentUtil;
    },
    painAreaText() {
      return this.$t(`painAreaGroups.${this.painAreaGroup}`);
    },
    painFactors() {
      return this.viewingOwnResults && !this.showAbsSpecialCase
        ? getPainFactors(this.results, this.painArea, this.assessmentType)
        : DefaultAreas;
    },
    descriptions() {
      return this.hoveredItem
        ? this.hoveredItem.text
        : [
            {
              mainText:
                "deskAssessment.results.painContent.importantFactors.hoverText.default.text.top"
            },
            {
              mainText:
                "deskAssessment.results.painContent.importantFactors.hoverText.default.text.bottom"
            }
          ];
    },
    showAbsSpecialCase() {
      return this.painArea === "abs" && this.viewingOwnResults;
    },
    showIconView() {
      return !this.showAbsSpecialCase;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    }
  },
  methods: {
    hoverOverItem(item) {
      if (!this.hoverable(item)) {
        return;
      }
      if (this.hoveredItem?.id !== item.id) {
        this.$mixpanel.track(`Hovered over pain content item`, {
          item: item.id
        });
      }
      this.hoveredItem = item;
    },
    hoverable(item) {
      return !item.disabled && this.viewingOwnResults;
    }
  }
};
</script>

<style scoped>
.hoverOver {
  transition: all 0.2s ease-in-out;
}
.hoverOver:hover {
  transform: scale(1.15);
}

.blur {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

.notAuthorizedContainer {
  background-color: var(--v-selectedPrimary-base);
}

.divider {
  background-color: var(--v-primary-base);
}
</style>
