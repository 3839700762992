<template>
  <svg
    width="641"
    height="638"
    viewBox="0 0 641 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M300.523 192.622C307.74 196.972 314.83 199.074 318.159 198.216C326.504 196.065 327.271 165.536 320.814 153.5C314.357 141.465 281.162 136.219 279.489 160.065C278.909 168.341 282.384 175.727 287.518 181.754L278.311 224.67H305.053L300.523 192.622Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M323.342 158.894C323.078 157.334 322.43 155.602 321.908 154.126C321.234 152.223 320.215 150.508 319.093 148.891C316.982 145.847 314.449 143.098 311.652 140.817C306.543 136.65 300.04 134.246 293.645 135.026C290.416 135.421 287.282 136.652 284.614 138.702C282.204 140.554 279.885 143.281 276.764 143.477C273.368 143.69 270.255 140.692 267.531 138.841C264.46 136.754 261.194 135.233 257.604 134.642C251.592 133.652 245.943 135.684 241.8 140.617C237.39 145.866 234.185 153.564 237.081 160.52C237.619 161.813 238.311 162.903 239.305 163.806C240.217 164.635 241.609 165.516 241.978 166.84C242.371 168.247 241.198 170.062 240.743 171.332C240.092 173.151 239.557 175.06 239.747 177.037C240.06 180.284 242.1 183.384 244.255 185.518C246.447 187.689 249.146 188.935 252.028 189.509C253.953 189.892 255.92 190.103 257.876 189.96C258.847 189.888 259.704 189.618 260.644 189.404C261.559 189.195 262.071 189.438 262.847 189.94C266.453 192.271 270.33 193.173 274.493 192.826C278.007 192.534 282.058 191.682 284.924 189.274C288.108 186.601 288.044 182.913 287.295 178.974C288.089 179.367 290.285 179.61 288.972 178.043C288.453 177.422 287.471 177.169 286.804 176.804C286.027 176.379 285.239 175.829 284.636 175.135C282.201 172.335 284.66 166.635 287.72 165.695C292.267 164.3 293.285 170.73 296.531 172.393C298.397 173.35 300.349 172.043 301.748 170.708C303.62 168.92 304.897 166.551 305.999 164.141C306.907 162.156 307.752 160.143 308.677 158.167C309.109 157.245 310.605 154.637 309.854 153.594C313.11 152.945 316.738 154.298 319.542 156.073C320.702 156.807 321.585 157.616 322.204 158.911C322.336 159.188 322.625 160.013 322.987 160.047C323.661 160.11 323.409 159.286 323.342 158.894Z"
      fill="#070707"
    />
    <path
      d="M383.066 257.826C387.929 259.447 393.159 256.911 394.78 252.048C396.392 247.185 393.711 242.229 388.835 240.604L329.828 218.934C324.964 217.322 319.708 219.95 318.087 224.808C316.479 229.676 319.103 234.932 323.966 236.549L383.066 257.826Z"
      fill="#B28B67"
    />
    <path
      d="M298.624 213.745C298.624 213.745 308.991 206.426 321.239 210.825C331.849 214.641 343.232 221.947 343.232 221.947L334.018 240.848C334.018 240.848 300.766 230.052 298.624 213.745Z"
      fill="#C86E01"
    />
    <path
      d="M453.877 264.527C458.957 265.19 460.953 255.711 455.86 255.044L388.889 240.954C383.801 240.278 379.149 243.661 378.487 248.745C377.807 253.825 381.402 258.286 386.473 258.949L453.877 264.527Z"
      fill="#B28B67"
    />
    <path
      d="M455.855 255.042C464.65 256.345 470.038 257.073 478.895 258.699C483.758 259.596 487.053 259.282 486.766 262.193C486.545 264.37 480.445 263.381 478.259 263.129C470.065 262.184 470.008 262.427 469.937 263.288C469.857 264.167 474.548 264.644 476.615 266.195C477.901 267.158 477.044 270.011 473.051 269.172C463.93 267.241 453.872 264.525 453.872 264.525L455.855 255.042Z"
      fill="#B28B67"
    />
    <path
      d="M199.297 260.907C194.438 262.528 189.204 260.001 187.587 255.134C185.966 250.271 188.66 245.31 193.528 243.694L252.54 222.024C257.403 220.403 262.655 223.04 264.267 227.903C265.892 232.766 263.264 238.022 258.392 239.639L199.297 260.907Z"
      fill="#B28B67"
    />
    <path
      d="M283.75 216.839C283.75 216.839 273.37 209.516 261.134 213.915C250.516 217.736 239.138 225.033 239.138 225.033L248.36 243.934C248.36 243.934 281.616 233.143 283.75 216.839Z"
      fill="#FEB760"
    />
    <path
      d="M128.491 267.601C123.416 268.268 121.424 258.794 126.499 258.127L193.488 244.028C198.568 243.361 203.228 246.74 203.89 251.824C204.557 256.903 200.975 261.36 195.895 262.031L128.491 267.601Z"
      fill="#B28B67"
    />
    <path
      d="M126.502 258.125C117.73 259.433 112.328 260.148 103.476 261.778C98.6039 262.679 95.3222 262.361 95.6181 265.267C95.8257 267.458 101.921 266.456 104.116 266.208C112.301 265.259 112.359 265.502 112.434 266.367C112.509 267.246 107.814 267.723 105.755 269.274C104.479 270.245 105.331 273.099 109.324 272.251C118.432 270.321 128.494 267.6 128.494 267.6L126.502 258.125Z"
      fill="#B28B67"
    />
    <path
      d="M329.173 249.061C328.095 237.436 323.881 232.727 320.939 226.429C316.032 215.916 319.31 211.442 315.608 209.684C314.296 209.052 307.662 208.548 305.621 208.557C301.964 208.579 288.934 208.685 285.294 208.623C281.681 208.557 268.907 209.56 261.787 216.172C257.202 220.439 263.655 265.453 265.356 277.216C273.319 332.172 251.212 351.133 254.15 360.798C258.88 376.381 326.012 352.014 327.328 349.505C329.055 346.205 323.28 289.734 324.53 276.593C325.392 267.538 330.494 263.28 329.173 249.061Z"
      fill="#FF9B21"
    />
    <path
      d="M386.265 559.775C386.265 559.775 386.578 566.074 395.412 568.37C401.574 569.974 413.257 570.729 418.584 571.626C422.515 572.284 429.808 571.935 431.146 568.026C432.259 564.74 430.342 560.879 423.69 559.929C417.533 559.064 413.063 557.615 410.156 556.86C404.03 555.269 403.306 552.769 403.306 552.769L404.083 542.429H387.431L386.878 551.369L386.265 559.775Z"
      fill="#B28B67"
    />
    <path
      d="M143.749 538.143C143.749 538.143 140.534 543.563 146.612 550.365C150.87 555.113 160.168 562.216 164.112 565.909C167.023 568.647 173.282 572.384 176.546 569.871C179.302 567.75 179.841 563.47 174.823 559C170.194 554.866 167.266 551.187 165.274 548.947C161.046 544.23 161.833 541.752 161.833 541.752L168.215 533.571L154.337 524.349L148.926 531.478L143.749 538.143Z"
      fill="#B28B67"
    />
    <path
      d="M388.511 427.552C388.511 439.814 385.353 524.762 385.353 544.352C385.353 544.352 387.217 546.918 396.757 546.918C404.116 546.918 406.974 544.352 406.974 544.352C406.974 544.352 422.252 464.465 422.557 427.552C422.606 422.141 414.532 413.409 399.218 415.334C387.005 416.872 388.511 425.931 388.511 427.552Z"
      fill="#17282F"
    />
    <path
      d="M420.074 421.724C398.055 399.922 362.193 365.283 327.206 348.697L289.087 348.795C289.087 348.795 275.191 370.42 307.077 400.288C331.8 423.447 377.189 436.919 389.764 445.55C389.764 445.55 399.005 459.768 413.74 445.59C423.784 435.925 424.089 425.695 420.074 421.724Z"
      fill="#17282F"
    />
    <path
      d="M219.824 422.143C219.824 422.143 225.655 420.937 236.87 428.398C248.963 436.445 247.921 441.87 247.921 441.87L167.796 537.441C167.796 537.441 164.801 538.762 157.381 533.828C149.823 528.801 150.375 525.855 150.375 525.855C168.595 496.791 207.452 441.189 219.824 422.143Z"
      fill="#223A43"
    />
    <path
      d="M257.314 348.15C239.246 369.38 241.636 394.159 218.181 424.654C218.181 424.654 212.21 441.116 223.632 447.446C237.824 455.33 244.719 446.139 244.719 446.139C244.719 446.139 261.99 432.274 273.514 419.853C302.891 388.214 310.921 371.389 314.786 348.725L257.314 348.15Z"
      fill="#223A43"
    />
    <path
      opacity="0.5"
      d="M278.994 374.486C263.335 396.771 230.661 442.243 225.24 445.857C219.82 449.471 184.737 498.557 167.873 522.648"
      stroke="white"
      stroke-width="0.903424"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M300.523 192.622C307.74 196.972 314.83 199.074 318.159 198.216C326.504 196.065 327.271 165.536 320.814 153.5C314.357 141.465 281.162 136.219 279.489 160.065C278.909 168.341 282.384 175.727 287.518 181.754L278.311 224.67H305.053L300.523 192.622Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M323.342 158.894C323.078 157.334 322.43 155.602 321.908 154.126C321.234 152.223 320.215 150.508 319.093 148.891C316.982 145.847 314.449 143.098 311.652 140.817C306.543 136.65 300.04 134.246 293.645 135.026C290.416 135.421 287.282 136.652 284.614 138.702C282.204 140.554 279.885 143.281 276.764 143.477C273.368 143.69 270.255 140.692 267.531 138.841C264.46 136.754 261.194 135.233 257.604 134.642C251.592 133.652 245.943 135.684 241.8 140.617C237.39 145.866 234.185 153.564 237.081 160.52C237.619 161.813 238.311 162.903 239.305 163.806C240.217 164.635 241.609 165.516 241.978 166.84C242.371 168.247 241.198 170.062 240.743 171.332C240.092 173.151 239.557 175.06 239.747 177.037C240.06 180.284 242.1 183.384 244.255 185.518C246.447 187.689 249.146 188.935 252.028 189.509C253.953 189.892 255.92 190.103 257.876 189.96C258.847 189.888 259.704 189.618 260.644 189.404C261.559 189.195 262.071 189.438 262.847 189.94C266.453 192.271 270.33 193.173 274.493 192.826C278.007 192.534 282.058 191.682 284.924 189.274C288.108 186.601 288.044 182.913 287.295 178.974C288.089 179.367 290.285 179.61 288.972 178.043C288.453 177.422 287.471 177.169 286.804 176.804C286.027 176.379 285.239 175.829 284.636 175.135C282.201 172.335 284.66 166.635 287.72 165.695C292.267 164.3 293.285 170.73 296.531 172.393C298.397 173.35 300.349 172.043 301.748 170.708C303.62 168.92 304.897 166.551 305.999 164.141C306.907 162.156 307.752 160.143 308.677 158.167C309.109 157.245 310.605 154.637 309.854 153.594C313.11 152.945 316.738 154.298 319.542 156.073C320.702 156.807 321.585 157.616 322.204 158.911C322.336 159.188 322.625 160.013 322.987 160.047C323.661 160.11 323.409 159.286 323.342 158.894Z"
      fill="#070707"
    />
    <path
      d="M383.066 257.826C387.929 259.447 393.159 256.911 394.78 252.048C396.392 247.185 393.711 242.229 388.835 240.604L329.828 218.934C324.964 217.322 319.708 219.95 318.087 224.808C316.479 229.676 319.103 234.932 323.966 236.549L383.066 257.826Z"
      fill="#B28B67"
    />
    <path
      d="M298.624 213.745C298.624 213.745 308.991 206.426 321.239 210.825C331.849 214.641 343.232 221.947 343.232 221.947L334.018 240.848C334.018 240.848 300.766 230.052 298.624 213.745Z"
      fill="#C86E01"
    />
    <path
      d="M453.877 264.527C458.957 265.19 460.953 255.711 455.86 255.044L388.889 240.954C383.801 240.278 379.149 243.661 378.487 248.745C377.807 253.825 381.402 258.286 386.473 258.949L453.877 264.527Z"
      fill="#B28B67"
    />
    <path
      d="M455.855 255.042C464.65 256.345 470.038 257.073 478.895 258.699C483.758 259.596 487.053 259.282 486.766 262.193C486.545 264.37 480.445 263.381 478.259 263.129C470.065 262.184 470.008 262.427 469.937 263.288C469.857 264.167 474.548 264.644 476.615 266.195C477.901 267.158 477.044 270.011 473.051 269.172C463.93 267.241 453.872 264.525 453.872 264.525L455.855 255.042Z"
      fill="#B28B67"
    />
    <path
      d="M199.297 260.907C194.438 262.528 189.204 260.001 187.587 255.134C185.966 250.271 188.66 245.31 193.528 243.694L252.54 222.024C257.403 220.403 262.655 223.04 264.267 227.903C265.892 232.766 263.264 238.022 258.392 239.639L199.297 260.907Z"
      fill="#B28B67"
    />
    <path
      d="M283.75 216.839C283.75 216.839 273.37 209.516 261.134 213.915C250.516 217.736 239.138 225.033 239.138 225.033L248.36 243.934C248.36 243.934 281.616 233.143 283.75 216.839Z"
      fill="#FEB760"
    />
    <path
      d="M128.491 267.601C123.416 268.268 121.424 258.794 126.499 258.127L193.488 244.028C198.568 243.361 203.228 246.74 203.89 251.824C204.557 256.903 200.975 261.36 195.895 262.031L128.491 267.601Z"
      fill="#B28B67"
    />
    <path
      d="M126.502 258.125C117.73 259.433 112.328 260.148 103.476 261.778C98.6039 262.679 95.3222 262.361 95.6181 265.267C95.8257 267.458 101.921 266.456 104.116 266.208C112.301 265.259 112.359 265.502 112.434 266.367C112.509 267.246 107.814 267.723 105.755 269.274C104.479 270.245 105.331 273.099 109.324 272.251C118.432 270.321 128.494 267.6 128.494 267.6L126.502 258.125Z"
      fill="#B28B67"
    />
    <path
      d="M329.173 249.061C328.095 237.436 323.881 232.727 320.939 226.429C316.032 215.916 319.31 211.442 315.608 209.684C314.296 209.052 307.662 208.548 305.621 208.557C301.964 208.579 288.934 208.685 285.294 208.623C281.681 208.557 268.907 209.56 261.787 216.172C257.202 220.439 263.655 265.453 265.356 277.216C273.319 332.172 251.212 351.133 254.15 360.798C258.88 376.381 326.012 352.014 327.328 349.505C328.275 347.695 343.642 341.954 344.5 326.5C345.498 308.515 323.966 282.525 324.53 276.593C325.392 267.538 330.494 263.28 329.173 249.061Z"
      fill="#FF9B21"
    />
    <path
      d="M386.265 559.775C386.265 559.775 386.578 566.074 395.412 568.37C401.574 569.974 413.257 570.729 418.584 571.626C422.515 572.284 429.808 571.935 431.146 568.026C432.259 564.74 430.342 560.879 423.69 559.929C417.533 559.064 413.063 557.615 410.156 556.86C404.03 555.269 403.306 552.769 403.306 552.769L404.083 542.429H387.431L386.878 551.369L386.265 559.775Z"
      fill="#B28B67"
    />
    <path
      d="M143.749 538.143C143.749 538.143 140.534 543.563 146.612 550.365C150.87 555.113 160.168 562.216 164.112 565.909C167.023 568.647 173.282 572.384 176.546 569.871C179.302 567.75 179.841 563.47 174.823 559C170.194 554.866 167.266 551.187 165.274 548.947C161.046 544.23 161.833 541.752 161.833 541.752L168.215 533.571L154.337 524.349L148.926 531.478L143.749 538.143Z"
      fill="#B28B67"
    />
    <path
      d="M388.511 427.552C388.511 439.814 385.353 524.762 385.353 544.352C385.353 544.352 387.217 546.918 396.757 546.918C404.116 546.918 406.974 544.352 406.974 544.352C406.974 544.352 422.252 464.465 422.557 427.552C422.606 422.141 414.532 413.409 399.218 415.334C387.005 416.872 388.511 425.931 388.511 427.552Z"
      fill="#17282F"
    />
    <path
      d="M420.074 421.724C398.055 399.922 362.193 365.283 327.206 348.697L289.087 348.795C289.087 348.795 275.191 370.42 307.077 400.288C331.8 423.447 377.189 436.919 389.764 445.55C389.764 445.55 399.005 459.768 413.74 445.59C423.784 435.925 424.089 425.695 420.074 421.724Z"
      fill="#17282F"
    />
    <path
      d="M219.824 422.143C219.824 422.143 225.655 420.937 236.87 428.398C248.963 436.445 247.921 441.87 247.921 441.87L167.796 537.441C167.796 537.441 164.801 538.762 157.381 533.828C149.823 528.801 150.375 525.855 150.375 525.855C168.595 496.791 207.452 441.189 219.824 422.143Z"
      fill="#223A43"
    />
    <path
      d="M257.314 348.15C239.246 369.38 241.636 394.159 218.181 424.654C218.181 424.654 212.21 441.116 223.632 447.446C237.824 455.33 244.719 446.139 244.719 446.139C244.719 446.139 261.99 432.274 273.514 419.853C302.891 388.214 310.921 371.389 314.786 348.725L257.314 348.15Z"
      fill="#223A43"
    />
    <path
      opacity="0.5"
      d="M278.994 374.486C263.335 396.771 230.661 442.243 225.24 445.857C219.82 449.471 184.737 498.557 167.873 522.648"
      stroke="white"
      stroke-width="0.903424"
    />
    <path
      d="M327 322C327 322 328.203 324.093 329.133 323.997C329.972 323.91 331 322 331 322"
      stroke="#DB841B"
    />
  </svg>
</template>

<script>
export default {
  name: "PregnancyExerciseOnceOverlay"
};
</script>
