import {
  addUserEquipment,
  deleteUserEquipment,
  getUserEquipment,
  updateUserEquipment
} from "@/api/userApi.js";
import i18n from "@/plugins/i18n.js";
import sentry from "@/sentry.js";
import { assessmentLocations } from "@/constants/constants.js";
import { sendEvent } from "@/services/analytics/analytics.js";

const LocationKey = {
  HOME: "homeEquipment",
  OFFICE: "officeEquipment"
};

const getInitialState = () => ({
  homeEquipment: null,
  officeEquipment: null,
  isSavingEquipment: false,
  isDeletingEquipment: false,
  isUpdatingEquipment: false,
  equipmentDialogOpen: false,
  editingEquipment: null,
  equipmentDialogLocation: assessmentLocations.office,
  deleteEquipmentConfirmDialogOpen: false,
  equipmentToDelete: null,
  errors: {
    homeEquipment: null,
    officeEquipment: null
  },
  loadingStates: {
    homeEquipment: false,
    officeEquipment: false
  },
  currentControllers: {
    homeEquipment: null,
    officeEquipment: null
  }
});

export const equipmentStore = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    SET_HOME_EQUIPMENT(state, equipment) {
      state.homeEquipment = equipment;
    },
    SET_OFFICE_EQUIPMENT(state, equipment) {
      state.officeEquipment = equipment;
    },
    ADD_EQUIPMENT(state, { equipment, location }) {
      if (location === assessmentLocations.home) {
        state.homeEquipment = [equipment, ...(state.homeEquipment || [])];
      } else if (location === assessmentLocations.office) {
        state.officeEquipment = [equipment, ...(state.officeEquipment || [])];
      }
    },
    UPDATE_EQUIPMENT(state, { equipment, location, oldLocation }) {
      const updateEquipmentInList = list => {
        if (!list) return list;
        return list.map(item => {
          if (item.id === equipment.id) {
            return { ...item, ...equipment };
          }
          return item;
        });
      };

      const removeFromLocation = locationName => {
        if (locationName === assessmentLocations.home) {
          state.homeEquipment = state.homeEquipment.filter(
            item => item.id !== equipment.id
          );
        } else if (locationName === assessmentLocations.office) {
          state.officeEquipment = state.officeEquipment.filter(
            item => item.id !== equipment.id
          );
        }
      };

      const addToLocation = locationName => {
        if (locationName === assessmentLocations.home) {
          state.homeEquipment = [equipment, ...(state.homeEquipment || [])];
        } else if (locationName === assessmentLocations.office) {
          state.officeEquipment = [equipment, ...(state.officeEquipment || [])];
        }
      };

      if (oldLocation !== location) {
        removeFromLocation(oldLocation);
        addToLocation(location);
      } else {
        if (location === assessmentLocations.home) {
          state.homeEquipment = updateEquipmentInList(state.homeEquipment);
        } else if (location === assessmentLocations.office) {
          state.officeEquipment = updateEquipmentInList(state.officeEquipment);
        }
      }
    },
    DELETE_EQUIPMENT(state, { equipmentId, location }) {
      if (location === assessmentLocations.home) {
        state.homeEquipment = state.homeEquipment.filter(
          item => item.id !== equipmentId
        );
      } else if (location === assessmentLocations.office) {
        state.officeEquipment = state.officeEquipment.filter(
          item => item.id !== equipmentId
        );
      }
    },
    SET_SAVING_EQUIPMENT(state, isSaving) {
      state.isSavingEquipment = isSaving;
    },
    SET_DELETING_EQUIPMENT(state, isDeleting) {
      state.isDeletingEquipment = isDeleting;
    },
    SET_UPDATING_EQUIPMENT(state, isUpdating) {
      state.isUpdatingEquipment = isUpdating;
    },
    SET_EQUIPMENT_DIALOG_OPEN(state, isOpen) {
      state.equipmentDialogOpen = isOpen;
    },
    SET_EDITING_EQUIPMENT(state, equipment) {
      state.editingEquipment = equipment;
    },
    SET_EQUIPMENT_DIALOG_LOCATION(state, location) {
      state.equipmentDialogLocation = location;
    },
    SET_DELETE_EQUIPMENT_CONFIRM_DIALOG_OPEN(state, isOpen) {
      state.deleteEquipmentConfirmDialogOpen = isOpen;
    },
    SET_EQUIPMENT_TO_DELETE(state, equipment) {
      state.equipmentToDelete = equipment;
    },
    SET_LOADING(state, { key, isLoading }) {
      state.loadingStates[key] = isLoading;
    },
    SET_ERROR(state, { key, error }) {
      state.errors[key] = error;
    },
    SET_CONTROLLER(state, { key, controller }) {
      state.currentControllers[key] = controller;
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    }
  },
  actions: {
    async fetchEquipment({ commit }, { key, userId }) {
      const controller = new AbortController();
      const location =
        key === LocationKey.HOME
          ? assessmentLocations.home
          : assessmentLocations.office;

      commit("SET_CONTROLLER", { key, controller });
      commit("SET_LOADING", { key, isLoading: true });
      commit("SET_ERROR", { key, error: null });

      try {
        const data = await getUserEquipment(
          userId,
          { location },
          controller.signal
        );
        commit(
          key === LocationKey.HOME
            ? "SET_HOME_EQUIPMENT"
            : "SET_OFFICE_EQUIPMENT",
          data
        );
      } catch (error) {
        if (error.name !== "AbortError") {
          commit("SET_ERROR", { key, error });
        }
      } finally {
        commit("SET_CONTROLLER", { key, controller: null });
        commit("SET_LOADING", { key, isLoading: false });
      }
    },
    async addEquipment({ commit, dispatch, state }, { userId, equipment }) {
      if (state.isSavingEquipment) return;

      commit("SET_SAVING_EQUIPMENT", true);

      try {
        const newEquipment = await addUserEquipment(userId, equipment);

        commit("ADD_EQUIPMENT", {
          equipment: newEquipment,
          location: equipment.location
        });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.adding.success"),
            type: "success"
          },
          { root: true }
        );
      } catch (error) {
        commit("SET_ERROR", {
          key:
            equipment.location === "home"
              ? LocationKey.HOME
              : LocationKey.OFFICE,
          error
        });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.adding.error"),
            type: "error"
          },
          { root: true }
        );

        sentry.captureException(
          "Error adding equipment in User Profile",
          error
        );
      } finally {
        commit("SET_SAVING_EQUIPMENT", false);
      }
    },
    async deleteEquipment(
      { commit, dispatch, state },
      { equipmentId, location }
    ) {
      if (state.isDeletingEquipment) return;

      commit("SET_DELETING_EQUIPMENT", true);

      try {
        await deleteUserEquipment(equipmentId);

        commit("DELETE_EQUIPMENT", { equipmentId, location });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.deleting.success"),
            type: "success"
          },
          { root: true }
        );
      } catch (error) {
        commit("SET_ERROR", {
          key: location === "home" ? LocationKey.HOME : LocationKey.OFFICE,
          error
        });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.deleting.error"),
            type: "error"
          },
          { root: true }
        );

        sentry.captureException(
          "Error deleting equipment in User Profile",
          error
        );
      } finally {
        commit("SET_DELETING_EQUIPMENT", false);
      }
    },
    async updateEquipment(
      { commit, dispatch, state },
      { equipmentId, equipment }
    ) {
      if (state.isUpdatingEquipment) return;

      commit("SET_UPDATING_EQUIPMENT", true);
      const newLocation = equipment.location;
      const originalEquipment = state.editingEquipment;
      const oldLocation = originalEquipment.location;

      try {
        await updateUserEquipment(equipmentId, equipment);

        const updatedEquipment = {
          ...originalEquipment,
          ...equipment,
          location: newLocation
        };

        commit("UPDATE_EQUIPMENT", {
          equipment: updatedEquipment,
          location: newLocation,
          oldLocation
        });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.updating.success"),
            type: "success"
          },
          { root: true }
        );
      } catch (error) {
        commit("SET_ERROR", {
          key: newLocation === "home" ? LocationKey.HOME : LocationKey.OFFICE,
          error
        });

        dispatch(
          "userProfile/showSnackbar",
          {
            message: i18n.t("userProfile.equipment.snackbar.updating.error"),
            type: "error"
          },
          { root: true }
        );

        sentry.captureException(
          "Error updating equipment in User Profile",
          error
        );
      } finally {
        commit("SET_UPDATING_EQUIPMENT", false);
      }
    },
    openEquipmentDialog(
      { commit, rootState, dispatch },
      { equipment = null, location = "office" } = {}
    ) {
      commit("SET_EDITING_EQUIPMENT", equipment);
      commit("SET_EQUIPMENT_DIALOG_LOCATION", location);
      commit("SET_EQUIPMENT_DIALOG_OPEN", true);

      const action = equipment ? "edit_clicked" : "add_clicked";
      dispatch("trackEquipmentAction", {
        action,
        userId: rootState.userProfile.id
      });
    },
    closeEquipmentDialog({ commit }) {
      commit("SET_EQUIPMENT_DIALOG_OPEN", false);
      commit("SET_EDITING_EQUIPMENT", null);
    },
    trackEquipmentAction({ rootState, rootGetters }, { action, userId }) {
      sendEvent("user_profile_equipment_action", {
        end_user: userId || rootState.userProfile.id,
        admin: rootGetters.userId,
        action: action
      });
    },
    equipmentActionCompleted({ commit, dispatch, rootState }, action) {
      commit("SET_EQUIPMENT_DIALOG_OPEN", false);
      commit("SET_EDITING_EQUIPMENT", null);

      dispatch("trackEquipmentAction", {
        action,
        userId: rootState.userProfile.id
      });
    },
    openDeleteEquipmentConfirmDialog({ commit }, equipment) {
      commit("SET_EQUIPMENT_TO_DELETE", equipment);
      commit("SET_DELETE_EQUIPMENT_CONFIRM_DIALOG_OPEN", true);
    },
    closeDeleteConfirmDialog({ commit }) {
      commit("SET_DELETE_EQUIPMENT_CONFIRM_DIALOG_OPEN", false);
      commit("SET_EQUIPMENT_TO_DELETE", null);
    },
    async confirmDeleteEquipment({ dispatch, state, rootState }) {
      try {
        await dispatch("deleteEquipment", {
          equipmentId: state.equipmentToDelete.id,
          location: state.equipmentToDelete.location
        });

        dispatch("trackEquipmentAction", {
          action: "delete_confirmed",
          userId: rootState.userProfile.id
        });
      } catch (error) {
        sentry.captureException(error);
      } finally {
        dispatch("closeDeleteConfirmDialog");
      }
    },
    reset({ state, commit }) {
      Object.values(state.currentControllers).forEach(controller => {
        if (controller) {
          controller.abort();
        }
      });
      commit("RESET_STATE");
    }
  },
  getters: {
    homeEquipment: state => state.homeEquipment,
    officeEquipment: state => state.officeEquipment,
    isDeletingEquipment: state => state.isDeletingEquipment,
    isSavingEquipment: state => state.isSavingEquipment,
    isUpdatingEquipment: state => state.isUpdatingEquipment,
    equipmentDialogOpen: state => state.equipmentDialogOpen,
    editingEquipment: state => state.editingEquipment,
    equipmentDialogLocation: state => state.equipmentDialogLocation,
    deleteEquipmentConfirmDialogOpen: state =>
      state.deleteEquipmentConfirmDialogOpen,
    equipmentToDelete: state => state.equipmentToDelete,
    loadingStates: state => state.loadingStates
  }
};
