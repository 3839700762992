export function calculatePercentageStanding(results) {
  if (
    results?.setup?.standingdesk &&
    results?.setup?.hours &&
    results?.setup?.hoursstandingdesk
  ) {
    return Math.round(
      (results.setup.hoursstandingdesk / results.setup.hours) * 100
    );
  }
  return 0;
}
