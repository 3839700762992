const state = {
  show: false,
  message: "",
  type: "success",
  undoAction: null,
  timeout: 3000
};

const mutations = {
  SET_SNACKBAR(
    state,
    { show, message, type = "success", undoAction = null, timeout = 3000 }
  ) {
    state.show = show;
    state.message = message;
    state.type = type;
    state.undoAction = undoAction;
    state.timeout = timeout;
  }
};

const actions = {
  showSnackbar(
    { commit },
    { message, type = "success", undoAction = null, timeout = null }
  ) {
    // If undo action is provided, set a longer default timeout
    const snackbarTimeout = timeout || (undoAction ? 8000 : 3000);

    commit("SET_SNACKBAR", {
      show: true,
      message,
      type,
      undoAction,
      timeout: snackbarTimeout
    });
  },

  hideSnackbar({ commit }) {
    commit("SET_SNACKBAR", {
      show: false,
      message: "",
      type: "success",
      undoAction: null,
      timeout: 3000
    });
  }
};

const getters = {
  snackbar: state => state
};

export const snackbarStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
