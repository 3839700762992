export const Theme = {
  WELCOME: "welcome",
  GET_STARTED: "getStarted",
  GET_STARTED_OUTRO: "getStartedOutro",
  PAIN: "pain",
  PAIN_OUTRO: "painOutro",
  CARRYING: "carrying",
  CARRYING_OUTRO: "carryingOutro",
  LIFESTYLE: "lifestyle",
  LIFESTYLE_OUTRO: "lifestyleOutro",
  RETURN_TO_WORK: "returnToWork",
  REPORT: "report"
};

export const AssessmentSteps = {
  Welcome: {
    THEME: Theme.WELCOME,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    LANDING_PAGE: {
      backgroundColor: "#FFFCEE",
      blobColor: "#F6CF17",
      calloutColor: "#F6EBDA"
    },
    BACKGROUND: {
      backgroundColor: "#FFFCEE"
    }
  },
  GetStarted: {
    THEME: Theme.GET_STARTED,
    STEPPER: true,
    STEPPER_GROUP: Theme.GET_STARTED,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #F8F5F0 66vh, #EEE8DF 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  GetStartedOutro: {
    THEME: Theme.GET_STARTED_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.GET_STARTED,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F8F5F0"
    }
  },
  Pain: {
    THEME: Theme.PAIN,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 550px, #DBEEEF 550px)"
    }
  },
  PainOutro: {
    THEME: Theme.PAIN_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.PAIN,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F3F9FA"
    }
  },
  Carrying: {
    THEME: Theme.CARRYING,
    STEPPER: true,
    STEPPER_GROUP: Theme.CARRYING,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #F7FBF3 66vh, #DEE6D7 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  CarryingOutro: {
    THEME: Theme.CARRYING_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.CARRYING,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F7FBF3"
    }
  },
  Lifestyle: {
    THEME: Theme.LIFESTYLE,
    STEPPER: true,
    STEPPER_GROUP: Theme.LIFESTYLE,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #F8F5F0 66vh, #EEE8DF 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  LifestyleOutro: {
    THEME: Theme.LIFESTYLE_OUTRO,
    STEPPER: true,
    STEPPER_GROUP: Theme.LIFESTYLE,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false,
    OUTRO: true,
    BACKGROUND: {
      backgroundColor: "#F8F5F0"
    }
  },
  ReturnToWork: {
    THEME: Theme.RETURN_TO_WORK,
    STEPPER: true,
    STEPPER_GROUP: Theme.RETURN_TO_WORK,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true,
    DYNAMIC_IMAGE: true,
    BACKGROUND: {
      backgroundImage: "linear-gradient(to bottom, #FDF9E6 66vh, #F6EDBE 66vh)"
    },
    DYNAMIC_IMAGE_CSS: {
      bottom: 0
    }
  },
  Report: {
    THEME: Theme.REPORT,
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false,
    BACKGROUND: {
      backgroundColor: "white"
    }
  }
};

export const STEP_ORDER = Object.keys(AssessmentSteps);

export const THEME_ORDER = STEP_ORDER.map(step => AssessmentSteps[step].THEME);

export const getStepByIndex = index => {
  const steps = Object.values(AssessmentSteps);
  return index >= 0 && index < steps.length ? steps[index] : null;
};
