<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <report-section-card
        imagePath="/images/illustrations/menopause-symptoms.svg"
        :fact="sectionTitle"
        :assessmentType="$options.assessmentTypes.menstruationAssessment"
        ><template #text>
          <p v-for="p in content" :key="p">{{ p }}</p></template
        ></report-section-card
      >
      <recommendation-container
        class="mt-10"
        :title="
          $t('menstruationAssessment.report.recommendations.symptoms.title')
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="symptomsRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menstruation/report/recommendations/recommendation-service.js";
import { assessmentTypes } from "@/constants/constants.js";
import ReportSectionCard from "@/components/assessment/common/report/ReportSectionCard.vue";

export default {
  name: "MenstruationReportSymptoms",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer,
    ReportSectionCard
  },
  data() {
    return { symptomsRecommendations: {} };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.symptomsRecommendations = getRecommendationsByType(
      RecommendationSections.SYMPTOMS,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return this.$t("menstruationAssessment.report.content.symptoms.title");
    },
    content() {
      return this.$t("menstruationAssessment.report.content.symptoms.text");
    }
  }
};
</script>
