<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M565.588 611.676H544.088L547.749 639.134C547.794 639.469 548.08 639.72 548.418 639.72H560.971C561.296 639.72 561.572 639.48 561.619 639.159L565.588 611.676Z"
      fill="#966F60"
    />
    <path
      d="M544.555 609.492H565.12L564.521 607.294C564.323 606.568 563.664 606.064 562.911 606.064H546.789C546.021 606.064 545.349 606.578 545.148 607.318L544.555 609.492Z"
      fill="#966F60"
    />
    <path
      d="M543.415 613.547H566.266C566.496 613.547 566.681 613.361 566.681 613.132V609.911C566.681 609.682 566.495 609.496 566.266 609.496H543.415C543.186 609.496 543 609.682 543 609.911V613.132C543 613.361 543.186 613.547 543.415 613.547Z"
      fill="#3B2723"
    />
    <path
      d="M546.194 627.552H563.279L563.796 624.135H545.779L546.194 627.552Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "LifestyleSleepMoreThanSeven"
};
</script>
