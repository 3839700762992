<template>
  <v-dialog v-model="show" max-width="420" persistent>
    <v-card>
      <div class="pa-6 pa-md-8">
        <h1 class="text-h5 mb-4">
          {{ $t("userProfile.equipment.deleteConfirmationDialog.title") }}
        </h1>

        <i18n
          path="userProfile.equipment.deleteConfirmationDialog.text"
          tag="p"
          class="text-body-1 mb-6"
        >
          <template #item>
            <strong>{{ equipmentToDelete.name }}</strong>
          </template>
        </i18n>

        <div
          class="d-flex flex-column-reverse flex-md-row justify-end"
          style="gap: 1rem"
        >
          <vfl-button
            variant="ghost"
            @click="cancel"
            :disabled="isDeletingEquipment"
          >
            {{ $t("buttons.cancel") }}
          </vfl-button>
          <vfl-button
            variant="danger"
            @click="confirm"
            :disabled="isDeletingEquipment"
            :loading="isDeletingEquipment"
          >
            {{ $t("buttons.delete") }}
          </vfl-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileConfirmEquipmentDeleteDialog",
  data() {
    return {
      show: true
    };
  },
  computed: {
    ...mapGetters("userProfile/equipment", [
      "isDeletingEquipment",
      "equipmentToDelete"
    ])
  },
  methods: {
    ...mapActions("userProfile/equipment", [
      "confirmDeleteEquipment",
      "closeDeleteConfirmDialog"
    ]),
    cancel() {
      this.closeDeleteConfirmDialog();
    },
    confirm() {
      this.confirmDeleteEquipment();
    }
  }
};
</script>
<style></style>
