import LifestyleRecommendations from "@/assets/json/recommendations/new-parent/LifestyleRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import Answers from "@/components/assessment/new-parent/answers.json";

const isBreastfeeding = results => {
  return (
    results.lifestyle.breastfeeding === Answers.lifestyle.breastfeeding.yes
  );
};

const breastfeedingAndLookingdown = results => {
  const expressesBreastmilk =
    results.lifestyle.expressBreastmilk !==
    Answers.lifestyle.expressBreastmilk.no;

  const looksAtPhone = results.lifestyle.expressBreastmilkActivity?.includes(
    Answers.lifestyle.expressBreastmilkActivity.scrollPhone
  );

  const looksDown =
    results.lifestyle.breastfeedingNeckPosition ===
    Answers.lifestyle.breastfeedingNeckPosition.down;

  return (
    isBreastfeeding(results) &&
    expressesBreastmilk &&
    (looksAtPhone || looksDown)
  );
};

const sleepDeprived = results => {
  const lessThanFourHoursSleep =
    results.lifestyle.totalHoursSleep ===
    Answers.lifestyle.totalHoursSleep.lessThanFour;
  return lessThanFourHoursSleep;
};

const notEnoughActivity = results => {
  const lessThanThreeHoursActivity =
    results.lifestyle.hoursActivePerWeek !==
    Answers.lifestyle.hoursActivePerWeek.moreThan3;

  return lessThanThreeHoursActivity;
};

const enoughActivity = results => {
  const moreThanThreeHoursActivity =
    results.lifestyle.hoursActivePerWeek ===
    Answers.lifestyle.hoursActivePerWeek.moreThan3;

  return moreThanThreeHoursActivity;
};

const hasBaby = results => {
  const youngerThanOne =
    results.getStarted.babyAge !== Answers.getStarted.babyAge.moreThanAYear;
  return youngerThanOne;
};

const hasToddler = results => {
  const oneOrOlder =
    results.getStarted.babyAge === Answers.getStarted.babyAge.moreThanAYear;
  return oneOrOlder;
};

const cardGroups = {
  breastfeeding: {
    titleKey:
      "newParentAssessment.recommendations.lifestyle.groups.breastfeeding",
    cards: {
      new_parent_do_neck_rolls_after_feeding: {
        showConditions: results => isBreastfeeding(results)
      },
      new_parent_roll_your_shoulders: {
        showConditions: results => isBreastfeeding(results)
      },
      new_parent_better_feeding_posture: {
        showConditions: results => isBreastfeeding(results)
      },
      new_parent_reset_your_neck: {
        showConditions: results => breastfeedingAndLookingdown(results)
      }
    }
  },
  sleepDeprivation: {
    titleKey:
      "newParentAssessment.recommendations.lifestyle.groups.sleepDeprivation",
    cards: {
      new_parent_take_a_warm_shower: {
        showConditions: results => sleepDeprived(results)
      },
      new_parent_inhale_exhale_sleep: {
        showConditions: results => sleepDeprived(results)
      },
      new_parent_try_wind_down_stretches: {
        showConditions: results => sleepDeprived(results)
      }
    }
  },
  boostingMovement: {
    titleKey:
      "newParentAssessment.recommendations.lifestyle.groups.boostingMovement",
    cards: {
      new_parent_power_stroll_for_cardio: {
        showConditions: results => notEnoughActivity(results)
      },
      new_parent_squat_with_your_baby: {
        showConditions: results =>
          notEnoughActivity(results) && hasBaby(results)
      },
      new_parent_bicep_curls: {
        showConditions: results => notEnoughActivity(results)
      },
      new_parent_regular_micro_walks: {
        showConditions: results => notEnoughActivity(results)
      },
      new_parent_squat_down_with_your_toddler: {
        showConditions: results =>
          notEnoughActivity(results) && hasToddler(results)
      },
      new_parent_regular_micro_walks_active: {
        showConditions: results => enoughActivity(results)
      },
      new_parent_mini_forward_fold: {
        showConditions: results => enoughActivity(results)
      },
      new_parent_play_mat_planks: {
        showConditions: results => enoughActivity(results)
      }
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, LifestyleRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getLifestyleRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
