<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M343.819 603.482C335.8 602.967 329.063 606.209 328.539 614.227C328.028 622.244 334.291 626.328 342.311 626.85L365.31 629.749C373.329 630.263 379.305 626.49 379.823 618.472C380.347 610.448 377.902 601.914 369.886 601.393L343.819 603.482Z"
      fill="#E7BE83"
    />
    <path
      d="M308.8 620.065C303.381 623.211 297.753 625.584 300.952 632.278C303.666 637.928 311.393 636.163 318.206 633.273C322.48 631.468 336.679 627.795 341.311 625.938C347.134 623.607 348.255 607.073 348.255 607.073C345.202 601.598 336.337 603.543 330.737 606.366C320.716 611.414 313.968 617.064 308.8 620.065Z"
      fill="#E7BE83"
    />
    <path
      d="M310.954 633.208C310.954 633.208 311.829 641.613 305.347 641.926C298.866 642.23 295.596 635.167 295.596 635.167C295.596 635.167 292.265 634.245 289.379 631.17C286.479 628.1 285.847 628.187 286.029 625.076C286.085 624.078 287.289 621.422 288.519 619.701C289.842 617.861 290.222 617.269 291.468 616.615C293.495 615.558 293.326 616.334 299.197 621.487C305.063 626.644 310.954 633.208 310.954 633.208Z"
      fill="#E7BE83"
    />
    <path
      d="M386.399 564.735C392.755 555.497 382.821 551.437 375.432 552.245C375.432 552.245 360.978 555.542 353.696 553.515C344.692 550.996 325.088 538.135 325.088 538.135L322.213 547.476C322.213 547.476 339.148 562.884 347.709 566.006C355.253 568.752 375.022 568.606 375.022 568.606C378.808 569.776 384.143 567.998 386.399 564.735Z"
      fill="#E7BE83"
    />
    <path
      d="M326.218 542.737C324.986 540.257 327.388 537.008 329.574 535.911C330.341 535.528 328.783 532.553 329.021 530.259C329.268 527.847 331.646 528.023 332.215 528.414C332.651 528.724 333.756 534.617 334.174 536.568C336.048 545.497 333.182 547.785 331.102 547.623C328.652 547.439 327.476 545.214 326.218 542.737Z"
      fill="#C79F7B"
    />
    <path
      d="M331.225 549.671L334.477 544.43L329.974 536.796L328.816 535.966L321.836 532.288C321.836 532.288 317.327 529.179 313.997 533.692C311.811 536.645 313.759 534.013 313.759 534.013C310.779 538.051 315.041 541.683 315.041 541.683L323.314 549.216C325.761 551.094 329.315 552.26 331.225 549.671Z"
      fill="#C79F7B"
    />
    <path
      d="M406.325 626.86C401.521 628.108 369.106 621.659 361.186 617.206C347.999 603.978 371.02 550.862 381.543 549.819C381.192 552.97 378.727 561.104 386.169 558.974C393.612 556.841 398.639 551.268 399.328 551.175C403.898 554.936 408.077 557.972 409.668 568.302C411.519 580.268 406.325 626.86 406.325 626.86Z"
      fill="#F1CC97"
    />
    <path
      d="M405.282 561.574C402.993 558.583 398.123 556.525 394.488 557.539C394.488 557.539 380.547 561.666 373.652 562.848C366.149 564.129 341.577 563.127 341.577 563.127L338.908 574.919C338.908 574.919 366.837 582.583 374.208 582.371C393.732 581.814 400.701 578.748 400.701 578.748C410.861 575.718 407.954 565.079 405.282 561.574Z"
      fill="#FAD5A1"
    />
    <path
      d="M344.546 569.253C341.993 567.961 342.006 563.78 343.158 561.52C343.549 560.739 340.42 559.218 339.215 557.154C337.951 554.994 340.033 553.692 340.742 553.672C341.304 553.649 345.832 557.896 347.378 559.265C352.551 563.789 351.595 566.213 350.446 568.468C349.31 570.715 347.107 570.548 344.546 569.253Z"
      fill="#C79F7B"
    />
    <path
      d="M349.79 573.67L351.212 566.522L342.964 562.176L341.528 561.893L333.361 561.53C333.361 561.53 327.777 560.583 326.656 566.263C325.891 569.994 326.56 566.67 326.56 566.67C325.541 571.762 331.128 573.303 331.128 573.303L342.17 576.722C345.29 577.399 349.12 576.938 349.79 573.67Z"
      fill="#C79F7B"
    />
    <path
      d="M362.914 613.771C353.529 613.264 345.642 616.433 345.035 624.279C344.43 632.118 351.759 636.109 361.144 636.612C361.144 636.612 378.96 641.897 387.167 641.714C401.471 641.403 405.393 634.818 406 626.979C406 626.979 401.226 614.737 391.846 614.232L362.914 613.771Z"
      fill="#FAD5A1"
    />
    <path
      d="M326.632 642.143C329.345 647.798 337.076 646.032 343.882 643.146C348.158 641.333 368.379 637.301 384.556 641.09C390.665 642.517 371.461 618.123 371.461 618.123C368.4 612.648 348.412 614.812 343.601 618.836C340.843 621.137 323.429 635.454 326.632 642.143Z"
      fill="#FAD5A1"
    />
    <path
      d="M336.635 643.08C336.635 643.08 337.505 651.488 331.025 651.79C324.543 652.098 317.948 644.111 315.057 641.04C312.156 637.97 311.528 638.051 311.703 634.943C311.767 633.948 313.408 631.13 314.639 629.411C315.957 627.563 315.901 627.131 317.147 626.481C319.176 625.423 319.007 626.202 324.88 631.359C330.746 636.513 336.635 643.08 336.635 643.08Z"
      fill="#FAD5A1"
    />
    <path
      d="M402 584.719C401.477 584.719 400.977 585.179 401.001 585.719C401.025 586.26 401.44 586.719 402 586.719C402.523 586.719 403.023 586.259 402.999 585.719C402.975 585.177 402.56 584.719 402 584.719Z"
      fill="white"
    />
    <path
      d="M401 562C400.477 562 399.977 562.46 400.001 563C400.025 563.542 400.44 564 401 564C401.523 564 402.023 563.54 401.999 563C401.975 562.458 401.56 562 401 562Z"
      fill="white"
    />
    <path
      d="M397 571C396.477 571 395.977 571.46 396.001 572C396.025 572.542 396.44 573 397 573C397.523 573 398.023 572.54 397.999 572C397.975 571.458 397.56 571 397 571Z"
      fill="white"
    />
    <path
      d="M386 564C385.477 564 384.977 564.46 385.001 565C385.025 565.542 385.44 566 386 566C386.523 566 387.023 565.54 386.999 565C386.975 564.458 386.56 564 386 564Z"
      fill="white"
    />
    <path
      d="M383 573C382.477 573 381.977 573.46 382.001 574C382.025 574.542 382.44 575 383 575C383.523 575 384.023 574.54 383.999 574C383.975 573.458 383.56 573 383 573Z"
      fill="white"
    />
    <path
      d="M376 568C375.477 568 374.977 568.46 375.001 569C375.025 569.542 375.44 570 376 570C376.523 570 377.023 569.54 376.999 569C376.975 568.458 376.56 568 376 568Z"
      fill="white"
    />
    <path
      d="M369 576C368.477 576 367.977 576.46 368.001 577C368.025 577.542 368.44 578 369 578C369.523 578 370.023 577.54 369.999 577C369.975 576.458 369.56 576 369 576Z"
      fill="white"
    />
    <path
      d="M360 567C359.477 567 358.977 567.46 359.001 568C359.025 568.542 359.44 569 360 569C360.523 569 361.023 568.54 360.999 568C360.975 567.458 360.56 567 360 567Z"
      fill="white"
    />
    <path
      d="M355 573C354.477 573 353.977 573.46 354.001 574C354.025 574.542 354.44 575 355 575C355.523 575 356.023 574.54 355.999 574C355.975 573.458 355.56 573 355 573Z"
      fill="white"
    />
    <path
      d="M395 593.719C394.477 593.719 393.977 594.179 394.001 594.719C394.025 595.26 394.44 595.719 395 595.719C395.523 595.719 396.023 595.259 395.999 594.719C395.975 594.177 395.56 593.719 395 593.719Z"
      fill="white"
    />
    <path
      d="M386 588.719C385.477 588.719 384.977 589.179 385.001 589.719C385.025 590.26 385.44 590.719 386 590.719C386.523 590.719 387.023 590.259 386.999 589.719C386.975 589.177 386.56 588.719 386 588.719Z"
      fill="white"
    />
    <path
      d="M376 599.719C375.477 599.719 374.977 600.179 375.001 600.719C375.025 601.26 375.44 601.719 376 601.719C376.523 601.719 377.023 601.259 376.999 600.719C376.975 600.177 376.56 599.719 376 599.719Z"
      fill="white"
    />
    <path
      d="M386 603.719C385.477 603.719 384.977 604.179 385.001 604.719C385.025 605.26 385.44 605.719 386 605.719C386.523 605.719 387.023 605.259 386.999 604.719C386.975 604.177 386.56 603.719 386 603.719Z"
      fill="white"
    />
    <path
      d="M386 621.719C385.477 621.719 384.977 622.179 385.001 622.719C385.025 623.26 385.44 623.719 386 623.719C386.523 623.719 387.023 623.259 386.999 622.719C386.975 622.177 386.56 621.719 386 621.719Z"
      fill="white"
    />
    <path
      d="M397 624C396.477 624 395.977 624.46 396.001 625C396.025 625.542 396.44 626 397 626C397.523 626 398.023 625.54 397.999 625C397.975 624.458 397.56 624 397 624Z"
      fill="white"
    />
    <path
      d="M394 634C393.477 634 392.977 634.46 393.001 635C393.025 635.542 393.44 636 394 636C394.523 636 395.023 635.54 394.999 635C394.975 634.458 394.56 634 394 634Z"
      fill="white"
    />
    <path
      d="M381 634C380.477 634 379.977 634.46 380.001 635C380.025 635.542 380.44 636 381 636C381.523 636 382.023 635.54 381.999 635C381.975 634.458 381.56 634 381 634Z"
      fill="white"
    />
    <path
      d="M377 626C376.477 626 375.977 626.46 376.001 627C376.025 627.542 376.44 628 377 628C377.523 628 378.023 627.54 377.999 627C377.975 626.458 377.56 626 377 626Z"
      fill="white"
    />
    <path
      d="M368 620C367.477 620 366.977 620.46 367.001 621C367.025 621.542 367.44 622 368 622C368.523 622 369.023 621.54 368.999 621C368.975 620.458 368.56 620 368 620Z"
      fill="white"
    />
    <path
      d="M363 632C362.477 632 361.977 632.46 362.001 633C362.025 633.542 362.44 634 363 634C363.523 634 364.023 633.54 363.999 633C363.975 632.458 363.56 632 363 632Z"
      fill="white"
    />
    <path
      d="M355 623C354.477 623 353.977 623.46 354.001 624C354.025 624.542 354.44 625 355 625C355.523 625 356.023 624.54 355.999 624C355.975 623.458 355.56 623 355 623Z"
      fill="white"
    />
    <path
      d="M348 632C347.477 632 346.977 632.46 347.001 633C347.025 633.542 347.44 634 348 634C348.523 634 349.023 633.54 348.999 633C348.975 632.458 348.56 632 348 632Z"
      fill="white"
    />
    <path
      d="M337 628C336.477 628 335.977 628.46 336.001 629C336.025 629.542 336.44 630 337 630C337.523 630 338.023 629.54 337.999 629C337.975 628.458 337.56 628 337 628Z"
      fill="white"
    />
    <path
      d="M334 639C333.477 639 332.977 639.46 333.001 640C333.025 640.542 333.44 641 334 641C334.523 641 335.023 640.54 334.999 640C334.975 639.458 334.56 639 334 639Z"
      fill="white"
    />
    <path
      d="M379 608.719C378.477 608.719 377.977 609.179 378.001 609.719C378.025 610.26 378.44 610.719 379 610.719C379.523 610.719 380.023 610.259 379.999 609.719C379.975 609.177 379.56 608.719 379 608.719Z"
      fill="white"
    />
    <path
      d="M368 606.719C367.477 606.719 366.977 607.179 367.001 607.719C367.025 608.26 367.44 608.719 368 608.719C368.523 608.719 369.023 608.259 368.999 607.719C368.975 607.177 368.56 606.719 368 606.719Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M351 607C350.477 607 349.977 607.46 350.001 608C350.025 608.542 350.44 609 351 609C351.523 609 352.023 608.54 351.999 608C351.975 607.458 351.56 607 351 607Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M367 557C366.477 557 365.977 557.46 366.001 558C366.025 558.542 366.44 559 367 559C367.523 559 368.023 558.54 367.999 558C367.975 557.458 367.56 557 367 557Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M355 557C354.477 557 353.977 557.46 354.001 558C354.025 558.542 354.44 559 355 559C355.523 559 356.023 558.54 355.999 558C355.975 557.458 355.56 557 355 557Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M340 549C339.477 549 338.977 549.46 339.001 550C339.025 550.542 339.44 551 340 551C340.523 551 341.023 550.54 340.999 550C340.975 549.458 340.56 549 340 549Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M346 613C345.477 613 344.977 613.46 345.001 614C345.025 614.542 345.44 615 346 615C346.523 615 347.023 614.54 346.999 614C346.975 613.458 346.56 613 346 613Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M337 608C336.477 608 335.977 608.46 336.001 609C336.025 609.542 336.44 610 337 610C337.523 610 338.023 609.54 337.999 609C337.975 608.458 337.56 608 337 608Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M335 619C334.477 619 333.977 619.46 334.001 620C334.025 620.542 334.44 621 335 621C335.523 621 336.023 620.54 335.999 620C335.975 619.458 335.56 619 335 619Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M327 614C326.477 614 325.977 614.46 326.001 615C326.025 615.542 326.44 616 327 616C327.523 616 328.023 615.54 327.999 615C327.975 614.458 327.56 614 327 614Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M326 624C325.477 624 324.977 624.46 325.001 625C325.025 625.542 325.44 626 326 626C326.523 626 327.023 625.54 326.999 625C326.975 624.458 326.56 624 326 624Z"
      fill="white"
    />
    <path
      d="M397 604.719C396.477 604.719 395.977 605.179 396.001 605.719C396.025 606.26 396.44 606.719 397 606.719C397.523 606.719 398.023 606.259 397.999 605.719C397.975 605.177 397.56 604.719 397 604.719Z"
      fill="white"
    />
    <path
      d="M400 614.719C399.477 614.719 398.977 615.179 399.001 615.719C399.025 616.26 399.44 616.719 400 616.719C400.523 616.719 401.023 616.259 400.999 615.719C400.975 615.177 400.56 614.719 400 614.719Z"
      fill="white"
    />
    <path
      d="M405 597.719C404.477 597.719 403.977 598.179 404.001 598.719C404.025 599.26 404.44 599.719 405 599.719C405.523 599.719 406.023 599.259 405.999 598.719C405.975 598.177 405.56 597.719 405 597.719Z"
      fill="white"
    />
    <path
      d="M372 590.719C371.477 590.719 370.977 591.179 371.001 591.719C371.025 592.26 371.44 592.719 372 592.719C372.523 592.719 373.023 592.259 372.999 591.719C372.975 591.177 372.56 590.719 372 590.719Z"
      fill="white"
    />
    <path
      d="M361 595.719C360.477 595.719 359.977 596.179 360.001 596.719C360.025 597.26 360.44 597.719 361 597.719C361.523 597.719 362.023 597.259 361.999 596.719C361.975 596.177 361.56 595.719 361 595.719Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M379.336 548.079C379.096 549.763 379.138 550.028 378.858 551.353C378.128 554.817 380 562.001 388.392 558.802C391.971 557.921 397.547 554.39 399.5 551.501C399.13 549.083 395.428 547.173 395.207 545.908C394.982 544.639 394.906 544.525 392.355 544.565C389.804 544.606 384.781 544.8 382.225 545.251C379.668 545.694 379.58 546.391 379.336 548.079Z"
      fill="#BF926A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M372.181 505.076C370.734 508.998 371.151 513.034 371.346 515.622C371.534 518.21 371.507 519.351 370.892 521.366C370.278 523.388 369.076 526.284 368.388 528.932C367.696 531.576 367.514 533.974 368.449 536.794C369.384 539.613 371.438 542.867 374.053 545.125C376.668 547.384 379.847 548.651 383.888 549.106C387.928 549.56 392.828 549.203 397.312 546.825C401.796 544.447 405.866 540.052 407.903 537.856C409.937 535.657 409.937 535.657 409.937 535.657C409.937 535.657 409.937 535.657 410.032 535.893C410.13 536.125 410.316 536.594 410.919 537.088C411.526 537.579 412.549 538.095 413.75 538.164C414.95 538.233 416.323 537.858 417.383 537.365C418.447 536.876 419.194 536.271 419.83 535.422C420.472 534.572 421.003 533.484 421.18 532.526C421.362 531.571 421.187 530.75 420.908 530.106C420.63 529.463 420.244 528.993 419.751 528.632C419.252 528.272 418.645 528.008 417.868 527.924C417.089 527.84 416.137 527.933 415.659 527.974C415.18 528.015 415.173 528.009 415.173 528.009C415.173 528.009 415.18 528.015 415.864 526.95C416.547 525.886 417.907 523.75 418.495 520.286C419.084 516.828 418.897 512.038 417.274 507.72C415.657 503.403 412.601 499.554 408.675 496.853C404.752 494.148 399.96 492.597 395.076 492.237C390.194 491.88 385.224 492.717 381.079 495.036C376.937 497.358 373.632 501.158 372.181 505.076Z"
      fill="#C79F7B"
    />
    <path
      d="M381.513 517.304C381.456 518.432 380.874 519.318 380.214 519.284C379.555 519.25 379.066 518.309 379.124 517.182C379.182 516.055 379.763 515.169 380.424 515.203C381.083 515.237 381.571 516.177 381.513 517.304Z"
      fill="#1D292B"
    />
    <path
      d="M397.047 519.835C396.818 520.94 396.239 521.754 395.755 521.654C395.269 521.553 395.061 520.576 395.29 519.471C395.519 518.366 396.099 517.551 396.583 517.651C397.068 517.751 397.276 518.729 397.047 519.835Z"
      fill="#1D292B"
    />
    <path
      d="M374.335 512.244C374.6 511.921 374.917 511.655 375.247 511.415C375.413 511.294 375.586 511.187 375.759 511.079C375.935 510.975 376.115 510.881 376.297 510.786C377.03 510.426 377.819 510.179 378.631 510.045C378.835 510.019 379.036 509.978 379.243 509.966C379.45 509.956 379.655 509.941 379.861 509.935C380.066 509.934 380.274 509.946 380.48 509.96L380.788 509.981L381.094 510.031C381.298 510.068 381.5 510.106 381.7 510.152C381.899 510.212 382.096 510.277 382.289 510.345C382.386 510.382 382.483 510.413 382.577 510.455C382.67 510.501 382.764 510.546 382.854 510.595C383.037 510.691 383.215 510.796 383.386 510.91C382.553 511.01 381.804 511.007 381.053 511.105L380.775 511.128L380.498 511.164C380.313 511.192 380.129 511.199 379.946 511.228C379.58 511.285 379.214 511.324 378.85 511.392C378.121 511.512 377.39 511.646 376.647 511.797C375.9 511.946 375.152 512.109 374.335 512.244Z"
      fill="#1D292B"
    />
    <path
      d="M394.63 512.633C395.157 512.431 395.688 512.425 396.202 512.484C396.716 512.552 397.215 512.712 397.679 512.951C398.145 513.185 398.565 513.526 398.912 513.927C398.997 514.031 399.077 514.138 399.153 514.247C399.227 514.358 399.288 514.482 399.349 514.6C399.377 514.661 399.407 514.721 399.433 514.783C399.457 514.846 399.477 514.912 399.496 514.978C399.535 515.109 399.563 515.244 399.58 515.382C399.051 515.175 398.653 514.956 398.236 514.762C397.825 514.568 397.443 514.364 397.046 514.162C396.652 513.959 396.261 513.74 395.86 513.499C395.459 513.252 395.056 512.993 394.63 512.633Z"
      fill="#1D292B"
    />
    <path
      d="M401.826 490.821C401.826 490.821 391.355 487.55 387.543 481.24C387.543 481.24 388.657 487.712 390.827 489.761C392.997 491.811 392.997 491.811 392.997 491.811C392.997 491.811 385.858 487.285 385.513 487.042C385.168 486.798 385.565 491.682 387.294 493.788C390.969 498.265 398.643 500.986 401.826 490.821Z"
      fill="#1D292B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M230.451 200.809C235.823 209.645 242.338 215.965 246.445 216.926C256.745 219.336 274.951 186.572 274.778 169.809C274.605 153.046 241.491 128.452 226.102 153.433C220.761 162.103 220.337 172.112 222.491 181.588L188.059 223.016L217.142 238.234L230.451 200.809Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M217.202 186.259C222.049 168.11 217.442 151.599 222.828 145.172C228.214 138.745 236.018 140.575 236.018 140.575C236.018 140.575 247.104 128.046 265.367 140.339C283.63 152.633 291.25 166.876 276.87 181.918C272.172 179.46 267.391 174.794 258.026 173.602C253.569 173.034 248.192 179.4 248.192 179.4L244.649 177.546C244.649 177.546 243.965 166.294 237.764 165.937C231.564 165.58 229.723 175.756 229.723 175.756L220.971 188.232L217.202 186.259Z"
      fill="#1D292B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M155.334 368.035L181.255 513.832L189.207 622.068L208.581 624.139L215.707 374.489L155.334 368.035Z"
      fill="#997659"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121.49 377.007C136.049 449.496 141.317 489.462 141.745 496.192C142.173 502.922 145.689 547.888 133.549 629.537L153.557 626.323C174.189 546.552 178.442 500.338 180.609 489.95C182.777 479.563 187.431 438.002 192.346 365.627L121.49 377.007Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M154.458 367.942C159.931 417.742 170.09 497.389 184.935 606.881L213.271 609.91C227.069 500.63 225.919 422.592 216 369.5L154.458 367.942Z"
      fill="#0D1F26"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121.351 377.028C135.85 449.526 140.176 522.551 131.907 613.847L161.927 609.026C182.798 530.358 194.723 455.088 196.656 364.934L121.351 377.028Z"
      fill="#294F5D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.547 639.249L130.578 622.63C130.622 621.908 131.324 621.415 132.031 621.565C141.796 623.639 155.264 618.969 155.264 618.969C168.819 628.57 188.36 632.977 195.241 634.296C196.572 634.551 197.426 635.894 197.056 637.198L195.517 642.629H155.264H131.783L129.547 639.249Z"
      fill="#213133"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M184.311 637.62L185.341 621.001C185.386 620.279 186.088 619.786 186.795 619.936C196.56 622.011 210.028 617.34 210.028 617.34C223.582 626.941 243.123 631.348 250.004 632.667C251.336 632.922 252.19 634.265 251.82 635.569L250.28 641H210.028H186.547L184.311 637.62Z"
      fill="black"
    />
    <path
      d="M340 635C339.477 635 338.977 635.46 339.001 636C339.025 636.542 339.44 637 340 637C340.523 637 341.023 636.54 340.999 636C340.975 635.458 340.56 635 340 635Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M340 619C339.477 619 338.977 619.46 339.001 620C339.025 620.542 339.44 621 340 621C340.523 621 341.023 620.54 340.999 620C340.975 619.458 340.56 619 340 619Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M330 626C329.477 626 328.977 626.46 329.001 627C329.025 627.542 329.44 628 330 628C330.523 628 331.023 627.54 330.999 627C330.975 626.458 330.56 626 330 626Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M321 626C320.477 626 319.977 626.46 320.001 627C320.025 627.542 320.44 628 321 628C321.523 628 322.023 627.54 321.999 627C321.975 626.458 321.56 626 321 626Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M321 633C320.477 633 319.977 633.46 320.001 634C320.025 634.542 320.44 635 321 635C321.523 635 322.023 634.54 321.999 634C321.975 633.458 321.56 633 321 633Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M312 626C311.477 626 310.977 626.46 311.001 627C311.025 627.542 311.44 628 312 628C312.523 628 313.023 627.54 312.999 627C312.975 626.458 312.56 626 312 626Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M318.478 312.105L268.029 308.624L272.273 332.43L315.355 324.902C332.163 330.153 340.545 331.466 340.5 328.841C340.462 326.628 338.771 325.611 337.213 324.675C335.999 323.945 334.866 323.265 334.66 322.105C334.187 319.456 340.816 315.543 347.264 312.1C353.712 308.657 350.196 307.048 347.862 306.635C339.219 306.858 329.424 308.682 318.478 312.105ZM54.8038 383.122C59.977 376.49 111.553 288.022 111.553 288.022L138.742 293.766C138.742 293.766 73.1041 385.199 70.2022 389.552C66.4326 395.207 68.8032 405.05 70.4106 411.724C70.6593 412.757 70.8897 413.713 71.0763 414.567C66.7274 415.523 65.262 412.974 63.7172 410.287C61.9722 407.252 60.1259 404.04 53.9087 405.502C51.5067 406.067 49.1838 406.842 46.9144 407.6C39.0755 410.215 31.8741 412.618 24.2501 405.411C23.0414 404.269 22.1902 400.734 26.3818 398.978C36.8244 394.605 52.2747 386.365 54.8038 383.122Z"
      fill="#997659"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M203.977 217.179L224.987 223.016C220.391 271.573 242.753 307.482 308.845 310.6L304.671 373.492C243.262 368.82 195.777 334 198.471 266.943C199.168 249.584 200.592 231.664 203.977 217.179Z"
      fill="#DA9222"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.1514 371.662C94.2621 296.028 138.107 241.614 194.687 208.421L196.801 208.859L196.814 208.883L227.56 222.969C218.291 267.671 219.666 310.018 220.87 347.111C221.161 356.086 221.443 364.753 221.561 373.072C221.561 373.072 190.339 372.409 170.501 374.5C157.509 375.87 140.104 379.401 129.238 381.769C128.966 382.909 128.695 384.052 128.426 385.197L120.911 383.639C119.72 383.914 119.06 384.072 119.06 384.072C119.135 383.816 119.209 383.559 119.284 383.301L63.1514 371.662Z"
      fill="#F2A226"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M123.199 387.602C134.534 341.128 148.318 298.899 167.213 261.469C156.796 301.08 143.484 357.953 142.958 391.7L123.199 387.602Z"
      fill="black"
      fill-opacity="0.1"
    />
    <path
      d="M345 640C344.477 640 343.977 640.46 344.001 641C344.025 641.542 344.44 642 345 642C345.523 642 346.023 641.54 345.999 641C345.975 640.458 345.56 640 345 640Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CarryingUnanswered"
};
</script>
