<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M519.661 174.89C519.311 172.188 519.151 169.594 521.002 167.564C521.263 167.277 521.56 167.011 521.847 166.753C522.189 166.446 522.512 166.156 522.781 165.834C523.715 164.716 523.836 163.284 523.091 162.186C522.318 161.049 520.562 160.354 519.004 160.569C517.395 160.792 516.119 162.031 516.275 163.221L515.587 163.274C515.394 161.799 516.903 160.322 518.881 160.049C520.754 159.789 522.782 160.586 523.703 161.942C524.561 163.205 524.427 164.844 523.362 166.118C523.068 166.472 522.714 166.789 522.372 167.096C522.083 167.355 521.81 167.6 521.568 167.866C519.852 169.748 520.012 172.237 520.349 174.837L519.661 174.89Z"
      fill="#888886"
    />
    <path
      d="M501.404 200.347L494.407 227.621L490.96 226.314L485 224.051L498.337 180.42L501.36 200.065L501.404 200.347Z"
      fill="#C4D7D0"
    />
    <path
      d="M557.402 224.047L550.703 226.59L547.998 227.618L540.992 200.346L541.036 200.064L544.054 180.422L557.402 224.047Z"
      fill="#C4D7D0"
    />
    <path
      d="M534.927 228.236H497.945V181.513C497.945 179.784 499.807 178.383 502.103 178.383C507.902 178.262 513.701 178.141 519.5 178.02C526.388 178.141 533.277 178.262 540.165 178.383C542.462 178.383 544.323 179.785 544.323 181.513V218.84C544.323 224.029 540.116 228.236 534.927 228.236Z"
      fill="#ABC6BB"
    />
    <path
      d="M502.117 182.666C507.851 183.005 513.689 181.1 518.12 177.443C519.479 176.322 521.376 174.985 522.785 176.042C523.464 176.553 523.698 177.446 524.076 178.208C525.715 181.513 529.999 182.285 533.669 182.654C535.52 182.84 537.371 183.027 539.222 183.213C540.215 183.312 541.252 183.407 542.184 183.049C543.116 182.69 543.897 181.728 543.7 180.749C538.587 177.354 530.517 179.722 526.592 175.004C525.979 174.267 525.526 173.403 524.87 172.704C522.784 170.478 518.689 170.68 516.832 173.1C516.085 174.073 514.576 174.692 513.576 175.402C509.916 178.002 505.345 178.855 501.116 180.361C500.74 180.495 500.316 180.686 500.21 181.072C500.078 181.555 500.54 181.999 501 182.2C501.46 182.4 501.799 182.278 502.117 182.666Z"
      fill="#BA8D7B"
    />
    <path
      d="M541.29 208.631H500.965V190.676H501.012V178.564C501.371 178.564 501.742 178.617 502.104 178.732C502.293 178.79 502.469 178.864 502.634 178.956C503.947 179.688 505.46 180.672 507.237 181.655C511.014 183.75 515.997 185.846 522.846 185.581C527.938 185.384 531.818 183.568 534.923 181.74C536.766 180.654 538.335 179.562 539.721 178.802C540.018 178.641 540.336 178.54 540.66 178.485C540.822 178.455 540.987 178.437 541.146 178.432V190.677C541.196 196.663 541.243 202.647 541.29 208.631Z"
      fill="#C4D7D0"
    />
    <path
      opacity="0.36"
      d="M541.29 208.631H500.965V190.676H501.012V178.564C501.371 178.564 501.742 178.617 502.104 178.732C502.293 178.79 502.469 178.864 502.634 178.956C503.947 179.688 505.46 180.672 507.237 181.655V190.676C510.06 191.803 514.543 193.196 520.188 193.331C526.755 193.487 531.927 191.871 534.923 190.676V181.74C536.766 180.654 538.335 179.562 539.721 178.802C540.018 178.641 540.336 178.54 540.66 178.485C540.822 178.455 540.987 178.437 541.146 178.432V190.677C541.196 196.663 541.243 202.647 541.29 208.631Z"
      fill="#C4D7D0"
    />
    <path
      d="M541.29 211.081H500.965V193.126H501.012V178.506H507.237V193.126C510.06 194.253 514.543 195.645 520.188 195.781C526.755 195.937 531.927 194.321 534.923 193.126V178.506H541.146V193.126C541.196 199.113 541.243 205.096 541.29 211.081Z"
      fill="#8DAA56"
    />
    <path
      d="M544.32 260.535C543.879 266.154 544.544 278.569 538.654 279.717C537.683 279.906 536.043 279.894 534.274 279.782C530.554 279.543 526.239 278.858 526.239 278.64C526.856 268.994 525.753 252.831 524.046 245.246C523.631 243.404 523.18 242.07 522.712 241.455C522.704 241.44 522.692 241.426 522.68 241.411C522.671 241.461 522.665 241.517 522.659 241.582C522.512 242.951 522.698 247.472 522.854 253.235C523.048 260.549 523.192 269.863 522.542 277.245C522.515 277.565 514.706 277.586 511.692 277.001C509.661 276.606 508.074 275.255 506.803 273.459C504.389 270.048 503.109 265.032 502.058 261.933C499.238 253.615 494.479 215.309 501.007 208.739C502.782 206.955 515.922 206.961 520.34 208.641C524.758 206.961 537.898 206.955 539.673 208.739C546.202 215.309 543.935 227.968 543.955 236.331C543.976 245.202 545.006 251.778 544.32 260.535Z"
      fill="#8DAA56"
    />
    <path
      d="M534.924 202.102H509.004V222.131H534.924V202.102Z"
      fill="#819B48"
    />
    <path
      d="M532.582 220.36H531.258V220.065H532.288V219.035H532.582V220.36Z"
      fill="white"
    />
    <path
      d="M528.599 220.361H525.942V220.066H528.599V220.361ZM523.284 220.361H520.627V220.066H523.284V220.361ZM517.97 220.361H515.312V220.066H517.97V220.361Z"
      fill="white"
    />
    <path
      d="M512.657 220.36H511.332V219.035H511.626V220.065H512.657V220.36Z"
      fill="white"
    />
    <path
      d="M511.626 216.266H511.332V213.498H511.626V216.266ZM511.626 210.731H511.332V207.963H511.626V210.731Z"
      fill="white"
    />
    <path
      d="M511.626 205.196H511.332V203.871H512.657V204.165H511.626V205.196Z"
      fill="white"
    />
    <path
      d="M528.599 204.165H525.942V203.871H528.599V204.165ZM523.284 204.165H520.627V203.871H523.284V204.165ZM517.97 204.165H515.312V203.871H517.97V204.165Z"
      fill="white"
    />
    <path
      d="M532.582 205.196H532.288V204.165H531.258V203.871H532.582V205.196Z"
      fill="white"
    />
    <path
      d="M532.583 216.266H532.289V213.498H532.583V216.266ZM532.583 210.731H532.289V207.963H532.583V210.731Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M534.281 279.782C530.56 279.543 526.245 278.858 526.245 278.639C526.863 268.994 525.759 252.831 524.052 245.246C523.637 243.403 523.187 242.07 522.719 241.455C522.86 241.161 523.019 240.893 523.204 240.66C523.578 240.186 524.049 239.854 524.635 239.694C529.383 238.432 532.135 249.888 532.835 253.061C534.222 259.351 534.34 265.882 534.448 272.352C534.493 274.828 534.531 277.33 534.281 279.782Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.15"
      d="M524.071 244.483C524.062 244.736 524.053 244.992 524.048 245.246C523.633 243.403 523.182 242.07 522.714 241.455C522.706 241.44 522.694 241.425 522.682 241.41C522.673 241.46 522.667 241.516 522.661 241.581C522.514 242.95 522.699 247.471 522.855 253.234C523.05 260.549 523.194 269.862 522.543 277.244C522.517 277.565 514.708 277.586 511.694 277C509.663 276.605 508.076 275.254 506.805 273.459H506.902C509.183 273.464 511.482 273.98 513.728 273.574C516.295 273.108 518.602 271.384 519.774 269.053C520.972 266.666 520.975 263.878 520.951 261.208C520.904 255.975 520.789 250.738 520.813 245.508C520.831 241.925 522.237 239.98 523.2 240.66C523.741 241.042 524.142 242.258 524.071 244.483Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.09"
      d="M535.17 192.493C535.106 193.187 535.032 193.909 534.685 194.515C534.149 195.442 533.081 195.907 532.074 196.269C524.118 199.148 515.267 199.504 507.105 197.273C506.213 197.031 505.256 196.702 504.756 195.924C504.476 195.489 504.376 194.962 504.288 194.453C503.364 189.172 502.692 183.847 502.277 178.502H507.234V193.122C510.057 194.249 514.54 195.641 520.185 195.777C526.752 195.933 531.924 194.317 534.92 193.122V178.502H535.983C537.702 181.513 535.48 189.163 535.17 192.493Z"
      fill="#8DAA56"
    />
    <path
      opacity="0.36"
      d="M501.405 200.347L494.408 227.621L490.961 226.314C491.656 224.001 492.395 222.037 492.659 221.384C495.535 214.343 498.405 207.296 501.278 200.253C501.301 200.191 501.331 200.126 501.36 200.064L501.405 200.347Z"
      fill="#C4D7D0"
    />
    <path
      opacity="0.36"
      d="M550.703 226.591L547.998 227.618L540.992 200.347L541.036 200.064C541.504 199.894 542.005 199.826 542.514 199.888C542.549 203.706 543.614 207.509 545.569 210.787C547.179 213.486 549.431 215.974 549.89 219.085C550.079 220.366 549.943 221.67 549.981 222.964C550.002 223.735 550.379 225.131 550.703 226.591Z"
      fill="#C4D7D0"
    />
    <path
      d="M484.661 174.89C484.311 172.188 484.151 169.594 486.002 167.564C486.263 167.277 486.56 167.011 486.847 166.753C487.189 166.446 487.512 166.156 487.781 165.834C488.715 164.716 488.836 163.284 488.091 162.186C487.318 161.049 485.562 160.354 484.004 160.569C482.395 160.792 481.119 162.031 481.275 163.221L480.587 163.274C480.394 161.799 481.903 160.322 483.881 160.049C485.754 159.789 487.782 160.586 488.703 161.942C489.561 163.205 489.427 164.844 488.362 166.118C488.068 166.472 487.714 166.789 487.372 167.096C487.083 167.355 486.81 167.6 486.568 167.866C484.852 169.748 485.012 172.237 485.349 174.837L484.661 174.89Z"
      fill="#888886"
    />
    <path
      d="M466.404 200.347L459.407 227.621L455.96 226.314L450 224.051L463.337 180.42L466.36 200.065L466.404 200.347Z"
      fill="#F7E0BF"
    />
    <path
      d="M522.402 224.047L515.703 226.59L512.998 227.618L505.992 200.346L506.036 200.064L509.054 180.422L522.402 224.047Z"
      fill="#F7E0BF"
    />
    <path
      d="M499.927 228.236H462.945V181.513C462.945 179.784 464.807 178.383 467.103 178.383C472.902 178.262 478.701 178.141 484.5 178.02C491.388 178.141 498.277 178.262 505.165 178.383C507.462 178.383 509.323 179.785 509.323 181.513V218.84C509.323 224.029 505.116 228.236 499.927 228.236Z"
      fill="#E9CCA1"
    />
    <path
      d="M467.117 182.666C472.851 183.005 478.689 181.1 483.12 177.443C484.479 176.322 486.376 174.985 487.785 176.042C488.464 176.553 488.698 177.446 489.076 178.208C490.715 181.513 494.999 182.285 498.669 182.654C500.52 182.84 502.371 183.027 504.222 183.213C505.215 183.312 506.252 183.407 507.184 183.049C508.116 182.69 508.897 181.728 508.7 180.749C503.587 177.354 495.517 179.722 491.592 175.004C490.979 174.267 490.526 173.403 489.87 172.704C487.784 170.478 483.689 170.68 481.832 173.1C481.085 174.073 479.576 174.692 478.576 175.402C474.916 178.002 470.345 178.855 466.116 180.361C465.74 180.495 465.316 180.686 465.21 181.072C465.078 181.555 465.54 181.999 466 182.2C466.46 182.4 466.799 182.278 467.117 182.666Z"
      fill="#BA8D7B"
    />
    <path
      d="M506.29 208.631H465.965V190.676H466.012V178.564C466.371 178.564 466.742 178.617 467.104 178.732C467.293 178.79 467.469 178.864 467.634 178.956C468.947 179.688 470.46 180.672 472.237 181.655C476.014 183.75 480.997 185.846 487.846 185.581C492.938 185.384 496.818 183.568 499.923 181.74C501.766 180.654 503.335 179.562 504.721 178.802C505.018 178.641 505.336 178.54 505.66 178.485C505.822 178.455 505.987 178.437 506.146 178.432V190.677C506.196 196.663 506.243 202.647 506.29 208.631Z"
      fill="#F7E0BF"
    />
    <path
      opacity="0.36"
      d="M506.29 208.631H465.965V190.676H466.012V178.564C466.371 178.564 466.742 178.617 467.104 178.732C467.293 178.79 467.469 178.864 467.634 178.956C468.947 179.688 470.46 180.672 472.237 181.655V190.676C475.06 191.803 479.543 193.196 485.188 193.331C491.755 193.487 496.927 191.871 499.923 190.676V181.74C501.766 180.654 503.335 179.562 504.721 178.802C505.018 178.641 505.336 178.54 505.66 178.485C505.822 178.455 505.987 178.437 506.146 178.432V190.677C506.196 196.663 506.243 202.647 506.29 208.631Z"
      fill="#F7E0BF"
    />
    <path
      d="M506.29 211.081H465.965V193.126H466.012V178.506H472.237V193.126C475.06 194.253 479.543 195.645 485.188 195.781C491.755 195.937 496.927 194.321 499.923 193.126V178.506H506.146V193.126C506.196 199.113 506.243 205.096 506.29 211.081Z"
      fill="#EBAB4E"
    />
    <path
      d="M509.32 260.535C508.879 266.154 509.544 278.569 503.654 279.717C502.683 279.906 501.043 279.894 499.274 279.782C495.554 279.543 491.239 278.858 491.239 278.64C491.856 268.994 490.753 252.831 489.046 245.246C488.631 243.404 488.18 242.07 487.712 241.455C487.704 241.44 487.692 241.426 487.68 241.411C487.671 241.461 487.665 241.517 487.659 241.582C487.512 242.951 487.698 247.472 487.854 253.235C488.048 260.549 488.192 269.863 487.542 277.245C487.515 277.565 479.706 277.586 476.692 277.001C474.661 276.606 473.074 275.255 471.803 273.459C469.389 270.048 468.109 265.032 467.058 261.933C464.238 253.615 459.479 215.309 466.007 208.739C467.782 206.955 480.922 206.961 485.34 208.641C489.758 206.961 502.898 206.955 504.673 208.739C511.202 215.309 508.935 227.968 508.955 236.331C508.976 245.202 510.006 251.778 509.32 260.535Z"
      fill="#EBAB4E"
    />
    <path
      d="M499.924 202.102H474.004V222.131H499.924V202.102Z"
      fill="#FFBA55"
    />
    <path
      d="M497.582 220.36H496.258V220.065H497.288V219.035H497.582V220.36Z"
      fill="white"
    />
    <path
      d="M493.599 220.361H490.942V220.066H493.599V220.361ZM488.284 220.361H485.627V220.066H488.284V220.361ZM482.97 220.361H480.312V220.066H482.97V220.361Z"
      fill="white"
    />
    <path
      d="M477.657 220.36H476.332V219.035H476.626V220.065H477.657V220.36Z"
      fill="white"
    />
    <path
      d="M476.626 216.266H476.332V213.498H476.626V216.266ZM476.626 210.731H476.332V207.963H476.626V210.731Z"
      fill="white"
    />
    <path
      d="M476.626 205.196H476.332V203.871H477.657V204.165H476.626V205.196Z"
      fill="white"
    />
    <path
      d="M493.599 204.165H490.942V203.871H493.599V204.165ZM488.284 204.165H485.627V203.871H488.284V204.165ZM482.97 204.165H480.312V203.871H482.97V204.165Z"
      fill="white"
    />
    <path
      d="M497.582 205.196H497.288V204.165H496.258V203.871H497.582V205.196Z"
      fill="white"
    />
    <path
      d="M497.583 216.266H497.289V213.498H497.583V216.266ZM497.583 210.731H497.289V207.963H497.583V210.731Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M499.281 279.782C495.56 279.543 491.245 278.858 491.245 278.639C491.863 268.994 490.759 252.831 489.052 245.246C488.637 243.403 488.187 242.07 487.719 241.455C487.86 241.161 488.019 240.893 488.204 240.66C488.578 240.186 489.049 239.854 489.635 239.694C494.383 238.432 497.135 249.888 497.835 253.061C499.222 259.351 499.34 265.882 499.448 272.352C499.493 274.828 499.531 277.33 499.281 279.782Z"
      fill="#EBAB4E"
    />
    <path
      opacity="0.15"
      d="M489.071 244.483C489.062 244.736 489.053 244.992 489.048 245.246C488.633 243.403 488.182 242.07 487.714 241.455C487.706 241.44 487.694 241.425 487.682 241.41C487.673 241.46 487.667 241.516 487.661 241.581C487.514 242.95 487.699 247.471 487.855 253.234C488.05 260.549 488.194 269.862 487.543 277.244C487.517 277.565 479.708 277.586 476.694 277C474.663 276.605 473.076 275.254 471.805 273.459H471.902C474.183 273.464 476.482 273.98 478.728 273.574C481.295 273.108 483.602 271.384 484.774 269.053C485.972 266.666 485.975 263.878 485.951 261.208C485.904 255.975 485.789 250.738 485.813 245.508C485.831 241.925 487.237 239.98 488.2 240.66C488.741 241.042 489.142 242.258 489.071 244.483Z"
      fill="#EBAB4E"
    />
    <path
      opacity="0.09"
      d="M500.17 192.493C500.106 193.187 500.032 193.909 499.685 194.515C499.149 195.442 498.081 195.907 497.074 196.269C489.118 199.148 480.267 199.504 472.105 197.273C471.213 197.031 470.256 196.702 469.756 195.924C469.476 195.489 469.376 194.962 469.288 194.453C468.364 189.172 467.692 183.847 467.277 178.502H472.234V193.122C475.057 194.249 479.54 195.641 485.185 195.777C491.752 195.933 496.924 194.317 499.92 193.122V178.502H500.983C502.702 181.513 500.48 189.163 500.17 192.493Z"
      fill="#EBAB4E"
    />
    <path
      opacity="0.36"
      d="M466.405 200.347L459.408 227.621L455.961 226.314C456.656 224.001 457.395 222.037 457.659 221.384C460.535 214.343 463.405 207.296 466.278 200.253C466.301 200.191 466.331 200.126 466.36 200.064L466.405 200.347Z"
      fill="#F7E0BF"
    />
    <path
      opacity="0.36"
      d="M515.703 226.591L512.998 227.618L505.992 200.347L506.036 200.064C506.504 199.894 507.005 199.826 507.514 199.888C507.549 203.706 508.614 207.509 510.569 210.787C512.179 213.486 514.431 215.974 514.89 219.085C515.079 220.366 514.943 221.67 514.981 222.964C515.002 223.735 515.379 225.131 515.703 226.591Z"
      fill="#F7E0BF"
    />
    <path
      d="M449.661 174.89C449.311 172.188 449.151 169.594 451.002 167.564C451.263 167.277 451.56 167.011 451.847 166.753C452.189 166.446 452.512 166.156 452.781 165.834C453.715 164.716 453.836 163.284 453.091 162.186C452.318 161.049 450.562 160.354 449.004 160.569C447.395 160.792 446.119 162.031 446.275 163.221L445.587 163.274C445.394 161.799 446.903 160.322 448.881 160.049C450.754 159.789 452.782 160.586 453.703 161.942C454.561 163.205 454.427 164.844 453.362 166.118C453.068 166.472 452.714 166.789 452.372 167.096C452.083 167.355 451.81 167.6 451.568 167.866C449.852 169.748 450.012 172.237 450.349 174.837L449.661 174.89Z"
      fill="#888886"
    />
    <path
      d="M431.404 200.347L424.407 227.621L420.96 226.314L415 224.051L428.337 180.42L431.36 200.065L431.404 200.347Z"
      fill="#FAD3BE"
    />
    <path
      d="M487.402 224.047L480.703 226.59L477.998 227.618L470.992 200.346L471.036 200.064L474.054 180.422L487.402 224.047Z"
      fill="#FAD3BE"
    />
    <path
      d="M464.927 228.236H427.945V181.513C427.945 179.784 429.807 178.383 432.103 178.383C437.902 178.262 443.701 178.141 449.5 178.02C456.388 178.141 463.277 178.262 470.165 178.383C472.462 178.383 474.323 179.785 474.323 181.513V218.84C474.323 224.029 470.116 228.236 464.927 228.236Z"
      fill="#EFBFA4"
    />
    <path
      d="M432.117 182.666C437.851 183.005 443.689 181.1 448.12 177.443C449.479 176.322 451.376 174.985 452.785 176.042C453.464 176.553 453.698 177.446 454.076 178.208C455.715 181.513 459.999 182.285 463.669 182.654C465.52 182.84 467.371 183.027 469.222 183.213C470.215 183.312 471.252 183.407 472.184 183.049C473.116 182.69 473.897 181.728 473.7 180.749C468.587 177.354 460.517 179.722 456.592 175.004C455.979 174.267 455.526 173.403 454.87 172.704C452.784 170.478 448.689 170.68 446.832 173.1C446.085 174.073 444.576 174.692 443.576 175.402C439.916 178.002 435.345 178.855 431.116 180.361C430.74 180.495 430.316 180.686 430.21 181.072C430.078 181.555 430.54 181.999 431 182.2C431.46 182.4 431.799 182.278 432.117 182.666Z"
      fill="#BA8D7B"
    />
    <path
      d="M471.29 208.631H430.965V190.676H431.012V178.564C431.371 178.564 431.742 178.617 432.104 178.732C432.293 178.79 432.469 178.864 432.634 178.956C433.947 179.688 435.46 180.672 437.237 181.655C441.014 183.75 445.997 185.846 452.846 185.581C457.938 185.384 461.818 183.568 464.923 181.74C466.766 180.654 468.335 179.562 469.721 178.802C470.018 178.641 470.336 178.54 470.66 178.485C470.822 178.455 470.987 178.437 471.146 178.432V190.677C471.196 196.663 471.243 202.647 471.29 208.631Z"
      fill="#FAD3BE"
    />
    <path
      opacity="0.36"
      d="M471.29 208.631H430.965V190.676H431.012V178.564C431.371 178.564 431.742 178.617 432.104 178.732C432.293 178.79 432.469 178.864 432.634 178.956C433.947 179.688 435.46 180.672 437.237 181.655V190.676C440.06 191.803 444.543 193.196 450.188 193.331C456.755 193.487 461.927 191.871 464.923 190.676V181.74C466.766 180.654 468.335 179.562 469.721 178.802C470.018 178.641 470.336 178.54 470.66 178.485C470.822 178.455 470.987 178.437 471.146 178.432V190.677C471.196 196.663 471.243 202.647 471.29 208.631Z"
      fill="#FAD3BE"
    />
    <path
      d="M471.29 211.081H430.965V193.126H431.012V178.506H437.237V193.126C440.06 194.253 444.543 195.645 450.188 195.781C456.755 195.937 461.927 194.321 464.923 193.126V178.506H471.146V193.126C471.196 199.113 471.243 205.096 471.29 211.081Z"
      fill="#CFA086"
    />
    <path
      d="M474.32 260.535C473.879 266.154 474.544 278.569 468.654 279.717C467.683 279.906 466.043 279.894 464.274 279.782C460.554 279.543 456.239 278.858 456.239 278.64C456.856 268.994 455.753 252.831 454.046 245.246C453.631 243.404 453.18 242.07 452.712 241.455C452.704 241.44 452.692 241.426 452.68 241.411C452.671 241.461 452.665 241.517 452.659 241.582C452.512 242.951 452.698 247.472 452.854 253.235C453.048 260.549 453.192 269.863 452.542 277.245C452.515 277.565 444.706 277.586 441.692 277.001C439.661 276.606 438.074 275.255 436.803 273.459C434.389 270.048 433.109 265.032 432.058 261.933C429.238 253.615 424.479 215.309 431.007 208.739C432.782 206.955 445.922 206.961 450.34 208.641C454.758 206.961 467.898 206.955 469.673 208.739C476.202 215.309 473.935 227.968 473.955 236.331C473.976 245.202 475.006 251.778 474.32 260.535Z"
      fill="#CFA086"
    />
    <path
      d="M464.924 202.102H439.004V222.131H464.924V202.102Z"
      fill="#D6A88E"
    />
    <path
      d="M462.582 220.36H461.258V220.065H462.288V219.035H462.582V220.36Z"
      fill="white"
    />
    <path
      d="M458.599 220.361H455.942V220.066H458.599V220.361ZM453.284 220.361H450.627V220.066H453.284V220.361ZM447.97 220.361H445.312V220.066H447.97V220.361Z"
      fill="white"
    />
    <path
      d="M442.657 220.36H441.332V219.035H441.626V220.065H442.657V220.36Z"
      fill="white"
    />
    <path
      d="M441.626 216.266H441.332V213.498H441.626V216.266ZM441.626 210.731H441.332V207.963H441.626V210.731Z"
      fill="white"
    />
    <path
      d="M441.626 205.196H441.332V203.871H442.657V204.165H441.626V205.196Z"
      fill="white"
    />
    <path
      d="M458.599 204.165H455.942V203.871H458.599V204.165ZM453.284 204.165H450.627V203.871H453.284V204.165ZM447.97 204.165H445.312V203.871H447.97V204.165Z"
      fill="white"
    />
    <path
      d="M462.582 205.196H462.288V204.165H461.258V203.871H462.582V205.196Z"
      fill="white"
    />
    <path
      d="M462.583 216.266H462.289V213.498H462.583V216.266ZM462.583 210.731H462.289V207.963H462.583V210.731Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M464.281 279.782C460.56 279.543 456.245 278.858 456.245 278.639C456.863 268.994 455.759 252.831 454.052 245.246C453.637 243.403 453.187 242.07 452.719 241.455C452.86 241.161 453.019 240.893 453.204 240.66C453.578 240.186 454.049 239.854 454.635 239.694C459.383 238.432 462.135 249.888 462.835 253.061C464.222 259.351 464.34 265.882 464.448 272.352C464.493 274.828 464.531 277.33 464.281 279.782Z"
      fill="#CFA086"
    />
    <path
      opacity="0.15"
      d="M454.071 244.483C454.062 244.736 454.053 244.992 454.048 245.246C453.633 243.403 453.182 242.07 452.714 241.455C452.706 241.44 452.694 241.425 452.682 241.41C452.673 241.46 452.667 241.516 452.661 241.581C452.514 242.95 452.699 247.471 452.855 253.234C453.05 260.549 453.194 269.862 452.543 277.244C452.517 277.565 444.708 277.586 441.694 277C439.663 276.605 438.076 275.254 436.805 273.459H436.902C439.183 273.464 441.482 273.98 443.728 273.574C446.295 273.108 448.602 271.384 449.774 269.053C450.972 266.666 450.975 263.878 450.951 261.208C450.904 255.975 450.789 250.738 450.813 245.508C450.831 241.925 452.237 239.98 453.2 240.66C453.741 241.042 454.142 242.258 454.071 244.483Z"
      fill="#CFA086"
    />
    <path
      opacity="0.09"
      d="M465.17 192.493C465.106 193.187 465.032 193.909 464.685 194.515C464.149 195.442 463.081 195.907 462.074 196.269C454.118 199.148 445.267 199.504 437.105 197.273C436.213 197.031 435.256 196.702 434.756 195.924C434.476 195.489 434.376 194.962 434.288 194.453C433.364 189.172 432.692 183.847 432.277 178.502H437.234V193.122C440.057 194.249 444.54 195.641 450.185 195.777C456.752 195.933 461.924 194.317 464.92 193.122V178.502H465.983C467.702 181.513 465.48 189.163 465.17 192.493Z"
      fill="#CFA086"
    />
    <path
      opacity="0.36"
      d="M431.405 200.347L424.408 227.621L420.961 226.314C421.656 224.001 422.395 222.037 422.659 221.384C425.535 214.343 428.405 207.296 431.278 200.253C431.301 200.191 431.331 200.126 431.36 200.064L431.405 200.347Z"
      fill="#FAD3BE"
    />
    <path
      opacity="0.36"
      d="M480.703 226.591L477.998 227.618L470.992 200.347L471.036 200.064C471.504 199.894 472.005 199.826 472.514 199.888C472.549 203.706 473.614 207.509 475.569 210.787C477.179 213.486 479.431 215.974 479.89 219.085C480.079 220.366 479.943 221.67 479.981 222.964C480.002 223.735 480.379 225.131 480.703 226.591Z"
      fill="#FAD3BE"
    />
  </svg>
</template>

<script>
export default {
  name: "GetStartedTriplets"
};
</script>
