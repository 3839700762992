import { getAssessmentHistory } from "@/components/assessment/api-service.js";
import {
  ASSESSMENT_STATUS,
  AssessmentStateCodes
} from "@/components/common/assessment-history/assessmentHistoryConstants.js";

let currentFetchAssessmentsPromise = null;

export const assessmentStore = {
  namespaced: true,
  state: {
    assessments: null,
    loading: false,
    loaded: false,
    error: null
  },
  actions: {
    async fetchAssessments({ commit, state }, { force = false } = {}) {
      if (state.loaded && !force) return state.assessments;

      if (currentFetchAssessmentsPromise && !force)
        return currentFetchAssessmentsPromise;

      try {
        commit("SET_LOADING", true);

        currentFetchAssessmentsPromise = getAssessmentHistory(true);
        const assessments = await currentFetchAssessmentsPromise;

        commit("SET_ASSESSMENTS", assessments);
      } catch (error) {
        commit("SET_ERROR", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
        currentFetchAssessmentsPromise = null;
      }
    },
    reset({ commit }) {
      commit("RESET");
    }
  },
  mutations: {
    SET_ASSESSMENTS(state, assessments) {
      state.assessments = assessments;
      state.loaded = true;
      state.error = null;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    RESET(state) {
      state.assessments = null;
      state.loaded = false;
      state.error = null;
      state.loading = false;
    }
  },
  getters: {
    assessments: state => state.assessments,
    loadingAssessmentHistory: state => state.loading,
    assessmentHistoryLoaded: state => state.loaded,
    assessmentHistoryError: state => state.error,
    sortedCompleteAssessments: state => {
      if (!state.assessments) return [];

      return state.assessments
        .filter(x => !!x.completedAt)
        .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
    },
    latestAssessmentByType: (state, getters) => assessmentType => {
      return getters.sortedCompleteAssessments.find(
        x => x.assessmentType === assessmentType
      );
    },
    getAssessmentStatus: state => assessmentType => {
      if (!state.assessments || state.assessments.length === 0) {
        return {
          status: ASSESSMENT_STATUS.NEVER_DONE,
          assessmentId: null
        };
      }

      const typeAssessments = state.assessments.filter(
        assessment => assessment.assessmentType === assessmentType
      );

      if (typeAssessments.length === 0) {
        return {
          status: ASSESSMENT_STATUS.NEVER_DONE,
          assessmentId: null
        };
      }

      typeAssessments.sort((a, b) => {
        const dateA = new Date(a.lastUpdatedAt || a.createdAt);
        const dateB = new Date(b.lastUpdatedAt || b.createdAt);
        return dateB - dateA;
      });

      const {
        id,
        state: assessmentState,
        completedAt,
        lastUpdatedAt,
        createdAt
      } = typeAssessments[0];

      const lastUpdated = completedAt || lastUpdatedAt || createdAt;

      if (assessmentState === AssessmentStateCodes.Completed) {
        return {
          status: ASSESSMENT_STATUS.COMPLETED,
          assessmentId: id,
          lastUpdated
        };
      } else if (assessmentState === AssessmentStateCodes.Pending) {
        return {
          status: ASSESSMENT_STATUS.INVITED,
          assessmentId: id,
          lastUpdated
        };
      } else if (
        [AssessmentStateCodes.Opened, AssessmentStateCodes.InProgress].includes(
          assessmentState
        )
      ) {
        return {
          status: ASSESSMENT_STATUS.IN_PROGRESS,
          assessmentId: id,
          lastUpdated
        };
      } else {
        return {
          status: ASSESSMENT_STATUS.NEVER_DONE,
          assessmentId: null
        };
      }
    },
    isAssessmentInvited: (state, getters) => assessmentType => {
      const status = getters.getAssessmentStatus(assessmentType);
      return status.status === ASSESSMENT_STATUS.INVITED;
    },
    isAssessmentInProgress: (state, getters) => assessmentType => {
      const status = getters.getAssessmentStatus(assessmentType);
      return status.status === ASSESSMENT_STATUS.IN_PROGRESS;
    },
    isAssessmentCompleted: (state, getters) => assessmentType => {
      const status = getters.getAssessmentStatus(assessmentType);
      return status.status === ASSESSMENT_STATUS.COMPLETED;
    },
    getInProgressAssessmentId: (state, getters) => assessmentType => {
      const status = getters.getAssessmentStatus(assessmentType);
      return status.status === ASSESSMENT_STATUS.IN_PROGRESS ||
        status.status === ASSESSMENT_STATUS.INVITED
        ? status.assessmentId
        : null;
    }
  }
};
