import DeskAssessmentQuestions from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import DeskAssessmentWindowScoring from "./desk-assessment-window-scoring";
import SimsAssessmentText from "./sims-assessment-text";
import SimsComponents from "@/assets/json/DeskAssessment/SimsComponents.json";
import i18n from "@/plugins/i18n.js";
import * as WebcamValues from "@/services/deskassessment/webcam-values.js";
import criteriaChecker from "@/components/common/criteria-checker.js";
import sentry from "@/sentry";
import SchemaService from "@/services/question-schema/schema-service.js";
import { cloneDeep } from "lodash";

/*
Transform question schema used by the forms to one which provides easier look-ups for the result page
It will look similar to the following:
{
  health: {
    questionName: questionObject
    ...
  },
  pain: {
    questionName: questionObject
    ...
  },
  setup: {
    workhabits: {
      questionName: questionObject
      ...
    },
    seating: {
      questionName: questionObject
      ...
    },
    equipment: {
      questionName: questionObject
      ...
    }
  }
}
*/
const questionsByTheme = DeskAssessmentQuestions.reduce(function (acc, item) {
  acc[item.theme] = {};
  switch (item.theme) {
    case "health":
      item.pages.forEach(page => {
        page.questions.forEach(question => {
          acc[item.theme][question.name] = question;
        });
      });
      break;

    case "pain":
      item.pages.forEach(page => {
        Object.values(page.questions).forEach(bodyPart => {
          bodyPart.forEach(question => {
            acc[item.theme][question.name] = question;
          });
        });
      });
      break;

    case "setup":
      item.pages.forEach(page => {
        acc[item.theme][page.name] = {};
        page.questions.forEach(question => {
          acc[item.theme][page.name][question.name] = question;
        });
      });
      break;
  }
  return acc;
}, {});

export default {
  questionsByTheme: questionsByTheme,
  maxHealthScore: 5,
  maxWorkHabitsScore: 4, // directly from schema
  maxEnvironmentScore: 4,
  maxSimsScore: 2,
  maxHeightScore(results) {
    if (results.setup.deskHeight) {
      return 1;
    }
    return 0;
  },
  maxSeatingScore(results) {
    var noStandingDesk =
      results.setup.standingdesk == 2 || results.setup.standingdesk == 3;
    var standingDeskUseLessThanWorkHours =
      results.setup.standingdesk == 1 &&
      results.setup.hoursstandingdesk < results.setup.hours;
    var hasChair =
      (results.setup.chair == 1 || results.setup.armrests == 1) &&
      results.setup.seatadjustable !== 3;

    let seatingScore = 0;
    if (noStandingDesk || standingDeskUseLessThanWorkHours || hasChair) {
      seatingScore = 4;
      if (results.setup.armrests == 1) {
        seatingScore = 5;
      }
    } else {
      seatingScore = 0;
    }

    return seatingScore;
  },
  maxEquipmentScore(results) {
    let equipmentScore = 3;
    if (results.setup.headset && results.setup.headset == 2) {
      equipmentScore++;
    }

    return equipmentScore;
  },
  getOverallScore(results) {
    let { webcamWeight, setupWeight, healthWeight } =
      getSectionWeightsForOverallScore(results);

    const webcamScore = this.getPercentageScore(
      this.calculateDeskSetupScore(results),
      this.getMaxWebcamScore(results)
    );

    const healthScore = this.getPercentageScore(
      this.calculateHealthScore(results),
      this.maxHealthScore
    );

    const setupScore = this.calculateSetupAndHabitsPercentage(results);

    return Math.round(
      webcamScore * webcamWeight +
        setupScore * setupWeight +
        healthScore * healthWeight
    );

    function getSectionWeightsForOverallScore(results) {
      const webcamResult = results.webcam.pictureTaken;
      const healthResult =
        results.health?.smoker &&
        results.health?.exercise !== null &&
        results.health?.exercise !== undefined;

      let setupWeight = 0.5;
      let webcamWeight = webcamResult ? 0.35 : 0;
      let healthWeight = healthResult ? 0.15 : 0;

      let totalWeights = setupWeight + webcamWeight + healthWeight;

      // normalise weights so they still add up to 1 to compensate for missing sections
      setupWeight = setupWeight / totalWeights;
      webcamWeight = webcamWeight / totalWeights;
      healthWeight = healthWeight / totalWeights;
      return { webcamWeight, setupWeight, healthWeight };
    }
  },
  calculateSetupAndHabitsPercentage(results) {
    const workHabitsPercentage = this.getPercentageScore(
      this.calculateWorkHabitsScore(results),
      this.maxWorkHabitsScore
    );
    const equipmentPercentage = this.calculateEquipmentPercentage(results);
    const environmentPercentage = this.calculateEnvironmentPercentage(results);

    if (environmentPercentage !== null) {
      return Math.round(
        workHabitsPercentage * 0.2 +
          equipmentPercentage * 0.6 +
          environmentPercentage * 0.2
      );
    } else {
      return Math.round(workHabitsPercentage * 0.3 + equipmentPercentage * 0.7);
    }
  },
  calculateHealthScore(results) {
    return this.getHealthScoreFromResult(
      questionsByTheme.health,
      results.health
    );
  },
  calculateWorkHabitsScore(results) {
    // Remove environment questions from habits
    let questions = questionsByTheme.setup.workhabits;
    delete questions.noise;
    delete questions.temperature;

    return this.getSetupScoreFromResult(questions, results.setup);
  },
  calculateEquipmentPercentage(results) {
    const wristPositioningAndNicheEquipment = this.getPercentageScore(
      this.getSetupScoreFromResult(
        questionsByTheme.setup.equipment,
        results.setup
      ),
      this.maxEquipmentScore(results)
    );

    let seatingPercentage = 0;
    const maxSeatingScore = this.maxSeatingScore(results);
    const seatingScoreExists = maxSeatingScore !== 0;
    if (maxSeatingScore !== 0) {
      seatingPercentage = this.getPercentageScore(
        this.getSetupScoreFromResult(
          questionsByTheme.setup.seating,
          results.setup
        ),
        maxSeatingScore
      );
    }

    let VisualDeskPlannerScore = 0;
    if (results.setup.deskItems) {
      VisualDeskPlannerScore = this.getVisualDeskPlannerScore(
        results.setup,
        500
      );
    } else {
      VisualDeskPlannerScore = this.getPercentageScore(
        this.getSetupScoreFromResult(
          questionsByTheme.setup.sims,
          results.setup
        ),
        this.maxSimsScore
      );
    }

    let maxHeightScore = this.maxHeightScore(results);
    const heightScoreExists = maxHeightScore !== 0;
    let heightScore = 0;
    if (heightScoreExists) {
      heightScore = this.getPercentageScore(
        this.getSetupScoreFromResult(
          questionsByTheme.setup.height,
          results.setup
        ),
        maxHeightScore
      );
    }

    let wristsWeight = 0.1;
    let visualDeskPlannerWeight = 0.5;

    // if both seat and height exist - give 0.2 each
    // if only one exists give that 0.4
    // if neither exist, give them both 0
    let seatingWeight = heightScoreExists ? 0.2 : 0.4;
    let heightWeight = heightScoreExists ? 0.2 : 0;
    if (!seatingScoreExists) {
      seatingWeight = 0;
      heightWeight = heightScoreExists ? 0.4 : 0;
    }

    let totalWeights =
      seatingWeight + wristsWeight + visualDeskPlannerWeight + heightWeight;

    // normalise weights so they still add up to 1 to compensate for missing sections
    seatingWeight = seatingWeight / totalWeights;
    wristsWeight = wristsWeight / totalWeights;
    heightWeight = heightWeight / totalWeights;
    visualDeskPlannerWeight = visualDeskPlannerWeight / totalWeights;

    return (
      seatingPercentage * seatingWeight +
      heightScore * heightWeight +
      wristPositioningAndNicheEquipment * wristsWeight +
      VisualDeskPlannerScore * visualDeskPlannerWeight
    );
  },
  calculateEnvironmentPercentage(results) {
    let setupResults = results.setup;
    if (
      setupResults.noise === undefined ||
      !setupResults.temperature === undefined
    ) {
      return null;
    }
    let temperatureScore = 0;
    let tempVal = Math.abs(setupResults.temperature);

    // The max environment score is 4 with noise being 75% (3) and temperature being 25% (1) of that.
    // Extreme temperatures give the full score of 1, chilly / warm get 0.2.
    if (tempVal > 0) {
      temperatureScore = tempVal > 1 ? 1 : 0.2;
    }

    // As the noise slider has a max of 4, we subtract one from the answer to get the score.
    // The noise at library / conversation levels however are both score 0.
    let noiseScore = setupResults.noise < 2 ? 0 : setupResults.noise - 1;
    return this.getPercentageScore(
      temperatureScore + noiseScore,
      this.maxEnvironmentScore
    );
  },
  getHealthScoreFromResult(healthQuestions, resultsObject) {
    //if questions not answered give full marks
    if (
      resultsObject.exercise === null ||
      resultsObject.exercise === undefined ||
      !resultsObject.smoker
    ) {
      return 0;
    }

    return (
      SchemaService.getScoreFromResult(
        healthQuestions.smoker,
        resultsObject.smoker
      ) + this.getExerciseScore(resultsObject.exercise)
    );
  },
  getExerciseScore(result) {
    if (result === 0) {
      return 3;
    }
    if (result <= 2) {
      return 1;
    }
    return 0;
  },
  getSetupScoreFromResult(setupQuestions, setupResults) {
    let totalScore = 0;
    for (const key in setupQuestions) {
      const question = setupQuestions[key];
      const result = setupResults[key];
      if (key === "hours") {
        totalScore += this.getSetupHoursScore(result);
      }
      if (result != undefined && question.scores != undefined) {
        totalScore += SchemaService.getScoreFromResult(question, result);
      }
    }
    return totalScore;
  },
  getSetupHoursScore(result) {
    if (result <= 7) {
      return 0;
    }
    if (result == 8) {
      return 1;
    }
    return 2;
  },
  calculateDeskSetupScore(results) {
    if (!results.webcam.pictureTaken) {
      return 0;
    }
    return (
      this.getScreenDistanceScore(results) +
      this.getScreenHeightScore(results) +
      this.getShoulderHeightScore(results) +
      this.getShoulderToEarScore(results)
    );
  },
  getScreenDistanceScore(results) {
    var range = WebcamValues.getScreenDistanceRange(
      results.webcam.distanceFromScreen
    );
    switch (range) {
      case WebcamValues.RangeConstants.screenDistance.tooFar:
      case WebcamValues.RangeConstants.screenDistance.tooClose: {
        return 2;
      }
      case WebcamValues.RangeConstants.screenDistance.far:
      case WebcamValues.RangeConstants.screenDistance.close: {
        return 1;
      }
      default:
        return 0;
    }
  },
  getScreenHeightScore(results) {
    var range = WebcamValues.getScreenHeightRange(
      results.webcam.screenHeightAngle
    );
    switch (range) {
      case WebcamValues.RangeConstants.screenHeight.tooHigh:
      case WebcamValues.RangeConstants.screenHeight.tooLow: {
        return 2;
      }
      case WebcamValues.RangeConstants.screenHeight.tooHigh:
      case WebcamValues.RangeConstants.screenHeight.tooLow: {
        return 1;
      }
      default:
        return 0;
    }
  },
  getShoulderHeightScore(results) {
    var range = WebcamValues.getShoulderHeightRange(
      results.webcam.shoulderHeightDifference
    );
    switch (range) {
      case WebcamValues.RangeConstants.generic.bad:
        return 2;
      case WebcamValues.RangeConstants.generic.average:
        return 1;
      default:
        return 0;
    }
  },
  getShoulderToEarScore(results) {
    var range = WebcamValues.getShoulderToEarRange(
      results.webcam.shoulderToEarDifference
    );
    switch (range) {
      case WebcamValues.RangeConstants.generic.bad:
        return 2;
      case WebcamValues.RangeConstants.generic.average:
        return 1;
      default:
        return 0;
    }
  },
  getMaxWebcamScore(results) {
    return results.webcam.shoulderToEarDifference === null ? 6 : 8;
  },
  getVisualDeskPlannerScore(results, canvasHeight) {
    let deskItems = results.deskItems;
    let score = 0;
    score += this.getVisualDeskPlannerScreenScore(deskItems);
    score += this.getDeskItemCount("clutter", deskItems) > 0 ? -2 : 0;
    score += this.getDeskItemCount("stackOfPaper", deskItems) > 1 ? -1 : 0;
    score += this.getDeskItemCount("plants", deskItems) > 0 ? 1 : 0;
    score += this.getBeneficialDeskItemScore(
      this.getDeskItemCount("water", deskItems),
      [1, 0.75, 0.5]
    );
    score +=
      this.getDeskItemCount("window", deskItems) === 0 && this.workarea !== 4
        ? this.getBeneficialDeskItemScore(
            this.getDeskItemCount("lamp", deskItems),
            [2.5, 1.7, 0.8]
          )
        : 0;
    const mouseCount =
      this.getDeskItemCount("mouse", deskItems) +
      this.getDeskItemCount("rollerMouse", deskItems) +
      this.getDeskItemCount("trackpad", deskItems) +
      this.getDeskItemCount("ergonomicMouse", deskItems);
    score += this.getBeneficialDeskItemScore(mouseCount, [1.5, 1, 0.5]);
    score +=
      this.getDeskItemCount("keyboard", deskItems) +
        this.getDeskItemCount("ergonomicKeyboard", deskItems) >
      0
        ? 1.5
        : 0;
    if (results.workarea !== 4) {
      // Only account for windows if we're not in a phone booth.
      score += DeskAssessmentWindowScoring.getVisualDeskPlannerWindowScore(
        deskItems,
        canvasHeight
      );
    }

    // only negate score for bean bag, stool and gym ball if no other seating option exists
    score += this.getSimsSeatingScore(deskItems, results.workarea);

    if (results.workarea > SimsAssessmentText.getValueFromWorkArea("desk")) {
      score = score / 2;
    }

    // max possible score currently 10, we need to move this to a calculated value based on whts available & the scoring system
    return score < 0 ? 0 : (score / 10) * 100;
  },
  getSimsSeatingScore(deskItems, workarea) {
    var stoolMainSeat = this.isMainSeatingOption(deskItems, "stool", workarea);
    if (stoolMainSeat) {
      return -2.3;
    }
    return this.isMainSeatingOption(deskItems, "beanBag", workarea) ||
      this.isMainSeatingOption(deskItems, "gymBall", workarea)
      ? -2.5
      : 0;
  },
  getDeskItemCount(id, deskItems, idIncludes = false) {
    return deskItems.filter(i => (idIncludes ? i.id.includes(id) : i.id === id))
      .length;
  },
  getBeneficialDeskItemScore(count, scores) {
    // If too many positive items, penalise as creates clutter.
    switch (count) {
      case 0:
        return 0;
      case 1:
        return scores[0];
      case 2:
        return scores[1];
      default:
        return scores[2];
    }
  },
  getVisualDeskPlannerScreenScore(deskItems) {
    let monitorCount = this.getDeskItemCount("computerScreen", deskItems, true);
    let laptopCount = this.getDeskItemCount("laptop", deskItems);
    let laptopWithStandCount = this.getDeskItemCount(
      "laptopWithStand",
      deskItems
    );
    let mouseCount =
      this.getDeskItemCount("mouse", deskItems) ||
      this.getDeskItemCount("rollerMouse", deskItems) ||
      this.getDeskItemCount("trackpad", deskItems) ||
      this.getDeskItemCount("ergonomicMouse", deskItems);
    let keyboardCount =
      this.getDeskItemCount("keyboard", deskItems) ||
      this.getDeskItemCount("ergonomicKeyboard", deskItems);

    if (
      laptopWithStandCount > 0 ||
      (monitorCount == 1 && laptopCount === 0) ||
      monitorCount > 1
    ) {
      return 2.5;
    } else if (
      laptopCount === 1 &&
      monitorCount === 1 &&
      mouseCount === 1 &&
      keyboardCount === 1
    ) {
      return 1.7;
    } else if (
      laptopCount === 1 &&
      monitorCount === 1 &&
      (mouseCount === 0 || keyboardCount === 0)
    ) {
      return 0.8;
    }
    return 0;
  },
  getVisualDeskPlannerRating(results, canvasHeight) {
    if (results.deskItems.length === 0) {
      return { rating: "", score: null };
    }
    let score = this.getVisualDeskPlannerScore(results, canvasHeight);
    let rating = this.getVisualDeskPlannerRatingFromScore(score);
    return { rating: rating, score: score };
  },
  getVisualDeskPlannerRatingFromScore(score) {
    let rating = i18n.t("deskAssessment.setup.sims.rating.veryGood");
    if (score < 39) {
      rating = i18n.t("deskAssessment.setup.sims.rating.needsImprovement");
    } else if (score < 56) {
      rating = i18n.t("deskAssessment.setup.sims.rating.fair");
    } else if (score < 78) {
      rating = i18n.t("deskAssessment.setup.sims.rating.good");
    }
    return rating;
  },
  getVisualDeskPlannerRatingColor(rating) {
    if (
      rating === i18n.t("deskAssessment.setup.sims.rating.needsImprovement")
    ) {
      return "poorOutcome";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.fair")) {
      return "fairOutcome";
    } else if (rating === i18n.t("deskAssessment.setup.sims.rating.good")) {
      return "primary";
    }
    return "green";
  },
  calculateVisualDeskPlannerResults(schema, results, deskItems) {
    var numberOfLaptops = this.getDeskItemCount("laptop", deskItems);
    var numberOfLaptopsWithStands = this.getDeskItemCount(
      "laptopWithStand",
      deskItems
    );
    var numberOfMonitors = this.getDeskItemCount(
      "computerScreen",
      deskItems,
      true
    );

    var multipleMonitors =
      numberOfLaptops + numberOfMonitors > 1 ||
      numberOfLaptopsWithStands + numberOfMonitors > 1;

    results.screennumber = this.getDeskPlannerResultsSchemaValue(
      schema,
      "screennumber",
      multipleMonitors ? "Yes" : "No"
    );

    results.laptop = this.getDeskPlannerResultsSchemaValue(
      schema,
      "laptop",
      numberOfLaptops + numberOfLaptopsWithStands > 0 ? "Yes" : "No"
    );

    results.dockingstation = this.getDeskPlannerResultsSchemaValue(
      schema,
      "dockingstation",
      numberOfLaptopsWithStands > 0 ? "Yes" : "No"
    );

    results.separatemousekeyboard = this.getDeskPlannerResultsSchemaValue(
      schema,
      "separatemousekeyboard",
      this.getDeskItemCount("keyboard", deskItems) +
        this.getDeskItemCount("ergonomicKeyboard", deskItems) >
        0 &&
        this.getDeskItemCount("mouse", deskItems) +
          this.getDeskItemCount("rollerMouse", deskItems) +
          this.getDeskItemCount("trackpad", deskItems) +
          this.getDeskItemCount("ergonomicMouse", deskItems) >
          0
        ? "Yes"
        : "No"
    );

    results.chair = this.getDeskPlannerResultsSchemaValue(
      schema,
      "chair",
      this.getDeskItemCount("chairNoArmrest", deskItems) > 0 ||
        this.getDeskItemCount("chairWithArmrest", deskItems) > 0 ||
        this.getDeskItemCount("stool", deskItems) > 0 ||
        this.getDeskItemCount("gymBall", deskItems) > 0 ||
        this.getDeskItemCount("beanBag", deskItems) > 0 ||
        this.getDeskItemCount("wheelchair", deskItems) > 0 ||
        this.getDeskItemCount("saddleChair", deskItems) > 0 ||
        this.getDeskItemCount("kneelingChair", deskItems) > 0
        ? "Yes"
        : "No"
    );

    let badSeatingOption =
      this.isMainSeatingOption(deskItems, "beanBag") ||
      this.isMainSeatingOption(deskItems, "gymBall") ||
      this.isMainSeatingOption(deskItems, "stool") ||
      (results.workarea && results.workarea > 1);
    results.armrests = badSeatingOption ? 2 : undefined;
    results.backsupport = badSeatingOption ? 2 : undefined;
    results.seatadjustable = badSeatingOption ? 2 : undefined;

    // if there is no chair, leave answer as undefined as otherwise question can be pre-filled on next page
    if (results.chair == 2) {
      results.armrests = undefined;
    } else {
      results.armrests = this.getDeskPlannerResultsSchemaValue(
        schema,
        "armrests",
        this.getDeskItemCount("chairWithArmrest", deskItems) > 0 ? "Yes" : "No"
      );
    }

    results.water = this.getDeskPlannerResultsSchemaValue(
      schema,
      "water",
      this.getDeskItemCount("water", deskItems) > 0 ? "Yes" : "No"
    );
    results.naturallight = this.getDeskPlannerResultsSchemaValue(
      schema,
      "naturallight",
      this.getDeskItemCount("window", deskItems) > 0 ? "Yes" : "No"
    );
    results.plants = this.getDeskPlannerResultsSchemaValue(
      schema,
      "plants",
      this.getDeskItemCount("plants", deskItems) > 0 ? "Yes" : "No"
    );
    results.wristrest = this.getDeskPlannerResultsSchemaValue(
      schema,
      "wristrest",
      this.getDeskItemCount("wristRest", deskItems) > 0 ? "Yes" : "No"
    );
    results.clutter = this.getDeskPlannerResultsSchemaValue(
      schema,
      "clutter",
      this.getDeskItemCount("clutter", deskItems) > 0 ? "Yes" : "No"
    );

    results.deskItems = deskItems;
  },
  getDeskPlannerResultsSchemaValue(schema, jsonName, optionsLabel) {
    return schema[2].pages[1].questions
      .find(q => q.name === jsonName)
      .options.find(o => o.label === optionsLabel).value;
  },
  isMainSeatingOption(deskItems, itemName, workarea) {
    if (workarea === 2 && workarea === 3) {
      return false; // return for sofa or bed
    }

    let seatingOptions = SimsComponents.find(i => i.id === "seats").items;
    let comparingOption = seatingOptions.find(i => i.id === itemName);

    // if seat doesn't exist just return false
    // if it does exist check if any seat with higher priority exists, if not then comparing option is main seat
    let seatExists = this.getDeskItemCount(itemName, deskItems) > 0;
    if (!seatExists) {
      return false;
    }

    let higherPriorityExists = false;
    seatingOptions.forEach(x => {
      if (x.priority < comparingOption.priority && !higherPriorityExists) {
        higherPriorityExists = this.getDeskItemCount(x.id, deskItems) > 0;
      }
    });
    return !higherPriorityExists;
  },
  getSchemaPages(schema) {
    return schema.reduce(function (acc, item) {
      item.pages.forEach(page => acc.push({ theme: item.theme, page: page }));
      return acc;
    }, []);
  },
  hasRedFlags(area, answers) {
    var painSection = DeskAssessmentQuestions.find(x => x.theme === "pain");
    var painQuestions = painSection.pages[0].questions[area];

    for (let key of Object.keys(answers)) {
      var answer = answers[key];
      var question = painQuestions.find(x => x.name === key);

      if (!question) {
        var message = `Question ${key} not found in ${area} pain section when getting red flags.`;
        var context = `Answers: ${JSON.stringify(
          answers
        )}, Questions: ${JSON.stringify(painQuestions)}`;
        sentry.captureMessage(message + context);
        continue;
      }

      var questionOptions = question.options ?? [];
      var hasFlag = false;
      if (Array.isArray(answer)) {
        var values = questionOptions.filter(x =>
          answer.some(t => x.value === t)
        );
        hasFlag = values.some(t => this.redFlagCheck(answers, t));
      } else {
        var value = questionOptions.find(x => x.value === answer);
        if (value) {
          hasFlag = this.redFlagCheck(answers, value);
        }
      }
      if (hasFlag) {
        return true;
      }
    }

    return false;
  },
  // checks if answer should return red flag value
  redFlagCheck(answers, redFlagValue) {
    // if red flag is not  defined return false.
    if (!redFlagValue.redFlag) {
      return false;
    }

    // if red flag is a boolean return boolean value
    if (typeof redFlagValue.redFlag === "boolean") {
      return redFlagValue.redFlag;
    }

    // if red flag is not a boolean assume it's an object/array which follows the criteria checker format
    return criteriaChecker.areCriteriaMet(answers, redFlagValue.redFlag);
  },
  getCurrentAssessmentIndex(originalResults) {
    const results = cloneDeep(originalResults);

    let pages = this.getSchemaPages(DeskAssessmentQuestions);
    if (results.completedAt) {
      return pages.filter(x => x.theme !== "report").length;
    }

    let pageStatuses = pages.map(x => {
      return { theme: x.theme, page: x.page, completed: false };
    });

    for (let i = 0; i < pageStatuses.length; i++) {
      let section = pageStatuses[i];
      let answers = results[section.theme];
      var questionIds = getSchemaSectionQuestionIds(section);
      var answerIds = getAnswerIds(section, answers);
      section.completed = schemaSectionIsComplete(
        section,
        questionIds,
        answerIds,
        answers
      );
    }

    var lastAnsweredSection = pageStatuses.filter(x => x.completed).pop();
    var lastAnsweredIndex = pageStatuses.indexOf(lastAnsweredSection);

    if (lastAnsweredSection && lastAnsweredSection.page.name === "sims") {
      return lastAnsweredIndex;
    }

    return lastAnsweredSection ? lastAnsweredIndex + 1 : 0;
  },
  getPercentageScore(scoreValue, totalPossibleScore) {
    if (scoreValue === 0) {
      return 100;
    }
    const percentage = (1 - scoreValue / totalPossibleScore) * 100;
    return Math.round(percentage);
  },
  getStandaloneSetupPercentageScore(results) {
    const equipmentPercentage = this.calculateEquipmentPercentage(results);
    const environmentPercentage = this.calculateEnvironmentPercentage(results);
    if (environmentPercentage) {
      // the weightings come from this document as of 12/07/2022:
      // https://docs.google.com/spreadsheets/d/1RMxxeTPgIexTqzmj6kNL_hsp8CuMZDrwe9f7iMw1SWQ/edit#gid=0
      return Math.round(
        environmentPercentage * 0.25 + equipmentPercentage * 0.75
      );
    }
    return equipmentPercentage;
  },
  getNumberOfScreens(results) {
    if (!results.setup?.deskItems) {
      return 0;
    }

    return results.setup.deskItems.filter(
      i =>
        i.id === "laptopWithStand" ||
        i.id === "laptop" ||
        i.id.includes("computerScreen")
    ).length;
  }
};

function getAnswerIds(section, answers) {
  if (section.theme === "pain") {
    let painAnswers = Object.values(answers.areas);
    answers = painAnswers.length > 0 ? Object.assign(...painAnswers) : {};
  }

  var uniqueAnswers = [...new Set(Object.keys(answers))];
  return uniqueAnswers;
}

function getSchemaSectionQuestionIds(section) {
  let questions = section.page.questions ?? [];

  if (section.theme === "pain") {
    questions = Object.values(questions).flatMap(x => x);
  }

  var mandatoryQuestionIds = questions.filter(x => !x.showOn);
  var uniqueIds = [...new Set(mandatoryQuestionIds.map(a => a.name))];
  return uniqueIds;
}

function schemaSectionIsComplete(section, questionIds, answerIds, answers) {
  let sectionAnswered = false;
  if (section.theme === "webcam") {
    sectionAnswered = Object.values(answers).some(a => a !== null);
  } else if (section.page.name === "sims") {
    sectionAnswered = answers.deskItems.length > 0;
  } else {
    sectionAnswered =
      answerIds.length > 0 &&
      questionIds.length > 0 &&
      questionIds.every(a => answerIds.includes(a));
  }
  return sectionAnswered;
}
