<template>
  <vfl-icon-button
    size="small"
    variant="primary"
    is-light
    :screen-reader="screenReaderText"
    @click="openConfigDialog"
    class="ml-3"
    ><v-icon class="icon" color="vflPrimary" size="16"
      >mdi-cog</v-icon
    ></vfl-icon-button
  >
</template>
<script>
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "AssessmentConfigurationButton",
  computed: {
    screenReaderText() {
      return this.$t("assessmentConfiguration.description");
    }
  },
  methods: {
    openConfigDialog() {
      sendEvent("Assessment configuration dialog opened");

      this.$emit("openConfigDialog");
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  top: -1px;
}
</style>
