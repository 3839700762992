<template>
  <svg
    width="640"
    height="637"
    viewBox="0 0 640 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="530.5" cy="542.5" rx="17.5" ry="5.5" fill="#F5B255" />
    <path
      d="M512.243 542.605L508 537V498L512.516 503.331L512.243 542.605Z"
      fill="#DA313E"
    />
    <path
      d="M538.169 503.463L533.275 497.438C533.085 497.205 532.8 497.069 532.498 497.069H509.101C508.259 497.069 507.794 498.046 508.325 498.7L512.194 503.463H538.169Z"
      fill="#D02D39"
    />
    <path
      d="M512.194 541.605C512.194 542.158 512.642 542.605 513.194 542.605H537.169C537.721 542.605 538.169 542.158 538.169 541.605V503.855C538.169 503.303 537.721 502.855 537.169 502.855H513.194C512.642 502.855 512.194 503.303 512.194 503.855V541.605Z"
      fill="#E93D4A"
    />
    <path
      d="M523.5 563L517.79 556.015V509.652L523.412 516.882L523.5 563Z"
      fill="#DA313E"
    />
    <path
      d="M550 517L544.967 510.394C544.778 510.146 544.483 510 544.171 510H518L523.333 517H550Z"
      fill="#D02D39"
    />
    <ellipse cx="538" cy="563.5" rx="15" ry="3.5" fill="#F5B255" />
    <path
      d="M523.292 562.246C523.292 562.798 523.74 563.246 524.292 563.246H549.405C549.958 563.246 550.405 562.798 550.405 562.246V517.883C550.405 517.33 549.958 516.883 549.405 516.883H524.292C523.74 516.883 523.292 517.33 523.292 517.883V562.246Z"
      fill="#E93D4A"
    />
  </svg>
</template>
<script>
export default {
  name: "PrenatalYogaOverlay"
};
</script>
