export async function testHeadless() {
    let tests = {};

    try {
        // Suggestions from https://piprogramming.org/articles/6-Ways-to-detect-a-headless-Browser-with-JavaScript--How-to-detect-a-Headless-Browser-0000000030.html
        tests.chromeDevConsole = testChromeDeveloperConsole();
        tests.notificationPermissions = await testNotificationPermissions();
        tests.inlinePdf = navigator.pdfViewerEnabled
        tests.appVersion = testAppVersion();
        tests.connectionRtt = testConnection();

        // Various device testing ideas.
        tests.mousePosition = await testMouse();
        let canEnumerateDevices = navigator.mediaDevices?.enumerateDevices;
        if (!canEnumerateDevices) {
            tests.enumerateDevices = false;
        } else {
            tests.speaker = await testSpeaker();
            tests.microphone = await testMicrophone();
            tests.webcam = await testWebcam();
        }

        // Known properties in headed browsing testing ideas.
        tests.windowProperties = testWindowProperties();
        tests.gpuStatus = await testGpu();
    }
    catch (err) {
        tests.push({test: "Error", result: err});
        return tests;
    }
    
    return tests;
}

function testChromeDeveloperConsole() {
    return !isChromeBrowser() || !!window.chrome;
}

async function testNotificationPermissions() {
    return new Promise(async resolve => {
        const permissionStatus = await navigator.permissions.query({
            name: 'notifications'
        });
        if (Notification.permission === 'denied' && permissionStatus.state === 'prompt') {
            // Headless
            resolve(false);
        } else {
            // Not Headless
            resolve(true);
        }
    });
}

function testAppVersion() {
    return !/headless/i.test(navigator.appVersion);
}

function testConnection() {
    let connection = navigator.connection;
    return {
        downlink: connection.downlink,
        rtt: connection.rtt,
        effectiveType: connection.effectiveType
    }
}

async function testMouse() {
    return new Promise(resolve => {
        const mouseHandler = (event) => {
            removeEventListener('mousemove', mouseHandler);
            resolve(logMouseEvent(event));
        };
        addEventListener('mousemove', mouseHandler);
    });
}

function logMouseEvent(event) {
    return {
        isTrusted: event.isTrusted,
        altKey: event.altKey,
        bubbles: event.bubbles,
        button: event.button,
        buttons: event.buttons,
        cancelBubble: event.cancelBubble,
        cancelable: event.cancelable,
        clientX: event.clientX,
        clientY: event.clientY,
        composed: event.composed,
        ctrlKey: event.ctrlKey,
        defaultPrevented: event.defaultPrevented,
        detail: event.detail,
        eventPhase: event.eventPhase,
        layerX: event.layerX,
        layerY: event.layerY,
        metaKey: event.metaKey,
        movementX: event.movementX,
        movementY: event.movementY,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
        pageX: event.pageX,
        pageY: event.pageY,
        returnValue: event.returnValue,
        screenX: event.screenX,
        screenY: event.screenY,
        shiftKey: event.shiftKey,
        timeStamp: event.timeStamp,
    };
}

async function testSpeaker() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'audiooutput');
}

async function testMicrophone() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'audioinput');
}

async function testWebcam() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'videoinput');
}

function testWindowProperties() {
    const windowProperties = Object.getOwnPropertyNames(window);
    const missingCommonProperties = commonWindowProperties.filter(item => !windowProperties.includes(item));
    const uniqueProperties = windowProperties.filter(item => !commonWindowProperties.includes(item));
    const unknownUniqueProperties = uniqueProperties.filter(item => !uniqueChromeWindowProperties.includes(item) && !uniqueFirefoxWindowProperties.includes(item) && !uniqueSafariWindowProperties.includes(item));
    return {missingCommonProperties: JSON.stringify(missingCommonProperties), unknownUniqueProperties: JSON.stringify(unknownUniqueProperties)};
}

async function testGpu() {
    const adaptor = await navigator.gpu.requestAdapter();
    return {
        isGpuAvailable: adaptor !== null,
        isGpuSupported: adaptor !== undefined,
        architecture: adaptor?.info.architecture,
        description: adaptor?.info.description,
        device: adaptor?.info.device,
        subgroupMaxSize: adaptor?.info.subgroupMaxSize,
        subgroupMinSize: adaptor?.info.subgroupMinSize,
        vendor: adaptor?.info.vendor,
        isFallbackAdaptor: adaptor?.isFallbackAdaptor
    }
}

function isChromeBrowser() {
    return eval.toString().length == 33;
}

function isIeBrowser() {
    return eval.toString().length == 39;
}

function isFirefoxSafariBrowser() {
    return eval.toString().length == 37;
}

// Generated by running Object.getOwnPropertyNames(window) in Chrome, Safari and Firefox (Edge was almost exactly the same as chrome with one unique element).
// These were then combined using const commonWindowProperties = chromeWindowProperties.filter(item => safariWindowProperties.includes(item) && firefoxWindowProperties.includes(item))
const commonWindowProperties = [
    "0",
    "Object",
    "Function",
    "Array",
    "Number",
    "parseFloat",
    "parseInt",
    "Infinity",
    "NaN",
    "undefined",
    "Boolean",
    "String",
    "Symbol",
    "Date",
    "Promise",
    "RegExp",
    "Error",
    "AggregateError",
    "EvalError",
    "RangeError",
    "ReferenceError",
    "SyntaxError",
    "TypeError",
    "URIError",
    "globalThis",
    "JSON",
    "Math",
    "Intl",
    "ArrayBuffer",
    "Atomics",
    "Uint8Array",
    "Int8Array",
    "Uint16Array",
    "Int16Array",
    "Uint32Array",
    "Int32Array",
    "BigUint64Array",
    "BigInt64Array",
    "Uint8ClampedArray",
    "Float32Array",
    "Float64Array",
    "DataView",
    "Map",
    "BigInt",
    "Set",
    "WeakMap",
    "WeakSet",
    "Proxy",
    "Reflect",
    "FinalizationRegistry",
    "WeakRef",
    "decodeURI",
    "decodeURIComponent",
    "encodeURI",
    "encodeURIComponent",
    "escape",
    "unescape",
    "eval",
    "isFinite",
    "isNaN",
    "console",
    "Option",
    "Image",
    "Audio",
    "webkitURL",
    "WebKitCSSMatrix",
    "XSLTProcessor",
    "XPathResult",
    "XPathExpression",
    "XPathEvaluator",
    "XMLSerializer",
    "XMLHttpRequestUpload",
    "XMLHttpRequestEventTarget",
    "XMLHttpRequest",
    "XMLDocument",
    "WritableStreamDefaultWriter",
    "WritableStreamDefaultController",
    "WritableStream",
    "Worker",
    "Window",
    "WheelEvent",
    "WebSocket",
    "WebGLVertexArrayObject",
    "WebGLUniformLocation",
    "WebGLTransformFeedback",
    "WebGLTexture",
    "WebGLSync",
    "WebGLShaderPrecisionFormat",
    "WebGLShader",
    "WebGLSampler",
    "WebGLRenderingContext",
    "WebGLRenderbuffer",
    "WebGLQuery",
    "WebGLProgram",
    "WebGLFramebuffer",
    "WebGLContextEvent",
    "WebGLBuffer",
    "WebGLActiveInfo",
    "WebGL2RenderingContext",
    "WaveShaperNode",
    "VisualViewport",
    "VideoPlaybackQuality",
    "ValidityState",
    "VTTCue",
    "UserActivation",
    "URLSearchParams",
    "URL",
    "UIEvent",
    "TreeWalker",
    "TransitionEvent",
    "TransformStreamDefaultController",
    "TransformStream",
    "TrackEvent",
    "ToggleEvent",
    "TimeRanges",
    "TextTrackList",
    "TextTrackCueList",
    "TextTrackCue",
    "TextTrack",
    "TextMetrics",
    "TextEncoderStream",
    "TextEncoder",
    "TextDecoderStream",
    "TextDecoder",
    "Text",
    "SubmitEvent",
    "StyleSheetList",
    "StyleSheet",
    "StorageEvent",
    "Storage",
    "StereoPannerNode",
    "StaticRange",
    "SourceBufferList",
    "SourceBuffer",
    "ShadowRoot",
    "Selection",
    "SecurityPolicyViolationEvent",
    "ScriptProcessorNode",
    "ScreenOrientation",
    "Screen",
    "SVGViewElement",
    "SVGUseElement",
    "SVGUnitTypes",
    "SVGTransformList",
    "SVGTransform",
    "SVGTitleElement",
    "SVGTextPositioningElement",
    "SVGTextPathElement",
    "SVGTextElement",
    "SVGTextContentElement",
    "SVGTSpanElement",
    "SVGSymbolElement",
    "SVGSwitchElement",
    "SVGStyleElement",
    "SVGStringList",
    "SVGStopElement",
    "SVGSetElement",
    "SVGScriptElement",
    "SVGSVGElement",
    "SVGRectElement",
    "SVGRect",
    "SVGRadialGradientElement",
    "SVGPreserveAspectRatio",
    "SVGPolylineElement",
    "SVGPolygonElement",
    "SVGPointList",
    "SVGPoint",
    "SVGPatternElement",
    "SVGPathElement",
    "SVGNumberList",
    "SVGNumber",
    "SVGMetadataElement",
    "SVGMatrix",
    "SVGMaskElement",
    "SVGMarkerElement",
    "SVGMPathElement",
    "SVGLinearGradientElement",
    "SVGLineElement",
    "SVGLengthList",
    "SVGLength",
    "SVGImageElement",
    "SVGGraphicsElement",
    "SVGGradientElement",
    "SVGGeometryElement",
    "SVGGElement",
    "SVGForeignObjectElement",
    "SVGFilterElement",
    "SVGFETurbulenceElement",
    "SVGFETileElement",
    "SVGFESpotLightElement",
    "SVGFESpecularLightingElement",
    "SVGFEPointLightElement",
    "SVGFEOffsetElement",
    "SVGFEMorphologyElement",
    "SVGFEMergeNodeElement",
    "SVGFEMergeElement",
    "SVGFEImageElement",
    "SVGFEGaussianBlurElement",
    "SVGFEFuncRElement",
    "SVGFEFuncGElement",
    "SVGFEFuncBElement",
    "SVGFEFuncAElement",
    "SVGFEFloodElement",
    "SVGFEDropShadowElement",
    "SVGFEDistantLightElement",
    "SVGFEDisplacementMapElement",
    "SVGFEDiffuseLightingElement",
    "SVGFEConvolveMatrixElement",
    "SVGFECompositeElement",
    "SVGFEComponentTransferElement",
    "SVGFEColorMatrixElement",
    "SVGFEBlendElement",
    "SVGEllipseElement",
    "SVGElement",
    "SVGDescElement",
    "SVGDefsElement",
    "SVGComponentTransferFunctionElement",
    "SVGClipPathElement",
    "SVGCircleElement",
    "SVGAnimationElement",
    "SVGAnimatedTransformList",
    "SVGAnimatedString",
    "SVGAnimatedRect",
    "SVGAnimatedPreserveAspectRatio",
    "SVGAnimatedNumberList",
    "SVGAnimatedNumber",
    "SVGAnimatedLengthList",
    "SVGAnimatedLength",
    "SVGAnimatedInteger",
    "SVGAnimatedEnumeration",
    "SVGAnimatedBoolean",
    "SVGAnimatedAngle",
    "SVGAnimateTransformElement",
    "SVGAnimateMotionElement",
    "SVGAnimateElement",
    "SVGAngle",
    "SVGAElement",
    "Response",
    "ResizeObserverSize",
    "ResizeObserverEntry",
    "ResizeObserver",
    "Request",
    "ReadableStreamDefaultReader",
    "ReadableStreamDefaultController",
    "ReadableStream",
    "Range",
    "RadioNodeList",
    "RTCTrackEvent",
    "RTCStatsReport",
    "RTCSessionDescription",
    "RTCSctpTransport",
    "RTCRtpTransceiver",
    "RTCRtpSender",
    "RTCRtpReceiver",
    "RTCPeerConnectionIceEvent",
    "RTCPeerConnection",
    "RTCIceTransport",
    "RTCIceCandidate",
    "RTCEncodedVideoFrame",
    "RTCEncodedAudioFrame",
    "RTCDtlsTransport",
    "RTCDataChannelEvent",
    "RTCDTMFToneChangeEvent",
    "RTCDTMFSender",
    "RTCCertificate",
    "PromiseRejectionEvent",
    "ProgressEvent",
    "ProcessingInstruction",
    "PopStateEvent",
    "PointerEvent",
    "PluginArray",
    "Plugin",
    "PeriodicWave",
    "PerformanceTiming",
    "PerformanceServerTiming",
    "PerformanceResourceTiming",
    "PerformancePaintTiming",
    "PerformanceObserverEntryList",
    "PerformanceObserver",
    "PerformanceNavigationTiming",
    "PerformanceNavigation",
    "PerformanceMeasure",
    "PerformanceMark",
    "PerformanceEntry",
    "Performance",
    "Path2D",
    "PannerNode",
    "PageTransitionEvent",
    "OscillatorNode",
    "OffscreenCanvasRenderingContext2D",
    "OffscreenCanvas",
    "OfflineAudioContext",
    "OfflineAudioCompletionEvent",
    "NodeList",
    "NodeIterator",
    "NodeFilter",
    "Node",
    "Navigator",
    "NamedNodeMap",
    "MutationRecord",
    "MutationObserver",
    "MouseEvent",
    "MimeTypeArray",
    "MimeType",
    "MessagePort",
    "MessageEvent",
    "MessageChannel",
    "MediaStreamTrackEvent",
    "MediaStreamTrack",
    "MediaStreamAudioSourceNode",
    "MediaStreamAudioDestinationNode",
    "MediaStream",
    "MediaSource",
    "MediaRecorder",
    "MediaQueryListEvent",
    "MediaQueryList",
    "MediaList",
    "MediaError",
    "MediaEncryptedEvent",
    "MediaElementAudioSourceNode",
    "MediaCapabilities",
    "MathMLElement",
    "Location",
    "KeyframeEffect",
    "KeyboardEvent",
    "IntersectionObserverEntry",
    "IntersectionObserver",
    "InputEvent",
    "ImageData",
    "ImageBitmapRenderingContext",
    "ImageBitmap",
    "IIRFilterNode",
    "IDBVersionChangeEvent",
    "IDBTransaction",
    "IDBRequest",
    "IDBOpenDBRequest",
    "IDBObjectStore",
    "IDBKeyRange",
    "IDBIndex",
    "IDBFactory",
    "IDBDatabase",
    "IDBCursorWithValue",
    "IDBCursor",
    "History",
    "Headers",
    "HashChangeEvent",
    "HTMLVideoElement",
    "HTMLUnknownElement",
    "HTMLUListElement",
    "HTMLTrackElement",
    "HTMLTitleElement",
    "HTMLTimeElement",
    "HTMLTextAreaElement",
    "HTMLTemplateElement",
    "HTMLTableSectionElement",
    "HTMLTableRowElement",
    "HTMLTableElement",
    "HTMLTableColElement",
    "HTMLTableCellElement",
    "HTMLTableCaptionElement",
    "HTMLStyleElement",
    "HTMLSpanElement",
    "HTMLSourceElement",
    "HTMLSlotElement",
    "HTMLSelectElement",
    "HTMLScriptElement",
    "HTMLQuoteElement",
    "HTMLProgressElement",
    "HTMLPreElement",
    "HTMLPictureElement",
    "HTMLParamElement",
    "HTMLParagraphElement",
    "HTMLOutputElement",
    "HTMLOptionsCollection",
    "HTMLOptionElement",
    "HTMLOptGroupElement",
    "HTMLObjectElement",
    "HTMLOListElement",
    "HTMLModElement",
    "HTMLMeterElement",
    "HTMLMetaElement",
    "HTMLMenuElement",
    "HTMLMediaElement",
    "HTMLMarqueeElement",
    "HTMLMapElement",
    "HTMLLinkElement",
    "HTMLLegendElement",
    "HTMLLabelElement",
    "HTMLLIElement",
    "HTMLInputElement",
    "HTMLImageElement",
    "HTMLIFrameElement",
    "HTMLHtmlElement",
    "HTMLHeadingElement",
    "HTMLHeadElement",
    "HTMLHRElement",
    "HTMLFrameSetElement",
    "HTMLFrameElement",
    "HTMLFormElement",
    "HTMLFormControlsCollection",
    "HTMLFontElement",
    "HTMLFieldSetElement",
    "HTMLEmbedElement",
    "HTMLElement",
    "HTMLDocument",
    "HTMLDivElement",
    "HTMLDirectoryElement",
    "HTMLDialogElement",
    "HTMLDetailsElement",
    "HTMLDataListElement",
    "HTMLDataElement",
    "HTMLDListElement",
    "HTMLCollection",
    "HTMLCanvasElement",
    "HTMLButtonElement",
    "HTMLBodyElement",
    "HTMLBaseElement",
    "HTMLBRElement",
    "HTMLAudioElement",
    "HTMLAreaElement",
    "HTMLAnchorElement",
    "HTMLAllCollection",
    "GeolocationPositionError",
    "GeolocationPosition",
    "GeolocationCoordinates",
    "Geolocation",
    "GamepadHapticActuator",
    "GamepadEvent",
    "GamepadButton",
    "Gamepad",
    "GainNode",
    "FormDataEvent",
    "FormData",
    "FontFace",
    "FocusEvent",
    "FileReader",
    "FileList",
    "File",
    "EventTarget",
    "EventSource",
    "Event",
    "ErrorEvent",
    "ElementInternals",
    "Element",
    "DynamicsCompressorNode",
    "DragEvent",
    "DocumentType",
    "DocumentTimeline",
    "DocumentFragment",
    "Document",
    "DelayNode",
    "DecompressionStream",
    "DataTransferItemList",
    "DataTransferItem",
    "DataTransfer",
    "DOMTokenList",
    "DOMStringMap",
    "DOMStringList",
    "DOMRectReadOnly",
    "DOMRectList",
    "DOMRect",
    "DOMQuad",
    "DOMPointReadOnly",
    "DOMPoint",
    "DOMParser",
    "DOMMatrixReadOnly",
    "DOMMatrix",
    "DOMImplementation",
    "DOMException",
    "CustomStateSet",
    "CustomEvent",
    "CustomElementRegistry",
    "Crypto",
    "CountQueuingStrategy",
    "ConvolverNode",
    "ContentVisibilityAutoStateChangeEvent",
    "ConstantSourceNode",
    "CompressionStream",
    "CompositionEvent",
    "Comment",
    "CloseEvent",
    "ClipboardEvent",
    "CharacterData",
    "ChannelSplitterNode",
    "ChannelMergerNode",
    "CanvasRenderingContext2D",
    "CanvasPattern",
    "CanvasGradient",
    "CSSTransition",
    "CSSSupportsRule",
    "CSSStyleSheet",
    "CSSStyleRule",
    "CSSStyleDeclaration",
    "CSSRuleList",
    "CSSRule",
    "CSSPageRule",
    "CSSNamespaceRule",
    "CSSMediaRule",
    "CSSLayerStatementRule",
    "CSSLayerBlockRule",
    "CSSKeyframesRule",
    "CSSKeyframeRule",
    "CSSImportRule",
    "CSSGroupingRule",
    "CSSFontPaletteValuesRule",
    "CSSFontFaceRule",
    "CSSCounterStyleRule",
    "CSSContainerRule",
    "CSSConditionRule",
    "CSSAnimation",
    "CSS",
    "CDATASection",
    "ByteLengthQueuingStrategy",
    "BroadcastChannel",
    "BlobEvent",
    "Blob",
    "BiquadFilterNode",
    "BeforeUnloadEvent",
    "BaseAudioContext",
    "BarProp",
    "AudioWorkletNode",
    "AudioScheduledSourceNode",
    "AudioProcessingEvent",
    "AudioParamMap",
    "AudioParam",
    "AudioNode",
    "AudioListener",
    "AudioDestinationNode",
    "AudioContext",
    "AudioBufferSourceNode",
    "AudioBuffer",
    "Attr",
    "AnimationTimeline",
    "AnimationPlaybackEvent",
    "AnimationEvent",
    "AnimationEffect",
    "Animation",
    "AnalyserNode",
    "AbstractRange",
    "AbortSignal",
    "AbortController",
    "window",
    "self",
    "document",
    "name",
    "location",
    "customElements",
    "history",
    "locationbar",
    "menubar",
    "personalbar",
    "scrollbars",
    "statusbar",
    "toolbar",
    "status",
    "closed",
    "frames",
    "length",
    "top",
    "opener",
    "parent",
    "frameElement",
    "navigator",
    "origin",
    "screen",
    "innerWidth",
    "innerHeight",
    "scrollX",
    "pageXOffset",
    "scrollY",
    "pageYOffset",
    "visualViewport",
    "screenX",
    "screenY",
    "outerWidth",
    "outerHeight",
    "devicePixelRatio",
    "event",
    "clientInformation",
    "screenLeft",
    "screenTop",
    "performance",
    "crypto",
    "indexedDB",
    "sessionStorage",
    "localStorage",
    "onabort",
    "onbeforetoggle",
    "onblur",
    "oncancel",
    "oncanplay",
    "oncanplaythrough",
    "onchange",
    "onclick",
    "onclose",
    "oncontextmenu",
    "oncuechange",
    "ondblclick",
    "ondrag",
    "ondragend",
    "ondragenter",
    "ondragleave",
    "ondragover",
    "ondragstart",
    "ondrop",
    "ondurationchange",
    "onemptied",
    "onended",
    "onerror",
    "onfocus",
    "onformdata",
    "oninput",
    "oninvalid",
    "onkeydown",
    "onkeypress",
    "onkeyup",
    "onload",
    "onloadeddata",
    "onloadedmetadata",
    "onloadstart",
    "onmousedown",
    "onmouseenter",
    "onmouseleave",
    "onmousemove",
    "onmouseout",
    "onmouseover",
    "onmouseup",
    "onpause",
    "onplay",
    "onplaying",
    "onprogress",
    "onratechange",
    "onreset",
    "onresize",
    "onscroll",
    "onsecuritypolicyviolation",
    "onseeked",
    "onseeking",
    "onselect",
    "onslotchange",
    "onstalled",
    "onsubmit",
    "onsuspend",
    "ontimeupdate",
    "ontoggle",
    "onvolumechange",
    "onwaiting",
    "onwebkitanimationend",
    "onwebkitanimationiteration",
    "onwebkitanimationstart",
    "onwebkittransitionend",
    "onwheel",
    "ongotpointercapture",
    "onlostpointercapture",
    "onpointerdown",
    "onpointermove",
    "onpointerup",
    "onpointercancel",
    "onpointerover",
    "onpointerout",
    "onpointerenter",
    "onpointerleave",
    "onselectstart",
    "onselectionchange",
    "onanimationend",
    "onanimationiteration",
    "onanimationstart",
    "ontransitionrun",
    "ontransitionstart",
    "ontransitionend",
    "ontransitioncancel",
    "onafterprint",
    "onbeforeprint",
    "onbeforeunload",
    "onhashchange",
    "onlanguagechange",
    "onmessage",
    "onmessageerror",
    "onoffline",
    "ononline",
    "onpagehide",
    "onpageshow",
    "onpopstate",
    "onrejectionhandled",
    "onstorage",
    "onunhandledrejection",
    "onunload",
    "isSecureContext",
    "crossOriginIsolated",
    "alert",
    "atob",
    "blur",
    "btoa",
    "cancelAnimationFrame",
    "captureEvents",
    "clearInterval",
    "clearTimeout",
    "close",
    "confirm",
    "createImageBitmap",
    "fetch",
    "find",
    "focus",
    "getComputedStyle",
    "getSelection",
    "matchMedia",
    "moveBy",
    "moveTo",
    "open",
    "postMessage",
    "print",
    "prompt",
    "queueMicrotask",
    "releaseEvents",
    "reportError",
    "requestAnimationFrame",
    "resizeBy",
    "resizeTo",
    "scroll",
    "scrollBy",
    "scrollTo",
    "setInterval",
    "setTimeout",
    "stop",
    "structuredClone",
    "WebAssembly",
    "caches",
    "ondevicemotion",
    "ondeviceorientation",
    "AudioWorklet",
    "Cache",
    "CacheStorage",
    "Clipboard",
    "ClipboardItem",
    "Credential",
    "CredentialsContainer",
    "CryptoKey",
    "DeviceMotionEvent",
    "DeviceOrientationEvent",
    "MediaDeviceInfo",
    "MediaDevices",
    "MediaKeyMessageEvent",
    "MediaKeySession",
    "MediaKeyStatusMap",
    "MediaKeySystemAccess",
    "MediaKeys",
    "NavigationPreloadManager",
    "ServiceWorker",
    "ServiceWorkerContainer",
    "ServiceWorkerRegistration",
    "StorageManager",
    "SubtleCrypto",
    "Worklet",
    "AuthenticatorAssertionResponse",
    "AuthenticatorAttestationResponse",
    "AuthenticatorResponse",
    "PublicKeyCredential",
    "FileSystemDirectoryHandle",
    "FileSystemFileHandle",
    "FileSystemHandle",
    "Lock",
    "LockManager",
    "WakeLock",
    "WakeLockSentinel",
    "speechSynthesis",
    "CSSFontFeatureValuesRule",
    "MediaMetadata",
    "MediaSession",
    "Notification",
    "PermissionStatus",
    "Permissions",
    "PushManager",
    "PushSubscription",
    "PushSubscriptionOptions",
    "RTCDataChannel",
    "SharedWorker",
    "SpeechSynthesis",
    "SpeechSynthesisErrorEvent",
    "SpeechSynthesisEvent",
    "SpeechSynthesisUtterance",
    "SpeechSynthesisVoice",
    "hj",
    "_hjSettings",
    "$",
    "jQuery",
    "unsupported",
    "unsupportedMessage",
    "app",
    "_sentryDebugIds",
    "webpackChunkStripeJSouter",
    "noop",
    "Stripe",
    "_sentryDebugIdIdentifier",
    "SENTRY_RELEASE",
    "global",
    "__SENTRY__",
    "__core-js_shared__",
    "core",
    "cptable",
    "XLSX",
    "Konva",
    "regeneratorRuntime",
    "dataLayer",
    "gtag"
];

const uniqueChromeWindowProperties = [
    "webkitRTCPeerConnection",
    "webkitMediaStream",
    "WindowControlsOverlayGeometryChangeEvent",
    "WindowControlsOverlay",
    "WebGLObject",
    "VisibilityStateEntry",
    "VirtualKeyboardGeometryChangeEvent",
    "ViewTransitionTypeSet",
    "ViewTimeline",
    "URLPattern",
    "TrustedTypePolicyFactory",
    "TrustedTypePolicy",
    "TrustedScriptURL",
    "TrustedScript",
    "TrustedHTML",
    "TouchList",
    "TouchEvent",
    "Touch",
    "TextUpdateEvent",
    "TextFormatUpdateEvent",
    "TextFormat",
    "TaskSignal",
    "TaskPriorityChangeEvent",
    "TaskController",
    "TaskAttributionTiming",
    "SyncManager",
    "ScrollTimeline",
    "Scheduling",
    "Scheduler",
    "Profiler",
    "PerformanceScriptTiming",
    "PerformanceLongTaskTiming",
    "PerformanceLongAnimationFrameTiming",
    "PerformanceElementTiming",
    "NetworkInformation",
    "NavigatorUAData",
    "NavigationTransition",
    "NavigationHistoryEntry",
    "NavigationDestination",
    "NavigationCurrentEntryChangeEvent",
    "Navigation",
    "NavigateEvent",
    "MediaStreamTrackVideoStats",
    "MediaStreamTrackProcessor",
    "MediaStreamTrackGenerator",
    "MediaStreamTrackAudioStats",
    "LayoutShiftAttribution",
    "LayoutShift",
    "InputDeviceCapabilities",
    "Ink",
    "ImageCapture",
    "FeaturePolicy",
    "External",
    "EncodedAudioChunk",
    "EditContext",
    "DelegatedInkTrailPresenter",
    "DOMError",
    "CloseWatcher",
    "CharacterBoundsUpdateEvent",
    "CSSPositionValue",
    "CSSPositionTryRule",
    "CSSPositionTryDescriptors",
    "CSSNestedDeclarations",
    "BrowserCaptureMediaStreamTrack",
    "BeforeInstallPromptEvent",
    "AudioSinkInfo",
    "AudioData",
    "navigation",
    "onsearch",
    "trustedTypes",
    "onappinstalled",
    "onbeforeinstallprompt",
    "onbeforexrselect",
    "onbeforematch",
    "onpointerrawupdate",
    "scheduler",
    "Iterator",
    "chrome",
    "cookieStore",
    "launchQueue",
    "sharedStorage",
    "documentPictureInPicture",
    "AICreateMonitor",
    "AbsoluteOrientationSensor",
    "Accelerometer",
    "AudioDecoder",
    "AudioEncoder",
    "BatteryManager",
    "CookieChangeEvent",
    "CookieStore",
    "CookieStoreManager",
    "DeviceMotionEventAcceleration",
    "DeviceMotionEventRotationRate",
    "FederatedCredential",
    "GPU",
    "GPUAdapter",
    "GPUAdapterInfo",
    "GPUBindGroup",
    "GPUBindGroupLayout",
    "GPUCanvasContext",
    "GPUCommandBuffer",
    "GPUCompilationInfo",
    "GPUCompilationMessage",
    "GPUComputePipeline",
    "GPUDevice",
    "GPUDeviceLostInfo",
    "GPUError",
    "GPUExternalTexture",
    "GPUInternalError",
    "GPUOutOfMemoryError",
    "GPUPipelineError",
    "GPUPipelineLayout",
    "GPUQuerySet",
    "GPURenderBundle",
    "GPURenderBundleEncoder",
    "GPURenderPipeline",
    "GPUSampler",
    "GPUShaderModule",
    "GPUSupportedFeatures",
    "GPUSupportedLimits",
    "GPUTexture",
    "GPUTextureView",
    "GPUUncapturedErrorEvent",
    "GPUValidationError",
    "GravitySensor",
    "Gyroscope",
    "IdleDetector",
    "ImageDecoder",
    "ImageTrack",
    "ImageTrackList",
    "Keyboard",
    "KeyboardLayoutMap",
    "LinearAccelerationSensor",
    "NavigatorManagedData",
    "OrientationSensor",
    "PasswordCredential",
    "ProtectedAudience",
    "RelativeOrientationSensor",
    "ScreenDetailed",
    "ScreenDetails",
    "Sensor",
    "SensorErrorEvent",
    "VirtualKeyboard",
    "WGSLLanguageFeatures",
    "XRDOMOverlayState",
    "XRLayer",
    "XRWebGLBinding",
    "Bluetooth",
    "BluetoothCharacteristicProperties",
    "BluetoothDevice",
    "BluetoothRemoteGATTCharacteristic",
    "BluetoothRemoteGATTDescriptor",
    "BluetoothRemoteGATTServer",
    "BluetoothRemoteGATTService",
    "CaptureController",
    "DevicePosture",
    "DocumentPictureInPicture",
    "EyeDropper",
    "FileSystemObserver",
    "FontData",
    "FragmentDirective",
    "HID",
    "HIDConnectionEvent",
    "HIDDevice",
    "HIDInputReportEvent",
    "IdentityCredential",
    "IdentityProvider",
    "NavigatorLogin",
    "IdentityCredentialError",
    "LaunchParams",
    "LaunchQueue",
    "NotRestoredReasonDetails",
    "NotRestoredReasons",
    "OTPCredential",
    "PaymentManager",
    "Presentation",
    "PresentationAvailability",
    "PresentationConnection",
    "PresentationConnectionAvailableEvent",
    "PresentationConnectionCloseEvent",
    "PresentationConnectionList",
    "PresentationReceiver",
    "PresentationRequest",
    "PressureObserver",
    "PressureRecord",
    "Serial",
    "SerialPort",
    "StorageBucket",
    "StorageBucketManager",
    "USB",
    "USBAlternateInterface",
    "USBConfiguration",
    "USBConnectionEvent",
    "USBDevice",
    "USBEndpoint",
    "USBInTransferResult",
    "USBInterface",
    "USBIsochronousInTransferPacket",
    "USBIsochronousInTransferResult",
    "USBIsochronousOutTransferPacket",
    "USBIsochronousOutTransferResult",
    "USBOutTransferResult",
    "XRAnchor",
    "XRAnchorSet",
    "XRBoundedReferenceSpace",
    "XRCPUDepthInformation",
    "XRCamera",
    "XRDepthInformation",
    "XRFrame",
    "XRHitTestResult",
    "XRHitTestSource",
    "XRInputSource",
    "XRInputSourceArray",
    "XRInputSourceEvent",
    "XRInputSourcesChangeEvent",
    "XRLightEstimate",
    "XRLightProbe",
    "XRPose",
    "XRRay",
    "XRReferenceSpace",
    "XRReferenceSpaceEvent",
    "XRRenderState",
    "XRRigidTransform",
    "XRSession",
    "XRSessionEvent",
    "XRSpace",
    "XRSystem",
    "XRTransientInputHitTestResult",
    "XRTransientInputHitTestSource",
    "XRView",
    "XRViewerPose",
    "XRViewport",
    "XRWebGLDepthInformation",
    "XRWebGLLayer",
    "XRHand",
    "XRJointPose",
    "XRJointSpace",
    "getDigitalGoodsService",
    "getScreenDetails",
    "queryLocalFonts",
    "showDirectoryPicker",
    "showOpenFilePicker",
    "showSaveFilePicker",
    "originAgentCluster",
    "onpageswap",
    "onpagereveal",
    "credentialless",
    "fence",
    "onscrollsnapchange",
    "onscrollsnapchanging",
    "BackgroundFetchManager",
    "BackgroundFetchRecord",
    "BackgroundFetchRegistration",
    "BluetoothUUID",
    "CSSMarginRule",
    "CSSViewTransitionRule",
    "ChapterInformation",
    "CropTarget",
    "DocumentPictureInPictureEvent",
    "Fence",
    "FencedFrameConfig",
    "HTMLFencedFrameElement",
    "HTMLSelectedContentElement",
    "PageRevealEvent",
    "PageSwapEvent",
    "PeriodicSyncManager",
    "RestrictionTarget",
    "SharedStorage",
    "SharedStorageWorklet",
    "SharedStorageAppendMethod",
    "SharedStorageClearMethod",
    "SharedStorageDeleteMethod",
    "SharedStorageModifierMethod",
    "SharedStorageSetMethod",
    "SnapEvent",
    "WebSocketError",
    "WebSocketStream",
    "webkitSpeechGrammar",
    "webkitSpeechGrammarList",
    "webkitSpeechRecognitionError",
    "webkitSpeechRecognitionEvent",
    "webkitRequestFileSystem",
    "webkitResolveLocalFileSystemURL",
    "dir",
    "dirxml",
    "profile",
    "profileEnd",
    "clear",
    "table",
    "keys",
    "values",
    "debug",
    "undebug",
    "monitor",
    "unmonitor",
    "inspect",
    "copy",
    "queryObjects",
    "$_",
    "$0",
    "$1",
    "$2",
    "$3",
    "$4",
    "getEventListeners",
    "getAccessibleName",
    "getAccessibleRole",
    "monitorEvents",
    "unmonitorEvents",
    "$$",
    "$x"
];

const uniqueSafariWindowProperties = [
    "GestureEvent",
    "WebKitPlaybackTargetAvailabilityEvent",
    "ApplePayError",
    "OverconstrainedErrorEvent",
    "SQLTransaction",
    "Counter",
    "CSSPrimitiveValue",
    "RGBColor",
    "Rect",
    "CSSValue",
    "CSSValueList",
    "OverflowEvent",
    "SVGDocument",
    "MediaController",
    "AudioTrack",
    "AudioTrackConfiguration",
    "AudioTrackList",
    "DataCue",
    "VideoTrack",
    "VideoTrackConfiguration",
    "VideoTrackList",
    "UserMessageHandler",
    "UserMessageHandlersNamespace",
    "WebKitNamespace",
    "WebKitPoint",
    "SVGPathSeg",
    "SVGPathSegArcAbs",
    "SVGPathSegArcRel",
    "SVGPathSegClosePath",
    "SVGPathSegCurvetoCubicAbs",
    "SVGPathSegCurvetoCubicRel",
    "SVGPathSegCurvetoCubicSmoothAbs",
    "SVGPathSegCurvetoCubicSmoothRel",
    "SVGPathSegCurvetoQuadraticAbs",
    "SVGPathSegCurvetoQuadraticRel",
    "SVGPathSegCurvetoQuadraticSmoothAbs",
    "SVGPathSegCurvetoQuadraticSmoothRel",
    "SVGPathSegLinetoAbs",
    "SVGPathSegLinetoHorizontalAbs",
    "SVGPathSegLinetoHorizontalRel",
    "SVGPathSegLinetoRel",
    "SVGPathSegLinetoVerticalAbs",
    "SVGPathSegLinetoVerticalRel",
    "SVGPathSegList",
    "SVGPathSegMovetoAbs",
    "SVGPathSegMovetoRel",
    "SVGViewSpec",
    "XMLHttpRequestProgressEvent",
    "openDatabase",
    "onwebkitmouseforcechanged",
    "onwebkitmouseforcedown",
    "onwebkitmouseforcewillbegin",
    "onwebkitmouseforceup",
    "webkitCancelRequestAnimationFrame",
    "webkitConvertPointFromPageToNode",
    "webkitConvertPointFromNodeToPage",
    "ApplePaySession",
    "ApplePaySetup",
    "ApplePaySetupFeature",
    "AudioSession",
    "WebKitMediaKeyMessageEvent",
    "WebKitMediaKeyNeededEvent",
    "WebKitMediaKeySession",
    "WebKitMediaKeys",
    "MediaSessionCoordinator",
    "BufferedChangeEvent",
    "ManagedMediaSource",
    "ManagedSourceBuffer",
    "MerchantValidationEvent",
    "Report",
    "SpeechRecognitionAlternative",
    "SpeechRecognitionErrorEvent",
    "SpeechRecognitionEvent",
    "SpeechRecognitionResult",
    "SpeechRecognitionResultList",
    "WebKitMediaKeyError",
    "MathMLMathElement",
    "webkitIndexedDB",
    "browser",
    "safari"
];

const uniqueFirefoxWindowProperties = [
    "InternalError",
    "WebTransportReceiveStream",
    "CSSMozDocumentRule",
    "MediaKeyError",
    "MediaStreamTrackAudioSourceNode",
    "TimeEvent",
    "Directory",
    "ScrollAreaEvent",
    "CanvasCaptureMediaStream",
    "GamepadPose",
    "KeyEvent",
    "MouseScrollEvent",
    "PopupBlockedEvent",
    "PaintRequest",
    "MediaCapabilitiesInfo",
    "WebTransportSendStream",
    "getDefaultComputedStyle",
    "scrollByLines",
    "scrollByPages",
    "updateCommands",
    "dump",
    "setResizable",
    "mozInnerScreenX",
    "mozInnerScreenY",
    "scrollMaxX",
    "scrollMaxY",
    "fullScreen",
    "InstallTrigger",
    "ondragexit",
    "onmozfullscreenchange",
    "onmozfullscreenerror",
    "netscape",
    "StyleSheetApplicableStateChangeEvent",
    "CSS2Properties",
    "PaintRequestList",
    "StyleSheetRemovedEvent",
    "NotifyPaintEvent",
    "FrameLoader"
];
