<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.circulation.title")
    }}</template>
    <template #content>
      <report-section-card
        imagePath="/images/pregnancy-assessment/report/woman-doing-yoga.svg"
        :fact="$t(`pregnancyAssessment.report.content.circulation.title`)"
        :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        ><template #text>
          <p
            v-for="(p, index) in pregnancyReportCirculationService.getText()"
            :key="`circulation-paragraph-${index}`"
            class="mb-8"
            v-html="p" /></template
      ></report-section-card>
      <recommendation-container
        class="mt-10"
        :title="
          $t(
            'pregnancyAssessment.report.content.circulation.recommendationsTitle'
          )
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="circulationRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { PregnancyReportCirculationService } from "@/components/assessment/pregnancy/report/pregnancy-report-circulation-service";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/pregnancy/report/recommendations/recommendation-service.js";
import ReportSectionCard from "@/components/assessment/common/report/ReportSectionCard.vue";

export default {
  name: "PregnancyReportCirculation",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer,
    ReportSectionCard
  },
  data() {
    return {
      pregnancyReportCirculationService: null,
      circulationRecommendations: {}
    };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.pregnancyReportCirculationService =
      new PregnancyReportCirculationService(this.results);

    this.circulationRecommendations = getRecommendationsByType(
      RecommendationSections.CIRCULATION,
      this.results
    );
  }
};
</script>
