<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle() }}</template>
    <template #content>
      <report-section-card
        imagePath="/images/illustrations/menopause-symptoms.svg"
        :fact="sectionTitle()"
        :assessmentType="$options.assessmentTypes.menopauseAssessment"
        ><template #text>
          <p v-for="p in content" :key="p">{{ p }}</p></template
        ></report-section-card
      >
      <recommendation-container
        v-if="hasRecommendations"
        class="mt-10"
        :title="$t('menopauseAssessment.report.recommendations.symptoms.title')"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="symptomsRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menopause/report/recommendations/recommendation-service.js";
import { assessmentTypes } from "@/constants/constants.js";
import ReportSectionCard from "@/components/assessment/common/report/ReportSectionCard.vue";

export default {
  name: "MenopauseReportSymptoms",
  components: {
    DidNotKnowThatFactButton,
    ReportSection,
    RecommendationContainer,
    ReportSectionCard
  },
  data() {
    return {
      symptomsRecommendations: {}
    };
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.symptomsRecommendations = getRecommendationsByType(
      RecommendationSections.SYMPTOMS,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return menopauseReportService.getSymptomsSectionTitle();
    },
    content() {
      return menopauseReportService.getSymptomsCardContent(this.results);
    },
    hasRecommendations() {
      return Object.keys(this.symptomsRecommendations?.groups || {}).length > 0;
    }
  }
};
</script>
