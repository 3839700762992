<template>
  <assessment-wrapper>
    <page-background
      v-if="currentStepObj.BACKGROUND && !loadingAssessment"
      :current-step="currentPageIndex"
      :backgrounds="pageBackgrounds"
    />

    <transition
      name="fade"
      mode="out-in"
      @after-leave="onDynamicAssessmentTransitionComplete"
    >
      <div
        v-if="
          !loadingReport &&
          currentTheme &&
          activeAsyncRequestsCount === 0 &&
          !loadingAssessment
        "
        :key="currentPageIndex"
        class="assessment-container"
        :class="containerClassList"
        :data-test-id="assessmentType"
      >
        <landing-page
          v-if="currentTheme === $options.steps.Welcome.THEME"
          :title="$t(`pregnancyAssessment.landingPage.title`)"
          :description="$t('pregnancyAssessment.landingPage.description')"
          imageSrc="/images/illustrations/pregnant-woman-sitting-illustration.svg"
          mobileImageSrc="/images/illustrations/pregnant-woman-sitting-illustration-mobile.svg"
          :show-background-blob="true"
          :show-image-blob="false"
          background-color="#F8F5F0"
          blob-color="#F0C460"
          callout-color="#F6EBDA"
          @next="onNext"
          class="assessment-full-width"
        />

        <dynamic-image
          v-if="hasDynamicImage"
          :schema="currentSchema"
          :currentTheme="currentTheme"
          :results="results"
          class="assessment-dynamic-image"
          :assessmentType="assessmentType"
          :style="currentStepObj.DYNAMIC_IMAGE_CSS"
        />

        <vfl-stepper
          class="mt-6 mb-4 assessment-stepper"
          :class="{ 'is-full-width': currentStepObj.OUTRO }"
          v-if="currentStepObj.STEPPER"
          :steps="stepper"
          :stepStructure="stepStructure"
          :currentStep="currentStepperIndex"
          :currentTheme="currentTheme"
          compact
        />

        <assessment-form
          v-if="currentStepObj.FORM"
          :key="currentTheme"
          :schema="currentSchema"
          v-model="results[currentTheme]"
          :ref="sectionKey"
          :isPainTheme="currentTheme === $options.steps.Pain.THEME"
          :results="results"
          :currentTheme="currentTheme"
          :assessmentType="assessmentType"
          :class="[
            'assessment-form ml-0 mr-0',
            {
              'assessment-full-width':
                currentTheme === $options.steps.Pain.THEME
            }
          ]"
          :disable-question-numbering="true"
        />

        <outro-page
          v-if="currentStepObj.OUTRO"
          :results="results"
          :step="currentStepObj"
          class="assessment-full-width assessment-outro-page"
        />

        <pregnancy-report
          v-if="currentTheme === $options.steps.Report.THEME"
          :results="results"
          :assessmentId="assessmentId"
          reportContext="assessment"
          class="assessment-full-width"
          style="position: relative"
        />

        <navigation
          v-if="currentStepObj.NAVIGATION"
          @previous="onPrevious"
          @next="onNext"
          :loading="nextClicked"
          :classList="navigationClassList"
          class="assessment-navigation assessment-full-width"
        />
      </div>
      <assessment-loader
        v-else-if="showLoader"
        @finished="onLoaderFinished"
        :animation-data="loaderAnimationData"
        class="assessment-loader"
      />
    </transition>
  </assessment-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { assessmentTypes } from "@/constants/constants.js";
import AssessmentForm from "@/components/assessment/AssessmentForm.vue";
import VflStepper from "@/components/common/VflStepper.vue";
import SaveAssessment from "@/components/common/assessments/save-assessment-service.js";
import {
  addAssessmentRecommendations,
  updateAssessmentState
} from "@/customApi";
import LoadingService from "@/services/assessment-loading.js";
import { GetAssessmentResults } from "@/components/common/assessments/get-assessment-service.js";
import { getSchema } from "@/services/assessment/get-schema-service.js";
import Navigation from "@/components/assessment/common/Navigation.vue";
import LandingPage from "@/components/assessment/common/landing-page/LandingPage.vue";
import {
  AssessmentSteps,
  getStepByIndex,
  Theme,
  THEME_ORDER
} from "@/components/assessment/pregnancy/steps.js";

import AssessmentLoader from "@/components/assessment/common/AssessmentLoader.vue";
import OutroPage from "@/components/assessment/pregnancy/outro-page/OutroPage.vue";
import DynamicImage from "@/components/assessment/common/dynamic-images/DynamicImage.vue";
import PageBackground from "@/components/assessment/common/PageBackground.vue";
import {
  buildStepperStructure,
  getCurrentStepperIndex
} from "@/components/assessment/common/step-builder-service.js";
import animationData from "@/assets/json/lottie/woman-waiting.json";
import AssessmentWrapper from "@/components/assessment/common/AssessmentWrapper.vue";
import PregnancyReport from "./report/PregnancyReport.vue";
import { getAllRecommendations } from "@/components/assessment/pregnancy/report/recommendations/recommendation-service.js";

const DEBUG_REPORT = false;

export default {
  name: "PregnancyAssessment",
  components: {
    AssessmentWrapper,
    PageBackground,
    DynamicImage,
    AssessmentLoader,
    AssessmentForm,
    VflStepper,
    Navigation,
    LandingPage,
    OutroPage,
    PregnancyReport
  },
  props: {
    assessmentId: String
  },
  steps: AssessmentSteps,
  data() {
    return {
      assessmentType: assessmentTypes.pregnancyAssessment,
      loaderAnimationData: animationData,
      currentPageIndex: 0,
      currentSchemaIndex: 0,
      stepper: null,
      stepStructure: null,
      results: {
        trimester: {},
        pain: {
          areas: {}
        },
        balance: {},
        circulation: {},
        lifestyle: {}
      },
      loadingReport: false,
      loadingAssessment: true,
      saving: false,
      nextClicked: false,
      loaderFinished: false
    };
  },
  created() {
    if (this.disableAssessments) {
      this.$router.push("/wellness");
    }

    this.resetAssessmentStore();

    this.schema = getSchema(assessmentTypes.pregnancyAssessment);

    this.buildSteps();
  },
  mounted() {
    document.body.classList.add("is-dynamic-layout-assessment");

    this.startAssessment();
  },
  beforeDestroy() {
    document.body.classList.remove("is-dynamic-layout-assessment");

    this.configureLayoutForAssessmentReport({
      condition: false,
      pageTitle: null
    });
  },
  computed: {
    ...mapGetters([
      "disableAssessments",
      "activeAsyncRequestsCount",
      "enablePregnancyAssessment"
    ]),
    containerClassList() {
      if (this.isWelcome) return "";
      if (this.isStepOutro) return "is-step-outro-page";

      return `mx-auto`;
    },
    currentStepObj() {
      if (DEBUG_REPORT) {
        return AssessmentSteps["Report"];
      }

      return getStepByIndex(this.currentPageIndex);
    },
    currentTheme() {
      if (DEBUG_REPORT) {
        return Theme.REPORT;
      }

      return this.currentStepObj?.THEME || Theme.WELCOME;
    },
    currentThemeQuestions() {
      if (this.currentTheme === Theme.PAIN) return this.currentSchema;
      const schemaForTheme = this.schema[this.currentTheme];
      return {
        theme: this.currentTheme,
        questions: schemaForTheme?.questions || []
      };
    },
    currentSchema() {
      return this.schema[this.currentTheme];
    },
    currentStepperIndex() {
      const step = getCurrentStepperIndex(
        this.currentTheme,
        this.stepStructure,
        AssessmentSteps
      );

      return step;
    },
    sectionKey() {
      return `${this.currentTheme}, page ${this.currentPageIndex}`;
    },
    onLastPage() {
      return this.currentTheme === Theme.LIFESTYLE;
    },
    isReport() {
      return this.currentTheme === Theme.REPORT;
    },
    isWelcome() {
      return this.currentTheme === Theme.WELCOME;
    },
    isStepOutro() {
      return this.currentStepObj.OUTRO;
    },
    isPain() {
      return this.currentTheme === Theme.PAIN;
    },
    hasDynamicImage() {
      return this.currentStepObj.DYNAMIC_IMAGE;
    },
    pageBackgrounds() {
      return Object.values(AssessmentSteps).map(step => step.BACKGROUND);
    },
    reportIndex() {
      return THEME_ORDER.findIndex(theme => theme === Theme.REPORT);
    },
    shouldSkipOutroPage() {
      const nextStep = getStepByIndex(this.currentPageIndex + 1);

      const skipSymptomsOutro = false; // add logic if required else remove

      return skipSymptomsOutro;
    },
    navigationClassList() {
      const noPaddingSteps = [Theme.PAIN];
      const isLargeScreen = this.$vuetify.breakpoint.mdAndUp;
      const isForm = this.currentStepObj.FORM;

      const shouldAddPadding = isLargeScreen
        ? !noPaddingSteps.includes(this.currentTheme) && isForm
        : true;

      const justifyClass =
        isLargeScreen && isForm ? "justify-end" : "justify-space-between";

      return shouldAddPadding ? `${justifyClass} pt-8` : justifyClass;
    },
    isLoadingReport() {
      return this.loadingReport && !this.loadingAssessment;
    },
    keepLoaderVisible() {
      return !this.loaderFinished || this.saving;
    },
    showLoader() {
      return this.isLoadingReport && this.keepLoaderVisible;
    }
  },
  watch: {
    assessmentId(val) {
      if (val) {
        this.startAssessment();
      }
    }
  },
  methods: {
    ...mapActions("layout", ["configureLayoutForAssessmentReport"]),
    ...mapActions({
      resetAssessmentStore: "assessmentStore/reset"
    }),
    scrollTop() {
      window.scrollTo(0, 0);
    },
    onPrevious() {
      const currentThemeIndex = THEME_ORDER.indexOf(this.currentTheme);

      if (currentThemeIndex > 0) {
        let prevIndex = this.currentPageIndex - 1;
        const prevStep = getStepByIndex(prevIndex);

        this.currentPageIndex = prevIndex;
      }
    },
    async onNext() {
      if (this.nextClicked) return;

      try {
        this.nextClicked = true;

        await this.goToNextPage();
      } catch (e) {
        this.$logger.captureException(e);
      } finally {
        this.nextClicked = false;
      }
    },
    async goToNextPage() {
      if (!this.currentStepObj.VALIDATION || this.isValid()) {
        await this.saveAssessment();
        const currentThemeIndex = THEME_ORDER.indexOf(this.currentTheme);

        if (currentThemeIndex < THEME_ORDER.length - 1) {
          this.currentPageIndex = this.getNextIndex();
        }

        this.$mixpanel.track(assessmentTypes.pregnancyAssessment, {
          step: `Go to ${this.sectionKey}`
        });
      }
    },
    getNextIndex() {
      let nextIndex = this.currentPageIndex + 1;

      if (this.shouldSkipOutroPage) {
        nextIndex++;
      }

      return nextIndex;
    },
    buildSteps() {
      this.stepStructure = buildStepperStructure(AssessmentSteps);
      this.stepper = this.stepStructure.length;
    },
    async startAssessment() {
      const setToOpened = true; // normally based on incomplete assessment dialog
      const getResults = false;

      const assessmentState = await LoadingService.getAssessmentStateAndResults(
        this.assessmentId,
        this.assessmentType,
        setToOpened,
        getResults
      );

      if (assessmentState.redirectObject) {
        this.$router.push(assessmentState.redirectObject);
        return;
      }

      // load schema after retrieving feature flags - required due to schema injection based on feature flags
      this.schema = getSchema(assessmentTypes.pregnancyAssessment);

      if (assessmentState.assessmentStarted) {
        this.results = await GetAssessmentResults(
          this.assessmentId,
          this.assessmentType,
          this.schema
        );
      }

      if (assessmentState.assessmentComplete) {
        const reportIndex = Object.values(AssessmentSteps).findIndex(
          step => step.THEME === Theme.REPORT
        );
        this.currentPageIndex = reportIndex;
      }

      this.loadingAssessment = false;
    },
    async saveAssessment() {
      const nextIndex = this.getNextIndex();
      const nextPageIsReport = nextIndex === this.reportIndex; // to mark as complete

      this.saving = true;
      if (nextPageIsReport) {
        this.loadingReport = true;
      }

      try {
        if (nextPageIsReport && this.currentStepObj.OUTRO) {
          await updateAssessmentState(this.assessmentId, "Completed");
        } else {
          const results = this.results[this.currentTheme];
          if (results) {
            await SaveAssessment(
              this.assessmentId,
              results,
              this.assessmentType,
              this.currentThemeQuestions,
              null,
              nextPageIsReport
            );
          }
        }

        if (nextPageIsReport) {
          await this.saveRecommendations();
        }
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.saving = false;
      }
    },
    async saveRecommendations() {
      const recommendations = getAllRecommendations(this.results);
      const allRecommendationIds = [];

      Object.values(recommendations).forEach(section => {
        if (section.groups) {
          Object.values(section.groups).forEach(group => {
            if (group.cards) {
              group.cards.forEach(card => {
                allRecommendationIds.push(card.id);
              });
            }
          });
        }
      });

      const dto = {
        assessmentId: this.assessmentId,
        recommendations: allRecommendationIds
      };

      await addAssessmentRecommendations(dto);
    },
    isValid() {
      if (this.isPain) {
        return this.isPainStepValid();
      }

      return this.$refs[this.sectionKey].isValid();
    },
    isPainStepValid() {
      const painPersonValid =
        this.$refs[this.sectionKey]?.$refs.painForm?.isValid() ?? false;
      const formValid = this.$refs[this.sectionKey].isValid();

      return painPersonValid && formValid;
    },
    onDynamicAssessmentTransitionComplete() {
      this.$emit("on-dynamic-assessment-transition-complete");
      this.scrollTop();
    },
    onLoaderFinished() {
      this.loaderFinished = true;
      if (!this.saving) {
        this.loadingReport = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
