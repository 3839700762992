<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M201.872 328.219H162.117V351.555H201.872V328.219Z"
      fill="#C3C4D5"
    />
    <path
      d="M202.574 336.496H161.566V337.439H202.574V336.496Z"
      fill="#C3C4D5"
    />
    <path
      d="M201.526 333.836L172.438 333.889L201.526 334.027V333.836Z"
      fill="#8C8FB0"
    />
    <path
      d="M173.965 328.219H111.805V351.555H173.965V328.219Z"
      fill="#D4D5E0"
    />
    <path
      d="M158.449 336.361L136.958 335.908L124.619 336.125L145.094 336.685L158.449 336.361Z"
      fill="#A7A9C3"
    />
    <path
      d="M170.83 345.453L149.339 345L137 345.217L157.475 345.777L170.83 345.453Z"
      fill="#A7A9C3"
    />
    <path d="M173.399 339.174H109.961V342.3H173.399V339.174Z" fill="#D4D5E0" />
  </svg>
</template>

<script>
export default {
  name: "ReturnPreparingForTransition"
};
</script>
