<template>
  <v-form
    ref="form"
    @submit.prevent
    :class="[
      'assessment-form-wrapper',
      {
        'two-column-form-layout': isTwoColumnFormLayout
      },
      {
        'py-8': isDriversOrPhysicalLabour
      },
      {
        'vfl-card-light py-4 px-8': useCardStyle
      }
    ]"
    data-test-id="assessment-form"
  >
    <div
      :class="[
        {
          'vfl-card-light py-4 px-8': useCardStylePain
        }
      ]"
    >
      <p v-if="schema.caption" class="text-overline mb-6 text-uppercase">
        {{ $t(schema.caption) }}
      </p>
      <i18n
        v-if="schema.title"
        :path="schema.title"
        tag="h1"
        :class="`text-h5 mb-${$vuetify.breakpoint.xs ? 4 : 8}`"
      >
        <template v-slot:highlightedText>
          <span class="primary--text">{{ highlightedTitleText }}</span>
        </template>
      </i18n>
      <p
        v-if="schema.introduction"
        class="text-subtitle-1 vflNeutralDark1--text mb-12"
        :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mb-8'"
        style="line-height: 1.4"
      >
        {{ introductionText }}
      </p>

      <template
        v-if="schema.introComponents?.length"
        v-for="(component, index) in schema.introComponents"
      >
        <component
          :is="component"
          :results="results"
          :key="index"
          :assessment-type="assessmentType"
        />
      </template>

      <vfl-callout
        v-if="schema.callout"
        :icon="schema.callout.icon"
        :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mb-8'"
        :backgroundColor="schema.callout.backgroundColor"
      >
        <template>
          <i18n :path="schema.callout.text" tag="p">
            <template v-if="schema.callout.emphasise" v-slot:emphasise>
              <strong>{{ $t(schema.callout.emphasise) }}</strong>
            </template>
          </i18n>
        </template>
      </vfl-callout>
    </div>

    <pain-form
      v-if="isPainTheme"
      ref="painForm"
      :questions="questions"
      :fullResults="results"
      v-model="internalValue"
      :assessmentType="assessmentType"
      :requireValidation="requireValidationOfPainPerson"
      :disable-question-numbering="disableQuestionNumbering"
    />

    <form-generator
      class="assessment-form"
      v-else
      :questions="questions"
      v-model="internalValue"
      @input="next($event)"
      :fullResults="results"
      :groups="groups"
      :disable-question-numbering="disableQuestionNumbering"
      :answers-to-keep="answersToKeep"
    />
  </v-form>
</template>

<script>
import FormGenerator from "@/components/form/FormGenerator.vue";
import PainForm from "@/components/form/PainForm.vue";
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import EmployerSharedDataCallout from "@/components/assessment/EmployerSharedDataCallout.vue";
import MenopauseTimeline from "@/components/assessment/menopause/MenopauseTimeline.vue";
import MenstruationTimeline from "@/components/assessment/menstruation/MenstruationTimeline.vue";
import PainPersonInstruction from "@/components/assessment/menopause/PainPersonInstruction.vue";

import { scrollToInvalidQuestion } from "@/components/common/assessments/assessment-validation.js";
import { EventBus } from "@/services/events/event-bus";
import { assessmentTypes } from "@/constants/constants";

export default {
  name: "AssessmentForm",
  components: {
    FormGenerator,
    PainForm,
    VflCallout,
    EmployerSharedDataCallout,
    MenopauseTimeline,
    MenstruationTimeline,
    PainPersonInstruction
  },
  props: {
    schema: Object,
    value: Object,
    isPainTheme: Boolean,
    results: Object,
    highlightedTitleText: String,
    assessmentType: {
      type: String,
      required: true
    },
    disableQuestionNumbering: Boolean,
    answersToKeep: {
      type: Array,
      default: () => []
    },
    requireValidationOfPainPerson: {
      type: Boolean,
      default: true
    },
    introductionSelector: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      questions: null,
      groups: null,
      internalValue: this.value
    };
  },
  created() {
    if (this.schema) {
      this.questions = this.schema.questions;
      this.groups = this.schema.formGroups;
    }
  },
  computed: {
    isTwoColumnFormLayout() {
      return (
        this.isPainTheme &&
        this.assessmentType !== assessmentTypes.physicalLabourAssessment &&
        this.assessmentType !== assessmentTypes.driverAssessment
      );
    },
    isDriversOrPhysicalLabour() {
      return (
        this.assessmentType === assessmentTypes.physicalLabourAssessment ||
        this.assessmentType === assessmentTypes.driverAssessment
      );
    },
    introductionText() {
      if (!this.schema.introduction) return "";

      const text = this.introductionSelector
        ? this.introductionSelector(this.schema.introduction, this.results)
        : this.schema.introduction;

      return this.$t(text);
    },
    useCardStyle() {
      return (
        this.assessmentType === assessmentTypes.deskAssessment &&
        !this.isPainTheme
      );
    },
    useCardStylePain() {
      return (
        this.assessmentType === assessmentTypes.deskAssessment &&
        this.isPainTheme
      );
    }
  },
  watch: {
    value: {
      handler() {
        this.internalValue = this.value;
      },
      deep: true
    },
    internalValue(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    isValid() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        EventBus.$emit("validateCustomFormComponents");
        scrollToInvalidQuestion(this, this.$refs.form);
      }
      return valid;
    },
    next(answer) {
      this.$emit("next", answer);
    }
  }
};
</script>

<style lang="scss" scoped>
.assessment-form-wrapper {
  margin: 0 auto;
  max-width: 100%;
}

caption {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
</style>
