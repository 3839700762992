<template>
  <section class="pt-6">
    <h3 class="text-h5 mb-4 d-flex align-center">
      {{ $t("dashboardAssessmentCards.title") }}
      <assessment-configuration-button
        v-if="isSuperAdmin && !loading"
        @openConfigDialog="openConfigDialog"
      />
    </h3>
    <div class="mr-4 mt-n2 cards-container">
      <div
        class="cards-scroller vfl-scrollbar-hidden d-flex pb-4 pt-8"
        ref="scrollContent"
        :class="{
          'has-gradient-left': !isScrolledFarLeft,
          'has-gradient-right': !isScrolledFarRight
        }"
      >
        <assessment-notification-setting-card />
        <external-notification-introduction-card />
        <card
          v-for="(card, index) in visibleCards"
          :key="index"
          :first="index === 0"
          v-bind="card"
          :loading="loading"
          v-on="getCardEventHandlers(card)"
        />
      </div>

      <navigation
        @onPrev="scrollLeft"
        @onNext="scrollRight"
        class="nav"
        :disablePrev="isScrolledFarLeft"
        :disableNext="isScrolledFarRight"
      />
    </div>

    <assessment-configuration-dialog v-model="showConfigDialog" />
  </section>
</template>

<script>
import Card from "@/components/dashboard/assessment-cards/DashboardAssessmentCard.vue";
import Navigation from "@/components/dashboard/assessment-cards/DashboardAssessmentCardsNav.vue";
import AssessmentNotificationSettingCard from "@/components/dashboard/AssessmentNotificationSettingCard.vue";
import ExternalNotificationIntroductionCard from "@/components/dashboard/external-notification-introduction/ExternalNotificationIntroductionCard.vue";
import { mapGetters } from "vuex";
import { startNewAssessment } from "@/customApi";
import * as Constants from "@/constants/constants.js";
import debounce from "lodash/debounce";
import { assessmentTypes } from "@/constants/constants.js";
import {
  DESK_BACKGROUND_GRADIENT,
  Theme,
  Image
} from "@/components/dashboard/assessment-cards/index.js";
import { routes } from "@/router/route-constants.js";
import AssessmentConfigurationDialog from "@/components/dashboard/assessment-configuration/AssessmentConfigurationDialog.vue";
import AssessmentConfigurationButton from "@/components/dashboard/assessment-configuration/AssessmentConfigurationButton.vue";

const LOCALE_ROOT = "dashboardAssessmentCards";

export default {
  name: "DashboardAssessmentCards",
  components: {
    AssessmentConfigurationButton,
    AssessmentConfigurationDialog,
    Card,
    Navigation,
    AssessmentNotificationSettingCard,
    ExternalNotificationIntroductionCard
  },
  data() {
    return {
      isStartingAssessment: false,
      isScrolledFarLeft: true,
      isScrolledFarRight: false,
      showConfigDialog: false,
      snackbar: {
        show: false,
        message: "",
        type: "success"
      }
    };
  },
  props: {
    loading: Boolean
  },
  mounted() {
    if (this.$refs?.scrollContent) {
      this.$refs.scrollContent.addEventListener("scroll", this.handleScroll);
    }

    window.addEventListener("resize", this.debouncedResizeListener);

    this.updateNavigationButtonVisibility();
  },
  beforeDestroy() {
    if (this.$refs?.scrollContent) {
      this.$refs.scrollContent.removeEventListener("scroll", this.handleScroll);
    }
    window.removeEventListener("resize", this.debouncedResizeListener);
  },
  computed: {
    ...mapGetters([
      "disableEmails",
      "disableAssessments",
      "driverAssessmentEnabled",
      "hideBurnoutCompletely",
      "hidePhysicalLabourFromAdminDashboard",
      "physicalLabourAssessmentEnabled",
      "userEmail",
      "showDemoAssessmentsInDashboardCards",
      "showAlternateDashboardCards",
      "enableMenopauseAssessment",
      "enableMenstruationAssessment",
      "enablePregnancyAssessment",
      "isSuperAdmin",
      "enableNewParentAssessment"
    ]),
    showPhysicalLabour() {
      return (
        this.physicalLabourAssessmentEnabled &&
        !this.hidePhysicalLabourFromAdminDashboard
      );
    },
    disableInvite() {
      return this.disableEmails || this.disableAssessments;
    },
    visibleCards() {
      const cards = [
        {
          actionCardType: assessmentTypes.deskAssessment,
          color: Theme.DESK,
          css: {
            backgroundImage: DESK_BACKGROUND_GRADIENT
          },
          description: `${LOCALE_ROOT}.desk.description`,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          image: Image.DESK,
          onboardingTooltips: true,
          show: true,
          title: this.showAlternateDashboardCards
            ? `${LOCALE_ROOT}.desk.alternateTitle`
            : `${LOCALE_ROOT}.desk.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Role", color: "#437CEE" }
            : null
        },
        {
          actionCardType: assessmentTypes.driverAssessment,
          color: Theme.DRIVER,
          description: `${LOCALE_ROOT}.driver.description`,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          image: Image.DRIVER,
          show: this.driverAssessmentEnabled,
          title: this.showAlternateDashboardCards
            ? `${LOCALE_ROOT}.driver.alternateTitle`
            : `${LOCALE_ROOT}.driver.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Role", color: "#437CEE" }
            : null
        },
        {
          actionCardType: assessmentTypes.physicalLabourAssessment,
          color: Theme.PHYSICAL,
          description: `${LOCALE_ROOT}.physicalLabour.description`,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          image: Image.PHYSICAL,
          show: this.showPhysicalLabour,
          title: this.showAlternateDashboardCards
            ? `${LOCALE_ROOT}.physicalLabour.alternateTitle`
            : `${LOCALE_ROOT}.physicalLabour.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Role", color: "#437CEE" }
            : null
        },
        {
          actionCardType: assessmentTypes.burnoutAssessment,
          color: Theme.RESILIENCE,
          description: `${LOCALE_ROOT}.resilience.description`,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          image: Image.RESILIENCE,
          show: !this.hideBurnoutCompletely,
          title: `${LOCALE_ROOT}.resilience.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Mental", color: "#9424AB" }
            : null
        },
        {
          actionCardType: assessmentTypes.menstruationAssessment,
          color: Theme.MENSTRUATION,
          image: Image.MENSTRUATION,
          show: this.enableMenstruationAssessment,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          title: `${LOCALE_ROOT}.menstruation.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Life stage", color: "#08A946" }
            : null
        },
        {
          actionCardType: assessmentTypes.menopauseAssessment,
          color: Theme.MENOPAUSE,
          image: Image.MENOPAUSE,
          show: this.enableMenopauseAssessment,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          title: `${LOCALE_ROOT}.menopause.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Life stage", color: "#08A946" }
            : null
        },
        {
          actionCardType: assessmentTypes.pregnancyAssessment,
          color: Theme.PREGNANCY,
          image: Image.PREGNANCY,
          show: this.enablePregnancyAssessment,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          title: `${LOCALE_ROOT}.pregnancy.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Life stage", color: "#08A946" }
            : null
        },
        {
          actionCardType: assessmentTypes.newParentAssessment,
          color: Theme.NEW_PARENTS,
          image: Image.NEW_PARENTS,
          show: this.enableNewParentAssessment,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("try"),
          title: `${LOCALE_ROOT}.newParent.title`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Life stage", color: "#08A946" }
            : null
        },
        {
          actionCardType: "demo",
          color: Theme.AIRPLANE,
          image: Image.AIRPLANE,
          show: this.showDemoAssessmentsInDashboardCards,
          title: this.showAlternateDashboardCards
            ? `Travel`
            : `Long distance<br/>air travel`,
          customRouteName: routes.TRAVEL_ASSESSMENT,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Lifestyle", color: "#D7B704" }
            : null
        },
        {
          actionCardType: "demo",
          color: Theme.HOBBIES,
          image: Image.HOBBIES,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Hobbies`,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Lifestyle", color: "#D7B704" }
            : null
        },
        {
          actionCardType: "demo",
          color: Theme.LAB_WORK,
          image: Image.LAB_WORK,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Lab work`,
          customRouteName: routes.LAB_ASSESSMENT,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Role", color: "#437CEE" }
            : null
        },
        {
          actionCardType: "demo",
          color: Theme.BANKING,
          image: Image.BANKING,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Banking`,
          customRouteName: routes.BANKING_ASSESSMENT,
          categoryChip: this.showAlternateDashboardCards
            ? { label: "Role", color: "#437CEE" }
            : null
        }
      ];

      return cards.filter(card => card.show);
    }
  },
  methods: {
    async startNewAssessmentForSimpleUser(assessmentType) {
      if (this.isStartingAssessment) return;

      try {
        this.isStartingAssessment = true;

        const invitation = {
          email: { value: this.userEmail },
          assessmentType: assessmentType
        };

        let assessmentId = await startNewAssessment(invitation);
        const paths = Constants.assessmentPaths;
        const url = `/${paths[assessmentType]}/${assessmentId}`;

        await this.$router.push(url);
      } finally {
        this.isStartingAssessment = false;
      }
    },
    onActionCardClicked(assessmentType) {
      this.$emit("onAssessmentCardClicked", assessmentType);
    },
    handleScroll() {
      this.updateNavigationButtonVisibility();
    },
    updateNavigationButtonVisibility() {
      const container = this.$refs.scrollContent;
      if (!container) return;

      this.isScrolledFarRight =
        container.scrollLeft === container.scrollWidth - container.clientWidth;

      this.isScrolledFarLeft = container.scrollLeft === 0;
    },
    scrollLeft() {
      const container = this.$refs.scrollContent;

      if (container) {
        container.scrollLeft -= 640;

        this.updateNavigationButtonVisibility();
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContent;

      if (container) {
        container.scrollLeft += 640;

        this.updateNavigationButtonVisibility();
      }
    },
    debouncedResizeListener: debounce(function () {
      this.handleResize();
    }, 200),
    handleResize() {
      this.updateNavigationButtonVisibility();
    },
    getTooltipText(action) {
      if (action === "invite") {
        return this.getInviteTooltipText();
      } else if (action === "try") {
        return "tooltips.trialOrSubHasExpired";
      } else {
        this.$logger.captureMessage(
          "Unknown action in dashboard assessment card tooltip",
          action
        );
      }
    },
    getInviteTooltipText() {
      if (this.disableEmails) {
        return "tooltips.disabledByAdmin";
      } else {
        return "tooltips.trialOrSubHasExpired";
      }
    },
    getCardEventHandlers(card) {
      if (card.customLink) {
        return {
          invite: () => window.open(card.customLink, "_blank").focus(),
          tryNow: () => window.open(card.customLink, "_blank").focus()
        };
      }

      if (card.customRouteName) {
        return {
          invite: () => this.$router.push({ name: card.customRouteName }),
          tryNow: () => this.$router.push({ name: card.customRouteName })
        };
      }

      if (card.actionCardType === "demo") {
        return {};
      }

      return {
        invite: () => this.onActionCardClicked(card.actionCardType),
        tryNow: () => this.startNewAssessmentForSimpleUser(card.actionCardType)
      };
    },
    openConfigDialog() {
      this.showConfigDialog = true;
    },
    showSnackbar(message, type = "success") {
      this.snackbar = {
        show: true,
        message: message,
        type: type
      };
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  container-type: inline-size;

  // Hide the image at smaller container sizes
  // Use container instead of window to account for sidebar open/closed state
  @container (width < 600px) {
    ::v-deep .image {
      display: none !important;
    }
  }
}

.cards-container {
  position: relative;

  &:hover {
    .nav {
      opacity: 1;
    }
  }
}

.cards-scroller {
  gap: 1.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;

  &:before,
  &:after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 1;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: -1px;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: -1px;
  }
}
</style>
