<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5289_54917)">
      <path d="M350.316 39H-69V264.534H350.316V39Z" fill="white" />
      <path
        opacity="0.64"
        d="M37.0908 165.931L58.8875 167.297L57.4907 145.531L37.0908 165.931Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M73.79 137.086L93.8597 137.286L92.4623 115.52L73.79 137.086Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M72.0654 102.658L93.862 104.024L92.4646 82.2578L72.0654 102.658Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M121.826 102.456L143.623 103.823L142.226 82.0566L121.826 102.456Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M123.248 75.6863L145.044 77.0533L143.647 55.2871L123.248 75.6863Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M151.03 75.6863L172.827 77.0533L171.43 55.2871L151.03 75.6863Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M125.49 136.883L145.042 137.081L143.645 115.314L124.686 119.99L125.49 136.883Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M153.645 136.881L172.624 137.689L171.227 115.922L153.645 136.881Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M124.352 167.113L144.638 167.771L143.241 146.004L124.352 167.113Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M151.233 166.404L173.029 167.771L171.633 146.004L151.233 166.404Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M176.65 166.136L198.447 167.503L197.05 145.736L176.65 166.136Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M123.652 198.853L145.448 200.22L144.052 178.453L123.652 198.853Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M225.343 77.8588L247.139 79.2258L245.743 57.459L225.343 77.8588Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M225.613 111.392L247.409 112.759L246.012 90.9922L225.613 111.392Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M225.343 141.404L247.139 142.771L245.743 121.004L225.343 141.404Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M271.587 102.859L293.383 104.226L291.986 82.459L271.587 102.859Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M270.234 136.66L292.031 138.026L290.634 116.26L270.234 136.66Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M225.885 173.581L247.681 174.948L246.285 153.182L225.885 173.581Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M71.8604 75.4819L93.657 76.8489L92.2602 55.082L71.8604 75.4819Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M37.4971 75.8881L59.2937 77.2551L57.8969 55.4883L37.4971 75.8881Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M37.4971 102.456L58.8881 103.823L57.8969 82.0566L37.4971 102.456Z"
        fill="#9995A7"
      />
      <path
        opacity="0.64"
        d="M39.1133 136.95L58.686 137.081L57.6955 115.314L39.1133 136.95Z"
        fill="#9995A7"
      />
      <path
        d="M57.8256 55.4766H37.4258V75.8764H57.8256V55.4766Z"
        fill="#F98D43"
      />
      <path
        d="M92.4057 55.4766H72.0059V75.8764H92.4057V55.4766Z"
        fill="#BCDB3A"
      />
      <path
        d="M57.8256 81.9043H37.4258V102.304H57.8256V81.9043Z"
        fill="#F98D43"
      />
      <path
        d="M92.4057 81.9043H72.0059V102.304H92.4057V81.9043Z"
        fill="#BCDB3A"
      />
      <path
        d="M143.442 55.4766H123.042V75.8764H143.442V55.4766Z"
        fill="#BCDB3A"
      />
      <path d="M171.26 55.4766H150.86V75.8764H171.26V55.4766Z" fill="#BCDB3A" />
      <path
        d="M142.437 81.9043H122.037V102.304H142.437V81.9043Z"
        fill="#5472C6"
      />
      <path
        d="M57.8256 115.52H37.4258V135.919H57.8256V115.52Z"
        fill="#F98D43"
      />
      <path
        d="M143.442 177.965H123.042V198.365H143.442V177.965Z"
        fill="#BCDB3A"
      />
      <path
        d="M57.8256 145.314H37.4258V165.714H57.8256V145.314Z"
        fill="#F98D43"
      />
      <path
        d="M57.8256 176.795H37.4258V197.195H57.8256V176.795Z"
        fill="#F7C946"
      />
      <path
        d="M60.4526 208.168H40.0527V228.568H60.4526V208.168Z"
        fill="#5472C6"
      />
      <path
        d="M95.9604 208.168H75.5605V228.568H95.9604V208.168Z"
        fill="#5472C6"
      />
      <path
        d="M245.458 57.2656H225.059V77.6655H245.458V57.2656Z"
        fill="#F98D43"
      />
      <path
        d="M245.458 90.8789H225.059V111.279H245.458V90.8789Z"
        fill="#F24976"
      />
      <path
        d="M245.458 120.678H225.059V141.078H245.458V120.678Z"
        fill="#F98D43"
      />
      <path
        d="M196.914 145.678H176.515V166.078H196.914V145.678Z"
        fill="#F24976"
      />
      <path d="M171.26 145.678H150.86V166.078H171.26V145.678Z" fill="#F98D43" />
      <path
        d="M143.442 145.678H123.042V166.078H143.442V145.678Z"
        fill="#F24976"
      />
      <path
        d="M245.458 152.965H225.059V173.365H245.458V152.965Z"
        fill="#F24976"
      />
      <path
        d="M92.4057 115.52H72.0059V135.919H92.4057V115.52Z"
        fill="#BCDB3A"
      />
      <path
        d="M143.442 115.52H123.042V135.919H143.442V115.52Z"
        fill="#F7C946"
      />
      <path d="M171.26 115.52H150.86V135.919H171.26V115.52Z" fill="#F7C946" />
      <path
        d="M291.712 82.2656H271.312V102.665H291.712V82.2656Z"
        fill="#F7C946"
      />
      <path
        d="M290.707 115.879H270.308V136.279H290.707V115.879Z"
        fill="#5472C6"
      />
      <path
        opacity="0.64"
        d="M297.873 76.3784L319.67 77.7447L318.273 55.9785L297.873 76.3784Z"
        fill="#9995A7"
      />
      <path
        d="M318.606 55.7598H298.206V76.1596H318.606V55.7598Z"
        fill="#F98D43"
      />
      <path
        opacity="0.64"
        d="M272.377 75.6863L294.174 77.0533L292.776 55.2871L272.377 75.6863Z"
        fill="#9995A7"
      />
      <path
        d="M292.573 55.4766H272.173V75.8764H292.573V55.4766Z"
        fill="#BCDB3A"
      />
      <path
        d="M22.3111 56.2812H20.2109V251.522H22.3111V56.2812Z"
        fill="#F6EDBE"
      />
      <path d="M108 56H106V252H108V56Z" fill="#F6EDBE" />
      <path d="M209 56H207V252H209V56Z" fill="#F6EDBE" />
      <path d="M266 56H264V252H266V56Z" fill="#F6EDBE" />
      <path d="M357 31H-76V41H357V31Z" fill="#F6EDBE" />
      <path d="M360 264H-68V274H360V264Z" fill="#F6EDBE" />
      <rect x="350" y="31" width="10" height="243" fill="#F9F2D0" />
      <rect x="12" y="31" width="10" height="243" fill="#F6EDBE" />
      <path
        d="M406.347 331.275L261.723 331.623C259.427 331.535 257.495 329.672 257.074 327.142L239.748 217.894C239.18 214.481 241.545 211.343 244.642 211.399L390.891 212.063C393.288 212.107 395.305 214.092 395.655 216.751L411.355 324.986C411.802 328.377 409.401 331.39 406.347 331.275Z"
        fill="#D4D5E0"
      />
      <path
        d="M343.649 293.912H311.507C309.136 293.912 307.118 292.189 306.747 289.847L303.66 270.379C303.197 267.452 305.458 264.805 308.42 264.805H340.562C342.932 264.805 344.951 266.528 345.322 268.87L348.409 288.338C348.872 291.264 346.611 293.912 343.649 293.912Z"
        fill="#B4B6C7"
      />
      <path
        d="M339.487 347.68L332.528 347.068L298.96 347.201L314.928 271.672H335.753L338.455 280.788L339.487 347.68Z"
        fill="#B4B6C7"
      />
      <path
        d="M332.528 347.068L295.097 347.201L314.928 271.672H335.753L332.528 347.068Z"
        fill="#D4D5E0"
      />
      <path
        d="M295.097 347.201L314.928 271.672"
        stroke="#B4B6C7"
        stroke-width="1.284"
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M150.529 569.245C152.246 572.837 155.195 580.301 154.548 589.97C153.934 599.139 150.403 605.57 149.26 607.523C147.184 611.073 142.92 618.135 134.034 622.115C126.181 625.632 119.049 624.781 116.481 624.442C114.131 624.131 109.413 623.458 104.426 620.424C97.2337 616.047 94.1391 609.591 91.526 604.139C89.2152 599.318 86.1661 592.957 86.662 584.26C87.0885 576.777 89.9342 571.158 91.7375 568.188C111.335 568.54 130.932 568.892 150.529 569.245Z"
      fill="#FFB455"
    />
    <path
      d="M121.125 578.974C137.389 578.974 150.574 574.849 150.574 569.76C150.574 564.671 137.389 560.545 121.125 560.545C104.861 560.545 91.6768 564.671 91.6768 569.76C91.6768 574.849 104.861 578.974 121.125 578.974Z"
      fill="#534836"
    />
    <path
      d="M121.995 568.911C125.379 534.37 127.238 538.82 126.898 525.322C126.733 518.804 126.407 508.288 123.098 494.741C119.641 480.587 114.7 470.382 112.778 466.595C105.378 452.02 94.8552 438.306 92.9538 439.204C91.2809 439.994 98.1661 451.207 103.532 470.765C105.566 478.177 110.576 496.949 110.127 520.27C109.791 537.694 106.625 546.376 111.339 562.615C113.106 568.704 116.875 578.933 119.38 578.668C121.097 578.487 121.551 573.447 121.995 568.911Z"
      fill="#91B252"
    />
    <path
      d="M121.696 575.734C119.051 573.203 121.938 564.161 123.448 550.506C123.778 547.531 123.789 536.677 123.799 515.09C123.81 489.386 123.69 486.516 125.901 482.585C126.941 480.736 132.435 476.663 143.423 468.516C147.605 465.416 149.541 464.06 150.432 465.12C151.967 466.945 149.068 474.344 145.526 485.011C144.167 489.106 139.446 503.323 136.415 516.06C132.172 533.889 130.051 542.804 130.107 554.872C130.141 562.21 130.926 570.313 127.303 574.278C125.732 575.999 123.156 577.131 121.696 575.734Z"
      fill="#91B252"
    />
    <path
      d="M50.2343 403.582C47.8207 405.502 64.5065 424.2 79.3893 458.823C81.6589 464.103 89.7933 483.494 95.7571 509.972C100.395 530.565 101.588 545.975 101.895 550.38C102.577 560.141 102.587 568.513 102.407 574.932C105.093 575.439 108.596 575.639 110.683 573.628C114.21 570.23 110.785 563.324 109.288 552.472C107.446 539.113 111.214 539.258 109.986 524.109C109.442 517.4 108.795 518.509 104.406 497.14C100.897 480.052 101.272 479.158 99.1632 472.135C98.1265 468.683 95.3257 459.875 89.8966 449.278C82.8682 435.558 75.3363 426.455 73.2171 423.95C65.392 414.701 52.289 401.948 50.2343 403.582Z"
      fill="#91B252"
    />
    <path
      d="M129.677 574.262C124.563 569.073 132.771 557.649 137.743 543.201C139.254 538.809 139.399 537.238 141.776 487.373C142.65 469.032 142.934 462.857 144.016 452.954C144.016 452.954 145.667 437.843 152.979 415.596C155.164 408.945 156.09 407.146 157.012 407.2C160.409 407.402 161.625 432.669 161.493 449.176C161.4 460.729 160.637 469.229 159.7 479.398C158.187 495.825 156.119 511.196 153.427 526.83C145.799 571.128 140.367 575.696 136.398 576.361C133.962 576.769 131.238 575.847 129.677 574.262Z"
      fill="#8EA060"
    />
    <path
      d="M123.937 570.833C128.467 563.721 124.618 555.845 121.757 541.938C120.565 536.146 117.86 522.588 119.031 508.137C120.237 493.247 124.31 492.795 126.118 475.971C127.756 460.734 125.715 449.018 125.028 445.441C121.584 427.5 114.009 415.254 110.853 410.549C104.17 400.586 94.7147 390.774 92.8621 392.012C90.8538 393.355 100.331 405.973 106.492 427.449C111.953 446.489 111.994 462.679 111.943 473.79C111.817 501.926 105.821 501.668 108.672 520.676C111.065 536.625 115.921 541.033 115.215 557.203C114.813 566.411 112.802 574.962 115.76 576.284C117.859 577.223 121.782 574.217 123.937 570.833Z"
      fill="#8EA060"
    />
    <path
      d="M107.189 575.867C108.518 574.687 111.557 570.794 99.557 537.704C95.7412 527.182 93.8335 521.922 90.2888 514.806C82.7371 499.647 73.3695 486.933 72.2978 487.547C71.3649 488.081 77.5996 498.206 85.3822 518.622C92.1042 536.257 95.4654 545.074 96.2859 553.514C97.4053 565.03 95.8304 574.755 100.647 576.957C102.6 577.85 105.432 577.428 107.189 575.867Z"
      fill="#8EA060"
    />
    <path
      d="M133.751 566.47C140.183 537.233 137.595 540.359 141.383 529.398C143.213 524.105 146.21 515.582 152.287 505.41C158.636 494.782 165.089 487.803 167.552 485.238C177.033 475.365 188.682 467.071 189.905 468.337C190.98 469.452 182.773 476.714 173.549 491.235C170.053 496.739 161.308 510.715 155.558 529.943C151.263 544.309 151.421 552.305 143.564 564.29C140.619 568.783 135.057 576.11 133.206 575.194C131.937 574.565 132.906 570.31 133.751 566.47Z"
      fill="#91B252"
    />
    <path
      d="M123.392 521.765C124.335 512.747 126.078 499.702 129.389 484.693C134.087 463.404 136.717 461.838 143.019 434.536C145.291 424.692 145.696 421.66 148.471 410.548C152.751 393.409 155.345 386.376 154.468 386.014C153.023 385.418 143.519 403.501 135.931 421.997C126.55 444.864 122.156 463.346 120.666 469.973C117.033 486.138 113.235 503.58 114.124 526.127C114.898 545.76 118.994 563.338 120.666 563.199C122.222 563.071 120.681 547.714 123.392 521.765Z"
      fill="#91B252"
    />
    <path
      d="M122.467 574.266C120.579 570.287 125.469 567.05 127.899 560.239C130.103 554.06 127.369 553.106 128.287 544.876C128.588 542.17 129.906 531.649 137.987 522.834C142.052 518.399 142.921 519.75 154.283 510.476C161.433 504.64 164.861 501.026 165.535 501.459C165.829 501.648 165.72 502.688 156.999 515.486C150.976 524.324 150.525 524.565 147.299 529.513C141.477 538.44 138.566 542.903 137.211 548.216C135.971 553.075 136.743 554.231 134.883 559.571C133.022 564.915 131.738 565.226 130.227 570.593C129.046 574.786 129.097 577.2 127.51 577.606C125.958 578.004 123.443 576.324 122.467 574.266Z"
      fill="#8EA060"
    />
    <defs>
      <clipPath id="clip0_5289_54917">
        <rect x="12" y="30" width="348" height="244" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ReturnOffice"
};
</script>
