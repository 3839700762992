<script>
import { assessmentTypes } from "@/constants/constants.js";
import { routes } from "@/router/route-constants.js";
import ReportLayout from "@/components/assessment/common/report/ReportLayout.vue";
import { REPORT_SECTION_CONFIG } from "@/components/assessment/new-parent/report/report.js";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";

export default {
  name: "NewParentReport",
  extends: ReportLayout,
  data() {
    return {
      sectionConfig: REPORT_SECTION_CONFIG,
      routeName: routes.NEW_PARENT_ASSESSMENT
    };
  },
  computed: {
    assessmentType() {
      return assessmentTypes.newParentAssessment;
    },
    hasPain() {
      return PainAssessmentService.hasPain(this.results);
    }
  }
};
</script>
