<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <report-section-card
        imagePath="/images/illustrations/parent-with-baby-on-laptop.svg"
        :fact="sectionTitle"
        :assessmentType="$options.assessmentTypes.newParentAssessment"
        ><template #text>
          <p v-for="p in content" :key="p">{{ p }}</p></template
        ></report-section-card
      >
      <recommendation-container
        class="mt-10"
        :title="$t('newParentAssessment.recommendations.returnToWork.title')"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="recommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import { assessmentTypes } from "@/constants/constants.js";
import ReportSectionCard from "@/components/assessment/common/report/ReportSectionCard.vue";
import {
  getTitle,
  getContent
} from "./new-parent-report-return-to-work-service.js";
import { getRecommendationsByType } from "@/components/assessment/new-parent/report/recommendations/recommendation-service.js";
import { RecommendationSections } from "@/components/assessment/new-parent/report/recommendations/constants.js";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";

export default {
  name: "NewParentReportReturnToWork",
  components: {
    RecommendationContainer,
    ReportSection,
    ReportSectionCard
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  computed: {
    recommendations() {
      return getRecommendationsByType(
        RecommendationSections.RETURN_TO_WORK,
        this.results
      );
    },
    sectionTitle() {
      return getTitle();
    },
    content() {
      return getContent(this.results);
    }
  }
};
</script>
