<template>
  <div class="pills-group d-flex flex-wrap">
    <v-checkbox
      v-for="option in processedOptions"
      :key="option.value"
      v-model="selectedAnswers"
      :value="option.value"
      :rules="rules"
      hide-details="auto"
      class="pill"
      :class="{ 'pill-selected': isSelected(option.value) }"
      multiple
    >
      <template v-slot:label>
        <span>{{ option.label }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import Answer from "@/components/common/assessments/answer-constants";

export default {
  name: "CheckBoxPillsQuestion",
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    processOptionLabels: {
      type: Boolean,
      default: true
    },
    hasNoneOption: {
      type: Boolean,
      default: false
    },
    optional: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    fullQuestion: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    selectedAnswers: {
      get() {
        return this.value || [];
      },
      set(selectedAnswers) {
        if (!selectedAnswers.length) {
          this.$emit("input", []);
          return;
        }

        // Check if the last selection is 'none of these'
        const isNoneSelected =
          selectedAnswers[selectedAnswers.length - 1] ===
          Answer.NONE_ANSWER_VALUE;

        if (isNoneSelected) {
          // If 'none of these' is selected, reset the selection to just that
          this.$emit("input", [Answer.NONE_ANSWER_VALUE]);
        } else {
          // Otherwise, filter out 'none of these' from the selections
          this.$emit(
            "input",
            selectedAnswers.filter(
              answer => answer !== Answer.NONE_ANSWER_VALUE
            )
          );
        }
      }
    },
    visibleOptions() {
      return this.options.filter(x => x.showAnswer);
    },
    processedOptions() {
      if (!this.processOptionLabels) return this.visibleOptions;
      return this.visibleOptions.map(option => ({
        ...option,
        label: this.$t(option.label)
      }));
    },
    rules() {
      if (this.optional) return [];
      return [
        v =>
          (v && v.length > 0) ||
          this.$t(this.fullQuestion.errorMessage || this.errorMessage)
      ];
    }
  },
  methods: {
    isSelected(value) {
      return this.selectedAnswers.includes(value);
    }
  }
};
</script>

<style scoped lang="scss">
.pills-group {
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.pill {
  margin: 0 !important;
  padding: 0 !important;

  ::v-deep .v-input__slot {
    background-color: white !important;
    border: 1px solid #d0d0d0;
    border-radius: 8px !important;
    cursor: pointer;
    margin: 0 !important;
    min-height: 40px !important;
    padding: 0 1rem !important;

    &:hover {
      background-color: color-mix(
        in srgb,
        var(--v-vflPrimary-base),
        #fff 98%
      ) !important;
    }

    .v-input--selection-controls__input {
      display: none;
    }

    label {
      color: var(--v-vflGreyDark-base) !important;
      font-weight: 500;
    }
  }

  &.v-input--is-focused ::v-deep .v-input__slot {
    outline: 2px dashed var(--v-vflPrimary-base) !important;
    outline-offset: -1px;
  }

  &.pill-selected ::v-deep .v-input__slot {
    background-color: color-mix(
      in srgb,
      var(--v-vflPrimary-base),
      #fff 95%
    ) !important;
    outline: 2px solid var(--v-vflPrimary-base);
    outline-offset: -1px;
  }

  &.error--text ::v-deep .v-input__slot {
    outline: 2px solid var(--v-error-base) !important;
    outline-offset: -1px;
  }
}
</style>
