<template>
  <div class="d-flex flex-column mb-8">
    <h1 class="text-h4 mb-10 mt-8">
      {{ $t(content.title, content.titleParams) }}
    </h1>
    <div class="d-flex flex-wrap" style="gap: 1.5rem">
      <div class="image">
        <img :src="content.imagePath" />
      </div>
      <div style="flex: 1 1 300px">
        <p
          v-for="(paragraph, index) in content.paragraphs"
          :key="index"
          v-html="$t(paragraph)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { OutroService } from "./outro-service";
import { Theme } from "./../steps.js";

export default {
  name: "OutroStep",
  props: {
    step: {
      type: Object,
      required: true
    },
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outroService: new OutroService()
    };
  },
  computed: {
    content() {
      switch (this.step.THEME) {
        case Theme.TRIMESTER_OUTRO:
          return this.outroService.getTrimesterContent(this.results.trimester);
        case Theme.PAIN_OUTRO:
          return this.outroService.getPainContent(this.results);
        case Theme.BALANCE_OUTRO:
          return this.outroService.getBalanceContent(this.results.balance);
        case Theme.CIRCULATION_OUTRO:
          return this.outroService.getCirculationContent();
        default:
          this.$logger.captureException(
            `Theme ${this.step.THEME} not handled in outro page component`
          );
          return { title: "", titleParams: {}, paragraphs: [] };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  border-radius: 8px;
  position: relative;
  max-width: 200px;
}
</style>
