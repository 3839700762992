import i18n from "@/plugins/i18n";
import mixpanel from "mixpanel-browser";
import store from "@/store";
import router from "@/router";

import { markNotificationsAsSelected, getAssessmentDetails } from "@/customApi";

export {
  NotificationBehaviour,
  GroupNotifications,
  ExtractNotificationEntities
};

const NotificationBehaviour = {
  CompletedAssessment: {
    icon: "laptop_chromebook",
    text: (name, length) => {
      return i18n.t("notificationsBadge.completedAssessment", {
        name: name
      });
    },
    onClick: async notificationGroup => {
      await SelectAssessmentNotification(notificationGroup);
    }
  },
  CompletedRecommendation: {
    icon: "mdi-check",
    text: (name, length) => {
      return i18n.tc(`notificationsBadge.completedRecommendation`, length, {
        name: name,
        number: length
      });
    },
    onClick: async notificationGroup => {
      if (!store.getters.isRestrictedAdminUser) {
        store.commit(
          "setRecommendationsToViewInReport",
          notificationGroup.notifications.map(x => x.recommendationName)
        );
      }
      await SelectAssessmentNotification(notificationGroup);
    }
  }
};

// Group the item by type, assessmentId and if selected
function GroupNotifications(notifications) {
  var groupedItems = [];
  notifications.forEach(apiModel => {
    var currentGroup = groupedItems.find(
      x =>
        x.type == apiModel.notification.type &&
        x.assessmentId == apiModel.notification.assessmentId &&
        x.selected == !!apiModel.notification.selectedAt
    );
    if (!currentGroup) {
      currentGroup = {
        type: apiModel.notification.type,
        assessmentId: apiModel.notification.assessmentId,
        selected: !!apiModel.notification.selectedAt,
        name: apiModel.nameOfUserWhoTriggered,
        notifications: []
      };
      groupedItems.push(currentGroup);
    }

    currentGroup.notifications.push(apiModel.notification);
  });
  return groupedItems;
}

function ExtractNotificationEntities(groupedNotifications) {
  const notificationArrays = groupedNotifications.map(x => x.notifications);
  return notificationArrays.flat();
}

async function SelectAssessmentNotification(notificationGroup) {
  const assessmentDetails = await getAssessmentDetails(
    notificationGroup.assessmentId
  );

  await markNotificationsAsSelected(notificationGroup.notifications);
  notificationGroup.selected = true;
  mixpanel.track(`Notification marked as selected`);

  try {
    await router.push({
      path: `/people/${assessmentDetails.userId}`,
      props: { userId: assessmentDetails.userId }
    });
  } catch (error) {
    if (error.name != "NavigationDuplicated") throw error;
  }

  await store.dispatch("userProfile/viewAssessment", {
    id: assessmentDetails.id,
    assessmentType: assessmentDetails.assessmentType,
    lastUpdatedAt:
      assessmentDetails.lastUpdatedAt ?? assessmentDetails.completedAt
  });
}
