<template>
  <v-dialog persistent max-width="600px" v-model="value">
    <v-card>
      <div class="pa-6 pa-md-8">
        <h1 class="text-h5 mb-4">
          {{
            $t("incompleteDialog.title", {
              type: this.assessmentTypeText
            })
          }}
        </h1>
        <p class="text-body-1 mb-6">
          {{ $t("incompleteDialog.body") }}
        </p>

        <div class="d-flex flex-column-reverse flex-md-row" style="gap: 1rem">
          <vfl-button
            variant="outline"
            @click.once="startNew"
            data-test-id="incomplete-assessment-dialog-start-new"
            :loading="isStartNewLoading"
            :disabled="isResumeLoading"
          >
            {{ $t("incompleteDialog.buttons.startNew") }}
          </vfl-button>
          <vfl-button
            class="mr-2"
            @click.once="resume"
            data-test-id="incomplete-assessment-dialog-resume"
            :loading="isResumeLoading"
            :disabled="isStartNewLoading"
          >
            {{ $t("incompleteDialog.buttons.resume") }}
          </vfl-button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { assessmentRoutes } from "@/router/route-constants.js";

export default {
  name: "IncompleteAssessmentDialog",
  props: {
    assessmentId: String,
    assessmentType: String,
    value: Boolean
  },
  data() {
    return {
      isOpen: false,
      isStartNewLoading: false,
      isResumeLoading: false
    };
  },
  computed: {
    assessmentTypeText() {
      const root = this.$t("assessmentTypes");
      const type = this.assessmentType
        ? root[this.assessmentType]
        : root.assessment;

      return type.toLowerCase();
    }
  },
  methods: {
    async startNew() {
      if (this.isStartNewLoading || this.isResumeLoading) {
        return;
      }

      this.isStartNewLoading = true;
      this.$emit("input", false);
      this.$emit("startNew");
    },
    resume() {
      if (this.isStartNewLoading || this.isResumeLoading) {
        return;
      }

      this.isResumeLoading = true;

      this.$router
        .push({
          name: assessmentRoutes[this.assessmentType],
          params: {
            assessmentId: this.assessmentId
          },
          replace: true
        })
        .catch(() => {
          this.isResumeLoading = false;
        });
    }
  }
};
</script>
