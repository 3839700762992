<template>
  <v-card min-height="500px" v-if="!results">
    <v-row style="height: 100%" justify="center" align="center" no-gutters>
      <v-progress-circular
        indeterminate
        size="200"
        width="10"
        color="primary"
      />
    </v-row>
  </v-card>
  <v-card v-else style="overflow: hidden" class="patient-report-card">
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog && enableGeneralFeedback"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>
    <feedback-button
      v-if="viewingOwnResults && enableGeneralFeedback"
      v-model="feedbackDialog"
      absolute
      :class="
        $vuetify.breakpoint.smAndDown ? 'feedbackSmallScreen' : 'feedback'
      "
    />

    <v-card-title class="pa-0 ma-0" style="z-index: 2">
      <v-toolbar
        dark
        color="var(--v-vflPrimary-base)"
        width="100%"
        class="no-print"
      >
        <h1 v-if="viewingOwnResults" class="text-h6">
          {{ title }}
        </h1>
        <h1 v-else class="text-body-1 d-flex flex-column">
          <span class="data-hj-suppress">{{ assessment.name }}</span>
          <span>
            {{
              $t("dashboardHistoryComponents.patientHistoryDialog.title", {
                "0": getFormattedDate(assessmentDate)
              })
            }}
          </span>
        </h1>
        <v-spacer></v-spacer>
        <v-btn
          v-if="canDownloadReport"
          icon
          @click="download"
          :loading="downloadInProgress || downloadOnMount"
          ><v-icon>mdi-download-outline</v-icon></v-btn
        >
        <v-btn icon dark @click="closeReport">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text
      id="reportSection"
      class="px-0 pb-0 text-body-dark"
      v-scroll.self="onScroll"
    >
      <div ref="container">
        <p id="reportDetails" v-show="false">
          {{
            $t("dashboardHistoryComponents.patientHistoryDialog.pdfText", {
              emailAddress: assessmentEmail,
              createdAt: getFormattedDate(assessment.createdAt),
              completedAt: getFormattedDate(assessment.completedAt)
            })
          }}
        </p>

        <section
          class="ai-seed-questions-wrapper pa-4 pb-0 mt-8 no-print"
          v-if="seedQuestions.length"
        >
          <ai-seed-question-container
            :items="seedQuestions"
            variant="cards"
            :heading="$t('aiAssistant.windowInput.default')"
            :scroll-to-end="false"
          />
        </section>

        <component
          :data-test-id="reportType"
          :is="reportType"
          :results="results"
          :assessmentId="assessment.id"
          :userName="assessment.name"
          :assessmentEmail="assessmentEmail"
          @closeReport="closeReport"
          reportContext="archive"
          @handleScreenAssessmentPanelVisibility="
            handleScreenAssessmentPanelVisibility
          "
          @handleVisualDeskPlannerVisibility="handleVisualDeskPlannerVisibility"
        />
      </div>
    </v-card-text>
    <v-snackbar id="errorMessage" v-model="error" color="red"
      ><p class="text-center mb-0">{{ $t("errorMessages.pdfGeneration") }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="error = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="showGenerateReportContextButton" class="output-context-button">
      <button
        class="vfl-button-primary"
        @click="generateReportContextForClinicalTeam"
      >
        Generate Context
      </button>
    </div>

    <AiFeatureIntroductionDialog
      v-if="showAiIntroductionDialog"
      attach="patient-report-card"
      @close="onAiFeatureIntroductionDialogClosed"
    />
  </v-card>
</template>

<script>
import DeskAssessmentReport from "@/components/deskassessment/report/Report.vue";
import BurnoutAssessmentReport from "@/components/burnout-assessment/report/BurnoutReport.vue";
import DriverReport from "@/components/driver-assessment/report/DriverReport.vue";
import PhysicalLabourReport from "@/components/assessment/physical-labour/report/PhysicalLabourReport.vue";
import MenopauseReport from "@/components/assessment/menopause/report/MenopauseReport.vue";
import MenstruationReport from "@/components/assessment/menstruation/report/MenstruationReport.vue";
import PregnancyReport from "@/components/assessment/pregnancy/report/PregnancyReport.vue";
import NewParentReport from "@/components/assessment/new-parent/report/NewParentReport.vue";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
import { mapActions, mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { EventBus } from "@/services/events/event-bus";
import * as Constants from "@/constants/constants.js";
import AiSeedQuestionContainer from "@/components/common/report/cards/AiSeedQuestionContainer.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { generateContextFromReport } from "@/services/ai/report-context-generator.js";
import getReportSeedQuestions from "@/services/ai/ai-seed-questions-report-overview.js";
import { contextToFile } from "@/services/ai/context-to-file.js";
import AiFeatureIntroductionDialog from "@/components/common/ai/feature-introduction/AiFeatureIntroductionDialog.vue";
import FeatureService from "@/services/new-feature-service.js";
import { formatWithLocale } from "@/services/date-formatting-service.js";

export default {
  components: {
    AiSeedQuestionContainer,
    DeskAssessmentReport,
    BurnoutAssessmentReport,
    FeedbackButton,
    FeedbackDialog,
    DriverReport,
    PhysicalLabourReport,
    AiFeatureIntroductionDialog,
    MenopauseReport,
    MenstruationReport,
    PregnancyReport,
    NewParentReport
  },
  props: {
    assessment: Object,
    results: Object,
    downloadOnMount: Boolean,
    healthConditions: Array
  },
  data() {
    return {
      resultIsAvailable: false,
      feedbackDialog: false,
      downloadInProgress: false,
      error: false,
      seedQuestions: [],
      childrenMounted: {
        webcamCard: false,
        workSetupContent: false,
        visualDeskPlannerCard: false,
        recommendationsContent: false
      },
      displayScreenAssessmentPanel: null,
      displayVisualDeskPlanner: null,
      contentRequiredForAiContextLoaded: false,
      hasSeenAiFeatureIntroduction: false
    };
  },
  assessmentTypes: Constants.assessmentTypes,
  created() {
    if (!this.showAiIntroductionDialog) {
      this.setSeedQuestions();
    }

    this.$store.dispatch("ai/setContextLoading", true);
  },
  mounted() {
    EventBus.$on("reportLoaded", this.checkForAutoDownload);
    if (this.$gtag) {
      this.$gtag.event("View report from dashboard", {
        event_category: "History"
      });
    }

    document.body.classList.add("patient-report-dialog-open");

    EventBus.$on("webcam-information-card-mounted", () =>
      this.handleComponentMounted("webcamCard")
    );
    EventBus.$on("work-setup-content-mounted", () =>
      this.handleComponentMounted("workSetupContent")
    );
    EventBus.$on("visual-desk-planner-report-card-mounted", () =>
      this.handleComponentMounted("visualDeskPlannerCard")
    );
    EventBus.$on("recommendations-content-mounted", () =>
      this.handleComponentMounted("recommendationsContent")
    );
  },
  beforeDestroy() {
    EventBus.$off("reportLoaded");
    EventBus.$off("webcam-information-card-mounted");
    EventBus.$off("work-setup-content-mounted");
    EventBus.$off("visual-desk-planner-report-card-mounted");
    EventBus.$off("recommendations-content-mounted");

    document.body.classList.remove("patient-report-dialog-open");

    if (this.canAccessAiFeatures && this.seedQuestions?.length) {
      this.removeConversationSuggestions();
    }
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "signedIn",
      "isSimpleUser",
      "hideAiFeatures",
      "clinicalTeam",
      "companyRole"
    ]),
    assessmentType() {
      return this.assessment?.assessmentType;
    },
    assessmentDate() {
      return (
        this.assessment.lastUpdatedAt || this.assessment.assessmentCompletedAt
      );
    },
    assessmentEmail() {
      return typeof this.assessment.email === "string"
        ? this.assessment.email
        : this.assessment.email.value;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    title() {
      return this.$t(
        `dashboardHistoryComponents.patientHistoryDialog.ownResultsTitle.${
          Constants.assessmentLocalePaths[this.assessmentType]
        }`
      );
    },
    reportType() {
      switch (this.assessmentType) {
        case this.$options.assessmentTypes.deskAssessment:
          return "DeskAssessmentReport";
        case this.$options.assessmentTypes.burnoutAssessment:
          return "BurnoutAssessmentReport";
        case this.$options.assessmentTypes.driverAssessment:
          return "DriverReport";
        case this.$options.assessmentTypes.physicalLabourAssessment:
          return "PhysicalLabourReport";
        case this.$options.assessmentTypes.menopauseAssessment:
          return "MenopauseReport";
        case this.$options.assessmentTypes.menstruationAssessment:
          return "MenstruationReport";
        case this.$options.assessmentTypes.pregnancyAssessment:
          return "PregnancyReport";
        case this.$options.assessmentTypes.newParentAssessment:
          return "NewParentReport";
        default:
          throw new Error(`Unknown assessment type ${this.assessmentType}`);
      }
    },
    enableGeneralFeedback() {
      return !this.signedIn;
    },
    canAccessAiFeatures() {
      return (
        !this.hideAiFeatures &&
        !this.isSimpleUser &&
        !viewingOwnResults(this.$route) &&
        this.assessmentType === this.$options.assessmentTypes.deskAssessment
      );
    },
    showGenerateReportContextButton() {
      const isStaged = import.meta.env.VITE_NODE_ENV === "staged";

      return this.clinicalTeam && isStaged;
    },
    showAiIntroductionDialog() {
      const showFeature = FeatureService.showFeature("AiIntroductionDialog");

      return (
        this.canAccessAiFeatures &&
        showFeature &&
        !this.hasSeenAiFeatureIntroduction
      );
    },
    canDownloadReport() {
      return (
        this.assessmentType !==
          this.$options.assessmentTypes.menopauseAssessment &&
        this.assessmentType !==
          this.$options.assessmentTypes.menstruationAssessment
      );
    }
  },
  watch: {
    childrenMounted: {
      deep: true,
      handler() {
        this.checkSubComponentsLoaded();
      }
    },
    displayScreenAssessmentPanel() {
      this.checkSubComponentsLoaded();
    },
    displayVisualDeskPlanner() {
      this.checkSubComponentsLoaded();
    },
    assessment(newVal) {
      if (newVal && !this.showAiIntroductionDialog) {
        this.setSeedQuestions();
      }
    }
  },
  methods: {
    ...mapActions({
      updateAssessmentInfo: "ai/updateAssessmentInfo",
      addConversationSuggestions: "ai/addConversationSuggestions",
      removeConversationSuggestions: "ai/removeConversationSuggestions"
    }),
    async download() {
      const originalTitle = document.title;
      document.title = this.getPdfTitle();

      window.print();

      document.title = originalTitle;

      // We need to reset this so that the loading state on the download button is reset.
      // There is no straightforward way to detect when the window print dialog is closed,
      // So resetting it when it opens instead
      this.downloadInProgress = false;

      this.$emit("downloadStarted");
    },
    getPdfTitle() {
      const dateStr =
        this.assessment.lastUpdatedAt || this.assessment.completedAt;
      const date = new Date(dateStr);
      const formattedDate = formatWithLocale(date, "yyyy-MM-dd");

      let assessmentTypeStr = this.assessment.assessmentType.toLowerCase();
      assessmentTypeStr = assessmentTypeStr.replace(/\s+/g, "-");

      const formattedEmail = this.assessmentEmail.replace(/@/g, "-at-");

      return `VIDA-assessment-${formattedEmail}-${formattedDate}`;
    },
    getFormattedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onScroll() {
      var scrollableDiv = document.getElementById("reportSection");
      if (scrollableDiv) {
        this.nearBottomOfPage =
          scrollableDiv.scrollHeight - scrollableDiv.scrollTop <=
          scrollableDiv.clientHeight + 500;
      }
    },
    checkForAutoDownload() {
      if (this.downloadOnMount && !this.downloadInProgress) {
        this.$nextTick(() => this.download());
      }
    },
    setSeedQuestions() {
      if (!this.canAccessAiFeatures) return;
      if (this.assessmentType === assessmentTypes.deskAssessment) {
        this.seedQuestions = getReportSeedQuestions(
          this.results,
          this.assessment.score,
          this.healthConditions,
          this.companyRole
        );

        if (this.seedQuestions.length) {
          this.addConversationSuggestions(this.seedQuestions);
        }
      }
    },
    async handleReportAiContext() {
      if (this.showAiIntroductionDialog) return;

      if (!this.canAccessAiFeatures) {
        await this.$store.dispatch("ai/setContextLoading", false);
        return;
      }

      try {
        const context = generateContextFromReport(
          this.assessment,
          this.results,
          this.healthConditions,
          this.companyRole
        );

        await this.updateAssessmentInfo({
          summary: context
        });
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        await this.$store.dispatch("ai/setContextLoading", false);
      }
    },
    checkSubComponentsLoaded() {
      if (
        this.displayScreenAssessmentPanel === null ||
        this.displayVisualDeskPlanner === null
      ) {
        this.contentRequiredForAiContextLoaded = false;
        return;
      }

      const requiredComponents = ["workSetupContent", "recommendationsContent"];

      if (this.displayScreenAssessmentPanel) {
        requiredComponents.push("webcamCard");
      }

      if (this.displayVisualDeskPlanner) {
        requiredComponents.push("visualDeskPlannerCard");
      }

      const allContentLoaded = requiredComponents.every(
        component => this.childrenMounted[component]
      );

      if (allContentLoaded) {
        this.contentRequiredForAiContextLoaded = true;
        this.handleReportAiContext();
      } else {
        this.contentRequiredForAiContextLoaded = false;
      }
    },
    handleComponentMounted(componentName) {
      this.childrenMounted[componentName] = true;
      this.checkSubComponentsLoaded();
    },
    handleScreenAssessmentPanelVisibility(value) {
      this.displayScreenAssessmentPanel = value;
    },
    handleVisualDeskPlannerVisibility(value) {
      this.displayVisualDeskPlanner = value;
    },
    generateReportContextForClinicalTeam() {
      contextToFile(
        this.assessment,
        this.results,
        this.healthConditions,
        this.companyRole
      );
    },
    onAiFeatureIntroductionDialogClosed() {
      this.hasSeenAiFeatureIntroduction = true;

      this.$nextTick(() => {
        this.setSeedQuestions();
        this.checkSubComponentsLoaded();
      });
    },
    closeReport() {
      this.$emit("input", false);
      this.$emit("onClose");
    }
  }
};
</script>

<style lang="scss">
html:has(.patient-report-dialog-open) {
  overflow-y: hidden;
}

@media print {
  html:has(.patient-report-dialog-open) {
    // Hide the main content behind the dialog so it doesn't get printed
    .v-application--wrap {
      display: none;
    }
  }

  .patient-report-dialog-open {
    .v-dialog__content,
    .v-dialog,
    .v-dialog--fullscreen {
      height: auto !important;
      left: 0;
      margin: 0;
      max-height: initial;
      overflow: initial !important;
      position: absolute !important;
      top: 0;

      > .v-card {
        max-height: initial !important;
        overflow: initial !important;
      }

      #reportSection {
        overflow: initial !important;
      }
    }
  }
}

.feedback {
  top: 50%;
  right: 20px;
}
.feedbackSmallScreen {
  top: 90%;
}
</style>

<style scoped>
h1 {
  word-break: normal;
}

.ai-seed-questions-wrapper {
  margin: 0 auto;
  max-width: 1600px;
}

.output-context-button {
  bottom: 1rem;
  left: 1rem;
  position: fixed;
  z-index: 1000;
}
</style>
