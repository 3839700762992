<template>
  <div class="user-profile-block-container pb-4">
    <div class="header">
      <h3 class="mb-0 text-overline text-body-dark d-flex">
        <slot name="title"></slot>
      </h3>
      <slot name="action"></slot>
    </div>
    <div class="content" :class="hasTabs ? 'mt-1' : 'mt-4'">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserProfileBlock",
  props: {
    hasTabs: {
      type: Boolean,
      default: false
    }
  }
};
</script>
