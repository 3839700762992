import { getReturnToWorkRecommendations } from "@/components/assessment/new-parent/report/recommendations/return-to-work-recommendations.js";
import { getLifestyleRecommendations } from "@/components/assessment/new-parent/report/recommendations/lifestyle-recommendations.js";
import { getPainRecommendations } from "@/components/assessment/new-parent/report/recommendations/pain-recommendations.js";
import { getLiftingAndCarryingRecommendations } from "@/components/assessment/new-parent/report/recommendations/lifting-carrying-recommendations.js";

const recommendationMap = {
  pain: getPainRecommendations,
  liftingAndCarrying: getLiftingAndCarryingRecommendations,
  lifestyle: getLifestyleRecommendations,
  returnToWork: getReturnToWorkRecommendations
};

export const getRecommendationsByType = (type, results) => {
  const getRecommendations = recommendationMap[type];

  if (!getRecommendations) {
    throw new Error(`Unknown recommendation type: ${type}`);
  }

  return getRecommendations(results);
};

export const getAllRecommendations = results => ({
  pain: getPainRecommendations(results),
  liftingAndCarrying: getLiftingAndCarryingRecommendations(results),
  lifestyle: getLifestyleRecommendations(results),
  returnToWork: getReturnToWorkRecommendations(results)
});
