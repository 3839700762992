<template>
  <div class="d-flex flex-column mb-8">
    <h1 class="text-h4 mb-10 mt-8">
      {{ $t(content.title, content.titleParams) }}
    </h1>
    <div class="d-flex flex-wrap" style="gap: 1.5rem">
      <div class="image">
        <img :src="imagePath" />
      </div>
      <div style="flex: 1 1 300px">
        <p
          v-for="(paragraph, index) in content.paragraphs"
          :key="index"
          v-html="$t(paragraph)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { OutroService } from "./outro-service";
import { Theme } from "./../steps.js";

export default {
  name: "OutroStep",
  props: {
    step: {
      type: Object,
      required: true
    },
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outroService: new OutroService()
    };
  },
  computed: {
    content() {
      switch (this.step.THEME) {
        case Theme.STAGE_OUTRO:
          return this.outroService.getStageContent(this.results.stage);
        case Theme.PAIN_OUTRO:
          return this.outroService.getPainContent(this.results);
        case Theme.SYMPTOMS_OUTRO:
          return this.outroService.getSymptomsContent(
            this.results.symptoms.menstruationSymptoms
          );
        default:
          return { title: "", titleParams: {}, paragraphs: [] };
      }
    },
    imagePath() {
      const imageMap = {
        [Theme.STAGE_OUTRO]:
          "/images/menstruation-assessment/outro-images/stage-outro.png",
        [Theme.PAIN_OUTRO]:
          "/images/menstruation-assessment/outro-images/pain-outro.png",
        [Theme.SYMPTOMS_OUTRO]:
          "/images/menstruation-assessment/outro-images/symptoms-outro.png"
      };
      return imageMap[this.step.THEME];
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  border-radius: 8px;
  position: relative;

  img {
    max-width: 200px;
  }
}
</style>
