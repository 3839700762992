<template>
  <report-header
    background-color="#fff3df"
    max-width="75ch"
    :title="$t('pregnancyAssessment.report.content.header.title')"
    :content="$t('pregnancyAssessment.report.content.header.content')"
  >
    <template #background>
      <img
        class="background-image"
        src="/images/illustrations/pregnant-woman-sitting-with-plant.svg"
        style="right: 5%"
      />
    </template>
  </report-header>
</template>

<script>
import ReportHeader from "@/components/assessment/common/report/ReportHeader.vue";

export default {
  name: "PregnancyReportHeader",
  components: { ReportHeader },
  props: {
    results: {
      type: Object,
      required: true
    }
  }
};
</script>
