import CirculationRecommendations from "./CirculationRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import Answers from "@/components/assessment/pregnancy/answers.json";

const cardGroups = {
  genericCirculation: {
    titleKey:
      "pregnancyAssessment.report.content.circulation.recommendationGroups.circulationGeneric",
    cards: {
      short_walk_pregnancy: {
        showConditions: () => true
      },
      calf_raise_pregnancy: {
        showConditions: () => true
      },
      ankle_circle_pregnancy: {
        showConditions: results => {
          return (
            results.circulation?.timeSpentSittingOrStanding !==
            Answers.circulation.timeSpentSittingOrStanding.mostlyStanding
          );
        }
      },
      elevate_leg_pregnancy: {
        showConditions: results => {
          return (
            results.circulation?.feetOrLegSwellingWhilstSeated ===
              Answers.circulation.feetOrLegSwellingWhilstSeated
                .moderateSwelling ||
            results.circulation?.feetOrLegSwellingWhilstSeated ===
              Answers.circulation.feetOrLegSwellingWhilstSeated
                .significantSwelling
          );
        }
      }
    }
  },
  firstTrimesterCirculation: {
    titleKey:
      "pregnancyAssessment.report.content.circulation.recommendationGroups.firstTrimester",
    cards: {
      happy_baby_pregnancy: {
        showConditions: () => true
      },
      downward_dog_pregnancy: {
        showConditions: () => true
      },
      butterfly_stretch_pregnancy: {
        showConditions: () => true
      }
    }
  },
  secondTrimesterCirculation: {
    titleKey:
      "pregnancyAssessment.report.content.circulation.recommendationGroups.secondTrimester",
    cards: {
      bridge_pose_balance_pregnancy: {
        showConditions: () => true
      },
      childs_pose_pregnancy: {
        showConditions: () => true
      },
      low_lunge_pregnancy: {
        showConditions: () => true
      }
    }
  },
  thirdTrimesterCirculation: {
    titleKey:
      "pregnancyAssessment.report.content.circulation.recommendationGroups.thirdTrimester",
    cards: {
      hamstring_stretch_pregnancy: {
        showConditions: () => true
      },
      goddess_stretch_pregnancy: {
        showConditions: () => true
      },
      pigeon_stretch_pregnancy: {
        showConditions: () => true
      }
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, CirculationRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getCirculationRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
