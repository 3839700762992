export default {
  getDomainFromEmail(email) {
    return email.split("@").pop();
  },
  emailMatchesAnyDomain(email, listOfDomains) {
    if (listOfDomains.some(d => d === "vitruehealth.com")) return true; // done to allow people to join a team set up by sales
    var emailDomain = this.getDomainFromEmail(email);
    return listOfDomains.some(d => d === emailDomain);
  }
};
