# MenopauseReportPain.vue
<template>
  <report-pain-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
    :results="results"
    :assessment-id="assessmentId"
    :assessment-type="$options.assessmentTypes.menopauseAssessment"
    :section-title="sectionTitle"
    :pain-area-title="painAreasCardTitle"
    :info-cards="infoCards"
    :recommendation-title="recommendationTitle"
    :pain-recommendations="painRecommendations"
  >
    <template #warning>
      <menopause-report-pain-callout
        v-if="showChronicPainWarning"
        class="mt-4"
      />
    </template>
  </report-pain-section>
</template>

<script>
import ReportPainSection from "@/components/assessment/common/report/ReportPainSection.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import MenopauseReportPainCallout from "@/components/assessment/menopause/report/MenopauseReportPainCallout.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menopause/report/recommendations/recommendation-service.js";
import BodyAreas from "@/components/painareas/body-areas.json";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "MenopauseReportPain",
  components: {
    ReportPainSection,
    MenopauseReportPainCallout
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      painRecommendations: {}
    };
  },
  created() {
    this.painRecommendations = getRecommendationsByType(
      RecommendationSections.PAIN,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return menopauseReportService.getPainSectionTitle(this.results);
    },
    painAreasCardTitle() {
      const title = menopauseReportService.getPainAreasCardTitle();

      return title();
    },
    infoCards() {
      return [
        {
          title: menopauseReportService.getPainContentCardTitle(),
          illustration: "/images/illustrations/menopause-pain.png",
          text: menopauseReportService.getPainContentCardBody(this.results),
          showCondition: true
        }
      ];
    },
    showChronicPainWarning() {
      if (!this.results?.pain?.areas) {
        return false;
      }

      const isChronicDurationBoundary = 4;
      return Object.values(this.results.pain.areas).some(
        area => area.duration < isChronicDurationBoundary
      );
    },
    recommendationTitle() {
      const painAreaKeys = Object.keys(this.results.pain.areas);
      if (painAreaKeys.length > 1) {
        return this.$t(
          "menopauseAssessment.report.recommendations.pain.title.generic"
        );
      }

      const translatedArea = this.$t(
        BodyAreas[painAreaKeys[0]].shortenedName
      ).toLowerCase();
      return this.$t(
        "menopauseAssessment.report.recommendations.pain.title.withArea",
        { area: translatedArea }
      );
    }
  }
};
</script>
