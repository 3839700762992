import NewParentReportHeader from "@/components/assessment/new-parent/report/NewParentReportHeader.vue";
import NewParentReportIntroduction from "@/components/assessment/new-parent/report/NewParentReportIntroduction.vue";
import NewParentReportPain from "@/components/assessment/new-parent/report/NewParentReportPain.vue";
import NewParentReportCaringForChild from "@/components/assessment/new-parent/report/NewParentReportCaringForChild.vue";
import NewParentReportLifestyle from "@/components/assessment/new-parent/report/NewParentReportLifestyle.vue";
import NewParentReportReturnToWork from "@/components/assessment/new-parent/report/NewParentReportReturnToWork.vue";

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: NewParentReportHeader,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "introduction",
    component: NewParentReportIntroduction,
    isVisible: () => true,
    skipNumbering: true
  },
  {
    id: "pain",
    component: NewParentReportPain,
    isVisible: vm => vm.hasPain
  },
  {
    id: "caringForBaby",
    component: NewParentReportCaringForChild,
    isVisible: () => true
  },
  {
    id: "lifestyle",
    component: NewParentReportLifestyle,
    isVisible: () => true
  },
  {
    id: "returnToWork",
    component: NewParentReportReturnToWork,
    isVisible: () => true
  }
];
