import Answers from "@/components/assessment/menstruation/answers.json";

export const STAGES = {
  PERIOD: "period",
  FOLLICULAR: "follicular",
  OVULATION: "ovulation",
  LUTEAL: "luteal"
};

export const STAGE_MAPPING = {
  [Answers.stage.menstruationPhase.follicular]: STAGES.FOLLICULAR,
  [Answers.stage.menstruationPhase.ovulation]: STAGES.OVULATION,
  [Answers.stage.menstruationPhase.luteal]: STAGES.LUTEAL
};

export const TREATMENT_TYPES = {
  INJECTABLE_CONTRACEPTIVES: "injectable_contraceptives"
};
