<template>
  <svg
    width="641"
    height="638"
    viewBox="0 0 641 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M345.724 491.694C348.008 496.641 353.787 498.848 358.731 496.56C363.682 494.27 365.567 488.571 363.279 483.617L337.074 422.106C334.783 417.155 328.916 414.998 323.966 417.293C319.022 419.58 317.643 424.997 319.937 429.944L345.724 491.694Z"
      fill="#987759"
    />
    <path
      d="M316.077 390.473C326.445 385.754 332.412 393.401 339.308 413.16C343.262 424.487 345.855 434.19 345.855 434.19L323.312 442.525C323.312 442.525 300.155 397.721 316.077 390.473Z"
      fill="#C86E01"
    />
    <path
      d="M381.078 555.635C383.503 560.518 393.048 556.658 390.63 551.778L363.402 484.256C360.987 479.366 355.238 477.252 350.351 479.671C345.47 482.096 343.656 487.902 346.071 492.785L381.078 555.635Z"
      fill="#987759"
    />
    <path
      d="M383.593 547.151C393.028 547.084 398.831 546.957 408.393 547.218C413.659 547.362 417.067 546.494 417.238 549.6C417.369 551.927 410.798 551.884 408.454 551.974C399.693 552.32 399.669 552.585 399.733 553.507C399.793 554.45 404.804 554.175 407.226 555.469C408.729 556.274 408.306 559.414 403.969 559.179C394.074 558.646 383.06 557.438 383.06 557.438L383.593 547.151Z"
      fill="#987759"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M350.11 425.338C341.863 427.064 335.238 430.351 333.384 433.245C328.735 440.502 348.955 463.387 361.878 467.808C374.801 472.229 402.688 453.475 387.681 434.868C382.472 428.41 374.899 425.365 367.035 424.446L344.565 386.741L324.976 404.946L350.11 425.338Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M356.352 465.573C357.608 466.535 359.262 467.364 360.649 468.089C362.438 469.025 364.352 469.587 366.274 470.008C369.893 470.801 373.62 471.09 377.222 470.857C383.801 470.432 390.201 467.766 394.354 462.841C396.451 460.354 397.909 457.318 398.468 454C398.972 451.003 398.815 447.427 400.968 445.159C403.31 442.691 407.631 442.768 410.886 442.27C414.557 441.708 417.985 440.598 421.016 438.588C426.095 435.22 428.85 429.887 428.527 423.453C428.183 416.606 425.291 408.784 418.435 405.661C417.16 405.08 415.911 404.753 414.568 404.767C413.337 404.781 411.717 405.083 410.545 404.365C409.3 403.601 408.923 401.474 408.392 400.234C407.631 398.458 406.723 396.695 405.238 395.377C402.798 393.211 399.194 392.329 396.163 392.233C393.078 392.134 390.254 393.059 387.752 394.601C386.081 395.63 384.496 396.814 383.161 398.251C382.498 398.964 382.054 399.745 381.511 400.543C380.983 401.319 380.443 401.489 379.533 401.649C375.305 402.396 371.851 404.375 369.037 407.463C366.662 410.07 364.275 413.451 363.814 417.166C363.302 421.292 365.859 423.95 369.089 426.326C368.24 426.578 366.466 427.894 368.494 428.149C369.298 428.25 370.189 427.767 370.926 427.58C371.785 427.363 372.736 427.229 373.65 427.327C377.34 427.721 379.419 433.57 377.817 436.342C375.436 440.459 370.313 436.442 366.803 437.433C364.785 438.003 364.245 440.289 364.129 442.219C363.974 444.804 364.652 447.408 365.485 449.924C366.172 451.996 366.923 454.045 367.59 456.122C367.901 457.092 368.581 460.02 369.841 460.273C367.898 462.966 364.319 464.443 361.057 465.053C359.708 465.304 358.51 465.313 357.175 464.786C356.889 464.672 356.116 464.264 355.828 464.486C355.291 464.899 356.037 465.331 356.352 465.573Z"
      fill="#070707"
    />
    <path
      d="M130.48 528.702C130.48 528.702 127.79 534.957 135.253 541.374C140.472 545.858 151.43 552.14 156.169 555.538C159.674 558.05 166.885 561.17 170.035 558.02C172.685 555.364 172.651 550.715 166.664 546.636C161.116 542.863 157.47 539.348 155.022 537.231C149.836 532.774 150.333 530.013 150.333 530.013L155.991 520.357L139.851 512.444L135.068 520.843L130.48 528.702Z"
      fill="#987759"
    />
    <path
      d="M198.327 381.798C198.327 381.798 206.696 367.952 221.095 375.009C236.631 382.63 232.499 393.715 230.074 398.579C203.771 451.486 154.855 526.021 154.855 526.021C154.855 526.021 151.527 528.054 142.002 523.378C132.295 518.619 132.49 515.05 132.49 515.05C149.492 477.91 186.692 406.213 198.327 381.798Z"
      fill="#17282F"
    />
    <path
      d="M294.461 398.889C300.498 409.451 306.529 412.101 312.033 416.817C321.216 424.676 324.708 425.886 328.961 425.957C330.491 425.984 337.004 423.606 338.936 422.717C342.398 421.12 355.338 412.232 358.179 409.713C364.78 403.87 368.503 398.577 367.976 394.217C366.836 384.705 351.031 366.623 340.392 355.504C306.157 319.729 313.599 301.556 306.653 293.657C295.44 280.915 242.251 319.108 242.086 322.05C241.872 325.927 241.045 369.057 261 385.5C271.862 394.45 287.075 385.969 294.461 398.889Z"
      fill="#FF9B21"
    />
    <path
      d="M376.76 428.849C374.439 427.655 373.211 425.417 374.016 423.848C374.828 422.281 377.364 421.976 379.678 423.167C381.996 424.354 383.224 426.591 382.415 428.164C381.61 429.731 379.078 430.036 376.76 428.849Z"
      fill="#B28B67"
    />
    <path
      d="M109.438 525.795C109.438 525.795 106.235 531.802 113.134 538.815C117.961 543.719 128.358 550.897 132.796 554.677C136.073 557.471 143.009 561.181 146.407 558.303C149.268 555.878 149.623 551.246 143.995 546.684C138.786 542.465 135.446 538.651 133.172 536.337C128.389 531.463 129.106 528.753 129.106 528.753L135.55 519.603L120.131 510.373L114.66 518.342L109.438 525.795Z"
      fill="#B28B67"
    />
    <path
      d="M190.301 385.09C190.301 385.09 197.177 383.333 210.932 391.564C225.784 400.452 226.757 403.038 224.456 407.959C208.97 441.087 134.948 525.185 134.948 525.185C134.948 525.185 131.467 526.932 122.357 521.478C113.083 515.924 113.576 512.389 113.576 512.389C133.613 476.788 176.66 408.452 190.301 385.09Z"
      fill="#391236"
    />
    <path
      d="M108.435 525.795C108.435 525.795 105.232 531.802 112.138 538.815C116.965 543.719 127.362 550.897 131.797 554.677C135.08 557.471 142.01 561.181 145.414 558.303C148.265 555.878 148.624 551.246 142.996 546.684C137.784 542.465 134.446 538.651 132.179 536.337C127.389 531.463 128.11 528.753 128.11 528.753L134.55 519.603L119.128 510.373L113.664 518.342L108.435 525.795Z"
      fill="#B28B67"
    />
    <path
      d="M190.301 385.09C190.301 385.09 197.177 383.333 210.932 391.564C225.784 400.452 226.757 403.038 224.456 407.959C208.97 441.087 134.948 525.185 134.948 525.185C134.948 525.185 131.467 526.932 122.357 521.478C113.083 515.924 113.576 512.389 113.576 512.389C133.613 476.788 176.66 408.452 190.301 385.09Z"
      fill="#223A43"
    />
    <path
      d="M247.206 287.906C222.838 308.919 191.296 384.592 186.822 392.105C186.822 392.105 180.486 408.01 193.828 415.996C210.415 425.928 223.512 409.479 223.512 409.479C223.512 409.479 250.711 365.674 263.245 349.863C276.958 332.606 299.389 318.653 309.102 298.247C309.102 298.247 304.947 282.009 291.292 278.105C267.548 271.32 254.561 281.573 247.206 287.906Z"
      fill="#223A43"
    />
    <path
      opacity="0.5"
      d="M274.405 303.432C252.271 336.557 205.474 406.241 195.356 419.973C185.237 433.705 147.474 487.429 129.857 512.574"
      stroke="white"
      stroke-width="0.903424"
    />
    <path
      d="M395.247 493.582C400.083 501.803 405.252 497.808 407.962 496.04C412.534 493.065 415.522 490.271 412.799 485.552L378.51 421.24C375.779 416.521 369.742 414.904 365.019 417.631C360.3 420.355 359.421 425.879 362.145 430.595C362.145 430.595 386.452 478.646 395.247 493.582Z"
      fill="#B28B67"
    />
    <path
      d="M362.223 384.714C365.309 383.469 371.84 392.854 380.49 411.909C385.447 422.837 389.506 431.826 389.506 431.826L366.641 442.82C366.641 442.82 336.913 394.91 362.223 384.714Z"
      fill="#FEB760"
    />
    <path
      d="M436.369 554.141C439.22 558.79 448.384 554.084 445.533 549.438L412.334 484.647C409.479 479.998 403.569 478.408 398.924 481.259C394.279 484.11 392.984 490.057 395.845 494.706L436.369 554.141Z"
      fill="#B28B67"
    />
    <path
      d="M438.096 545.246C447.535 544.927 453.327 544.649 462.896 544.656C468.162 544.659 471.54 543.703 471.798 546.802C471.993 549.127 465.415 549.254 463.081 549.412C454.333 549.989 454.313 550.257 454.401 551.173C454.491 552.105 459.489 551.706 461.947 552.937C463.473 553.698 463.128 556.73 458.791 556.73C437.838 556.734 437.838 555.54 437.838 555.54L438.096 545.246Z"
      fill="#B28B67"
    />
  </svg>
</template>

<script>
export default {
  name: "PregnancyExerciseUnansweredOverlay"
};
</script>
