import Answers from "@/components/assessment/new-parent/answers.json";
import i18n from "@/plugins/i18n";
import sentry from "@/sentry.js";

const basePath = "newParentAssessment.report.caringForChild.cards";

export class NewParentReportCaringForChildService {
  constructor(results) {
    this.results = results;
  }

  getBabyCareRoutineActivities() {
    const babyCareRoutine = this.results?.carrying?.babyCareRoutine;

    if (!babyCareRoutine || !Array.isArray(babyCareRoutine)) {
      sentry.captureMessage(
        `Missing or invalid babyCareRoutine data: ${JSON.stringify(
          this.results?.carrying
        )}`
      );
      return [];
    }

    return babyCareRoutine;
  }

  getBabyAge() {
    return this.results?.getStarted?.babyAge;
  }

  isBabyMoreThanOneYear() {
    const babyAge = this.getBabyAge();
    return babyAge === Answers.getStarted.babyAge.moreThanAYear;
  }

  hasCareRoutine(routineId) {
    const careRoutines = this.getBabyCareRoutineActivities();
    return careRoutines.includes(routineId);
  }

  getIllustrationPathForType(cardType) {
    const imagePath = "/images/new-parent-assessment/report/";

    const typeToIllustrationMap = {
      carryOnNeck: `${imagePath}child-on-neck.svg`,
      leaningOverCrib: `${imagePath}over-the-railing.svg`,
      holdingWithOneArm: `${imagePath}multitasking.svg`,
      twistingPickingUp: `${imagePath}picking-up-quickly.svg`,
      carryingBuggyStairs: `${imagePath}stairs.svg`,
      pushingBuggyOneArm: `${imagePath}one-arm.svg`,
      otherRisks: `${imagePath}other-risks.svg`,
      default: `${imagePath}other-risks.svg`
    };

    return typeToIllustrationMap[cardType] || typeToIllustrationMap.default;
  }

  getCarryOnNeckCard() {
    if (this.hasCareRoutine(Answers.carrying.babyCareRoutine.carryBabyOnNeck)) {
      return {
        title: i18n.t(`${basePath}.carryOnNeck.title`),
        text: [
          i18n.t(`${basePath}.carryOnNeck.paragraph1`),
          i18n.t(`${basePath}.carryOnNeck.paragraph2`)
        ],
        illustration: this.getIllustrationPathForType("carryOnNeck"),
        cardType: "carryOnNeck",
        priority: 1,
        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getLeaningOverCribCard() {
    if (
      this.hasCareRoutine(
        Answers.carrying.babyCareRoutine.leaningOverCribWhenLifting
      )
    ) {
      return {
        title: i18n.t(`${basePath}.leaningOverCrib.title`),
        text: [
          i18n.t(`${basePath}.leaningOverCrib.paragraph1`),
          i18n.t(`${basePath}.leaningOverCrib.paragraph2`)
        ],
        illustration: this.getIllustrationPathForType("leaningOverCrib"),
        cardType: "leaningOverCrib",
        priority: 2,
        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getHoldingWithOneArmCard() {
    if (
      this.hasCareRoutine(
        Answers.carrying.babyCareRoutine.holdingBabyOneArmAndMultitasking
      )
    ) {
      return {
        title: i18n.t(`${basePath}.holdingWithOneArm.title`),
        text: [
          i18n.t(`${basePath}.holdingWithOneArm.paragraph1`),
          i18n.t(`${basePath}.holdingWithOneArm.paragraph2`)
        ],
        illustration: this.getIllustrationPathForType("holdingWithOneArm"),
        cardType: "holdingWithOneArm",
        priority: 3,

        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getTwistingPickingUpCard() {
    if (
      this.hasCareRoutine(
        Answers.carrying.babyCareRoutine.twistWhilstPickingUpBaby
      )
    ) {
      const paragraphs = [i18n.t(`${basePath}.twistingPickingUp.paragraph1`)];

      // Add paragraph about babies over 1 year if applicable
      if (this.isBabyMoreThanOneYear()) {
        paragraphs.push(
          i18n.t(`${basePath}.twistingPickingUp.olderBabyParagraph`)
        );
      }

      paragraphs.push(i18n.t(`${basePath}.twistingPickingUp.paragraph2`));

      return {
        title: i18n.t(`${basePath}.twistingPickingUp.title`),
        text: paragraphs,
        illustration: this.getIllustrationPathForType("twistingPickingUp"),
        cardType: "twistingPickingUp",
        priority: 4,
        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getCarryingBuggyUpDownStairsCard() {
    if (
      this.hasCareRoutine(
        Answers.carrying.babyCareRoutine.carryingBuggyUpDownStairs
      )
    ) {
      return {
        title: i18n.t(`${basePath}.carryingBuggyStairs.title`),
        text: [
          i18n.t(`${basePath}.carryingBuggyStairs.paragraph1`),
          i18n.t(`${basePath}.carryingBuggyStairs.paragraph2`)
        ],
        illustration: this.getIllustrationPathForType("carryingBuggyStairs"),
        cardType: "carryingBuggyStairs",
        priority: 5,
        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getPushingBuggyOneArmCard() {
    if (
      this.hasCareRoutine(Answers.carrying.babyCareRoutine.pushBuggyWithOneParm)
    ) {
      return {
        title: i18n.t(`${basePath}.pushingBuggyOneArm.title`),
        text: [
          i18n.t(`${basePath}.pushingBuggyOneArm.paragraph1`),
          i18n.t(`${basePath}.pushingBuggyOneArm.paragraph2`)
        ],
        illustration: this.getIllustrationPathForType("pushingBuggyOneArm"),
        cardType: "pushingBuggyOneArm",
        priority: 6,
        useHtml: true,
        showCondition: true
      };
    }
    return null;
  }

  getSummaryCard(extraRiskIds) {
    const paragraphs = [];

    try {
      extraRiskIds.forEach(riskId => {
        if (!riskId) {
          sentry.captureException(
            `Invalid risk ID in getSummaryCard: ${riskId}`
          );
          return;
        }

        if (
          riskId === Answers.carrying.babyCareRoutine.twistWhilstPickingUpBaby
        ) {
          paragraphs.push(i18n.t(`${basePath}.otherRisks.twistingPickingUp`));
        } else if (
          riskId === Answers.carrying.babyCareRoutine.carryingBuggyUpDownStairs
        ) {
          paragraphs.push(i18n.t(`${basePath}.otherRisks.carryingBuggyStairs`));
        } else if (
          riskId === Answers.carrying.babyCareRoutine.pushBuggyWithOneParm
        ) {
          paragraphs.push(i18n.t(`${basePath}.otherRisks.pushingBuggyOneArm`));
        } else {
          sentry.captureMessage(`Unknown risk ID in getSummaryCard: ${riskId}`);
        }
      });
    } catch (error) {
      sentry.captureException(`Error in getSummaryCard: ${error.message}`);
      // Ensure we at least have some content even if an error occurs
      if (paragraphs.length <= 1) {
        paragraphs.push(i18n.t(`${basePath}.otherRisks.twistingPickingUp`));
      }
    }

    return {
      title: i18n.t(`${basePath}.otherRisks.title`),
      text: paragraphs,
      illustration: this.getIllustrationPathForType("otherRisks"),
      cardType: "otherRisks",
      priority: 999, // Always at the end
      useHtml: true,
      showCondition: true
    };
  }

  getDefaultCard() {
    return {
      title: i18n.t(`${basePath}.default.title`),
      text: [i18n.t(`${basePath}.default.paragraph`)],
      illustration: this.getIllustrationPathForType("default"),
      cardType: "default",
      priority: 0,
      useHtml: true,
      showCondition: true
    };
  }

  getAllPotentialCards() {
    const cards = [];

    const carryOnNeckCard = this.getCarryOnNeckCard();
    if (carryOnNeckCard) cards.push(carryOnNeckCard);

    const leaningOverCribCard = this.getLeaningOverCribCard();
    if (leaningOverCribCard) cards.push(leaningOverCribCard);

    const holdingWithOneArmCard = this.getHoldingWithOneArmCard();
    if (holdingWithOneArmCard) cards.push(holdingWithOneArmCard);

    const twistingPickingUpCard = this.getTwistingPickingUpCard();
    if (twistingPickingUpCard) cards.push(twistingPickingUpCard);

    const carryingBuggyStairsCard = this.getCarryingBuggyUpDownStairsCard();
    if (carryingBuggyStairsCard) cards.push(carryingBuggyStairsCard);

    const pushingBuggyOneArmCard = this.getPushingBuggyOneArmCard();
    if (pushingBuggyOneArmCard) cards.push(pushingBuggyOneArmCard);

    return cards;
  }

  getCards() {
    try {
      const allCards = this.getAllPotentialCards();

      if (!Array.isArray(allCards)) {
        sentry.captureException(
          `getAllPotentialCards did not return an array: ${typeof allCards}`
        );
        return [this.getDefaultCard()];
      }

      // If 0 or 1 cards, add the default card
      if (allCards.length <= 1) {
        const result = [...allCards];
        result.push(this.getDefaultCard());
        return result;
      }

      // Sort cards by priority
      const sortedCards = [...allCards].sort((a, b) => {
        if (!a || !a.priority || !b || !b.priority) {
          sentry.captureException(
            `Invalid card object found during sorting: ${JSON.stringify({
              a,
              b
            })}`
          );
          return 0;
        }
        return a.priority - b.priority;
      });

      // If 4 or more cards, we need to add a summary card for the extras
      if (sortedCards.length > 4) {
        const topThreeCards = sortedCards.slice(0, 3);

        const extraCards = sortedCards.slice(3);
        const summaryCard = this.createSummaryCardFromExtraCards(extraCards);

        return [...topThreeCards, summaryCard];
      }

      // Otherwise, just return all cards (up to 4)
      return sortedCards;
    } catch (error) {
      sentry.captureException(`Error in getCards: ${error.message}`);
      // Return default card as fallback in case of errors
      return [this.getDefaultCard()];
    }
  }

  createSummaryCardFromExtraCards(extraCards) {
    const paragraphs = [];

    try {
      extraCards.forEach(card => {
        if (!card.cardType) {
          sentry.captureException(
            `Invalid card in summary card creation: ${JSON.stringify(card)}`
          );
          return;
        }

        const textKeyMap = {
          twistingPickingUp: `${basePath}.otherRisks.twistingPickingUp`,
          carryingBuggyStairs: `${basePath}.otherRisks.carryingBuggyStairs`,
          pushingBuggyOneArm: `${basePath}.otherRisks.pushingBuggyOneArm`
        };

        const textKey = textKeyMap[card.cardType];
        if (textKey) {
          paragraphs.push(i18n.t(textKey));
        } else {
          sentry.captureMessage(
            `Unknown card type in summary card: ${card.cardType}`
          );
        }
      });
    } catch (error) {
      sentry.captureException(
        `Error in createSummaryCardFromExtraCards: ${error.message}`
      );

      // Fallback content
      if (paragraphs.length <= 1) {
        paragraphs.push(i18n.t(`${basePath}.otherRisks.twistingPickingUp`));
      }
    }

    return {
      title: i18n.t(`${basePath}.otherRisks.title`),
      text: paragraphs,
      illustration: this.getIllustrationPathForType("otherRisks"),
      cardType: "otherRisks",
      priority: 999,
      useHtml: true,
      showCondition: true
    };
  }
}
