<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M457.902 607.275C457.902 607.275 459.75 607.968 460.905 608.199C462.06 608.43 463.445 605.658 463.445 605.658L457.902 607.275Z"
      fill="#DD943C"
    />
    <path
      d="M480.078 559.888C480.078 559.888 482.019 558.976 482.799 556.792C483.579 554.609 482.019 542.987 483.735 538.464C485.451 533.94 487.089 529.416 495.824 526.998C504.56 524.58 513.763 529.572 516.259 533.16C518.755 536.748 519.613 545.093 518.911 547.433C518.209 549.773 516.415 553.907 515.089 554.843C513.763 555.778 512.125 560.38 511.579 562.252C511.033 564.124 508.849 567.088 503.468 567.4C498.086 567.712 495.834 570.047 495.868 571.18C495.902 572.314 480.01 564.232 480.078 559.888Z"
      fill="#D6AF8C"
    />
    <path
      d="M424.537 589.16C424.537 589.16 418.765 592.202 413.149 596.18C413.149 596.18 409.25 594.854 404.96 595.166C401.66 595.406 398.174 595.4 395.21 596.414C395.21 596.414 395.132 602.029 395.21 603.121C395.288 604.213 409.64 604.681 412.759 606.085C415.879 607.489 431.946 601.951 431.946 601.951C431.946 601.951 427.423 589.238 424.537 589.16Z"
      fill="#CAA686"
    />
    <path
      d="M457.059 607.566C457.059 607.566 477.719 603.706 486.923 593.878C496.126 584.051 496.759 574.418 496.603 570.362C496.447 566.307 482.096 558.117 482.096 558.117C482.096 558.117 470.708 566.541 448.09 571.22C448.09 571.22 462.597 586.196 457.059 607.566Z"
      fill="#D6AF8C"
    />
    <path
      d="M455.035 569.111C455.035 569.111 465.33 581.255 461.634 607.815C461.634 607.815 475.409 604.934 480.804 599.731C487.271 593.495 494.2 580.1 496.279 572.478C496.279 572.478 492.63 560.775 481.752 557.734C481.752 557.734 460.908 565.514 455.035 569.111Z"
      fill="#F2A226"
    />
    <path
      d="M449.656 571.302C449.656 571.302 447.423 570.395 445.5 570.639C440.183 571.314 430.618 574.191 426.442 578.833C420.757 585.153 423.48 592.567 423.88 596.624C424.266 600.541 431.307 609.086 455.348 609.188C456.091 609.191 457.77 608.91 458.349 608.447C458.475 608.346 458.631 608.296 458.786 608.247C459.057 608.162 459.555 606.897 459.204 598.152C458.573 582.432 449.656 571.302 449.656 571.302Z"
      fill="#F2A226"
    />
    <path
      d="M427.262 601.326C427.262 601.326 425.624 598.596 428.12 593.839C430.616 589.081 436.777 583.855 443.719 584.791C450.661 585.727 451.519 598.518 451.051 602.574C450.583 606.63 449.803 607.254 448.633 608.19C447.463 609.126 434.984 612.947 427.262 601.326Z"
      fill="#D6AF8C"
    />
    <path
      d="M416.814 603.589C416.814 603.589 414.045 602.146 412.953 601.912C411.861 601.678 409.522 601.834 408.742 604.33C407.962 606.826 405.934 609.556 404.842 610.804C403.75 612.052 403.165 612.948 403.516 614.08C403.925 615.397 404.725 615.054 404.725 615.054C404.725 615.054 404.374 616.731 404.998 617.355C405.622 617.979 406.48 617.667 406.48 617.667C406.48 617.667 406.634 619.162 407.181 619.461C408.039 619.929 408.312 619.266 408.312 619.266C408.312 619.266 408.975 622.425 411.471 620.475C412.993 619.286 413.499 616.342 415.917 613.924C418.335 611.506 426.095 611.779 432.179 612.325C438.262 612.871 440.68 614.587 443.956 612.091C447.232 609.595 447.934 607.021 448.558 603.043C449.182 599.065 444.736 587.288 444.736 587.288C444.736 587.288 437.638 584.792 431.087 591.656C431.087 591.656 430.424 596.141 431.36 599.572C431.36 599.572 423.717 600.352 416.814 603.589Z"
      fill="#D6AF8C"
    />
    <path
      d="M395.209 596.412C395.209 596.412 393.103 596.802 391.075 595.944C389.047 595.086 387.565 599.453 384.446 602.261C381.326 605.069 380 606.629 380 607.565C380 608.501 381.248 607.799 381.248 607.799C381.248 607.799 380.546 608.501 381.17 609.203C381.794 609.905 382.964 609.047 382.964 609.047C382.964 609.047 382.886 610.373 383.588 610.763C384.289 611.153 384.992 610.763 384.992 610.763C384.992 610.763 385.381 611.465 386.084 611.543C386.786 611.621 387.331 611.153 387.331 611.153C387.331 611.153 389.671 607.487 392.167 605.927C394.663 604.367 395.537 603.934 396.816 603.722C398.094 603.509 397.471 597.582 395.209 596.412Z"
      fill="#CAA686"
    />
    <path
      d="M406.115 609.054C406.008 609.202 405.903 609.345 405.799 609.484C405.785 609.505 405.77 609.524 405.756 609.545C405.53 609.88 405.331 610.232 405.129 610.583C405.512 610.124 406.266 609.179 407.02 608.004C406.706 608.344 406.398 608.689 406.115 609.054Z"
      fill="#999999"
    />
    <path
      d="M445.453 588.698L445.368 588.455L445.244 588.68C445.209 588.743 445.175 588.807 445.141 588.871L445.117 588.916L445.134 588.963C446.264 592.179 448.84 599.987 448.365 603.013C448.082 604.821 447.766 606.572 447.033 608.129L446.951 608.303L447.143 608.293C447.201 608.29 447.259 608.288 447.317 608.287L447.389 608.286L447.419 608.22C448.148 606.639 448.464 604.883 448.748 603.072C449.247 599.889 446.61 591.959 445.453 588.698Z"
      fill="#BCA087"
    />
    <path
      d="M431.505 599.362C431.031 597.512 431.014 595.36 431.085 593.861L431.102 593.51L430.88 593.782C430.821 593.855 430.765 593.93 430.71 594.007L430.69 594.034L430.689 594.069C430.631 595.509 430.66 597.59 431.115 599.405C429.854 599.558 422.999 600.495 416.816 603.373C413.767 601.63 412.695 602.756 416.723 603.76L416.808 603.805L416.894 603.764C423.721 600.563 431.302 599.772 431.378 599.764L431.469 599.755C431.967 599.903 433.937 600.518 435.988 601.577L436.091 601.63L436.268 601.286L436.166 601.233C433.954 600.091 431.868 599.467 431.505 599.362Z"
      fill="#BCA087"
    />
    <path
      d="M451.699 608.892C451.699 608.892 453.316 604.524 453.004 599.454L457.761 599.064C457.761 599.064 457.606 604.992 456.747 608.424C456.747 608.424 454.997 610.14 451.699 608.892Z"
      fill="#DD943C"
    />
    <path
      d="M456.868 598.209C455.459 586.773 452.681 579.804 450.598 575.971C448.344 571.822 446.402 570.358 446.383 570.344L446.429 570.281C446.449 570.296 448.405 571.77 450.667 575.934C452.753 579.773 455.535 586.752 456.946 598.2L456.868 598.209Z"
      fill="#DD943C"
    />
    <path
      d="M425.631 593.495C425.613 593.429 425.579 593.372 425.531 593.323C425.505 593.303 425.478 593.283 425.452 593.263C425.391 593.227 425.325 593.21 425.255 593.209L425.175 593.209C425.122 593.207 425.072 593.218 425.025 593.243C424.977 593.259 424.935 593.286 424.9 593.323C424.862 593.359 424.835 593.4 424.819 593.449C424.795 593.496 424.783 593.546 424.785 593.599C424.79 593.634 424.795 593.669 424.799 593.703C424.818 593.769 424.851 593.826 424.9 593.875C424.926 593.896 424.952 593.916 424.978 593.936C425.039 593.971 425.105 593.989 425.175 593.989L425.255 593.989C425.309 593.991 425.359 593.98 425.405 593.956C425.454 593.94 425.495 593.913 425.531 593.875C425.569 593.84 425.596 593.798 425.611 593.749C425.635 593.703 425.647 593.653 425.645 593.599C425.64 593.564 425.636 593.53 425.631 593.495Z"
      fill="white"
    />
    <path
      d="M430.033 588.271C429.531 588.271 429.53 589.052 430.033 589.052C430.535 589.052 430.536 588.271 430.033 588.271Z"
      fill="white"
    />
    <path
      d="M425.013 585.404C424.512 585.404 424.511 586.184 425.013 586.184C425.515 586.184 425.516 585.404 425.013 585.404Z"
      fill="white"
    />
    <path
      d="M432.141 577.998C432.125 577.949 432.098 577.907 432.06 577.872C432.025 577.834 431.983 577.807 431.935 577.792C431.888 577.767 431.838 577.756 431.785 577.758C431.75 577.763 431.716 577.767 431.681 577.772C431.615 577.791 431.558 577.824 431.509 577.872C431.489 577.899 431.468 577.925 431.448 577.951C431.413 578.012 431.395 578.078 431.395 578.148L431.395 578.228C431.393 578.281 431.404 578.331 431.428 578.378C431.444 578.426 431.471 578.468 431.509 578.503C431.544 578.541 431.586 578.568 431.634 578.584C431.681 578.608 431.731 578.619 431.785 578.617C431.819 578.613 431.854 578.608 431.888 578.603C431.955 578.585 432.012 578.552 432.061 578.503C432.081 578.477 432.101 578.45 432.122 578.424C432.157 578.363 432.175 578.298 432.175 578.228L432.175 578.148C432.177 578.094 432.165 578.044 432.141 577.998Z"
      fill="white"
    />
    <path
      d="M435.266 582.67C435.236 582.577 435.177 582.482 435.087 582.437C435.034 582.41 434.981 582.384 434.928 582.357C434.834 582.31 434.731 582.289 434.627 582.318C434.537 582.343 434.439 582.413 434.394 582.497C434.346 582.588 434.322 582.697 434.355 582.798C434.385 582.891 434.444 582.985 434.534 583.031C434.587 583.057 434.64 583.084 434.693 583.111C434.787 583.158 434.89 583.179 434.994 583.15C435.084 583.125 435.182 583.055 435.227 582.971C435.275 582.88 435.299 582.77 435.266 582.67Z"
      fill="white"
    />
    <path
      d="M445.065 582.664C445.046 582.597 445.013 582.54 444.965 582.491C444.938 582.471 444.912 582.451 444.886 582.43C444.825 582.395 444.759 582.377 444.689 582.377L444.609 582.377C444.556 582.375 444.505 582.386 444.459 582.411C444.411 582.427 444.369 582.454 444.334 582.491C444.295 582.527 444.268 582.568 444.253 582.617C444.228 582.663 444.217 582.713 444.219 582.767C444.224 582.802 444.228 582.836 444.233 582.871C444.251 582.937 444.285 582.994 444.334 583.043C444.36 583.063 444.386 583.083 444.412 583.104C444.473 583.139 444.539 583.157 444.609 583.157L444.689 583.157C444.742 583.159 444.792 583.148 444.839 583.123C444.887 583.107 444.929 583.08 444.964 583.043C445.003 583.008 445.029 582.966 445.045 582.917C445.069 582.871 445.081 582.821 445.079 582.767C445.074 582.733 445.07 582.698 445.065 582.664Z"
      fill="white"
    />
    <path
      d="M440.76 574.937C440.741 574.87 440.708 574.813 440.66 574.764C440.633 574.738 440.606 574.711 440.58 574.685C440.544 574.647 440.503 574.62 440.454 574.604C440.408 574.58 440.358 574.569 440.304 574.571C440.25 574.569 440.2 574.58 440.154 574.604C440.105 574.62 440.063 574.647 440.028 574.685C440.008 574.711 439.988 574.738 439.967 574.764C439.932 574.825 439.914 574.89 439.914 574.961C439.919 574.995 439.923 575.03 439.928 575.064C439.947 575.131 439.98 575.188 440.028 575.237C440.055 575.263 440.081 575.29 440.108 575.316C440.143 575.354 440.185 575.381 440.233 575.397C440.28 575.421 440.33 575.433 440.384 575.431C440.437 575.433 440.487 575.421 440.534 575.397C440.582 575.381 440.624 575.354 440.659 575.316C440.679 575.29 440.7 575.264 440.72 575.238C440.755 575.177 440.773 575.111 440.773 575.041C440.769 575.006 440.764 574.971 440.76 574.937Z"
      fill="white"
    />
    <path
      d="M448.231 573.617C448.215 573.568 448.188 573.527 448.15 573.491C448.115 573.453 448.073 573.426 448.025 573.411C447.978 573.387 447.928 573.375 447.874 573.377C447.84 573.382 447.805 573.386 447.771 573.391C447.705 573.41 447.647 573.443 447.599 573.491C447.579 573.518 447.558 573.544 447.538 573.57C447.503 573.631 447.485 573.697 447.485 573.767L447.485 573.847C447.483 573.9 447.494 573.95 447.518 573.997C447.534 574.045 447.561 574.087 447.599 574.122C447.634 574.16 447.676 574.187 447.724 574.203C447.771 574.227 447.821 574.239 447.874 574.237C447.909 574.232 447.944 574.227 447.978 574.222C448.044 574.204 448.101 574.171 448.15 574.122C448.17 574.096 448.191 574.069 448.211 574.043C448.246 573.982 448.264 573.917 448.264 573.846L448.264 573.767C448.266 573.713 448.255 573.663 448.231 573.617Z"
      fill="white"
    />
    <path
      d="M451.056 587.635C450.555 587.635 450.554 588.415 451.056 588.415C451.558 588.415 451.559 587.635 451.056 587.635Z"
      fill="white"
    />
    <path
      d="M454.662 591.492C454.636 591.439 454.609 591.386 454.582 591.333C454.54 591.249 454.438 591.178 454.349 591.154C454.256 591.128 454.132 591.14 454.049 591.193C453.966 591.247 453.892 591.327 453.87 591.426C453.865 591.461 453.86 591.495 453.855 591.53C453.856 591.6 453.873 591.666 453.909 591.727C453.935 591.78 453.962 591.833 453.988 591.886C454.031 591.97 454.133 592.041 454.222 592.065C454.315 592.091 454.439 592.079 454.522 592.026C454.605 591.972 454.679 591.892 454.701 591.793C454.706 591.758 454.711 591.724 454.715 591.689C454.715 591.619 454.697 591.553 454.662 591.492Z"
      fill="white"
    />
    <path
      d="M453.373 596.158C452.871 596.158 452.87 596.938 453.373 596.938C453.875 596.938 453.875 596.158 453.373 596.158Z"
      fill="white"
    />
    <path
      d="M450.342 579.43C449.84 579.43 449.839 580.21 450.342 580.21C450.844 580.21 450.844 579.43 450.342 579.43Z"
      fill="white"
    />
    <path
      d="M442.615 571.225C442.113 571.225 442.112 572.005 442.615 572.005C443.117 572.005 443.118 571.225 442.615 571.225Z"
      fill="white"
    />
    <path
      d="M431.705 574.65C431.203 574.65 431.202 575.43 431.705 575.43C432.207 575.43 432.207 574.65 431.705 574.65Z"
      fill="white"
    />
    <path
      d="M509.696 552.788C509.517 553.395 509.077 553.801 508.712 553.693C508.348 553.586 508.197 553.007 508.376 552.399C508.555 551.792 508.995 551.386 509.36 551.494C509.724 551.601 509.875 552.18 509.696 552.788Z"
      fill="#4C1200"
    />
    <path
      d="M514.386 554.686C514.386 554.686 514.386 557.181 514.776 558.195C515.166 559.209 513.606 559.131 512.124 558.897C510.642 558.663 514.386 554.686 514.386 554.686Z"
      fill="#D6AF8C"
    />
    <path
      d="M507.754 547.979C508.015 547.772 508.322 547.641 508.635 547.546C508.95 547.453 509.276 547.398 509.604 547.387C509.932 547.377 510.262 547.399 510.582 547.472C510.903 547.538 511.214 547.655 511.498 547.823C511.33 547.839 511.172 547.831 511.012 547.835C510.854 547.839 510.698 547.832 510.543 547.837C510.233 547.835 509.927 547.846 509.622 547.854C509.317 547.863 509.011 547.884 508.703 547.906L507.754 547.979Z"
      fill="#1D292B"
    />
    <path
      d="M512.391 563.385C512.002 564.707 511.268 565.656 510.751 565.504C510.235 565.352 510.132 564.156 510.521 562.834C510.91 561.512 511.645 560.563 512.161 560.715C512.678 560.867 512.781 562.062 512.391 563.385Z"
      fill="#732D47"
    />
    <path
      d="M512.121 562.016C512.121 562.016 511.341 562.874 511.42 564.043L512.2 564.433C512.2 564.433 512.634 563.937 512.894 562.832C512.952 562.588 512.828 562.337 512.6 562.233L512.121 562.016Z"
      fill="#732D47"
    />
    <path
      d="M510.721 573.003C510.542 573.003 510.329 572.933 510.093 572.72C509.331 572.031 509.401 570.202 510.309 567.128C510.834 565.347 511.5 563.667 511.738 563.081C511.787 562.96 511.926 562.903 512.046 562.954C512.164 563.004 512.22 563.139 512.172 563.257C511.937 563.834 511.277 565.499 510.756 567.264C509.657 570.99 510.066 572.064 510.407 572.373C510.649 572.593 510.777 572.532 510.825 572.509C511.711 572.091 512.278 567.726 512.122 564.446L512.512 563.889C512.634 566.456 512.37 572.297 511.025 572.933C510.942 572.972 510.839 573.003 510.721 573.003Z"
      fill="#DBB087"
    />
    <path
      d="M494.416 552.54C493.751 552.54 492.974 552.285 492.974 551.565C492.974 551.354 492.753 551.064 492.473 550.697C491.927 549.982 491.18 549.002 491.18 547.509C491.18 546.799 491.389 546.263 491.8 545.918C492.481 545.348 493.46 545.515 493.501 545.522L493.46 545.753C493.451 545.751 492.546 545.599 491.949 546.099C491.594 546.397 491.414 546.872 491.414 547.509C491.414 548.923 492.133 549.866 492.659 550.556C492.966 550.957 493.208 551.275 493.208 551.565C493.208 552.285 494.293 552.306 494.417 552.306L494.416 552.54Z"
      fill="#BA9371"
    />
    <path
      d="M493.055 547.001C493.055 547.001 492.587 546.845 492.665 547.781C492.743 548.717 493.211 548.717 493.289 549.342C493.367 549.965 493.757 550.043 493.757 550.043C493.757 550.043 493.913 548.639 493.991 547.625C494.069 546.612 493.055 547.001 493.055 547.001Z"
      fill="#BA9371"
    />
    <path
      d="M483.453 536.138C483.453 536.138 484.146 531.056 489.227 527.361C494.309 523.665 505.164 524.82 509.09 526.899C513.017 528.978 515.326 531.287 515.557 531.749C515.788 532.211 486.918 538.909 483.453 536.138Z"
      fill="#F2A226"
    />
    <path
      d="M509.789 536.137C509.789 536.137 511.868 532.211 513.023 532.211C514.177 532.211 515.563 532.904 516.256 533.135C516.949 533.366 516.025 537.292 515.101 537.754C514.178 538.216 510.713 538.909 509.789 536.137Z"
      fill="#F26458"
    />
    <path
      d="M512.042 540.789C512.042 540.789 517.145 543.949 518.305 547.812C518.305 547.812 518.597 544.231 517.726 542.85C516.855 541.468 516.855 541.468 516.855 541.468C516.855 541.468 520.022 544.839 520.172 545.015C520.321 545.191 520.776 542.549 520.147 541.197C518.81 538.324 515.114 535.836 512.042 540.789Z"
      fill="#1D292B"
    />
    <path
      d="M481.199 578.357C481.199 578.357 481.589 582.881 482.213 588.574C482.837 594.268 486.503 602.535 487.049 604.641C487.595 606.747 490.169 607.527 494.225 607.527C498.28 607.527 507.094 607.995 510.838 607.527C510.838 607.527 515.829 608.229 517.077 607.839C518.325 607.449 518.871 603.159 518.871 601.443C518.871 599.727 513.177 599.962 512.164 600.351C511.149 600.741 497.812 596.062 497.812 596.062C497.812 596.062 497.423 590.056 497.032 586.858C496.642 583.661 497.11 575.627 496.798 573.989C496.486 572.351 484.241 564.552 481.199 578.357Z"
      fill="#D6AF8C"
    />
    <path
      d="M492.457 597.227L492.402 597.081C492.436 597.068 495.795 595.829 497.817 595.984L497.805 596.14C495.817 595.986 492.49 597.215 492.457 597.227Z"
      fill="#999999"
    />
    <path
      d="M482.592 590.984L498.469 588.567C498.469 588.567 497.084 573.554 496.391 571.475C495.698 569.397 493.619 568.011 487.845 568.704C482.071 569.396 478.725 574.694 482.592 590.984Z"
      fill="#DD943C"
    />
    <path
      d="M482.533 547.685C482.533 547.685 500.086 541.449 508.401 540.987C514.744 540.635 517.502 540.632 518.453 540.65C518.661 540.654 518.814 540.458 518.759 540.257C518.315 538.616 516.653 532.515 516.254 531.518C515.792 530.363 483.688 534.52 482.764 535.213C481.84 535.906 481.609 537.523 482.071 539.139C482.533 540.757 481.609 545.145 482.533 547.685Z"
      fill="#DD943C"
    />
  </svg>
</template>

<script>
export default {
  name: "GetStartedUpToSixMonths"
};
</script>
