<template>
  <div v-show="show" class="radio-pills-question">
    <v-radio-group
      v-model="selectedAnswer"
      @change="emitToParent(selected)"
      :rules="rules"
      class="mt-0"
      hide-details
    >
      <div v-for="option in visibleOptions" v-bind:key="option.value">
        <v-radio :value="option.value"
          ><template v-slot:label>
            <span>{{ option.label }}</span>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioPillsQuestion",
  props: {
    errorMessage: String,
    name: String,
    question: String,
    optional: Boolean,
    options: Array,
    show: {
      default: true,
      type: Boolean
    },
    value: Number,
    fullQuestion: Object
  },
  data() {
    return { selected: null };
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.value;
      },
      set(selectedAnswer) {
        this.selected = selectedAnswer;
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t(this.fullQuestion.errorMessage)];
    },
    visibleOptions() {
      return this.processedOptions.filter(x => x.showAnswer);
    }
  },
  created() {
    this.processOptions();
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    },
    processOptions() {
      this.processedOptions = this.options.map(option => ({
        ...option,
        label: this.$t(option.label),
        title: this.$t(option.title)
      }));
    }
  }
};
</script>

<style scoped lang="scss">
$radio-text-color: var(--v-vflPrimary-base);
$radio-border-color: var(--v-vflGreyVeryLight-base);
$radio-border-color-active: var(--v-vflPrimary-base);
$radio-error-color: var(--v-error-base);

.radio-pills-question {
  ::v-deep .v-input--radio-group__input {
    column-gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.5rem;

    > div {
      display: contents;
    }
  }

  .v-radio {
    background-color: white;
    border: 1px solid $radio-border-color;
    border-radius: 8px;
    margin: 0;
    overflow: hidden;

    &:hover {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 98%);
    }

    &:has(:focus) {
      outline: 2px dashed $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(:checked) {
      outline: 2px solid $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(.error--text) {
      outline: 2px solid $radio-error-color;
      outline-offset: -1px;
    }

    ::v-deep .v-input--selection-controls__input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    ::v-deep label {
      align-items: initial;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      min-height: 40px;
      padding: 0 1rem;
      text-align: center;

      &.error--text {
        color: $radio-text-color !important;
      }
    }
  }
}

.v-radio.v-item--active {
  ::v-deep {
    label {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
    }
  }
}
</style>
