import LiftingAndCarryingRecommendations from "@/assets/json/recommendations/new-parent/LiftingAndCarryingRecommendations.json";
import Answers from "@/components/assessment/new-parent/answers.json";
import { LinkTypes } from "@/services/recommendations/link-types.js";

const hasToddler = results => {
  const oneOrOlder =
    results.getStarted.babyAge === Answers.getStarted.babyAge.moreThanAYear;
  return oneOrOlder;
};

const carryBabyOnNeck = results => {
  return results.carrying.babyCareRoutine?.includes(
    Answers.carrying.babyCareRoutine.carryBabyOnNeck
  );
};

const carryBabyOnSide = results => {
  return (
    results.carrying.babyHoldingSide ===
      Answers.carrying.babyHoldingSide.left ||
    results.carrying.babyHoldingSide === Answers.carrying.babyHoldingSide.right
  );
};

const carryingToddlerWhileMultitasking = results => {
  const multitasksWhileCarrying = results.carrying.babyCareRoutine?.includes(
    Answers.carrying.babyCareRoutine.holdingBabyOneArmAndMultitasking
  );
  return hasToddler(results) && multitasksWhileCarrying;
};

const cards = {
  new_parent_bend_your_knee_not_your_back: {
    showConditions: () => true
  },
  new_parent_squeeze_your_glute_when_lifting: {
    showConditions: () => true
  },
  new_parent_do_thumb_stretches: {
    showConditions: () => true
  },
  new_parent_wall_push_ups: {
    showConditions: hasToddler
  },
  new_parent_chin_tuck_exercise: {
    showConditions: carryBabyOnNeck
  },
  new_parent_switch_carrying_sides: {
    showConditions: carryBabyOnSide
  },
  new_parent_try_a_learning_tower: {
    showConditions: carryingToddlerWhileMultitasking
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCards = (cards, results) => {
  return Object.entries(cards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, LiftingAndCarryingRecommendations[cardId])
    );
};

export const getLiftingAndCarryingRecommendations = results => {
  const visibleCards = getVisibleCards(cards, results);

  return {
    groups: {
      returnToWork: {
        cards: visibleCards,
        hideTab: true,
        titleKey: "liftingAndCarrying"
      }
    }
  };
};
