import sentry from "@/sentry";
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import i18n from "@/plugins/i18n.js";
import { Conditions } from "@/components/common/pre-existing-conditions/conditionsAndAids.js";
import { AdminRolesToTextKeyMapping } from "@/constants/constants.js";
import store from "@/store";
import {
  EquipmentScoreHelper,
  HealthScoreHelper,
  WorkHabitsScoreHelper,
  DeskSetupScoreHelper,
  PainHelper,
  OverallScoreHelper
} from "@/components/assessment/desk/results-helpers/results-helper.js";

function getIntroText(companyRole) {
  let text = "I am this person's employer. ";

  if (companyRole) {
    const textKey = AdminRolesToTextKeyMapping[companyRole];

    if (textKey) {
      text += `My role is ${i18n.t(`productRoles.${textKey}`)}.`;
    }
  }

  text += "\nThis is the person's report:";

  return text;
}

function getOverallScoreText(assessment, results) {
  try {
    let score = assessment.score;

    if (typeof score !== "number" || isNaN(score)) {
      score = results.savedScore;
    }

    if (typeof score !== "number" || isNaN(score)) {
      throw new Error("Invalid score");
    }

    return `Overall workplace assessment score: ${score}%`;
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getIndustryAverageText(assessment, results) {
  try {
    let score = assessment.score;

    if (typeof score !== "number" || isNaN(score)) {
      score = results.savedScore;
    }

    if (typeof score !== "number" || isNaN(score)) {
      throw new Error("Invalid score");
    }

    const industryAverage = 71;
    const difference = score - industryAverage;
    const absoluteDifference = Math.abs(difference);

    if (difference === 0) {
      return `This score is equal to the industry average of ${industryAverage}%.`;
    } else {
      const comparisonWord = difference > 0 ? "higher" : "lower";
      return `This score is ${absoluteDifference}% ${comparisonWord} than the industry average of ${industryAverage}%.`;
    }
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getSetUpScore(results) {
  try {
    const utilityService = getUtilityService();

    return utilityService.getStandaloneSetupPercentageScore(results);
  } catch (error) {
    sentry.captureException(error);
    return null;
  }
}

function getHabitsScore(results) {
  try {
    const utilityService = getUtilityService("habits");

    return utilityService.getPercentageScore(
      utilityService.calculateWorkHabitsScore(results),
      utilityService.maxWorkHabitsScore
    );
  } catch (error) {
    sentry.captureException(error);
    return null;
  }
}

function getHealthScore(results) {
  try {
    if (!results.health) return null;

    const incompleteHealthSection =
      !results.health?.smoker ||
      results.health?.exercise === null ||
      results.health?.exercise === undefined;

    if (incompleteHealthSection) return null;

    const utilityService = getUtilityService("health");

    return utilityService.getPercentageScore(
      utilityService.calculateHealthScore(results),
      utilityService.maxHealthScore
    );
  } catch (error) {
    sentry.captureException(error);
    return null;
  }
}

function getPostureScore(results) {
  if (!results.webcam || !results.webcam.pictureTaken) {
    return null;
  }
  try {
    const utilityService = getUtilityService("setup");

    return utilityService.getPercentageScore(
      utilityService.calculateDeskSetupScore(results),
      utilityService.getMaxWebcamScore(results)
    );
  } catch (error) {
    sentry.captureException(error);
    return null;
  }
}

function getAllScoresText(results) {
  try {
    const habitsScore = getHabitsScore(results);
    const setupScore = getSetUpScore(results);
    const healthScore = getHealthScore(results);
    const postureScore = getPostureScore(results);

    const scoreLines = [
      habitsScore !== null ? `Habits: ${habitsScore}%` : "",
      setupScore !== null ? `Set up: ${setupScore}%` : "",
      healthScore !== null ? `Health: ${healthScore}%` : "",
      postureScore !== null ? `Posture: ${postureScore}%` : ""
    ].filter(line => line !== "");

    return scoreLines.length > 0 ? `Insights\n${scoreLines.join("\n")}` : "";
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getPainText(results) {
  try {
    if (!results?.pain?.areas)
      return "This person has not reported any musculoskeletal pain";

    const workpainOptions = {
      1: "straightAway",
      2: "fewHours",
      3: "endOfDay",
      4: "notAffected"
    };

    let painInfo = "";
    const workpain = results.setup?.workpain;
    const workpainLabel = workpainOptions[workpain] || "";

    for (const [area, details] of Object.entries(results.pain.areas)) {
      try {
        const areaText = i18n.t(`reportText.pain.painAreaNames.${area}.text`);
        const painLevel = details.level;
        if (typeof painLevel !== "number") {
          throw new Error(`Invalid pain level for ${area}: ${painLevel}`);
        }
        painInfo += `Pain in ${areaText}\nPain rated ${painLevel}/10\n\n`;
      } catch (error) {
        sentry.captureException(error);
      }
    }

    if (painInfo && workpainLabel) {
      const workRelationText =
        {
          straightAway: "Pain starts straight away during work",
          fewHours: "Pain starts after a few hours of work",
          endOfDay: "Pain starts at the end of the work day",
          notAffected: "Work does not affect pain"
        }[workpainLabel] || "Relationship between work and pain is unclear";

      painInfo += `${workRelationText}\n`;
    }

    return painInfo.trim();
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getHealthConditionsText(healthConditions) {
  try {
    if (!Array.isArray(healthConditions) || healthConditions.length === 0) {
      return "";
    }

    const conditionsMap = new Map(
      Conditions.map(condition => [condition.id, condition.text])
    );

    const conditions = healthConditions
      .map(id => {
        const text = conditionsMap.get(id);
        return text ? `- ${i18n.t(text)}` : null;
      })
      .filter(Boolean)
      .join("\n");

    return conditions
      ? `This person has the following health conditions:\n${conditions}`
      : "";
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getWorkspaceItemsText(results) {
  try {
    if (!results?.setup?.deskItems || !Array.isArray(results.setup.deskItems)) {
      return "";
    }

    if (!results.setup?.deskItems.length) return "";

    const itemCounts = results.setup.deskItems.reduce((counts, item) => {
      counts[item.id] = (counts[item.id] || 0) + 1;
      return counts;
    }, {});

    const setupText = Object.entries(itemCounts)
      .map(([item, count]) => {
        let itemText = "";

        switch (item) {
          case "beanBag":
            itemText = `bean bag${count > 1 ? "s" : ""}`;
            break;
          case "cat":
            itemText = `cat${count > 1 ? "s" : ""}`;
            break;
          case "chairNoArmrest":
            itemText = `chair${count > 1 ? "s" : ""} without armrest`;
            break;
          case "chairWithArmrest":
            itemText = `chair${count > 1 ? "s" : ""} with armrest`;
            break;
          case "clutter":
            itemText = `area${count > 1 ? "s" : ""} of clutter`;
            break;
          case "computerScreen":
            itemText = `computer screen${count > 1 ? "s" : ""}`;
            break;
          case "computerScreenUltraWide":
            itemText = `ultra-wide computer screen${count > 1 ? "s" : ""}`;
            break;
          case "computerScreenVertical":
            itemText = `vertical computer screen${count > 1 ? "s" : ""}`;
            break;
          case "dog":
            itemText = `dog${count > 1 ? "s" : ""}`;
            break;
          case "ergonomicKeyboard":
            itemText = `ergonomic keyboard${count > 1 ? "s" : ""}`;
            break;
          case "ergonomicMouse":
            itemText = `ergonomic mouse${count > 1 ? "mice" : ""}`;
            break;
          case "gymBall":
            itemText = `gym ball${count > 1 ? "s" : ""}`;
            break;
          case "headphones":
            itemText = `pair${count > 1 ? "s" : ""} of headphones`;
            break;
          case "keyboard":
            itemText = `keyboard${count > 1 ? "s" : ""}`;
            break;
          case "kneelingChair":
            itemText = `kneeling chair${count > 1 ? "s" : ""}`;
            break;
          case "lamp":
            itemText = `lamp${count > 1 ? "s" : ""}`;
            break;
          case "laptop":
            itemText = `laptop${count > 1 ? "s" : ""} without stand`;
            break;
          case "laptopClosed":
            itemText = `closed laptop${count > 1 ? "s" : ""}`;
            break;
          case "laptopWithStand":
            itemText = `laptop${count > 1 ? "s" : ""} with stand`;
            break;
          case "microDesk":
            itemText = `micro desk${count > 1 ? "s" : ""}`;
            break;
          case "mobilephone":
            itemText = `mobile phone${count > 1 ? "s" : ""}`;
            break;
          case "mouse":
            itemText = `mouse${count > 1 ? "mice" : ""}`;
            break;
          case "notepad":
            itemText = `notepad${count > 1 ? "s" : ""}`;
            break;
          case "officephone":
            itemText = `office phone${count > 1 ? "s" : ""}`;
            break;
          case "plants":
            itemText = `plant${count > 1 ? "s" : ""}`;
            break;
          case "rollerMouse":
            itemText = `roller mouse${count > 1 ? "mice" : ""}`;
            break;
          case "saddleChair":
            itemText = `saddle chair${count > 1 ? "s" : ""}`;
            break;
          case "stackOfPaper":
            itemText = `stack${count > 1 ? "s" : ""} of paper`;
            break;
          case "stool":
            itemText = `stool${count > 1 ? "s" : ""}`;
            break;
          case "tea":
            itemText = `tea cup${count > 1 ? "s" : ""}`;
            break;
          case "trackpad":
            itemText = `trackpad${count > 1 ? "s" : ""}`;
            break;
          case "water":
            itemText = `water bottle${count > 1 ? "s" : ""}`;
            break;
          case "wheelchair":
            itemText = `wheelchair${count > 1 ? "s" : ""}`;
            break;
          case "window":
            itemText = `window${count > 1 ? "s" : ""}`;
            break;
          case "wristRest":
            itemText = `wrist rest${count > 1 ? "s" : ""}`;
            break;
          default:
            itemText = `${item}${count > 1 ? "s" : ""}`;
        }

        return `${count} ${itemText}`;
      })
      .join("\n");

    return `This person has:\n${setupText}`;
  } catch (error) {
    return "";
  }
}

function getWorkspaceArea(results) {
  try {
    if (!results?.setup?.workarea) return "";

    const workareaOptions = {
      1: "desk",
      2: "sofa",
      3: "bed",
      4: "phone booth"
    };

    const locationLabel = workareaOptions[results.setup.workarea];
    return locationLabel
      ? `This person is working from a ${locationLabel}.`
      : "";
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getSetupText(results) {
  try {
    if (!results?.setup) {
      return "No setup information available.";
    }

    const { setup } = results;
    const details = [];

    const addYesNoDetail = (value, yesText, noText) => {
      if (value === 1) {
        details.push(yesText);
      } else if (value === 2) {
        details.push(noText);
      }
    };

    // Working location
    const workingLocation = {
      1: "This person is working from home.",
      2: "This person is working from the office.",
      3: "This person is not at their usual workspace."
    };
    if (workingLocation[setup.workingfromhome]) {
      details.push(workingLocation[setup.workingfromhome]);
    }

    // Hours worked
    if (setup.hours) {
      details.push(`They are working for ${setup.hours} hours per day.`);
    }

    // Breaks and lunch
    addYesNoDetail(
      setup.breaks,
      "They take regular breaks.",
      "They don't take regular breaks."
    );

    const breakActivity = {
      1: "They are physically active during breaks.",
      2: "They are not physically active during breaks.",
      3: "They are sometimes physically active during breaks."
    };
    if (breakActivity[setup.breakActivity]) {
      details.push(breakActivity[setup.breakActivity]);
    }

    const lunch = {
      1: "They take their lunch away from their desk.",
      2: "They don't take their lunch away from their desk.",
      3: "They sometimes take their lunch away from their desk."
    };
    if (lunch[setup.lunch]) {
      details.push(lunch[setup.lunch]);
    }

    // Temperature and noise
    const tempDescriptions = [
      "freezing",
      "chilly",
      "comfortable",
      "warm",
      "too hot"
    ];
    if (setup.temperature !== undefined) {
      const tempIndex = setup.temperature + 2; // Adjust for -2 to 2 range
      if (tempIndex >= 0 && tempIndex < tempDescriptions.length) {
        details.push(
          `The temperature in their workspace is ${tempDescriptions[tempIndex]}.`
        );
      }
    }

    const noiseDescriptions = [
      "very quiet",
      "library-like",
      "normal conversation level",
      "coffee shop level",
      "vacuum cleaner level",
      "heavy traffic level"
    ];
    if (setup.noise !== undefined) {
      const noiseIndex = setup.noise + 1; // Adjust for -1 to 4 range
      if (noiseIndex >= 0 && noiseIndex < noiseDescriptions.length) {
        details.push(
          `The noise level in their workspace is ${noiseDescriptions[noiseIndex]}.`
        );
      }
    }

    // Equipment
    const equipmentChecks = [
      {
        prop: "chair",
        text: "They have a chair.",
        noText: "They don't have a chair."
      },
      {
        prop: "armrests",
        text: "Their chair has armrests.",
        noText: "Their chair doesn't have armrests."
      },
      {
        prop: "laptop",
        text: "They are using a laptop.",
        noText: "They are not using a laptop."
      },
      {
        prop: "dockingstation",
        text: "They have a docking station.",
        noText: "They don't have a docking station."
      },
      {
        prop: "separatemousekeyboard",
        text: "They have a separate mouse and keyboard.",
        noText: "They don't have a separate mouse and keyboard."
      },
      {
        prop: "wristrest",
        text: "They have a wrist rest.",
        noText: "They don't have a wrist rest."
      },
      {
        prop: "phonecalls",
        text: "They use a phone for calls frequently.",
        noText: "They don't use a phone for calls frequently."
      },
      {
        prop: "headset",
        text: "They have a headset.",
        noText: "They don't have a headset."
      },
      {
        prop: "water",
        text: "They have water at their desk.",
        noText: "They don't have water at their desk."
      },
      {
        prop: "naturallight",
        text: "They have natural light in their workspace.",
        noText: "They don't have natural light in their workspace."
      },
      {
        prop: "plants",
        text: "They have plants in their workspace.",
        noText: "They don't have plants in their workspace."
      },
      {
        prop: "clutter",
        text: "Their workspace is cluttered.",
        noText: "Their workspace is not cluttered."
      },
      {
        prop: "relaxedarms",
        text: "Their wrists are straight when typing.",
        noText: "Their wrists are not straight when typing."
      },
      {
        prop: "wristspace",
        text: "They have space to rest their wrists.",
        noText: "They don't have space to rest their wrists."
      },
      {
        prop: "easytoreach",
        text: "Items are easy to reach.",
        noText: "Items are not easy to reach."
      },
      {
        prop: "feetsupported",
        text: "Their feet are supported.",
        noText: "Their feet are not supported."
      },
      {
        prop: "kneeangle",
        text: "Their legs are parallel to the floor.",
        noText: "Their legs are not parallel to the floor."
      },
      {
        prop: "standingdesk",
        text: "They have a standing desk.",
        noText: "They don't have a standing desk."
      }
    ];

    equipmentChecks.forEach(check => {
      addYesNoDetail(setup[check.prop], check.text, check.noText);
    });

    // Standing desk hours
    if (setup.standingdesk === 1 && setup.hoursstandingdesk) {
      details.push(
        `They use their standing desk for ${setup.hoursstandingdesk} hours per day.`
      );
    }

    return details.join(" ");
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function scrapePanelTextFromReport(panelId) {
  try {
    const panel = document.querySelector(`#${panelId}`);
    if (!panel) return "";

    const cards = Array.from(panel.querySelectorAll("[data-report-context]"));
    if (cards.length === 0) return "";

    const extractedText = cards
      .flatMap(card => Array.from(card.querySelectorAll("p")))
      .map(p => p.textContent.trim())
      .filter(Boolean)
      .join("\n\n");

    return extractedText
      ? `The following information was presented to and read by this person:\n\n${extractedText}\n\n--- End of information read by employee ---\n`
      : "";
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function scrapeRecommendationsPanelToJSON(panelId) {
  try {
    const panel = document.querySelector(`#${panelId}`);
    if (!panel) return "";

    const cards = Array.from(panel.querySelectorAll("[data-report-context]"));
    if (cards.length === 0) return "";

    const recommendations = cards.map(card => {
      const title =
        card
          .querySelector("[data-recommendation-card-heading]")
          ?.textContent.trim() || "";
      const subtitle =
        card
          .querySelector("[data-recommendation-card-subheading]")
          ?.textContent.trim() || "";
      const description =
        card
          .querySelector("[data-recommendation-card-explanation]")
          ?.textContent.trim() || "";

      return {
        title,
        subtitle,
        description
      };
    });

    const jsonOutput = JSON.stringify(
      {
        message:
          "The following recommendations were provided to this person. They are listed by priority:",
        recommendations
      },
      null,
      2
    );

    return jsonOutput;
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

export function generateContextFromReport(
  assessment,
  results,
  healthConditions,
  companyRole
) {
  try {
    if (!assessment || typeof assessment !== "object") {
      const error = new Error("Invalid assessment object");
      sentry.captureException(error);

      return;
    }

    if (!results || typeof results !== "object") {
      const error = new Error("Invalid results object");
      sentry.captureException(error);

      return;
    }

    const sections = [
      getIntroText(companyRole),
      getOverallScoreText(assessment, results),
      getIndustryAverageText(assessment, results),
      getAllScoresText(results),
      getPainText(results),
      getHealthConditionsText(healthConditions),
      getWorkspaceArea(results),
      getWorkspaceItemsText(results),
      getSetupText(results),
      scrapePanelTextFromReport("screenAssessmentPanel"),
      scrapePanelTextFromReport("setupPanel"),
      scrapeRecommendationsPanelToJSON("recommendationsPanel"),
      scrapeRecommendationsPanelToJSON("fixedRecommendationsPanel")
    ];

    return sections.filter(Boolean).join("\n\n");
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getUtilityService(type = "overall") {
  const isV2 = store.getters["deskAssessmentSchemaStore/schemaVersion"];
  if (!isV2) return DeskAssessmentUtil;

  switch (type) {
    case "equipment":
      return EquipmentScoreHelper;
    case "health":
      return HealthScoreHelper;
    case "habits":
      return WorkHabitsScoreHelper;
    case "setup":
      return DeskSetupScoreHelper;
    case "pain":
      return PainHelper;
    case "overall":
    default:
      return OverallScoreHelper;
  }
}
