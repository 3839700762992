<template>
  <div class="page-header">
    <div ref="sentinel"></div>
    <header
      class="sticky-header py-2 d-flex"
      :class="[{ 'is-scrolled': isScrolled }]"
    >
      <div
        class="d-flex align-center"
        :class="$vuetify.breakpoint.xs ? 'px-4' : 'px-8'"
      >
        <h1 v-if="title">{{ title }}</h1>
        <back-button v-if="showBackButton" class="ml-n2" />
        <page-header-actions v-if="showPageActions" class="ml-auto" />
      </div>
    </header>
  </div>
</template>

<script>
import PageHeaderActions from "@/components/layout/PageHeaderActions.vue";
import { mapGetters } from "vuex";
import BackButton from "@/components/layout/BackButton.vue";

export default {
  name: "PageHeader",
  components: { BackButton, PageHeaderActions },
  props: {
    title: String
  },
  data() {
    return {
      isScrolled: false,
      observer: null
    };
  },
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapGetters("layout", ["backButton"]),
    showPageActions() {
      return (
        !this.$route.meta?.hideActionMenuInTopBar &&
        this.signedIn &&
        this.$vuetify.breakpoint.mdAndUp
      );
    },
    showBackButton() {
      return this.backButton.show;
    }
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      };

      this.observer = new IntersectionObserver(([entry]) => {
        this.isScrolled = !entry.isIntersecting;
      }, options);

      this.observer.observe(this.$refs.sentinel);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  display: contents;
}

.sticky-header {
  background-color: white;
  min-height: 64px;
  position: sticky;
  top: 0;
  z-index: 20;

  &.is-scrolled {
    box-shadow: 0 0 2px 2px hsla(0, 0%, 0%, 0.075);
  }

  > div {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
  }
}
</style>
