export default {
  prioritisedDeskRecommendations_iHRsdr: [
    "pain_coach",
    "breakIdeas",
    "breaks",
    "standingBreaks",
    "lunch",
    "fresh_air",
    "natural_light",
    "clutter"
  ],
  prioritisedDeskRecommendations_wltNL: ["employee_services_wltNL"],
  prioritisedDeskRecommendations_beCsMA: [
    "pain_coach",
    "breakIdeas_beCsMA",
    "breaks_beCsMA",
    "standingBreaks_beCsMA",
    "lunch_beCsMA",
    "fresh_air_beCsMA",
    "natural_light_beCsMA",
    "clutter_beCsMA"
  ],
  prioritisedDeskRecommendations_KcaRDa: [
    "pain_coach",
    "occupational_health_KcaRDa"
  ],
  prioritisedDeskRecommendations_AwEATt: [
    "pain_coach",
    "eye_test_AwEATt",
    "health_condition_AwEATt"
  ],
  prioritisedDeskRecommendations_UjeUTz: [
    "pain_coach",
    "ergonomicReferral_UjeUTz"
  ],
  prioritisedDeskRecommendations_HloNsE: [
    "pain_coach",
    "equipmentRequests_HloNsE",
    "furnitureRequests_HloNsE"
  ],
  prioritisedHabitRecommendations: [
    "pain_coach",
    "breakIdeas",
    "breaks",
    "standingBreaks",
    "lunch",
    "fresh_air",
    "natural_light",
    "clutter"
  ],
  prioritisedDeskRecommendations_ShaneDemoTeam: ["pain_coach"]
};
