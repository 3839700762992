﻿import i18n from "@/plugins/i18n";
import BodyAreas from "@/components/painareas/body-areas.json";
import PainAreas from "@/components/painareas/PainAreasMale.json";
import {
  BACK_PAIN_AREAS,
  LOWER_LIMB_AREAS,
  UPPER_LIMB_AREAS,
  WRIST_AREAS
} from "@/services/assessment/pain/constants.js";

export default {
  hasPain(results) {
    if (!results?.pain?.areas) {
      return false;
    }
    const areas = Object.values(results.pain.areas);
    return areas.length > 0 && areas.some(area => area.level > 0);
  },
  hasPainArea(results, area) {
    if (!results?.pain?.areas) {
      return false;
    }
    return Object.keys(results.pain.areas).some(a => a.includes(area));
  },
  getPainAreaGroup(area) {
    return PainAreas[area].group;
  },
  hasSomePainAreas(results, areas) {
    const painAreas = Object.keys(results.pain.areas);
    return areas.some(a => painAreas.some(pa => pa.includes(a)));
  },
  getRelevantPainAreas(results, areas) {
    const validAreas = Object.keys(results.pain.areas).filter(k =>
      areas.some(a => k.includes(a))
    );
    const painAreas = [];
    validAreas.forEach(a => {
      const area = results.pain.areas[a];
      painAreas.push({ area: a, level: area.level });
    });
    return painAreas;
  },
  getHighestRelevantPainLevel(results, areas) {
    const validAreas = this.getRelevantPainAreas(results, areas);
    if (validAreas.length === 0) {
      return 0;
    }

    return Math.max(...validAreas.map(x => x.level));
  },
  hasPainAreaAnswer(results, areas, question, answers) {
    const painAreas = Object.keys(results.pain.areas);
    return painAreas.some(a => {
      const hasArea = areas.some(ar => a.includes(ar));
      if (!hasArea) {
        return false;
      }
      return answers.some(ans => results.pain.areas[a][question] === ans);
    });
  },
  hasBackPain(results) {
    return this.hasSomePainAreas(results, BACK_PAIN_AREAS);
  },
  hasUpperLimbPain(results) {
    return this.hasSomePainAreas(results, UPPER_LIMB_AREAS);
  },
  hasLowerLimbPain(results) {
    return this.hasSomePainAreas(results, LOWER_LIMB_AREAS);
  },
  hasWristPain(results) {
    return this.hasSomePainAreas(results, WRIST_AREAS);
  },
  getAreaText(area) {
    return i18n.t(BodyAreas[area].shortenedName).toLowerCase();
  },
  getAreasText(areas) {
    const areaNames = areas.map(area => this.getAreaText(area));
    const uniqueAreas = Array.from(new Set(areaNames));

    const connectorString = i18n.t("form.generator.connector");
    if (uniqueAreas.length === 1) {
      return uniqueAreas[0];
    } else if (uniqueAreas.length >= 2) {
      const lastArea = uniqueAreas.pop();
      return `${uniqueAreas.join(", ")} ${connectorString} ${lastArea}`;
    }

    return "";
  },
  getAllPainAreasText(results) {
    const areaNames = Object.keys(results.pain?.areas).map(area => {
      return i18n.t(BodyAreas[area].shortenedName).toLowerCase();
    });
    const uniqueAreas = Array.from(new Set(areaNames));

    const connectorString = i18n.t("form.generator.connector");
    if (uniqueAreas.length === 1) {
      return uniqueAreas[0];
    } else if (uniqueAreas.length >= 2) {
      const lastArea = uniqueAreas.pop();
      return `${uniqueAreas.join(", ")} ${connectorString} ${lastArea}`;
    }

    return "";
  },
  getBackPainAreas(results) {
    return BACK_PAIN_AREAS.filter(area => this.hasPainArea(results, area));
  },
  getUpperLimbPainAreas(results) {
    return UPPER_LIMB_AREAS.filter(area => this.hasPainArea(results, area));
  },
  getLowerLimbPainAreas(results) {
    return LOWER_LIMB_AREAS.filter(area => this.hasPainArea(results, area));
  },
  getWristPainAreas(results) {
    return WRIST_AREAS.filter(area => this.hasPainArea(results, area));
  },
  hasPainAreaFromValues(values, areas) {
    if (!values) return false;

    const areaValues = Object.keys(BodyAreas)
      .filter(a => areas.some(area => a.includes(area)))
      .map(a => BodyAreas[a].value);
    return values.some(v => areaValues.includes(v));
  },
  getPainAreasFromResults(results) {
    return Object.entries(results?.pain?.areas || {}).filter(
      ([_, area]) => area.level > 0
    );
  }
};
